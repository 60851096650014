import React, {Component} from 'react'
import {FormattedMessage} from "react-intl"
import {Link} from "react-router-dom";
import LinkService from "../services/LinkService";

class HelpTour extends Component {
    renderImage = (image, description) => {
        return (
            <p className="text-center">
                <img style={{maxWidth: 600}} src={"/static/images/help/" + image} alt={description}/>
            </p>
        )
    };

    render = () => {
        return (
            <div className="help-content">
                <h3>
                    <FormattedMessage id="help.tourOfGrabDuck"/>
                </h3>

                <h4>Bookmarks</h4>
                <h5>Overview</h5>
                <p>
                    Here's what a bookmark normally looks like on GrabDuck:
                </p>
                {this.renderImage("brief-bookmark.png", "Brief Bookmark")}

                <p>
                    A bookmark has a title, URL and a short description. By clicking on the title you can open the bookmark's URL in new browser's tab. When you
                    hover mouse cursor on a bookmark you can see a tool belt appears with actions you can perform on the bookmark.
                </p>

                <p>
                    If you click on the bookmark itself, it will be expanded and more information will be available:
                </p>
                {this.renderImage("expanded-bookmark.png", "Expanded Bookmark")}

                <p>
                    So here you can see active content if there is any (such as embedded Youtube video or SlideShare presentation), toolbar for performing
                    actions, full description, list of one or several tags assigned to the bookmark and list of original URLs that were used to create the
                    bookmark.
                </p>

                <h5>Editing Bookmark</h5>
                <p>
                    At the moment you can edit title of the bookmark and enter from zero to several tags. GrabDuck tries to help you with defining tags and
                    shows recommendations - tags which were extracted from the content and tags that you tend to use for such bookmarks.
                </p>
                {this.renderImage("edit-bookmark.png", "Edit Bookmark")}

                <h4>Bulk Edit</h4>
                <p>
                    Sometimes you'd like to change several bookmarks at the same time, for example, remove in one step all articles that not alive, in order to
                    keep your collection clean. You can use Bulk Edit for this.
                </p>
                <p>
                    Just hover mouse on a bookmark's icon and click on checkbox - you're now in Bulk Edit mode. Here you can add new bookmarks into selection
                    with various methods: add single bookmarks, group of them using combinations of keyboard's Shift pressed with mouse clicking or just use
                    operations on Bulk Edit toolbar.
                </p>
                {this.renderImage("multiple-selection.png", "Multiple Selection")}

                <p>
                    Once you're done with bookmark's selection do the Bulk Edit operation you need:
                </p>
                {this.renderImage("bulk-edit.png", "Bulk Edit")}

                <h4>Staying organized</h4>
                <h5>Overview</h5>
                <p>
                    We're providing several tools to keep your bookmark's collection organized. You can find all of them at left hand side of the screen. For
                    now they are Inbox, Browsing History, Favorites, Tags Cloud, Websites Cloud and Sources Cloud.
                </p>
                {this.renderImage("left-hand-menu.png", "GrabDuck Left Hand Menu")}

                <h5>Inbox</h5>
                <p>
                    In Inbox you keep bookmarks ready for action - consider this your to-do list! Once you have finished with a bookmark, you can mark it as
                    ‘Done’. Try to keep this list as empty as you can. If you need to come back to it soon, then ‘Flag’ it for faster access in the future.
                </p>
                {this.renderImage("inbox-bookmark.png", "Inbox Bookmark")}

                <h5>Browsing History</h5>
                <p>
                    In Browsing History collected all the articles you have recently visited. So when you need to go back to the article you've read couple of
                    days ago - History is the place where to find it very fast.
                </p>

                <h5>Favorites</h5>
                <p>
                    As name says here you can find all your bookmarks. For better experience we provide some usefull filters here. They can help you find those
                    bookmarks that still require tagging or show the bookmarks that are no longer alive on the web (remove them to make your collection
                    cleaner). Also with filters you can see which of your bookmarks are private and which could be shown to other users as recommendations.
                </p>
                {this.renderImage("favorites-filters.png", "GrabDuck Favorites Filters")}

                <h5>Tags and Websites Clouds</h5>
                <p>
                    Tags and Website clouds provide you word's clouds for additional filtering your bookmarks. You can use any combinations of these two for
                    better defining what bookmarks you'd like to see.
                </p>
                {this.renderImage("tags-cloud.png", "GrabDuck Tags Cloud")}

                <h5>Sources Cloud</h5>
                <p>
                    Sources cloud helps you show where each bookmark came from, so it's easy to view a list of your bookmarks filtered by a specific source.
                </p>
                {this.renderImage("sources-cloud.png", "GrabDuck Sources Cloud")}

                <h4>Searching</h4>
                <p>
                    GrabDuck provides fulltext search capabilities for finding your bookmarks. Just type a request in the search box and press Enter. You can
                    use any combinations of search request, tags and websites to make results more accurate.
                </p>
                {this.renderImage("how-to/search-text-box.png", "GrabDuck Search Text-box")}

                <p>
                    If your search is more complicated, or you are not sure what exactly you should type, use the extended mode. The more you type, the more
                    different searching options GrabDuck can offer for you. In this case the searching textbox is expanded and
                    several widgets with additional information, based on your input, are presented.
                </p>

                {this.renderImage("how-to/advanced-search-box.png", "GrabDuck Advanced Search-box")}

                <p>
                    For more information about searching rules see <Link
                    to={LinkService.helpHowToSearchingBookmarks()}><FormattedMessage id="help.howTo.searchingBookmarks"/></Link>.
                </p>

                <p>
                    When using fulltext search GrabDuck shows you recommendations to other materials which might be interesting for you. They are displayed in
                    different color for distinguishing. If you like the recommendation - add it to your collection. You can define how many recommendations
                    should be shown (or if they should be shown at all).
                </p>
                {this.renderImage("recommendations.png", "Recommendations")}

                <h4>Saving bookmarks</h4>
                <p>
                    There are number of ways how to add new bookmarks to your GrabDuck account. To name them, you can use a chrome extension, a browser
                    scriptlet we developed for you, you can add new bookmarks directly from the website or send a mail with what you need to special email
                    address we define for you. Also if you like recommendations GrabDuck shows you in response to your request it's also possible just to save
                    them into your account. For more information about adding bookmarks see <Link
                    to={LinkService.helpHowToSavingBookmarks()}><FormattedMessage id="help.howTo.savingBookmarks"/></Link>.
                </p>

                <h4>Integrating with other services</h4>
                <p>
                    You can set up GrabDuck to automatically synchronize your links from a number of outside services, including Stackoverflow, SlideShare,
                    Youtube and RSS feeds. GrabDuck will periodically check if there are new bookmarks on the services you configured and save them into your
                    GrabDuck account.
                </p>
                {this.renderImage("sync-with-services.png", "Sync with Services")}

                <p>
                    The list of services for integration is not a final one - we're doing our best to cover all that matter for our users, so if you have a
                    specific service on your mind which we currently do not support, send us a request, we will take a look at what we can do for you here.
                </p>

                <h4>Reader</h4>
                <p>
                    While fetching your bookmarks GrabDuck always tries to get the article's content in readable form. So if you see a special icon "Reader" on
                    a bookmark then you know there is a readable version available for you.
                </p>
                {this.renderImage("reader-icon.png", "Reader Icon")}

                <p>
                    There are some benefits you could get out of this: it provides you better reading experience without disturbing with endless ads and popups,
                    it keeps the article available all the time even if the website went down and finally you can send the article to your Kindle device (if you
                    have any) and keep reading it offline when it's better time for you.
                </p>
                {this.renderImage("reader.png", "GrabDuck's Reader")}

                <h4>Collaborating with Search Engines</h4>
                <p>
                    There is a browser's extension available that let you enable GrabDuck search results to be shown side by side with your favorite Search
                    Engine's results. So when you are searching for something in Google or DuckDuckGo a special box from GrabDuck appears and shows if you have
                    something already saved in your bookmark's collection. For more information see <Link
                    to={LinkService.helpHowToShowResultsNextToISE()}><FormattedMessage id="help.howTo.showResultsNextToISE"/></Link>
                </p>

                <h4>RSS Feeds</h4>
                <p>GrabDuck provides RSS feeds for every list of bookmarks you can generate. Look for the RSS icon at the
                    top of the page. Feeds for pages contain a secret token in the URL, to safeguard your privacy.
                </p>
                {this.renderImage("rss-feed.png", "RSS Feed")}

                <h4>Privacy</h4>
                <p>
                    By default all of your bookmarks could be shown to other users as a recommendation to their requests. Sometimes you do not like this and
                    prefer to keep a bookmark secret - that's why there is a "Make private" action available. Private bookmark has special "Lock" icon on
                    it.
                </p>
                {this.renderImage("make-private-icon.png", "Make Private icon")}

                <p>
                    Another case - you would prefer to keep all your new bookmarks private from the very beginning. That's totally valid case - many of our
                    users don't share any public content at all. There is a special setting "Make all bookmarks private by default" in you account for that.
                    When this feature is enabled you can see a small locker badge displayed on your avatar at the right top side:
                </p>
                {this.renderImage("private-by-default.png", "Private Bookmarks by default")}

                <p>
                    More information about privacy you can find in <Link to={LinkService.helpTOS()}>
                    <FormattedMessage id="help.termsOfService"/></Link>
                </p>

                <h4>Sign Up!</h4>
                <p>
                    So this was a very short tour of the GrabDuck service. We could write a lot about how wonderful the service is, we are proud of what
                    GrabDuck is today! But we have one great rule — it’s better to make your own opinion, the one based on your own feelings. Now it’s up to you
                    and probably it's a time to check it out and sign up for an account today!
                </p>
            </div>
        )
    }
}

export default HelpTour;
