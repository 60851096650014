import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import {Popover} from "react-bootstrap";
import SyncService from "../../../services/SyncService";
import AccountIdBasedProvider from "./AccountIdBasedProvider";

class StackexchangeProvider extends Component {
    getListUrl = (userId) => {
        return `${this.props.service.website}/users/${userId}`;
    };

    extractUserId = (value) => {
        if (value !== undefined) {
            const pattern = `${this.props.service.website}/users/(\\d+)/\\w+`;
            const template = new RegExp(pattern, "i");
            const match = value.match(template);
            return match ? match[1] : value;
        } else {
            return value;
        }
    };

    checkUserId = (userId) => {
        return SyncService.checkStackexchangeUserId(this.props.service.provider, userId);
    };

    renderHelp = () => {
        return (
            <Popover id="popover-stackexchange">
                <h6><FormattedMessage id="account.sync.providers.stackexchange.howFindUserId"/></h6>
                <ol>
                    <li><p><FormattedMessage id="account.sync.providers.stackexchange.help1"/></p></li>
                    <li><p><FormattedMessage id="account.sync.providers.stackexchange.help2"/></p></li>
                </ol>
            </Popover>
        );
    };

    render = () => {
        const {formatMessage} = this.props.intl;
        return (
            <AccountIdBasedProvider service={this.props.service}
                                    onChange={this.props.onChange}
                                    validate={this.props.validate}

                                    providerId="stackexchangeProvider"
                                    providerDescription={formatMessage({id: "account.sync.providers.stackexchange.desc"})}
                                    inputTitle={formatMessage({id: "account.sync.providers.stackexchange.userIdOrProfile"})}
                                    inputPlaceholder={formatMessage({id: "account.sync.providers.stackexchange.enterUserIdOrProfile"})}
                                    invalidFeedbackMsg={formatMessage({id: "account.sync.providers.stackexchange.validationFailed"})}

                                    getAccountUrl={this.getListUrl}
                                    renderHelp={this.renderHelp}
                                    checkAccountId={this.checkUserId}
                                    extractAccountId={this.extractUserId}
            />
        )
    }
}

StackexchangeProvider.propTypes = {
    service: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired
};

export default injectIntl(StackexchangeProvider);
