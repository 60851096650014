import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import Form from "react-bootstrap/Form";
import LinkService from "../services/LinkService";
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {Alert} from "react-bootstrap";
import ProgressModal from "../shell/ProgressModal";
import BookmarksService from "../services/BookmarksService"

class AccountImportExport extends Component {
    state = {
        showDownloadError: false,
        showDownloadProgress: false,
        bookmarks: null,

        showUploadError: false
    };

    handleUploadFile = (event) => {
        const {formatMessage} = this.props.intl;
        this.setState({
            showUploadProgress: true,
            showUploadError: false
        });
        BookmarksService
            .importBookmarks(event.target.files[0])
            .then(data => {
                this.setState({
                    showUploadProgress: false
                }, () => {
                    this.clearUploader();
                    this.props.onUserUpdate();
                    this.props.onAddToast({
                        id: Date.now(),
                        type: "success",
                        title: formatMessage({id: "common.topMenuTitle.account"}) + " / " + formatMessage({id: "help.howTo.importBookmarks"}),
                        description: formatMessage({id: 'account.bookmarksImportFinished'}, {count: data.importedBookmarksCount})
                    });
                });
            })
            .catch(() => {
                this.setState({
                    showUploadProgress: false,
                    showUploadError: true
                },  this.clearUploader);
            });
    };

    clearUploader = () => {
        document.getElementById('upload-file').value = "";
    };

    downloadBookmarks = () => {
        this.setState({
            showDownloadProgress: true,
            showDownloadError: false
        });

        BookmarksService
            .exportBookmarks()
            .then(data => {
                this.setState({
                    showDownloadProgress: false,
                    showDownloadError: false,
                    bookmarks: data
                });
            })
            .catch(() => {
                this.setState({
                    showDownloadProgress: false,
                    showDownloadError: true,
                });
            });
    };

    render = () => {
        const {formatMessage} = this.props.intl;
        return (
            <div className="help-content">
                <h3 className="account-title"><FormattedMessage id="account.menu.importExport"/></h3>
                <h4><FormattedMessage id="help.howTo.importBookmarks"/></h4>
                <Form>
                    <Alert variant="danger" show={this.state.showUploadError}
                           onClose={() => this.setState({showUploadError: false})} dismissible>
                        <FormattedMessage id="account.errorWhileImportingBookmarks"/>
                    </Alert>
                    <Form.Group controlId="userSettings.bookmarksPrivacy" className="settings-block">
                        <Form.Label><FormattedMessage id="account.selectBookmarksFile"/></Form.Label>
                        <Form.Control type="file"
                                      id="upload-file"
                                      onChange={this.handleUploadFile}
                                      className="upload-file bg-secondary"
                                      placeholder={formatMessage({id: "welcome.enterEmail"})}
                        />
                        <Form.Text className="text-muted"><FormattedMessage id="account.importBookmarks.desc"/></Form.Text>
                        <Form.Text className="text-muted">
                            <FormattedMessage id="common.see"/>:&nbsp;
                            <Link to={LinkService.helpHowToImportBookmarks()}>
                                <FormattedMessage id="help.howTo"/>&nbsp;<FormattedMessage id="help.howTo.importBookmarks"/>
                            </Link>
                        </Form.Text>

                    </Form.Group>
                </Form>
                <ProgressModal show={this.state.showUploadProgress} message={formatMessage({id: "account.uploadingBookmarks"})}/>


                <h4><FormattedMessage id="account.exportBookmarks"/></h4>
                <Form>

                    <Alert variant="danger" show={this.state.showDownloadError}
                           onClose={() => this.setState({showDownloadError: false})} dismissible>
                        <FormattedMessage id="account.errorWhileDownloadingBookmarks"/>
                    </Alert>

                    <Button variant="primary" size="lg" style={{margin: 15}} disabled={this.state.bookmarks} onClick={this.downloadBookmarks}>
                        <FormattedMessage id="account.downloadBookmarks"/>
                    </Button>
                    {this.state.bookmarks &&
                    <Button variant="primary"
                            size="lg"
                            href={this.state.bookmarks.data}
                            style={{margin: 15}}
                            download={this.state.bookmarks.filename}>
                        <FormattedMessage id="common.save"/>
                    </Button>
                    }

                    <Form.Text className="text-muted"><FormattedMessage id="account.exportBookmarks.desc"/></Form.Text>
                </Form>
                <ProgressModal show={this.state.showDownloadProgress} message={formatMessage({id: "account.downloadingBookmarks"})}/>
            </div>
        )
    }
}

AccountImportExport.propTypes = {
    onUserUpdate: PropTypes.func.isRequired,
    onAddToast: PropTypes.func.isRequired,
};

export default injectIntl(AccountImportExport);
