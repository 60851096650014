import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Card} from "react-bootstrap";
import UrlService from "../../services/UrlService";
import EmbedResponsive from "./EmbedResponsive";
import classNames from 'classnames'
import DefaultFavicon from "../default-favicon.png";
import BookmarkTags from "./BookmarkTags";
import BookmarkTitle from "./BookmarkTitle";
import BookmarksService from "../../services/BookmarksService";
import BookmarkProperties from "./BookmarkProperties";
import OriginalUrls from "./OriginalUrls";
import Toolbelt from "./Toolbelt";
import EmbedReadability from "./EmbedReadability";

class BookmarkExpanded extends Component {
    state = {
        favIconError: false,
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (this.props.bookmark.faviconUrl !== nextProps.bookmark.faviconUrl) {
            this.setState({favIconError: false})
        }
    };

    handleTitleUpdate = (title) => {
        this.props.bookmark.title = title;
        return BookmarksService.updateBookmarkProps(this.props.bookmark.id, title, null);
    };

    handleTagsUpdate = (tags) => {
        this.props.bookmark.tags = tags;
        return BookmarksService.updateBookmarkProps(this.props.bookmark.id, null, tags);
    };

    render = () => {
        const {bookmark} = this.props;
        let bookmarkTitle = this.props.bookmark.canonicalUrl;
        if (this.props.bookmark.title) {
            bookmarkTitle = this.props.bookmark.title;
        }

        return (
            <Card className={classNames("default-cursor", "bookmark-expanded", "shadow", {
                "bg-light": !bookmark.favorite,
                "bg-to-extraction": bookmark.status === "TO_EXTRACTION",
                "bg-failed": bookmark.status === "FAILED"
            })}>
                {bookmark.embed && <EmbedResponsive link={bookmark.embed} title={bookmarkTitle}/>}
                {bookmark.readability && !bookmark.embed &&
                <EmbedReadability favorite={bookmark.favorite}
                                  link={bookmark.link}
                                  onReader={this.props.onReader}
                                  privileges={this.props.privileges}
                />
                }
                <Card.Body>
                    <button type="button" className="no-focus close close-button" onClick={this.props.onExpand}><span aria-hidden="true">×</span><span
                        className="sr-only">Close</span></button>
                    <BookmarkTitle onOpen={this.props.onOpen}
                                   editable={bookmark.favorite}
                                   onTitleUpdate={this.handleTitleUpdate}
                                   onAddToast={this.props.onAddToast}
                                   title={bookmark.title || ""}
                                   url={bookmark.canonicalUrl}/>
                    <Card.Subtitle className="mb-2 text-secondary">
                        <img className="small-bookmark-icon" onError={() => this.setState({favIconError: true})}
                             src={!this.state.favIconError ? bookmark.faviconUrl : DefaultFavicon}
                             alt={bookmarkTitle}
                        />
                        {UrlService.decodeUrl(UrlService.punyCode(bookmark.canonicalUrl))}
                    </Card.Subtitle>
                    <Card.Text>
                        {bookmark.description}
                    </Card.Text>
                    <BookmarkTags tags={bookmark.tags}
                                  website={bookmark.website}
                                  bookmarkId={bookmark.id}
                                  editable={bookmark.favorite}
                                  onAddToast={this.props.onAddToast}
                                  onTagsUpdate={this.handleTagsUpdate}
                    />
                    <BookmarkProperties bookmark={bookmark}/>
                    {bookmark.urls && <OriginalUrls urls={bookmark.urls} onAddToast={this.props.onAddToast}/>}


                </Card.Body>
                <Card.Footer>
                    <Toolbelt bookmark={bookmark}
                              privileges={this.props.privileges}
                              onReader={this.props.onReader}
                              onBookmarkUpdateAction={this.props.onBookmarkUpdateAction}
                              onShareBookmark={this.props.onShareBookmark}
                              onShredBookmark={this.props.onShredBookmark}
                              onShowShredDialog={this.props.onShowShredDialog}

                    />
                </Card.Footer>

                {/*<BookmarkIndicators bookmark={bookmark}/>*/}
            </Card>
        )
    }
}

BookmarkExpanded.propTypes = {
    bookmark: PropTypes.object.isRequired,
    privileges: PropTypes.array.isRequired,
    onExpand: PropTypes.func.isRequired,
    onOpen: PropTypes.func.isRequired,
    onAddToast: PropTypes.func.isRequired,
    onReader: PropTypes.func.isRequired,
    onBookmarkUpdateAction: PropTypes.func.isRequired,
    onShareBookmark: PropTypes.func.isRequired,
    onShowShredDialog: PropTypes.func.isRequired,
    onShredBookmark: PropTypes.func.isRequired
};

export default BookmarkExpanded;
