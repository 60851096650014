import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import {Alert, Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import GoogleLogin from "./GoogleLogin";
import UserService from "../services/UserService";
import ProgressModal from "../shell/ProgressModal";
import ValidationService from "../services/ValidationService";
import WireWithGoogleDialog from "./WireWithGoogleDialog";
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import InputGroup from "react-bootstrap/InputGroup";

class Login extends Component {
    state = {
        email: "",
        password: "",

        showError: false,
        showProgress: false,

        emailValidationMessage: undefined,
        passwordValidationMessage: undefined,

        openWireWithGoogle: false,
        wireWithGoogleEmail: "",
        googleToken: null,

        showForgotPassword: false,
        showPasswordText: false
    };

    handleCloseForgotPassword = () => {
        this.setState({showForgotPassword: false});
    };

    showForgotPasswordDialog = () => {
        this.setState({showForgotPassword: true});
    };

    // handleChangeField = (prop, value) => {
    //     this.setState({changed: true, [prop]: value})
    // };

    handleCloseAlert = () => {
        this.setState({showError: false});
    };

    handleLogin = () => {
        this.setState({
            showError: false,
            showProgress: true
        });
        UserService
            .loginWithCredentials(this.state.email, this.state.password, "en")
            .then(data => {
                this.setState({
                    showProgress: false,
                    showError: false
                }, () => this.props.onLogin(data.authToken, data.expires));
            })
            .catch(() => {
                this.setState({
                    showProgress: false,
                    showError: true
                });
            });
    };

    validateEmail = (email) => {
        const {formatMessage} = this.props.intl;

        if (!email || email.trim().length === 0) {
            return formatMessage({id: "common.validation.fieldRequired"});

        } else if (!ValidationService.validateEmail(email)) {
            return formatMessage({id: "common.validation.validEmail"});

        } else {
            return null;
        }
    };

    validatePassword = (password) => {
        const {formatMessage} = this.props.intl;

        if (!password || password.trim().length === 0) {
            return formatMessage({id: "common.validation.fieldRequired"});

        } else {
            return null;
        }
    };

    handleEmailChange = (event) => {
        let emailValidationMessage = this.validateEmail(event.target.value);
        this.setState({
            email: event.target.value,
            emailValidationMessage: emailValidationMessage
        });
    };

    handlePasswordChange = (event) => {
        let messageValidationMessage = this.validatePassword(event.target.value);
        this.setState({
            password: event.target.value,
            passwordValidationMessage: messageValidationMessage
        });
    };

    isFormInvalid = (email, password) => {
        return !this.isFormValid(email, password);
    };

    isFormValid = (email, password) => {
        return this.validatePassword(password) === null && this.validateEmail(email) === null;
    };

    getEmailValidationClass = () => {
        if (this.state.emailValidationMessage === null) {
            return "is-valid";
        } else if (this.state.emailValidationMessage !== undefined) {
            return "is-invalid";
        } else {
            return "";
        }
    };

    getPasswordValidationClass = () => {
        if (this.state.passwordValidationMessage === null) {
            return "is-valid";
        } else if (this.state.passwordValidationMessage !== undefined) {
            return "is-invalid";
        } else {
            return "";
        }
    };

    loginWithGoogleToken = (token) => {
        this.setState({
            showProgress: true,
            showError: false
        });
        UserService
            .loginWithGoogle(token, "en")
            .then(data => {
                this.setState({showProgress: false, showError: false}, () =>
                    this.props.onLogin(data.authToken, data.expires));
            })
            .catch(() => {
                this.setState({
                    showProgress: false,
                    showError: true
                });
            });
    };

    wireWithGoogle = (token, email) => {
        this.setState({
            openWireWithGoogle: true,
            googleToken: token,
            wireWithGoogleEmail: email
        });
    };

    responseGoogle = (token) => {
        this.setState({showProgress: true});

        UserService
            .isGoogleUserRegistered(token)
            .then(data => {
                if (data.registered) {
                    if (data.connected) {
                        // just login
                        this.loginWithGoogleToken(token);
                    } else {
                        // ask for password and wire to google account
                        this.setState({showProgress: false});
                        this.wireWithGoogle(token, data.user.email);
                    }
                } else {
                    data.user.password = Math.random().toString(36).slice(-8);
                    this.setState({
                        showProgress: false,
                    }, this.props.onCreateAccount(data.user));
                }
            })
            .catch(() => {
                this.setState({showProgress: false});
            });
    };

    handleCloseWireWithGoogle = () => {
        this.setState({openWireWithGoogle: false});
    };

    handleWiredWithGoogle = () => {
        this.loginWithGoogleToken(this.state.googleToken);
    };

    render = () => {
        const {formatMessage} = this.props.intl;
        const {email, password} = this.state;

        const emailValidationClass = this.getEmailValidationClass();
        const passwordValidationClass = this.getPasswordValidationClass();

        return (
            <Container fluid={true} style={{marginTop: 96, marginBottom: 96}}>
                <Row noGutters={false} className="justify-content-center">
                    <Col lg={6} md={8} sm={10} xm={12}>
                        <h2 className="text-center"><FormattedMessage id="welcome.login"/></h2>
                        <p className="lead text-center" id={this.props.loginId}><FormattedMessage id="welcome.haveAccount"/></p>

                        <Card>
                            <Card.Body>
                                <h4 className="text-center"><FormattedMessage id="welcome.login"/></h4>
                                <Alert variant="danger" show={this.state.showError}
                                       onClose={this.handleCloseAlert} dismissible>
                                    <FormattedMessage id="welcome.usernameOrPasswordIncorrect"/>
                                </Alert>
                                <Form>
                                    <Form.Group controlId="formLoginEmail">
                                        <Form.Label><FormattedMessage id="common.userEmail"/></Form.Label>
                                        <Form.Control type="email"
                                                      className={emailValidationClass}
                                                      placeholder={formatMessage({id: "welcome.enterEmail"})}
                                                      value={email}
                                                      onChange={this.handleEmailChange}
                                        />
                                        <Form.Text className="invalid-feedback">{this.state.emailValidationMessage}</Form.Text>
                                    </Form.Group>

                                    <Form.Group controlId="formLoginPassword">
                                        <Form.Label><FormattedMessage id="common.password"/></Form.Label>
                                        <InputGroup>
                                            <Form.Control type={this.state.showPasswordText ? "text" : "password"}
                                                          placeholder={formatMessage({id: "welcome.typePassword"})}
                                                          value={password}
                                                          className={passwordValidationClass}
                                                          onChange={this.handlePasswordChange}
                                            />
                                            <InputGroup.Append>
                                                <Button variant="outline-secondary"
                                                        onClick={() => this.setState({showPasswordText: !this.state.showPasswordText})}>
                                                    {this.state.showPasswordText ? (<i className="fas fa-eye-slash"/>) : (<i className="fas fa-eye"/>)}
                                                </Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                        <Form.Text className="invalid-feedback">{this.state.passwordValidationMessage}</Form.Text>
                                    </Form.Group>

                                    <div className="d-flex justify-content-between"
                                         style={{marginTop: 45, marginBottom: 45}}>
                                        <Button variant="link" type="button" onClick={this.showForgotPasswordDialog}>
                                            <FormattedMessage id="welcome.forgotPassword"/>
                                        </Button>
                                        <Button variant="primary" type="button" size="lg"
                                                onClick={this.handleLogin}
                                                disabled={this.isFormInvalid(this.state.email, this.state.password)}
                                        >
                                            <FormattedMessage id="welcome.login"/>
                                        </Button>
                                    </div>
                                    <hr/>
                                    <div className="d-flex justify-content-center" style={{marginTop: 25, marginBottom: 25}}>
                                        <GoogleLogin onLogin={this.responseGoogle}/>
                                    </div>

                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <ProgressModal show={this.state.showProgress} message={formatMessage({id: "welcome.loggingIn"})}/>
                <ForgotPasswordDialog show={this.state.showForgotPassword}
                                      onClose={this.handleCloseForgotPassword}
                                      email={this.state.email}
                                      onAddToast={this.props.onAddToast}
                />
                <WireWithGoogleDialog show={this.state.openWireWithGoogle}
                                      onClose={this.handleCloseWireWithGoogle}
                                      email={this.state.wireWithGoogleEmail}
                                      onWired={this.handleWiredWithGoogle}
                />
            </Container>
        )
    }
}

Login.propTypes = {
    loginId: PropTypes.string.isRequired,
    onLogin: PropTypes.func.isRequired,
    onCreateAccount: PropTypes.func.isRequired,
    onAddToast: PropTypes.func.isRequired
    // onForgotPassword: PropTypes.func.isRequired,
};

export default injectIntl(Login);
