import React, {Component} from 'react'
import LinkService from "../../services/LinkService";
import {Link} from "react-router-dom";

class HelpPrivacyGDPRShort extends Component {
    render = () => {
        return (
            <React.Fragment>
                <p>to create a registered account at GrabDuck.com</p>
                <p>1. You hereby agree to ECONOR s.r.o. IČ: 29379946 (hereinafter referred to as the
                    "Administrator") in
                    the sense of Act No. 101/2000 Coll. (hereinafter referred to as the "Personal Data
                    Protection Act")
                    and the GDPR (General Privacy Act) to process next personal data:</p>
                <ul>
                    <li>user email</li>
                    <li>password</li>
                </ul>

                <p>2. The above personal details must be processed to create a registered account on
                    GrabDuck.com. These
                    personal data will be processed for the live time of the registered account.</p>

                <p>3. You give your explicit consent to the above processing. The consent can be withdrawn at
                    any time,
                    for example by deleting the registering account.</p>

                <p>4. Please note that you are entitled under the General Data Protection Regulation to:</p>
                <ul>
                    <li>revocate of consent at any time,</li>
                    <li>ask us for information about how we process your personal information,</li>
                    <li>request an explanation for us regarding the processing of personal data,</li>
                    <li>request access to these data and update or repair these data,</li>
                    <li>request us to erase this personal information,</li>
                    <li>in the event of any doubt as to compliance with the personal data processing
                        obligations,
                        contact us or the Office for Personal Data Protection.
                    </li>
                </ul>

                <p>The full version of the "GDPR - Privacy Policy" document can be found <Link
                    to={LinkService.helpPrivacyGDPR()}>here</Link>.</p>
            </React.Fragment>
        )
    }
}

export default HelpPrivacyGDPRShort;
