import React, {Component} from 'react'
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import {injectIntl} from "react-intl";
import LinkService from "../../services/LinkService";
import InboxFlagged from "./InboxFlagged";
import FavoriteFilters from "./FavoriteFilters";
import SearchService from "../../services/SearchService";
import {withRouter} from "react-router-dom";
import PropTypes from 'prop-types'
import AdvancedSearchBox from "./AdvancedSearchBox";

class SearchPanel extends Component {
    startSearch = (query) => {
        const link = SearchService.startSearch(query);
        if (link) {
            this.props.history.push(link);
        }
    };

    render = () => {
        return (
            <div>
                <Row noGutters>
                    <Col>
                        <AdvancedSearchBox startSearch={this.startSearch} location={this.props.location}/>
                    </Col>
                </Row>
                {window.location.pathname.startsWith(LinkService.inbox()) && <InboxFlagged/>}
                {window.location.pathname.startsWith(LinkService.favorites()) && <FavoriteFilters/>}
            </div>
        )
    }
}

SearchPanel.propTypes = {
    onRoot: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
};

export default withRouter(injectIntl(SearchPanel));
