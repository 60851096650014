import React, {Component} from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import {FormattedMessage} from "react-intl";

class SearchHelp extends Component {
    render = () => {
        return (
            <Container fluid={true}>
                <Row>
                    <Col md={6} sm={12}>
                        <span className="text-monospace">#java</span>
                        <span style={{marginLeft: 8}} className="text-secondary">
                            <FormattedMessage id="search.helpSearchWithinTag"/>
                        </span>
                    </Col>
                    <Col md={6} sm={12}>
                        <span className="text-monospace">@youtube.com</span>
                        <span style={{marginLeft: 8}} className="text-secondary">
                            <FormattedMessage id="search.helpSearchWithinWebsite"/>
                        </span>
                    </Col>
                    <Col md={6} sm={12}>
                        <span className="text-monospace">*added-from-browser</span>
                        <span style={{marginLeft: 8}} className="text-secondary">
                            <FormattedMessage id="search.helpSearchWithinSource"/>
                        </span>
                    </Col>
                    <Col md={6} sm={12}>
                        <span className="text-monospace">words here</span>
                        <span style={{marginLeft: 8}} className="text-secondary">
                            <FormattedMessage id="search.helpSearchForPhrase"/>
                        </span>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default SearchHelp;
