import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import {Alert, Button} from "react-bootstrap";
import ProgressModal from "../../shell/ProgressModal";
import UserService from "../../services/UserService";

class BillingInfo extends Component {
    state = {
        fullName: "",
        fullAddress: "",
        country: "",
        changed: false,

        showProgress: false,
        showError: false
    };

    resetData = () => {
        this.setState({
            fullName: this.props.billingInfo.fullName,
            fullAddress: this.props.billingInfo.fullAddress,
            country: this.props.billingInfo.country,
            changed: false
        });
    };

    handleFieldChange = (prop, value) => {
        this.setState({
            changed: true,
            [prop]: value,
        });
    };

    saveBillingInfo = () => {
        const {formatMessage} = this.props.intl;

        this.setState({showProgress: true, showError: false});
        UserService
            .saveBillingInfo(this.state.fullName, this.state.fullAddress, this.state.country)
            .then(data => {
                this.setState({
                    showProgress: false,
                    showError: false,
                    changed: false
                }, () => {
                    this.props.onUserUpdate();
                    this.props.onAddToast({
                        id: Date.now(),
                        type: "success",
                        title: formatMessage({id: "account.menu.membership"}) + " / " + formatMessage({id: "account.billingInfo"}),
                        description: formatMessage({id: "common.successfullySaved"})
                    });
                });

            })
            .catch(() => {
                this.setState({
                    showProgress: false,
                    showError: true
                });
            });
    };

    componentDidMount = () => {
        this.resetData();
    };

    render = () => {
        const {formatMessage} = this.props.intl;

        return (
            <React.Fragment>
                <Form>
                    <p className="lead">
                        <FormattedMessage id="account.billingInfo"/>
                        <span className="text-muted"> (<FormattedMessage id="common.notPubliclyAvailable"/>)</span>
                    </p>

                    <Alert variant="danger" show={this.state.showError} onClose={() => this.setState({showError: false})} dismissible>
                        <FormattedMessage id="common.errorWhileSavingData"/>
                    </Alert>

                    <Form.Group controlId="account.billingInfo.name">
                        <Form.Label><FormattedMessage id="account.billingInfo.fullName"/></Form.Label>
                        <InputGroup>
                            <Form.Control type="text"
                                          placeholder={formatMessage({id: "account.billingInfo.fullName"})}
                                          value={this.state.fullName}
                                          onChange={(e) => this.handleFieldChange("fullName", e.target.value)}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group controlId="account.billingInfo.fullAddress">
                        <Form.Label><FormattedMessage id="account.billingInfo.fullAddress"/></Form.Label>
                        <InputGroup>
                            <Form.Control type="text"
                                          placeholder={formatMessage({id: "account.billingInfo.fullAddress"})}
                                          value={this.state.fullAddress}
                                          onChange={(e) => this.handleFieldChange("fullAddress", e.target.value)}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group controlId="account.billingInfo.country">
                        <Form.Label><FormattedMessage id="account.billingInfo.country"/></Form.Label>
                        <InputGroup>
                            <Form.Control type="text"
                                          placeholder={formatMessage({id: "account.billingInfo.country"})}
                                          value={this.state.country}
                                          onChange={(e) => this.handleFieldChange("country", e.target.value)}
                            />
                        </InputGroup>
                    </Form.Group>
                </Form>

                <div className="d-flex justify-content-end" style={{marginTop: 45, marginBotton: 45}}>
                    <Button variant="light" type="button" disabled={!this.state.changed}
                            onClick={this.resetData} style={{marginLeft: 8}}>
                        <FormattedMessage id="common.reset"/>
                    </Button>
                    <Button variant="primary" type="button" disabled={!this.state.changed}
                            onClick={this.saveBillingInfo} style={{marginLeft: 8}}>
                        <FormattedMessage id="common.save"/>
                    </Button>
                </div>

                <ProgressModal show={this.state.showProgress} message={formatMessage({id: "common.saving"})}/>
            </React.Fragment>
        )
    }
}

BillingInfo.propTypes = {
    billingInfo: PropTypes.object.isRequired,
    onUserUpdate: PropTypes.func.isRequired,
    onAddToast: PropTypes.func.isRequired,
};

export default injectIntl(BillingInfo);
