import React, {Component} from 'react'

class HelpTOSContent extends Component {
    render = () => {
        return (
            <React.Fragment>
                <p>Last updated: December 4, 2018</p>

                <p>Welcome to GrabDuck! We invite you to access our website and use the service, but please note that your
                    invitation is subject to your agreement with these Terms of Service, so please review them carefully - we did our best for making these
                    reading easy and understandable for you.</p>

                <h4>What kind of a service GrabDuck offers?</h4>
                <p>So what you can get from the service and, which might be even more important, what you cannot get from
                    it.</p>
                <p>First, GrabDuck is not a product produced by a large technological enterprise such as Google or Facebook
                    but rather a group of very few developers (sometimes it was even one person - the author working on the product). And we are very keen on
                    that fact and will do all the best to stay in this set, because we truly believe that best products are usually being produced by real
                    enthusiasts who listen what users say of the product and who really like what they are doing. And big corporations… well you know those
                    usually truly care only about their own profit and big numbers.</p>
                <p>So what is GrabDuck? In short, GrabDuck is a bookmarking service that offers full text search for your
                    bookmarks. It focuses not on bookmarks and links themselves as usually most of bookmarking services do but rather on real content people are
                    saving.</p>
                <p>What does that mean? Well, we can probably try to explain this with a practical advice - if you would like
                    to get out of GrabDuck as much as possible consider it as a good storage for a good content. It could be whatever you find in internet what
                    gives you some knowledge or fun - articles, videos or other materials you might find interesting for you.</p>
                <p><strong>Hint:</strong> It does not make much sense to keep in GrabDuck links to different web
                    applications, such as Gmail or your personal online banking tool (even though you still can do that). Just remember these kind of links is
                    not something
                    you would go back one day and read it through again ;-)</p>


                <h4>Your rights</h4>
                <p>Having an account on GrabDuck intends that you should have some rights, shouldn't you? So what are
                    they?</p>
                <p>You have the right to export your bookmarks from the site at any time. The output will be compatible with
                    other bookmarking services or popular modern browsers.</p>
                <p>We never want to trap people inside GrabDuck. While we're sorry when people decide to cancel their
                    account, we never make this process confusing. So you can close your account at any time without any explanation.</p>
                <p>Your private bookmarks will not be intentionally shown to other users or shared with third parties -
                    remember, selling your data to other companies is not something on what our business is based and we promise it will never be.</p>
                <p>So your personal data, including your browsing history, email address and identity will not be shared with
                    anyone without your consent.</p>
                <p>If there is a significant outage, you will receive a full explanation of the circumstances and what
                    measures will be taken to prevent a recurrence.</p>
                <p>If the site ceases operation (which is definitely not something we have in our plans), you will receive an
                    opportunity to download your stored data in a format suitable for import into other bookmarking services or browsers.</p>
                <p>You have the right to receive an explanation, and when possible advance warning, if your account is
                    throttled or cancelled for the reasons outlined below.</p>
                <p>Any new features that affect privacy will be presented as strictly opt-in.</p>
                <p>You can choose to remain anonymous. The only signup requirements are payment and a working email
                    address.</p>
                <p>And the last in the list but the most important thing - you will always be treated with respect and
                    discretion. We promise... ;-)</p>

                <h4>Your Responsibilities</h4>
                <p>Remember, having rights means there are some responsibilities as well? So what are your
                    responsibilities?</p>
                <p>You may not make automated requests to the site more frequently than once every thirty
                    seconds.</p>
                <p>Posting links with the primary goal of directing traffic to outside sites, or increasing their search
                    engine visibility, will be construed as spam and may result in account cancellation.</p>
                <p>You must not abuse the site by knowingly posting malicious code, or links to malicious sites, that could
                    harm other users. If you find a security or privacy bug in GrabDuck, We ask that you please report it to us.</p>
                <p>You may not use the site to harass other users.</p>
                <p>We reserve the right to throttle or close accounts consuming an unreasonable proportion of site resources
                    (thousands of bookmarks per day, for example).</p>
                <p>You cannot save content illegal under the General Law.</p>


                <h4>Your Privacy</h4>
                <p>Privacy. It was not such a visible topic in the past but now a days it's an important case for everyone.
                    So we decided to take this very seriously and mark as "Prio One" all our requests regarding Privacy (all companies say this to you, don’t
                    they? ;-)). So what would you need to know about privacy on GrabDuck?</p>
                <p>GrabDuck saves only pages which are publicly available on internet. So if you're saving an article which
                    is accessible only after authorisation and contains your private information, or another example - your bank withdrawals log from your
                    online banking tool - only publicly available for everyone content will be saved as a bookmark.</p>
                <p>We know some services allow to create public tags and private ones. We think this is confusing. You need
                    to remember some special rules how to define your information and it's easy to make a mistake. So we decided to simplify it. There are no
                    public tags or notes. All meta information you create stays private from the beginning and will never be shared or shown to other
                    users.</p>
                <p>Normally all your bookmarks could be shown to other users as a recommendation to their requests (finally
                    this was the main idea of the service to let people discover something new). But this is done in very unpersonalised way and contains no
                    private information at all, so the user can see only what's freely available with having a link to the webpage. However you might find even
                    this not secure enough for you and would want to prevent such sharing experience. That's not a problem - we do appreciate your opinion and
                    give you a tool to mark your bookmark invisible to others. So no one will see it. Never. And if your intention is to not share any of your
                    bookmarks, you can configure this in your account - many of our users don't share any public content at all.</p>
                <p>So these were the terms of the use of GrabDuck. And if you agree to them, then probably it’s time to sign
                    up for the service and create a new Account.</p>
            </React.Fragment>
        )
    }
}

export default HelpTOSContent;
