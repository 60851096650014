import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import {Button, Form, FormCheck} from "react-bootstrap";
import WhyPayForm from "./WhyPayForm";
import ConsentForm from "./ConsentForm";
import TOSForm from "./TOSForm";
import ValidationService from "../services/ValidationService";
import UserService from "../services/UserService";
import ProgressModal from "../shell/ProgressModal";
import Alert from "react-bootstrap/Alert";
import InputGroup from "react-bootstrap/InputGroup";

class CreateAccountStep extends Component {
    state = {
        name: "",
        email: "",
        password: "",
        avatar: undefined,
        wireAccount: false,

        processPersonalData: false,
        termsOfService: false,

        changed: true,

        displayWhyPay: false,
        displayConsent: false,
        displayTOS: false,

        validationMessage: {},

        showProgress: false,
        showError: false,

        showPasswordText: false
    };

    handleShowTrigger = (trigger, show) => {
        this.setState({[trigger]: show})
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.onWireWithGoogle !== null) {
            this.setState({
                avatar: nextProps.onWireWithGoogle.avatar,
                wireAccount: true
            }, () => {
                this.handleTextFieldChange("name", nextProps.onWireWithGoogle.username, this.validateNotEmpty);
                this.handleTextFieldChange("email", nextProps.onWireWithGoogle.email, this.validateEmail);
                this.handleTextFieldChange("password", nextProps.onWireWithGoogle.password, this.validateNotEmpty);
            });
        }
    };

    getValidationClass = (field) => {
        if (this.state.validationMessage[field] === null) {
            return "is-valid";
        } else if (this.state.validationMessage[field] !== undefined) {
            return "is-invalid";
        } else {
            return "";
        }
    };

    validateEmail = (email) => {
        const {formatMessage} = this.props.intl;

        if (!email || email.trim().length === 0) {
            return formatMessage({id: "common.validation.fieldRequired"});

        } else if (!ValidationService.validateEmail(email)) {
            return formatMessage({id: "common.validation.validEmail"});

        } else {
            return null;
        }
    };

    validateNotEmpty = (name) => {
        const {formatMessage} = this.props.intl;

        if (!name || name.trim().length === 0) {
            return formatMessage({id: "common.validation.fieldRequired"});

        } else {
            return null;
        }
    };

    validateTrue = (name) => {
        const {formatMessage} = this.props.intl;
        if (name !== true) {
            return formatMessage({id: "common.validation.agreementRequired"});

        } else {
            return null;
        }
    };

    handleTextFieldChange = (prop, value, validateFn) => {
        const validationMessage = this.state.validationMessage;
        validationMessage[prop] = validateFn(value);

        this.setState({
            changed: true,
            [prop]: value,
            validationMessage: validationMessage
        });
    };

    isFormInvalid = (username, email, password, processPersonalData, termsOfService) => {
        return !(
            this.validateNotEmpty(username) === null &&
            this.validateEmail(email) === null &&
            this.validateNotEmpty(password) === null &&
            this.validateTrue(processPersonalData) === null &&
            this.validateTrue(termsOfService) === null
        );
    };

    proceed = () => {
        this.setState({
            showProgress: true,
            showError: false
        });

        UserService
            .validateEmail(this.state.email)
            .then(data => {
                if (data.valid) {
                    this.setState({showProgress: false}, () =>
                        this.props.handleProceed({
                            username: this.state.name,
                            email: this.state.email,
                            password: this.state.password,
                            avatar: this.state.avatar,
                            connections: {
                                googlePlus: this.state.wireAccount
                            }
                        }));
                } else {
                    this.showRegistrationError();
                }
            })
            .catch(() => {
                this.showRegistrationError()
            });
    };

    showRegistrationError = () => {
        this.setState({
            changed: false,
            showError: true,
            showProgress: false
        });
    };

    render = () => {
        const validationClass = {
            email: this.getValidationClass("email"),
            name: this.getValidationClass("name"),
            password: this.getValidationClass("password"),
            processPersonalData: this.getValidationClass("processPersonalData"),
            termsOfService: this.getValidationClass("termsOfService")
        };

        const {formatMessage} = this.props.intl;
        const {
            name, email, password, wireAccount,
            processPersonalData, termsOfService,
            displayTOS, displayConsent, displayWhyPay
        } = this.state;

        return (
            <React.Fragment>
                <h4 className="text-center"><FormattedMessage id="welcome.createAccount"/></h4>

                {this.props.onWireWithGoogle !== null && email === this.props.onWireWithGoogle.email &&
                <p><strong><FormattedMessage id="welcome.createAccountAndWireToGoogle"/></strong></p>
                }

                <p>
                    <FormattedMessage id="welcome.grabduckChargesForBasicAccount"/>&nbsp;
                    <Button variant="link" size="small"
                            style={{padding: 0, border: 0, marginTop: -3}}
                            onClick={() => this.handleShowTrigger("displayWhyPay", true)}>
                        <FormattedMessage id="help.whyPay"/>
                    </Button>
                </p>

                <Alert variant="danger" show={this.state.showError} onClose={() => this.setState({showError: false})} dismissible>
                    <FormattedMessage id="welcome.emailAlreadyRegistered"/>
                </Alert>

                <Form>
                    <Form.Group controlId="formRegisterUserName">
                        <Form.Label><FormattedMessage id="common.userName"/></Form.Label>
                        <Form.Control type="text"
                                      placeholder={formatMessage({id: "welcome.enterUserName"})}
                                      value={name}
                                      className={validationClass.name}
                                      onChange={(e) => this.handleTextFieldChange("name", e.target.value, this.validateNotEmpty)}
                        />
                        <Form.Text className="invalid-feedback">{this.state.validationMessage.name}</Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formRegisterEmail">
                        <Form.Label><FormattedMessage id="common.userEmailNotPublic"/></Form.Label>
                        <Form.Control type="email"
                                      placeholder={formatMessage({id: "welcome.enterEmail"})}
                                      value={email}
                                      className={validationClass.email}
                                      onChange={(e) => this.handleTextFieldChange("email", e.target.value, this.validateEmail)}
                        />
                        <Form.Text className="invalid-feedback">{this.state.validationMessage.email}</Form.Text>
                        <Form.Text className="text-muted">
                            <FormattedMessage id="welcome.weNeverShareYourEmail"/>
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formRegisterPassword">
                        <Form.Label><FormattedMessage id="common.password"/></Form.Label>
                        <InputGroup>
                            <Form.Control type={this.state.showPasswordText ? "text" : "password"}
                                          placeholder={formatMessage({id: "welcome.typePassword"})}
                                          value={password}
                                          className={validationClass.password}
                                          onChange={(e) => this.handleTextFieldChange("password", e.target.value, this.validateNotEmpty)}
                            />
                            <InputGroup.Append>
                                <Button variant="outline-secondary"
                                        onClick={() => this.setState({showPasswordText: !this.state.showPasswordText})}>
                                    {this.state.showPasswordText ? (<i className="fas fa-eye-slash"></i>) : (<i className="fas fa-eye"></i>)}
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                        <Form.Text className="invalid-feedback">{this.state.validationMessage.password}</Form.Text>
                    </Form.Group>

                    {this.props.onWireWithGoogle !== null && email === this.props.onWireWithGoogle.email &&
                    <Form.Group controlId="formRegisterWireWithGoogle">
                        <FormCheck custom>
                            <FormCheck.Input checked={wireAccount}
                                             value="true"
                                             onChange={(e) => this.handleTextFieldChange("wireAccount", e.target.checked, () => {
                                                 return null
                                             })}
                            />
                            <FormCheck.Label>
                                <FormattedMessage id="welcome.allowSingInWithGoogle"/>
                            </FormCheck.Label>
                        </FormCheck>
                    </Form.Group>
                    }

                    <Form.Group controlId="formRegisterProcessPersonalData">
                        <FormCheck custom>
                            <FormCheck.Input checked={processPersonalData}
                                             value="true"
                                             className={validationClass.processPersonalData}
                                             onChange={(e) => this.handleTextFieldChange("processPersonalData", e.target.checked, this.validateTrue)}
                            />
                            <FormCheck.Label>
                                <FormattedMessage id="welcome.agreeWithProcessingPersonalData"/>&nbsp;-
                                <Button variant="link" size="small"
                                        style={{padding: 0, border: 0, marginTop: -3, marginLeft: 8}}
                                        onClick={() => this.handleShowTrigger("displayConsent", true)}>
                                    <FormattedMessage id="common.display"/>
                                </Button>
                            </FormCheck.Label>
                        </FormCheck>
                    </Form.Group>

                    <Form.Group controlId="formRegisterTermsOfService">
                        <FormCheck custom>
                            <FormCheck.Input checked={termsOfService}
                                             value="true"
                                             className={validationClass.termsOfService}
                                             onChange={(e) => this.handleTextFieldChange("termsOfService", e.target.checked, this.validateTrue)}
                            />
                            <FormCheck.Label>
                                <FormattedMessage id="welcome.agreeWithTermOfService"/>&nbsp;-
                                <Button variant="link" size="small"
                                        style={{padding: 0, border: 0, marginTop: -3, marginLeft: 8}}
                                        onClick={() => this.handleShowTrigger("displayTOS", true)}>
                                    <FormattedMessage id="common.display"/>
                                </Button>
                            </FormCheck.Label>
                        </FormCheck>
                    </Form.Group>

                    <Button variant="primary" type="button" size="lg"
                            onClick={this.proceed}
                            disabled={this.isFormInvalid(name, email, password, processPersonalData, termsOfService)}>
                        <FormattedMessage id="common.proceed"/>
                    </Button>
                </Form>
                <WhyPayForm show={displayWhyPay} onHide={() => this.handleShowTrigger("displayWhyPay", false)}/>
                <ConsentForm show={displayConsent} onHide={() => this.handleShowTrigger("displayConsent", false)}/>
                <TOSForm show={displayTOS} onHide={() => this.handleShowTrigger("displayTOS", false)}/>
                <ProgressModal show={this.state.showProgress} message={formatMessage({id: 'welcome.registering'})}/>
            </React.Fragment>
        )
    }
}

CreateAccountStep.propTypes = {
    onWireWithGoogle: PropTypes.object,
    handleProceed: PropTypes.func.isRequired
};

export default injectIntl(CreateAccountStep);
