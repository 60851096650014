import React, {Component} from 'react'
import {FormattedMessage} from "react-intl";

class HelpHowToImportBookmarks extends Component {
    render = () => {
        return (
            <div className="help-content">
                <h3>
                    <FormattedMessage id="help.howTo.importBookmarks"/>
                    <small className="text-muted"> / <FormattedMessage id="help.howTo"/></small>
                </h3>

                <h4>
                    <FormattedMessage id="help.import.bookmarks.chrome.short"/>
                </h4>

                <h6>Prepare a html-file containing your bookmarks. You can create
                    this file yourself in a text editor or export bookmarks in the form of html-file from the browser
                    you use.</h6>
                <p>
                    Click on the menu icon <img src="/static/images/help/how-to/chrome-menu.png" alt="Chrome Menu"/> in the upper
                    right
                    corner of the window. Then, select <mark>Bookmarks</mark> &gt;
                    <mark>Bookmark manager</mark>
                    .
                </p>
                <p className="text-center"><img src="/static/images/help/how-to/chrome1.gif" alt="Chrome"/></p>

                <p>Open the menu <mark>Organize</mark> and select <mark>Export
                    bookmarks to HTML file...</mark>.</p>
                <p className="text-center"><img src="/static/images/help/how-to/chrome2.gif" alt="Chrome"/></p>

                <p>In the window "Export bookmarks file", choose a location to save
                    the file.</p>
                <p>Click the Save button. Window "Export bookmarks file" will be
                    closed.</p>

                <p><em>Your favorites have been successfully exported from Google
                    Chrome. The HTML file you saved can be imported into Grabduck.</em></p>

                <h6>Choose created html-file in the "HTML document with
                    bookmarks" and download it by clicking the "Download" button</h6>
                <h6>In the bookmark list, choose the ones that have to be
                    imported into GrabDuck and click on "Import Bookmarks"</h6>

                <p>As a result of actions a task to import bookmarks will be created.
                    The bookmarks themselves will appear in your collection after some time.</p>


                <h4>
                    <FormattedMessage id="help.import.bookmarks.firefox.short"/>
                </h4>

                <h6>Prepare a html-file containing your bookmarks. You can create
                    this file yourself in a text editor or export bookmarks in the form of html-file from the browser
                    you use.</h6>
                <p>Click on the button Bookmarks
                    <img src="/static/images/help/how-to/firefox-bookmarks.png"
                         alt="Firefox Bookmarks"/> and
                    select <mark>Show All Bookmarks</mark>, to open the Library window.</p>
                <p className="text-center"><img src="/static/images/help/how-to/firefox1.gif" alt="Firefox"/></p>

                <p>In the toolbar of the "Library" window open menu <mark><img
                    src="/static/images/how-to/firefox-import-and-buckup.png" alt="Firefox import and backup"/> Import and Backup
                </mark> and
                    select <mark>Export Bookmarks to HTML...</mark>.</p>
                <p className="text-center"><img src="/static/images/help/how-to/firefox2.gif" alt="Firefox"/></p>

                <p>In the window "Export bookmarks file", choose a location to save
                    the file. By default, the file name is bookmarks.html. A good place to save is usually the desktop,
                    but any place that is easy to remember will suit.</p>

                <p>Click the Save button. Window "Export bookmarks file" will be
                    closed.</p>
                <p>Close the "Library" window.</p>

                <p><em>Your favorites have been successfully exported from Mozilla
                    Firefox. The HTML file you saved can be imported into GrabDuck.</em></p>

                <h6>Choose created html-file in the "HTML document with
                    bookmarks" and download it by clicking the "Download" button</h6>
                <h6>In the bookmark list, choose the ones that have to be
                    imported into GrabDuck and click on "Import Bookmarks"</h6>


                <p>As a result of actions a task to import bookmarks will be created.
                    The bookmarks themselves will appear in your collection after some time.</p>
            </div>
        )
    }
}

export default HelpHowToImportBookmarks;
