import React, {Component} from 'react'
import PropTypes from 'prop-types'
import YoutubeListProvider from "./providers/YoutubeListProvider";
import HabrProvider from "./providers/HabrProvider";
import StackexchangeProvider from "./providers/StackexchangeProvider";
import SlideshareProvider from "./providers/SlideshareProvider";
import RssProvider from "./providers/RssProvider";

class ProviderSelector extends Component {
    render = () => {
        const providerId = this.props.type.substring(0, this.props.type.indexOf("/"));
        return (
            <React.Fragment>
                {providerId === "youtube" &&
                <YoutubeListProvider service={this.props.service}
                                     onChange={this.props.onChange}
                                     validate={this.props.validate}/>
                }

                {providerId === "habrahabr" &&
                <HabrProvider service={this.props.service}
                              onChange={this.props.onChange}
                              validate={this.props.validate}/>
                }

                {providerId === "slideshare" &&
                <SlideshareProvider service={this.props.service}
                                    onChange={this.props.onChange}
                                    validate={this.props.validate}/>
                }

                {providerId === "stackexchange" &&
                <StackexchangeProvider service={this.props.service}
                                       onChange={this.props.onChange}
                                       validate={this.props.validate}/>
                }

                {providerId === "rss" &&
                <RssProvider service={this.props.service}
                             onChange={this.props.onChange}
                             validate={this.props.validate}/>
                }
            </React.Fragment>
        )
    }
}

ProviderSelector.propTypes = {
    type: PropTypes.string.isRequired,
    service: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired
};

export default ProviderSelector;
