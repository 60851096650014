import React, {Component} from 'react'
import {FormattedMessage} from "react-intl"
import {Alert, Button} from "react-bootstrap";
import LinkService from "../services/LinkService";
import {Link} from "react-router-dom";
import LocalStorage from "../services/LocalStorage";

class CookiesPolicy extends Component {
    state = {
        show: LocalStorage.getOrDefault("Main.showCookiesPolicy", true)
    };

    handleDismiss = () => {
        this.setState({show: false}, () => LocalStorage.set("Main.showCookiesPolicy", false));
    };

    render = () => {
        return (
            <Alert variant="info" style={{marginTop: "1rem", marginLeft: 16}} className="component-margin" show={this.state.show} onClose={this.handleDismiss} dismissible>
                <Alert.Heading><FormattedMessage id="common.cookiesPolicy"/></Alert.Heading>
                <p>
                    <FormattedMessage id="common.agreeToUseCookies"/>
                    - <Link className="alert-link" to={LinkService.helpPrivacyCookieInfo()}><FormattedMessage id="help.cookieInfo"/></Link>
                </p>
                {/*<hr />*/}
                <div className="d-flex justify-content-end">
                    <Button variant="info" onClick={this.handleDismiss}>
                        <FormattedMessage id="common.gotIt"/>
                    </Button>
                </div>
            </Alert>
        )
    }
}

export default CookiesPolicy;
