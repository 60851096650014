import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl"
import {Form, FormCheck} from "react-bootstrap";

class PaymentMethods extends Component {
    render = () => {
        const paypalLogoUrl = this.props.logoType === "small"
            ? "https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_37x23.jpg"
            : "https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_74x46.jpg";

        return (
            <React.Fragment>
                <p className="text-center lead">
                    <FormattedMessage id="account.paymentMethods"/>
                </p>

                <Form>
                    <Form.Group controlId="paymentMethod">
                        <FormCheck className="align-middle" custom>
                            <FormCheck.Input checked={true} value="true" type="radio" style={{height: "100%"}}/>
                            <FormCheck.Label>
                                <img src={paypalLogoUrl} border="0" alt="PayPal Logo"/>
                            </FormCheck.Label>
                        </FormCheck>
                    </Form.Group>
                </Form>
            </React.Fragment>
        )
    }
}

PaymentMethods.propTypes = {
    logoType: PropTypes.oneOf(['small', 'large'])
};

export default PaymentMethods;
