import Cookies from "./Cookies";
import LocalStorage from "./LocalStorage";

export const AuthService = {
    login: function (token, expires) {
        Cookies.put(AuthService.getAuthTokenName(), token, {
            "expires": new Date(expires),
            "domain": ".grabduck.com",
            "path": "/"
        });
        // AuthService.setAuthToken(token);
    },

    logout: function () {
        Cookies.remove(AuthService.getAuthTokenName());
        // LocalStorage.delete(AuthService.getAuthTokenName());
    },

    getAuthToken: function () {
        return LocalStorage.get(AuthService.getAuthTokenName());
    },

    setAuthToken: function (token) {
        LocalStorage.set(AuthService.getAuthTokenName(), token);
    },

    getAuthTokenName: function () {
        return "X-Auth-Token";
    },
};

export default AuthService;