import axios from "axios/index";
import ReturnPromise from "./ReturnPromise";

const ReaderService = {
    getCurrent: function () {
        return ReturnPromise.get(axios.get("/api/v3/users/me?expand=stats"), true);
    },

    sendToKindle: function (documentId) {
        return ReturnPromise.get(axios.post('/api/v3/reader/kindle/' + documentId, {}));
    },

    findByIdV4: function (id) {
        return ReturnPromise.get(axios.get('/api/v4/articles/' + id));
    }
};

export default ReaderService;
