import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ReaderService from "../../services/ReaderService";
import {Button, Card} from "react-bootstrap";
import {FormattedMessage} from "react-intl";

class EmbedReadability extends Component {
    state = {
        article: undefined
    };

    handleReader = (e) => {
        e.stopPropagation();
        this.props.onReader();
    };

    UNSAFE_componentWillMount = () => {
        const link = this.props.link.substring(this.props.link.indexOf("/s/") + "/s/".length);

        ReaderService
            .findByIdV4(link)
            .then(article => {
                if (article.id === link) {
                    this.setState({article: article.body});
                }
            })
            .catch(() => {
            });
    };

    render = () => {
        return (
            <Card className="border-0">
                {this.state.article &&
                <Card.Body className="embed-readability">
                    <div dangerouslySetInnerHTML={{__html: this.state.article}}/>
                </Card.Body>
                }
                <Card.Footer className="text-center border-0">
                    <Button variant="light"
                            onClick={this.handleReader}
                            disabled={!this.props.favorite || (this.props.favorite && !this.props.privileges.includes('VIEW_READABILITY_CONTENT'))}>
                        <i className="fa-fw fas fa-book-reader text-black-50 button-icon"/>
                        <FormattedMessage id="main.reader"/>
                    </Button>
                </Card.Footer>
            </Card>
        )
    }
}

EmbedReadability.propTypes = {
    favorite: PropTypes.bool.isRequired,
    link: PropTypes.string.isRequired,
    onReader: PropTypes.func.isRequired,
    privileges: PropTypes.array.isRequired,
};

export default EmbedReadability;
