import React, {Component} from 'react'
import Duck16Png from "./duck-16.png";

class GrabDuckDivider extends Component {
    render = () => {
        return (
            <div style={{marginTop: 32}}>
                <hr style={{marginTop: 8, marginBottom: 8}}/>
                <img src={Duck16Png} style={{
                    position: "relative",
                    top: -17,
                    display: "block",
                    margin: "0 auto",
                    paddingLeft: 16,
                    paddingRight: 16,}} alt="" className="bg-white"/>
            </div>
        )
    }
}

export default GrabDuckDivider;
