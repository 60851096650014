import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import ProgressModal from "../../shell/ProgressModal";
import {FormCheck} from "react-bootstrap";
import UserService from "../../services/UserService";

class DeleteAccountDialog extends Component {
    state = {
        password: "",
        confirm1: false,
        confirm2: false,

        validationMessage: {},

        showProgress: false,
        errorMessage: null,

        showPassword: false,
    };

    getValidationClass = (field) => {
        if (this.state.validationMessage[field] === null) {
            return "is-valid";
        } else if (this.state.validationMessage[field] !== undefined) {
            return "is-invalid";
        } else {
            return "";
        }
    };

    validateNotEmpty = (name) => {
        const {formatMessage} = this.props.intl;

        if (!name || name.trim().length === 0) {
            return formatMessage({id: "common.validation.fieldRequired"});

        } else {
            return null;
        }
    };

    validateTrue = (name) => {
        const {formatMessage} = this.props.intl;
        if (name !== true) {
            return formatMessage({id: "common.validation.agreementRequired"});

        } else {
            return null;
        }
    };

    handleTextFieldChange = (prop, value, validateFn) => {
        const validationMessage = this.state.validationMessage;
        validationMessage[prop] = validateFn(value);

        this.setState({
            [prop]: value,
            validationMessage: validationMessage
        });
    };

    isFormInvalid = (password, confirm1, confirm2) => {
        return this.validateNotEmpty(password) !== null || this.validateTrue(confirm1) !== null || this.validateTrue(confirm2) !== null;
    };

    onClose = () => {
        this.setState({
            password: "",
            confirm1: false,
            confirm2: false,
            errorMessage: null,
            showPassword: false,
            validationMessage: {},

        }, this.props.onClose);
    };

    handleDeleteAccount = () => {
        const {formatMessage} = this.props.intl;
        this.setState({
            showProgress: true,
            errorMessage: null
        });

        UserService
            .deleteAccount(this.state.password)
            .then(data => {
                this.setState({
                    errorMessage: null,
                    showProgress: false,
                });
                this.onClose();
                this.props.onLogout();
            })
            .catch(error => {
                this.setState({
                    showProgress: false,
                    errorMessage: error.response.status === 401
                        ? formatMessage({id: "welcome.passwordIncorrect"}) : formatMessage({id: 'account.errorWhileDeletingAccount'})
                });
            });
    };

    render = () => {
        const {formatMessage} = this.props.intl;

        const validationClass = {
            password: this.getValidationClass("password"),
            confirm1: this.getValidationClass("confirm1"),
            confirm2: this.getValidationClass("confirm2"),
        };

        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const accountCreated = new Date(2008, 1, 12);
        const today = this.props.accountCreatedAt;

        const diffDays = Math.round(Math.abs((today - accountCreated) / oneDay));

        return (
            <React.Fragment>
                <Modal show={this.props.open} onHide={this.onClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title><FormattedMessage id="account.deleteAccount"/></Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p><FormattedMessage id="account.neverWantTrapPeople"/></p>
                        <Alert variant="danger" show={this.state.errorMessage !== null} onClose={() => this.setState({errorMessage: null})} dismissible>
                            {this.state.errorMessage}
                        </Alert>
                        <Form>
                            <Form.Group controlId="deleteAccountForm.password" className={validationClass.password}>
                                <Form.Label><FormattedMessage id="common.password"/></Form.Label>
                                <InputGroup>
                                    <Form.Control type={this.state.showPassword ? "text" : "password"}
                                                  placeholder={formatMessage({id: "welcome.typePassword"})}
                                                  value={this.state.password}
                                                  className={validationClass.password}
                                                  onChange={(e) => this.handleTextFieldChange("password", e.target.value, this.validateNotEmpty)}
                                    />
                                    <InputGroup.Append>
                                        <Button variant="outline-secondary"
                                                onClick={() => this.setState({showPassword: !this.state.showPassword})}>
                                            {this.state.showCurrentPassword ? (<i className="fas fa-eye-slash"/>) : (<i className="fas fa-eye"/>)}
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                                <Form.Text className="invalid-feedback">{this.state.validationMessage.password}</Form.Text>
                            </Form.Group>

                            <Form.Group controlId="deleteAccountForm.confirm1">
                                <FormCheck>
                                    <FormCheck.Input checked={this.state.confirm1}
                                                     value="true"
                                                     className={validationClass.confirm1}
                                                     onChange={(e) => this.handleTextFieldChange("confirm1", e.target.checked, this.validateTrue)}
                                    />
                                    <FormCheck.Label><FormattedMessage id="account.delete.yesWantDeleteAccount"/></FormCheck.Label>
                                </FormCheck>
                            </Form.Group>

                            <Form.Group controlId="deleteAccountForm.confirm2">
                                <FormCheck>
                                    <FormCheck.Input checked={this.state.confirm2}
                                                     value="true"
                                                     className={validationClass.confirm2}
                                                     onChange={(e) => this.handleTextFieldChange("confirm2", e.target.checked, this.validateTrue)}
                                    />
                                    <FormCheck.Label><FormattedMessage id="account.delete.confidentDeleteAccount"/></FormCheck.Label>
                                </FormCheck>
                            </Form.Group>
                        </Form>
                        {diffDays <= 4 &&
                        <p>
                            <mark><small><FormattedMessage id="account.doForRefund"/></small></mark>
                        </p>
                        }
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="light" onClick={this.onClose}><FormattedMessage id="common.cancel"/></Button>
                        <Button variant="danger"
                                onClick={this.handleDeleteAccount}
                                disabled={this.isFormInvalid(this.state.password, this.state.confirm1, this.state.confirm2)}>
                            <FormattedMessage id="account.deleteAccount"/>
                        </Button>
                    </Modal.Footer>
                </Modal>
                <ProgressModal show={this.state.showProgress} message={formatMessage({id: "account.deletingAccount"})}/>
            </React.Fragment>
        )
    }
}

DeleteAccountDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    accountCreatedAt: PropTypes.any.isRequired,
    onClose: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired
};

export default injectIntl(DeleteAccountDialog);
