import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import {Alert, Button, Form, FormCheck} from "react-bootstrap";
import PaypalButton from "./PaypalButton";
import ProgressModal from "./ProgressModal";
import UserService from "../services/UserService";
import AuthService from "../services/AuthService";
import {SubscriptionService} from "../services/SubscriptionService";

class CheckoutWithPaypal extends Component {
    state = {
        selectedItem: null,
        selectedPlan: null,
        totalPayment: null,

        showProgress: false,
        showError: false
    };

    getLabel = (item) => {
        return (
            <div className="clickable" onClick={(e) => this.selectItem(item.id, e)}>
                <p style={{marginBottom: 0}}>{item.billed}</p>
                <h4 style={{marginBottom: 0}}>
                    {item.currency} {item.price.toFixed(2)}
                    <small className="text-muted"> / <FormattedMessage id="account.checkout.monthly"/></small>
                </h4>
                <p style={{marginBottom: 0}}>{item.help}</p>
                {item.saveValue &&
                <p className="lead text-center" style={{marginBottom: 0}}>
                    <mark><FormattedMessage id="account.bestValueSave" values={{saveValue: item.saveValue}}/></mark>
                </p>
                }
            </div>
        );
    };

    findItemById = (itemId) => {
        var found = null;
        this.props.subscription.items.forEach(item => {
            if (item.id === itemId) {
                found = item;
            }
        });
        return found;
    };

    updatePlanDetails = (planId) => {
        const plan = this.findItemById(planId);
        this.setState({
            selectedItem: plan.id,
            selectedPlan: plan,
            totalPayment: plan.currency + " " + (plan.price * plan.billingPeriod).toFixed(2)
        });
    };

    selectItem = (itemId) => {
        this.updatePlanDetails(itemId);
    };

    componentDidMount = () => {
        this.updatePlanDetails(this.props.subscription.items[0].id);
    };

    checkoutWithPaypal = () => {
        const {user, billingPlan} = this.props;
        const {selectedPlan} = this.state;
        this.setState({
            showProgress: true,
            showError: false
        });

        if (user !== undefined && user !== null) {
            UserService
                .createUser(
                    user.username, user.email, user.password, user.avatar, user.connections.googlePlus,
                    billingPlan, selectedPlan.id.toUpperCase(), "PAYPAL"
                )
                .then(data => {
                    AuthService.login(data.auth.accessToken, data.auth.expires);
                    window.location.href = data.subscription.approvalUrl;
                })
                .catch(() => {
                    this.setState({
                        showProgress: false,
                        showError: true
                    });
                });

        } else {
            SubscriptionService
                .createSubscription(this.props.billingPlan, this.state.selectedPlan.id.toUpperCase(), "PAYPAL")
                .then(data => {
                    window.location.href = data.approvalUrl;
                })
                .catch(() => {
                    this.setState({
                        showProgress: false,
                        showError: true
                    });
                });
        }

    };

    render = () => {
        const {selectedItem} = this.state;
        const {user, billingPlan} = this.props;
        const {formatMessage} = this.props.intl;

        return (
            <React.Fragment>
                <Alert variant="danger" show={this.state.showError} onClose={() => this.setState({showError: false})} dismissible>
                    <FormattedMessage id="account.checkout.failed"/>
                </Alert>
                <Form>
                    {this.props.subscription.items.map(item => {
                        return (
                            <Form.Group controlId={"paymentMethod-" + item.id} key={item.id}>
                                <FormCheck className="align-middle">
                                    <FormCheck.Input checked={this.state.selectedItem === item.id}
                                                     value="true" type="radio" style={{height: "100%"}}
                                                     onClick={(e) => this.selectItem(item.id, e)}
                                    />
                                    <FormCheck.Label style={{marginLeft: 15}}>
                                        {this.getLabel(item)}
                                    </FormCheck.Label>
                                </FormCheck>
                            </Form.Group>
                        );
                    })}
                </Form>
                <p>
                    <FormattedMessage id="account.paypalWillBeCharged" values={{totalPayment: this.state.totalPayment}}/>
                    {this.state.selectedPlan && this.state.selectedPlan.renewal &&
                    <FormattedMessage id="account.subscriptionAutoRenew"/>
                    }
                </p>

                <div className="text-center" style={{marginTop: 30}}>
                    {(selectedItem === 'monthly' || selectedItem === 'annually') &&
                    <Button variant="primary"
                            size="lg"
                            style={{margin: 8, paddingLeft: 45, paddingRight: 45}}
                            onClick={this.checkoutWithPaypal}>
                        <FormattedMessage id="account.checkout.paypal"/>
                    </Button>
                    }
                    {selectedItem === 'one_time' && billingPlan !== undefined &&
                    <PaypalButton user={user} billingPlan={billingPlan}/>
                    }

                    {this.state.selectedPlan && this.state.selectedPlan.renewal &&
                    <p style={{marginBottom: 0}}><FormattedMessage id="account.cancelAnyTime"/></p>
                    }
                    {this.state.selectedPlan && !this.state.selectedPlan.renewal &&
                    <p style={{marginBottom: 0}}><FormattedMessage id="account.autoFinishInYear"/></p>
                    }

                    <p style={{marginBottom: 0}}><FormattedMessage id="account.feeRefundableForThreeDays"/></p>
                </div>

                <ProgressModal show={this.state.showProgress} message={formatMessage({id: 'account.checkingOut'})}/>
            </React.Fragment>
        )
    }
}

CheckoutWithPaypal.propTypes = {
    subscription: PropTypes.object.isRequired,
    billingPlan: PropTypes.string.isRequired,
    user: PropTypes.object
};

export default injectIntl(CheckoutWithPaypal);
