import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import {Form, Image, Table} from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import SyncProviderDialog from "./SyncProviderDialog";

class AllServices extends Component {
    state = {
        filter: "",
        showConfigDialog: {},
    };

    handleFilterChange = (event) => {
        this.setState({filter: event.target.value});
    };

    convertToSync = (service) => {
        const sync = JSON.parse(JSON.stringify(service));
        sync.readLater = true;
        sync.status = "ACTIVE";
        sync.tags = [];
        sync.updated = Date.now();
        sync.userId = "";
        sync.rssFeeds = [];
        sync.accountId = "";
        return sync;
    };

    changeVisibilityForConfigDialog = (serviceId, visibility) => {
        const showConfigDialog = this.state.showConfigDialog;
        showConfigDialog[serviceId] = visibility;
        this.setState({showConfigDialog: showConfigDialog});
    };

    renderIcon = (service) => {
        const iconUrl = service.iconImageUrl.replace("/common-images/", `${process.env.PUBLIC_URL}/static/images/`); // "https://grabduck.com/static/images/");
        return (<Image src={iconUrl} rounded style={{width: 32}}/>)
    };

    render = () => {
        const {formatMessage} = this.props.intl;
        const freeSpots = Math.max(this.props.maxAllowed - this.props.numberOfDefined, 0);
        const services = this.props.services.filter(service => (this.state.filter && this.state.filter.trim().length > 0)
            ? `${service.title} ${service.website} ${service.description}`.indexOf(this.state.filter) >= 0 : true
        );

        return (
            <React.Fragment>
                <div className="d-flex justify-content-end">
                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control type="text" placeholder={formatMessage({id: "common.search"})} onChange={this.handleFilterChange}
                                          value={this.state.filter}/>
                        </Form.Group>
                    </Form>
                </div>
                <Alert variant="info" show={freeSpots === 0}><FormattedMessage id="account.sync.reachedAccountLimit"/></Alert>
                {services.length > 0 &&
                <Table hover>
                    <tbody>
                    {services.map(service => {
                        const title = service.title + (service.website.trim().length > 0 ? ` (${service.website})` : "");

                        return (
                            <React.Fragment key={service.provider}>
                                <tr className="clickable" onClick={() => this.changeVisibilityForConfigDialog(service.provider, true)}>
                                    <td style={{paddingRight: 0}}>{this.renderIcon(service)}</td>
                                    <td>
                                        <span>{title}</span><br/>
                                        <small className="text-muted">{service.description}</small>
                                    </td>
                                </tr>
                                <SyncProviderDialog show={this.state.showConfigDialog[service.provider] === true}
                                                    onHide={() => this.changeVisibilityForConfigDialog(service.provider, false)}
                                                    service={this.convertToSync(service)}
                                                    onAddToast={this.props.onAddToast}
                                                    onConfiguredServicesChange={this.props.onConfiguredServicesChange}
                                />
                            </React.Fragment>
                        );
                    })}
                    </tbody>
                </Table>
                }
                {services.length === 0 && <p className="lead text-center"><FormattedMessage id="common.nothingWasFound"/></p>}
            </React.Fragment>
        )
    }
}

AllServices.propTypes = {
    services: PropTypes.array.isRequired,
    onConfiguredServicesChange: PropTypes.func.isRequired,
    maxAllowed: PropTypes.number.isRequired,
    numberOfDefined: PropTypes.number.isRequired,
    onAddToast: PropTypes.func.isRequired,
};

export default injectIntl(AllServices);
