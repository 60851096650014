import React, {Component} from 'react'
import {FormattedMessage} from "react-intl";

class HelpHowToSearchingBookmarks extends Component {
    render = () => {
        return (
            <div className="help-content">
                <h3>
                    <FormattedMessage id="help.howTo.searchingBookmarks"/>
                    <small className="text-muted"> / <FormattedMessage id="help.howTo"/></small>
                </h3>

                <p>
                    Probably the best way to begin a complex full text searching would be to find a search textbox at the top of the screen and just start
                    typing there.
                </p>

                <p className="text-center">
                    <img style={{border: "1px solid #BDBDBD"}}
                         src="/static/images/help/how-to/search-text-box.png"
                         alt="GrabDuck Search Text-box"/>
                </p>

                <p>
                    The more you type, the more different searching options GrabDuck can offer for you. In this case the searching textbox is expanded and
                    several widgets with additional information, based on your input, are presented.
                </p>

                <p className="text-center">
                    <img style={{border: "1px solid #BDBDBD"}}
                         src="/static/images/help/how-to/advanced-search-box.png"
                         alt="GrabDuck Advanced Search-box"/>
                </p>
                <p>
                    If you need to refine and narrow your search, here are some basic tips:
                    <ul>
                        <li>To search within specific tag(s), enter them starting with Hash (#) sign: <mark>#java spring</mark> searches for mentions of
                            "spring" within the "java" tag.
                        </li>
                        <li>To search within specific website, enter it starting with At (@) sign: <mark>@youtube.com microservices architecture</mark> searches
                            for mentions of "microservices architecture" from the youtube website. You can specify only one website at a time.
                        </li>
                        <li>To search bookmarks coming from specific place, enter it starting with Asterisk (*) sign: <mark>*synced-from-rss-feed Distributed
                            Systems Pattern</mark> searches for mentions of "Distributed Systems Pattern" within bookmarks that were synced from one of your RSS
                            feeds. You can specify only one source at a time.
                        </li>
                        <li>You can always mix different approaches of the search together to get better results: <mark>#microservices @youtube.com
                            *added-from-browser Distributed Systems Pattern</mark></li>
                    </ul>
                </p>

                <p>
                    For triggering the search, just press Enter. It's possible later to fine tune your search results by changing the search request again, by
                    selecting different searching sections and their options (Inbox, History, Favorites) or by using Tags-, Websites- or Sources clouds.
                </p>

            </div>
        )
    }
}

export default HelpHowToSearchingBookmarks;
