import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import GoBackButton from "../shell/GoBackButton";
import NavLinkItem from "../shell/NavLinkItem";
import LinkService from "../services/LinkService";
import AvatarCard from "../shell/AvatarCard";
import StatisticsPanel from "./StatisticsPanel";

class AccountSidebar extends Component {
    render = () => {
        const {formatMessage} = this.props.intl;
        const userPlanId = "account.plan." + this.props.user.plan.toLowerCase();

        return (
            <React.Fragment>
                <GoBackButton user={this.props.user} sidebarToggled={this.props.sidebarToggled}/>
                <hr className="divider"/>

                {!this.props.sidebarToggled &&
                <React.Fragment>
                    <AvatarCard user={this.props.user} />
                    <p className="text-center">
                        <FormattedMessage id="account.currentPlan"/>: <strong>{formatMessage({id: userPlanId})}</strong>
                    </p>
                    <StatisticsPanel stats={this.props.user.stats}/>
                    <hr className="divider"/>
                </React.Fragment>
                }

                <NavLinkItem link={LinkService.accountSummary()}
                             titleId="account.menu.summary"
                             sidebarToggled={this.props.sidebarToggled}
                             icon="fas fa-user-circle"
                             size="lg"
                />
                <NavLinkItem link={LinkService.accountSubscription()}
                             titleId="account.menu.membership"
                             sidebarToggled={this.props.sidebarToggled}
                             icon="fas fa-id-card-alt"
                             size="lg"
                />
                <NavLinkItem link={LinkService.accountSettings()}
                             titleId="account.menu.settings"
                             sidebarToggled={this.props.sidebarToggled}
                             icon="fas fa-cog"
                             size="lg"
                />
                <NavLinkItem link={LinkService.accountImportExport()}
                             titleId="account.menu.importExport"
                             sidebarToggled={this.props.sidebarToggled}
                             icon="fas fa-cloud-upload-alt"
                             size="lg"
                />
                <NavLinkItem link={LinkService.accountSync()}
                             titleId="account.menu.integrations"
                             sidebarToggled={this.props.sidebarToggled}
                             icon="fas fa-sync"
                             size="lg"
                />
            </React.Fragment>
        )
    }
}

AccountSidebar.propTypes = {
    user: PropTypes.object.isRequired,
    sidebarToggled: PropTypes.bool.isRequired,
};

export default injectIntl(AccountSidebar);
