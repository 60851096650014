import axios from "axios/index";
import ReturnPromise from "./ReturnPromise";

const TagsService = {
    getMatches: function (query) {
        return ReturnPromise.get(axios.get("/api/v4/suggestions/tags", {params: {q: query}}));
    }
};

export default TagsService;
