import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {IntlProvider} from "react-intl";
import i18n from "./i18n";
import {BrowserRouter} from "react-router-dom";
import axios from "axios/index";

function configAxios() {
    axios.defaults.baseURL = process.env.NODE_ENV === "development" ? "http://localhost:8083" : "";
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.withCredentials = true;
}

function prefetchProgressImage() {
    let progressImage = new Image();
    progressImage.src = process.env.PUBLIC_URL + '/static/images/progress.gif';
}

configAxios();
prefetchProgressImage();

ReactDOM.render(
    <IntlProvider locale="en" messages={i18n["en"]}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </IntlProvider>

    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
