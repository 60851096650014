import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl"
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class AlertDialog extends Component {
    render = () => {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p className="text-center">
                        {this.props.type === "success" && <i className="fas fa-fw fa-5x fa-check-circle text-success"/>}
                        {this.props.type === "danger" && <i className="fas fa-fw fa-5x fa-times-circle text-danger"/>}
                    </p>
                    <p className="lead text-center">{this.props.message}</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={this.props.onHide}><FormattedMessage id="common.ok"/></Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

AlertDialog.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['success', 'danger'])
};

export default AlertDialog;
