import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl"
import PlanFree from "./PlanFree";
import PlanDemo from "./PlanDemo";
import PlanBasic from "./PlanBasic";

class CurrentLevel extends Component {
    render = () => {
        const userPlanId = `account.plan.${this.props.plan.toLowerCase()}`;
        return (
            <React.Fragment>
                <p className="lead"><FormattedMessage id="account.accountLevel"/>: <strong><FormattedMessage id={userPlanId}/></strong></p>
                {this.props.plan === "FREE" && <PlanFree/>}
                {this.props.plan === "DEMO" && <PlanDemo/>}
                {(this.props.plan === "BASIC" || this.props.plan === "BASIC2" || this.props.plan === "ADMIN") && <PlanBasic/>}
            </React.Fragment>
        )
    }
}

CurrentLevel.propTypes = {
    plan: PropTypes.string.isRequired,
};

export default CurrentLevel;
