import React, {Component} from 'react'
import PropTypes from 'prop-types'
import TopNavbar from "./TopNavbar";
import Container from "react-bootstrap/Container";
import Sidebar from "./sidebar/Sidebar";
import LinkService from "../services/LinkService";
import Footer from "./Footer";
import {Route, Switch} from "react-router-dom";
import Welcome from "../welcome/Welcome";
import StackedToasts from "./StackedToasts";
import HelpSidebar from "../help/HelpSidebar";
import LocalStorage from "../services/LocalStorage";
import HelpRoot from "../help/HelpRoot";
import HelpTOS from "../help/HelpTOS";
import HelpPrivacyPolicy from "../help/privacy/HelpPrivacyPolicy";
import HelpPrivacyGDPR from "../help/privacy/HelpPrivacyGDPR";
import HelpPrivacyCookies from "../help/privacy/HelpPrivacyCookies";
import HelpAbout from "../help/HelpAbout";
import HelpTour from "../help/HelpTour";
import HelpHowToImportBookmarks from "../help/how-to/HelpHowToImportBookmarks";
import HelpHowToSavingBookmarks from "../help/how-to/HelpHowToSavingBookmarks";
import HelpHowToShowResultsNextToISE from "../help/how-to/HelpHowToShowResultsNextToISE";
import HelpFaqSeeFailed from "../help/faq/HelpFaqSeeFailed";
import HelpFaqRemoveMany from "../help/faq/HelpFaqRemoveMany";
import HelpFAQ from "../help/HelpFAQ";
import CookiesPolicy from "./CookiesPolicy";
import GDPRPolicy from "./GDPRPolicy";
import AccountSidebar from "../account/AccountSidebar";
import MainSidebar from "../main/MainSidebar";
import AccountSummary from "../account/summary/AccountSummary";
import Main from "../main/Main";
import AccountSettings from "../account/AccountSettings";
import AccountImportExport from "../account/AccountImportExport";
import AccountSubscription from "../account/subscription/AccountSubscription";
import UpgradeSubscription from "../account/subscription/UpgradeSubscription";
import CheckoutSubscription from "../account/subscription/CheckoutSubscription";
import AccountSync from "../account/sync/AccountSync";
import Reader from "../reader/Reader";
import AddBookmark from "../add-bookmark/AddBookmark";
import NewBookmarkDialog from "../main/NewBookmarkDialog";
import GoTop from "./GoTop";
import HelpHowToSearchingBookmarks from "../help/how-to/HelpHowToSearchingBookmarks";
import HelpFaqWhatArePermalinks from "../help/faq/HelpFaqWhatArePermalinks";

class Layout extends Component {
    state = {
        toasts: [],
        sidebarToggled: LocalStorage.getOrDefault("sidebar.toggled", false),
        registerId: "register",
        loginId: "login",
        openChangePassword: true,
        reload: 0,
        showNewBookmarkDlg: false
    };

    // componentDidMount() {
    //     // this.refreshTimerID = setInterval(() => this.handleRefresh(), 2 * 60 * 1000);
    //     setTimeout(() => {
    //         this.longPollingForUpdates()
    //     });
    // }

    // componentWillUnmount() {
    //     // clearInterval(this.refreshTimerID);
    // }

    handleSidebarToggle = () => {
        this.setState({sidebarToggled: !this.state.sidebarToggled}, () => {
            LocalStorage.set("sidebar.toggled", this.state.sidebarToggled)
        });
    };

    removeToast = (toastId) => {
        this.setState({toasts: this.state.toasts.filter(toast => toast.id !== toastId)});
    };

    handleAddToast = (toast) => {
        let toasts = this.state.toasts;
        toasts.push(toast);
        this.setState({toasts: toasts});
    };

    renderWelcome = () => {
        return (
            <Welcome loginId={this.state.loginId}
                     registerId={this.state.registerId}
                     onLogin={this.props.onLogin}
                     onAddToast={this.handleAddToast}
            />
        );
    };

    renderAccount = () => {
        window.history.replaceState({}, "Account Summary", LinkService.accountSummary());
        return this.renderAccountSummary();
    };

    renderAccountSummary = () => {
        return (
            <React.Fragment>
                {this.props.user &&
                <AccountSummary user={this.props.user}
                                onLogout={this.props.onLogout}
                                onUserUpdate={this.props.onUserUpdate}
                                onAddToast={this.handleAddToast}
                />}
            </React.Fragment>
        );
    };

    renderAccountSubscription = () => {
        return (
            <React.Fragment>
                {this.props.user &&
                <AccountSubscription user={this.props.user}
                                     onUserUpdate={this.props.onUserUpdate}
                                     onAddToast={this.handleAddToast}
                />}
            </React.Fragment>
        );
    };

    renderAccountSubscriptionUpgrade = () => {
        return (
            <React.Fragment>
                {this.props.user && <UpgradeSubscription user={this.props.user}/>}
            </React.Fragment>
        );
    };

    renderAccountSubscriptionCheckout = () => {
        return (
            <React.Fragment>
                {this.props.user && <CheckoutSubscription/>}
            </React.Fragment>
        );
    };

    renderAccountSettings = () => {
        return (
            <React.Fragment>
                {this.props.user &&
                <AccountSettings user={this.props.user}
                                 onUserUpdate={this.props.onUserUpdate}
                                 onAddToast={this.handleAddToast}
                />}
            </React.Fragment>
        );
    };

    renderAccountImportExport = () => {
        return (
            <React.Fragment>
                {this.props.user && <AccountImportExport onUserUpdate={this.props.onUserUpdate} onAddToast={this.handleAddToast}/>}
            </React.Fragment>
        );
    };

    renderAccountSync = () => {
        return (
            <React.Fragment>
                {this.props.user && <AccountSync onAddToast={this.handleAddToast} user={this.props.user} onUserUpdate={this.props.onUserUpdate}/>}
            </React.Fragment>
        );
    };

    renderHelpSidebar = () => {
        return (<HelpSidebar user={this.props.user} sidebarToggled={this.state.sidebarToggled}/>)
    };

    renderAccountSidebar = () => {
        return (
            <React.Fragment>
                {this.props.user && <AccountSidebar user={this.props.user} sidebarToggled={this.state.sidebarToggled}/>}
            </React.Fragment>
        );
    };

    renderMainSidebar = () => {
        return (
            <React.Fragment>
                {this.props.user &&
                <MainSidebar
                    user={this.props.user}
                    sidebarToggled={this.state.sidebarToggled}
                    reload={this.state.reload}
                />
                }
            </React.Fragment>
        );
    };

    renderMain = () => {
        return (
            <React.Fragment>
                {this.props.user &&
                <Main user={this.props.user}
                      onUserUpdate={this.props.onUserUpdate}
                      location={window.location}
                      onAddToast={this.handleAddToast}
                      reload={this.state.reload}
                      onRoot={this.props.onRoot}
                />
                }
                <NewBookmarkDialog show={this.state.showNewBookmarkDlg}
                                   onHide={() => this.setState({showNewBookmarkDlg: false})}
                                   onAddToast={this.handleAddToast}/>
            </React.Fragment>
        );
    };

    renderAddBookmark = () => {
        return (
            <AddBookmark user={this.props.user}/>
        );
    };

    renderReader = () => {
        return <Reader user={this.props.user} onAddToast={this.handleAddToast}/>;
    };

    handleRefresh = () => {
        this.props.onUserUpdate();
        this.setState({reload: Date.now()});
    };

    render = () => {
        const showCookiesPolicy = LocalStorage.getOrDefault("Main.showCookiesPolicy", true);
        const showGDPRPolicy = LocalStorage.getOrDefault("Main.showGDPRPolicy", true);

        const showSidebar = !(
            this.props.location.pathname.toLowerCase().startsWith(LinkService.welcome())
            || this.props.location.pathname.toLowerCase().startsWith(LinkService.reader())
            || this.props.location.pathname.toLowerCase().startsWith(LinkService.addBookmark())
        );

        const showShell = !(
            this.props.location.pathname.toLowerCase().startsWith(LinkService.reader())
            || this.props.location.pathname.toLowerCase().startsWith(LinkService.addBookmark())
        );

        return (
            <React.Fragment>
                {showShell &&
                <TopNavbar locationPathname={this.props.location.pathname}
                           user={this.props.user}
                           registerId={this.state.registerId}
                           loginId={this.state.loginId}
                           onLogout={this.props.onLogout}
                           onRefresh={this.handleRefresh}
                           onNewBookmark={() => this.setState({showNewBookmarkDlg: true})}
                />
                }

                <Container className={["wrapper", showShell ? "shell-container" : ""].join(" ")}>
                    {showSidebar &&
                    <Sidebar toggled={this.state.sidebarToggled} onToggle={this.handleSidebarToggle} user={this.props.user} onAddToast={this.handleAddToast}>
                        <Switch>
                            <Route path={LinkService.help()} render={this.renderHelpSidebar}/>
                            <Route path={LinkService.account()} render={this.renderAccountSidebar}/>
                            <Route path={LinkService.inbox()} render={this.renderMainSidebar}/>
                            <Route path={LinkService.history()} render={this.renderMainSidebar}/>
                            <Route path={LinkService.favorites()} render={this.renderMainSidebar}/>
                            <Route path={LinkService.search()} render={this.renderMainSidebar}/>
                        </Switch>
                    </Sidebar>
                    }
                    <div className="d-flex flex-column" style={{width: "100%"}}>
                        <Container style={{padding: 0}}>
                            {showCookiesPolicy && <CookiesPolicy/>}
                            {showGDPRPolicy && <GDPRPolicy/>}

                            <Switch>
                                {/*HELP*/}
                                <Route exact path={LinkService.helpHowToSearchingBookmarks()} component={HelpHowToSearchingBookmarks}/>
                                <Route exact path={LinkService.helpHowToImportBookmarks()} component={HelpHowToImportBookmarks}/>
                                <Route exact path={LinkService.helpHowToSavingBookmarks()} component={HelpHowToSavingBookmarks}/>
                                <Route exact path={LinkService.helpHowToShowResultsNextToISE()} component={HelpHowToShowResultsNextToISE}/>
                                <Route exact path={LinkService.helpFAQSeeFailed()} component={HelpFaqSeeFailed}/>
                                <Route exact path={LinkService.helpFAQRemoveMany()} component={HelpFaqRemoveMany}/>
                                <Route exact path={LinkService.helpFAQWhatArePermalinks()} component={HelpFaqWhatArePermalinks}/>
                                <Route exact path={LinkService.helpTOS()} component={HelpTOS}/>
                                <Route exact path={LinkService.helpPrivacyGDPR()} component={HelpPrivacyGDPR}/>
                                <Route exact path={LinkService.helpPrivacyPolicy()} component={HelpPrivacyPolicy}/>
                                <Route exact path={LinkService.helpPrivacyCookieInfo()} component={HelpPrivacyCookies}/>
                                <Route exact path={LinkService.helpFAQ()} component={HelpFAQ}/>
                                <Route exact path={LinkService.helpAbout()} component={HelpAbout}/>
                                <Route exact path={LinkService.helpTour()} component={HelpTour}/>
                                <Route path={LinkService.help()} component={HelpRoot}/>

                                {/* WELCOME */}
                                <Route path={LinkService.welcome()} render={this.renderWelcome}/>

                                {/* ACCOUNT */}
                                <Route exact path={LinkService.account()} render={this.renderAccount}/>
                                <Route path={LinkService.accountSummary()} render={this.renderAccountSummary}/>
                                <Route path={LinkService.accountSubscriptionCheckoutBase()} render={this.renderAccountSubscriptionCheckout}/>
                                <Route path={LinkService.accountSubscriptionUpgrade()} render={this.renderAccountSubscriptionUpgrade}/>
                                <Route path={LinkService.accountSubscription()} render={this.renderAccountSubscription}/>
                                <Route path={LinkService.accountSettings()} render={this.renderAccountSettings}/>
                                <Route path={LinkService.accountImportExport()} render={this.renderAccountImportExport}/>
                                <Route path={LinkService.accountSync()} render={this.renderAccountSync}/>

                                {/* READER */}
                                <Route path={LinkService.reader()} render={this.renderReader}/>

                                {/*/!* MAIN *!/*/}
                                <Route path={LinkService.inbox()} render={this.renderMain}/>
                                <Route path={LinkService.history()} render={this.renderMain}/>
                                <Route path={LinkService.favorites()} render={this.renderMain}/>
                                <Route path={LinkService.search()} render={this.renderMain}/>

                                {/* ADD BOOKMARK */}
                                <Route exact path={LinkService.addBookmark()} render={this.renderAddBookmark}/>

                                {/*404*/}

                            </Switch>
                        </Container>
                    </div>
                </Container>
                {!showSidebar && showShell && <Footer onAddToast={this.handleAddToast} user={this.props.user}/>}
                <StackedToasts toasts={this.state.toasts} handleRemove={this.removeToast}/>
                <GoTop/>
            </React.Fragment>

        )
    }
}

Layout.propTypes = {
    user: PropTypes.object,
    location: PropTypes.object.isRequired,
    onLogin: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    onUserUpdate: PropTypes.func.isRequired,
    onRoot: PropTypes.func.isRequired
};

export default Layout;
