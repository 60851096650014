import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {injectIntl} from "react-intl"
import Progress from "./Progress";

class StatisticsPanel extends Component {
    render = () => {
        const {formatMessage} = this.props.intl;
        return (
            <div style={{width: "100%"}}>
                <Progress label={formatMessage({id: 'main.menu.favorites'})}
                          max={this.props.stats.bookmarksMax}
                          value={this.props.stats.bookmarksCount}/>
                <Progress label={formatMessage({id: 'account.menu.integrations'})}
                          max={this.props.stats.syncsMax}
                          value={this.props.stats.syncsCount}/>
            </div>
        )
    }
}

StatisticsPanel.propTypes = {
    stats: PropTypes.object.isRequired,
};

export default injectIntl(StatisticsPanel);
