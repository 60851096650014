import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {injectIntl} from "react-intl"
import SyncService from "../../../services/SyncService";
import AccountIdBasedProvider from "./AccountIdBasedProvider";

class HabrProvider extends Component {
    getRssFeedUrl = (userId) => {
        return userId;
    };

    checkRssFeed = (feedUrl) => {
        return SyncService
            .checkRssUrls([feedUrl])
            .then(response => response.result ? Promise.resolve() : Promise.reject());
    };

    extractUserId = (value) => {
        return value;
    };

    render = () => {
        const {formatMessage} = this.props.intl;
        return (
            <AccountIdBasedProvider service={this.props.service}
                                    onChange={this.props.onChange}
                                    validate={this.props.validate}

                                    providerId="rssProvider"
                                    providerDescription={formatMessage({id: "account.sync.providers.rss.desc"})}
                                    inputTitle={formatMessage({id: "account.sync.providers.rss.url"})}
                                    inputPlaceholder={formatMessage({id: "account.sync.providers.rss.enterUrl"})}
                                    invalidFeedbackMsg={formatMessage({id: "account.sync.providers.rss.validationFailed"})}

                                    getAccountUrl={this.getRssFeedUrl}
                                    checkAccountId={this.checkRssFeed}
                                    extractAccountId={this.extractUserId}
            />
        )
    }
}

HabrProvider.propTypes = {
    service: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired
};

export default injectIntl(HabrProvider);
