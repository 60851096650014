import React, {Component} from 'react'
import {FormattedMessage} from "react-intl"

class HelpPrivacyPolicy extends Component {
    render = () => {
        return (
            <div className="help-content">
                <h3>
                    <FormattedMessage id="help.privacyPolicy"/>
                    <small className="text-muted"> / <FormattedMessage id="help.privacy"/></small>
                </h3>

                <p>Last updated: Dec 05, 2017</p>

                <h4>Introduction</h4>
                <p>We are committed to protecting the privacy of your data. This
                    Privacy Policy explains more specifically what information GrabDuck (“we” or “us”) collects and
                    receives from End Users (“you”) through GrabDuck’s website and different applications such as
                    browser extensions, which we refer to collectively as “the Service.” Also this Privacy Policy covers
                    how End User’s information and End User’s data are being used and shared.</p>
                <p>Although significant changes are rare, this policy may be amended
                    as new features, technology, or legal requirements arise, so please check back from time to time.
                    We’ll notify you if we make a significant change and, where required, seek your
                    consent.</p>


                <h4>What information does GrabDuck collect and receive?</h4>
                <p>We built GrabDuck to help you remember and organize your bookmarks
                    and all metadata related to them. That means you can input, upload, or store in GrabDuck any links,
                    tags, and related text data that you choose (collectively, “Content”). We also collect and receive
                    the following types of information:</p>
                <ul>
                    <li><strong>Basic subscriber information.</strong> To open your account and process payments, we
                        collect and receive basic information like your email address and your billing address and
                        other payment information, or contact information to other third party services such as
                        Amazon Kindle.
                    </li>
                    <li><strong>Log data.</strong> When you use GrabDuck, we collect data to understand how you and
                        others access and use the Service, as well as information about actions you take when using
                        the Service. This includes the use of cookies and analytics technologies, as described more
                        fully in our Cookie Information page. This information helps us provide the Service to you,
                        learn how the Service is used, and identify and suggest ways we can make the Service more
                        useful to you.
                    </li>
                    <li><strong>Web-browsing related information.</strong> When you use our web-browser’s extensions
                        for adding new bookmarks while browsing, we receive urls of web sites you’re visiting. We
                        use this information to check and show you if the web page you’re currently on was
                        previously saved as your bookmark in the Service. Also when adding a new bookmark with the
                        Service’s extension we can send the whole content of the webpage for providing tag
                        recommendations based on the page content. We are not storing or analysing this Content
                        (visited urls and scraped website pages) in a way other than described above. Also we’re not
                        sending this Content to third parties.
                    </li>
                    <li><strong>Integration with third party services.</strong> When you use GrabDuck for
                        integrating with third party services such as StackOverflow or SlideShare we keep
                        information identifying you on these services. We need this for possibility of making
                        requests to the services and ask them to provide related to you information for
                        synchronising it. Also if you use integration with browser’s bookmarks functionality we
                        periodically may ask your browser for your previously saved in browser bookmarks for later
                        saving them as the Service’s bookmarks.
                    </li>
                    <li><strong>Parsing bookmarks.</strong> When you save a bookmark, the Service does not store the
                        exact content of the web-page you can see in your browser. Instead a link to the content is
                        being sent to the Service and later this link is being parsed by the service. In practice
                        this means that if you’re saving the content which is available only after autorization the
                        Service will parse and save only the public available part of the page.
                    </li>
                </ul>
                <p>We also may link your subscriber information with data we receive
                    from other third parties to help understand your needs and provide you with a better experience. For
                    example, if you create or log into an GrabDuck account using your Google Apps credentials via single
                    sign-on, we will have access to certain information such as your name and email address as
                    authorized in your Google Apps profile settings.</p>


                <h4>How does GrabDuck use my information?</h4>
                <p>We are committed to protecting the privacy of your information.
                    Below, we describe the ways in which we use the information we collect and receive to provide,
                    maintain, and improve the Service; to provide troubleshooting and customer support; to protect the
                    Service for all our users; to contact you.</p>
                <ul>
                    <li>We use a number of technologies to help you get the most out of the Service. Our systems
                        automatically analyze your data to power GrabDuck features and to continually improve the
                        Service for you in a way that does not require anyone to look at your Content. This may
                        include, for example making sure you find what you’re looking for when you search your
                        account.
                    </li>
                    <li>To provide troubleshooting and customer support, our Customer Support may need to access
                        your information, such as your account email address and information about the GrabDuck
                        application you are using, subject to the protections described above.
                    </li>
                    <li>Based on other user’s requests GrabDuck can show in unpersonalised way your previously saved
                        bookmarks as recommendations. In this case no private information such as tags or text notes
                        you made for the bookmark will be shown to other users.
                    </li>
                </ul>
                <p>To test and improve our product offerings for our users, we use
                    aggregated data that does not contain any personal information, does not identify any person, and
                    cannot be connected to any specific user.</p>


                <h4>How does GrabDuck share or disclose my information?</h4>
                <p>GrabDuck is not in the business of selling or renting your
                    information. Here are instances when we may disclose your information — and then only the minimum
                    information necessary.</p>
                <p>We can share your information with Service Providers who process
                    data on our behalf, such as Payment service when you decide to pay for an extended version of
                    Subscription.</p>
                <p>We do not share your information with any third parties for their
                    own advertising purposes.</p>
            </div>
        )
    }
}

export default HelpPrivacyPolicy;
