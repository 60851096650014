import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {injectIntl} from "react-intl"
import {Button, Form, InputGroup, OverlayTrigger} from "react-bootstrap";

class AccountIdBasedProvider extends Component {
    state = {
        validationClass: "",

        accountId: ""
    };

    componentDidMount() {
        this.setState({accountId: this.props.service.accountId}, () => {
            if (this.props.service.accountId.trim().length > 0) {
                this.handleEndEdit();
            }
        });
    };

    validateInput = (accountId) => {
        if (!accountId || accountId.trim().length === 0) {
            return Promise.resolve(false);
        }
        return this.props
            .checkAccountId(accountId)
            .then(() => Promise.resolve(true))
            .catch(() => Promise.resolve(false));
    };

    handleChange = (event) => {
        const accountId = this.props.extractAccountId(event.target.value);
        this.setState({accountId});
    };

    handleStartEdit = () => {
        this.props.validate(false);
    };

    handleEndEdit = () => {
        this
            .validateInput(this.state.accountId)
            .then(validationResult => {
                this.setState({validationClass: validationResult ? "is-valid" : "is-invalid"}, () => {
                    const service = JSON.parse(JSON.stringify(this.props.service));
                    service.accountId = this.state.accountId;
                    this.props.onChange(service);
                    this.props.validate(validationResult);
                });
            });
    };

    render = () => {
        const accountUrl = this.props.getAccountUrl(this.state.accountId);

        return (
            <React.Fragment>
                <p>{this.props.providerDescription}</p>
                <Form>
                    <Form.Group controlId={`${this.props.providerId}-accountId`} className={this.state.validationClass}>
                        <Form.Label>{this.props.inputTitle}</Form.Label>
                        <InputGroup>
                            <Form.Control type="text"
                                          className={this.state.validationClass}
                                          placeholder={this.props.inputPlaceholder}
                                          value={this.state.accountId}
                                          onChange={this.handleChange}
                                          onFocus={this.handleStartEdit}
                                          onBlur={this.handleEndEdit}
                            />
                            {this.props.renderHelp &&
                            <InputGroup.Append>
                                <OverlayTrigger placement="bottom" overlay={this.props.renderHelp()}>
                                    <Button variant="outline-secondary"><i className="fas fa-fw fa-info"/></Button>
                                </OverlayTrigger>
                            </InputGroup.Append>
                            }

                        </InputGroup>
                        <Form.Text className="text-muted">
                            <a href={accountUrl} target="_blank" rel="noopener noreferrer">{accountUrl}</a>
                        </Form.Text>
                        <Form.Text className="invalid-feedback">{this.props.invalidFeedbackMsg}</Form.Text>
                    </Form.Group>
                </Form>
            </React.Fragment>
        )
    }
}

AccountIdBasedProvider.propTypes = {
    service: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,

    providerId: PropTypes.string.isRequired,
    providerDescription: PropTypes.string.isRequired,
    inputTitle: PropTypes.string.isRequired,
    inputPlaceholder: PropTypes.string.isRequired,
    invalidFeedbackMsg: PropTypes.string.isRequired,
    renderHelp: PropTypes.func,
    getAccountUrl: PropTypes.func.isRequired,
    checkAccountId: PropTypes.func.isRequired,
    extractAccountId: PropTypes.func.isRequired,
};

export default injectIntl(AccountIdBasedProvider);
