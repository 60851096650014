import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl"
import {Button, Card, Row} from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

class BulkBookmarksEdit extends Component {
    renderBulkOperationAction = (tooltipId, isDisabled, onAction, count, icon) => {
        return (
            <OverlayTrigger key={tooltipId} placement="bottom" overlay={<Tooltip><FormattedMessage id={tooltipId}/></Tooltip>}>
                <Button variant="light" disabled={isDisabled} onClick={onAction} className="no-focus">
                    <i className={["fa-fw", "fas", icon].join(" ")}/>({count})
                </Button>
            </OverlayTrigger>
        );
    };

    renderSelectionOption = (messageId, onAction) => {
        return (<Button key={messageId} variant="light" onClick={onAction} style={{marginLeft: 8}} className="no-focus"><FormattedMessage
            id={messageId}/></Button>);
    };

    render = () => {
        const {privileges, selectedBookmarksCount} = this.props;
        return (
            <Card body style={{marginBottom: 16}} bg="light">
                <Row noGutters className="justify-content-between align-items-center">
                    <Button variant="light" onClick={this.props.onSelectNone}>
                        <i className="fas fa-arrow-left" style={{marginRight: 8}}/>
                        <FormattedMessage id="common.back"/>
                    </Button>
                    <span><strong>{this.props.selectedNumber}</strong> <FormattedMessage id="main.selected"/></span>
                    <div>
                        <FormattedMessage id="main.selection"/>:
                        {[{
                            messageId: "main.selection.all",
                            onAction: this.props.onSelectAll
                        }, {
                            messageId: "main.selection.none",
                            onAction: this.props.onSelectNone
                        }, {
                            messageId: "main.selection.revert",
                            onAction: this.props.onSelectRevert
                        }].map(option => {
                            return this.renderSelectionOption(option.messageId, option.onAction)
                        })}
                    </div>
                </Row>
                <Row noGutters className="justify-content-around align-items-center">
                    {[{
                        tooltipId: "main.addToFavs",
                        isDisabled: selectedBookmarksCount.notFavorites === 0,
                        onAction: this.props.onActionAddToFavs,
                        count: selectedBookmarksCount.notFavorites,
                        icon: "fa-star"
                    }, {
                        tooltipId: "main.removeFromFavs",
                        isDisabled: selectedBookmarksCount.favorites === 0,
                        onAction: this.props.onActionDelFromFavs,
                        count: selectedBookmarksCount.favorites,
                        icon: "fa-trash"
                    }, {
                        tooltipId: "main.moveToInbox",
                        isDisabled: selectedBookmarksCount.favorites - selectedBookmarksCount.inbox === 0,
                        onAction: this.props.onActionMovToInbox,
                        count: selectedBookmarksCount.favorites - selectedBookmarksCount.inbox,
                        icon: "fa-inbox"
                    }, {
                        tooltipId: "main.markAsDone",
                        isDisabled: selectedBookmarksCount.inbox === 0,
                        onAction: this.props.onActionMrkAsDone,
                        count: selectedBookmarksCount.inbox,
                        icon: "fa-check"
                    }, {
                        tooltipId: "main.makeBookmarkPrivate",
                        isDisabled: selectedBookmarksCount.public === 0 || !privileges.includes('MAKE_BOOKMARK_PRIVATE'),
                        onAction: this.props.onActionMakePrivate,
                        count: selectedBookmarksCount.public,
                        icon: "fa-lock"
                    }, {
                        tooltipId: "main.makeBookmarkVisible",
                        isDisabled: selectedBookmarksCount.private === 0 || !privileges.includes('MAKE_BOOKMARK_PRIVATE'),
                        onAction: this.props.onActionMakePublic,
                        count: selectedBookmarksCount.private,
                        icon: "fa-lock-open"
                    }].map(action => {
                        return this.renderBulkOperationAction(action.tooltipId, action.isDisabled, action.onAction, action.count, action.icon);
                    })}
                </Row>
            </Card>
        )
    }
}

BulkBookmarksEdit.propTypes = {
    privileges: PropTypes.array.isRequired,
    selectedNumber: PropTypes.number.isRequired,
    selectedBookmarksCount: PropTypes.object.isRequired,

    onSelectNone: PropTypes.func.isRequired,
    onSelectAll: PropTypes.func.isRequired,
    onSelectRevert: PropTypes.func.isRequired,

    onActionAddToFavs: PropTypes.func.isRequired,
    onActionDelFromFavs: PropTypes.func.isRequired,
    onActionMovToInbox: PropTypes.func.isRequired,
    onActionMrkAsDone: PropTypes.func.isRequired,
    onActionMakePrivate: PropTypes.func.isRequired,
    onActionMakePublic: PropTypes.func.isRequired
};

export default BulkBookmarksEdit;
