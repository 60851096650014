import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl"
import SearchService from "../../services/SearchService";
import {Link} from "react-router-dom";

class Spellcheck extends Component {
    render = () => {
        const search = SearchService.extractSearch();
        const originalQuery = search.q;
        search.spell = false;
        const originalLink = window.location.pathname + "?" + SearchService.joinSearch(search);

        search.q = this.props.spellcheckQuery;
        delete search.spell;
        const spellcheckLink = window.location.pathname + "?" + SearchService.joinSearch(search);

        return (
            <div className="alert alert-info" role="alert">
                <span className="lead">
                    <FormattedMessage id="main.spellcheck.showResult"/>&#32;
                    <Link className="alert-link" to={spellcheckLink}>{this.props.spellcheckQuery}</Link>.
                </span><br/>
                <span>
                    <FormattedMessage id="main.spellcheck.searchInstead"/>&#32;
                    “<Link className="alert-link" to={originalLink}>{originalQuery}</Link>”?
                </span>
            </div>
        )
    }
}

Spellcheck.propTypes = {
    spellcheckQuery: PropTypes.string.isRequired
};

export default Spellcheck;
