import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Modal from "react-bootstrap/Modal";
import EmbedResponsive from "./EmbedResponsive";

class PlayVideoDialog extends Component {
    render = () => {
        return (
            <Modal show={true} onHide={this.props.onClose} centered size="xl">
                <EmbedResponsive link={this.props.embeddedUrl} title={this.props.title}/>
            </Modal>
        )
    }
}

PlayVideoDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    embeddedUrl: PropTypes.string
};

export default PlayVideoDialog;
