import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ValidationService from "../services/ValidationService";
import UserService from "../services/UserService";
import ProgressModal from "../shell/ProgressModal";

class ForgotPasswordDialog extends Component {
    state = {
        email: "",

        showError: false,
        showProgress: false,
    };

    handleHide = () => {
        this.setState({email: "", showError: false});
        this.props.onClose();
    };

    handleCloseAlert = () => {
        this.setState({showError: false});
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        this.handleEmailChange({
            target: {
                value: nextProps.email
            }
        });
    };

    handleEmailChange = (event) => {
        let emailValidationMessage = this.validateEmail(event.target.value);
        this.setState({
            email: event.target.value,
            emailValidationMessage: emailValidationMessage
        });
    };

    validateEmail = (email) => {
        const {formatMessage} = this.props.intl;

        if (!email || email.trim().length === 0) {
            return formatMessage({id: "common.validation.fieldRequired"});

        } else if (!ValidationService.validateEmail(email)) {
            return formatMessage({id: "common.validation.validEmail"});

        } else {
            return null;
        }
    };

    getEmailValidationClass = () => {
        if (this.state.emailValidationMessage === null) {
            return "is-valid";
        } else if (this.state.emailValidationMessage !== undefined) {
            return "is-invalid";
        } else {
            return "";
        }
    };

    isFormInvalid = (email) => {
        return !this.isFormValid(email);
    };

    isFormValid = (email) => {
        return this.validateEmail(email) === null;
    };

    handleSend = () => {
        const {formatMessage} = this.props.intl;

        this.setState({
            showProgress: true,
            showError: false
        });

        UserService
            .sendRestorePassword(this.state.email)
            .then(data => this.setState({showProgress: false}, () => {
                this.props.onAddToast({
                    id: Date.now(),
                    type: "success",
                    title: formatMessage({id: "welcome.forgotPassword"}),
                    description: formatMessage({id: "welcome.forgotPasswordSent"}, {email: this.state.email})
                });
                this.handleHide();
            }))
            .catch(() => this.setState({showProgress: false, showError: true}));
    };

    render = () => {
        const {formatMessage} = this.props.intl;
        const emailValidationClass = this.getEmailValidationClass();

        return (
            <React.Fragment>
                <Modal show={this.props.show} onHide={this.handleHide} centered>
                    <Modal.Header closeButton>
                        <Modal.Title><FormattedMessage id="welcome.forgotPassword"/></Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Alert variant="danger" show={this.state.showError} onClose={this.handleCloseAlert} dismissible>
                            <FormattedMessage id="common.message.cannotBeSent"/>
                        </Alert>

                        <p><FormattedMessage id="welcome.sendUsEmail"/></p>

                        <Form>
                            <Form.Group controlId="contactUsForm.email">
                                <Form.Label><FormattedMessage id="common.emailAddress"/></Form.Label>
                                <Form.Control type="email"
                                              className={emailValidationClass}
                                              value={this.state.email}
                                              onChange={this.handleEmailChange}
                                />
                                <Form.Text className="invalid-feedback">{this.state.emailValidationMessage}</Form.Text>
                            </Form.Group>

                        </Form>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="light" onClick={this.handleHide}><FormattedMessage id="common.cancel"/></Button>
                        <Button variant="primary"
                                onClick={this.handleSend}
                                disabled={this.isFormInvalid(this.state.email)}>
                            <FormattedMessage id="common.send"/>
                        </Button>
                    </Modal.Footer>
                </Modal>
                <ProgressModal show={this.state.showProgress} message={formatMessage({id: "common.sendingMessage"})}/>
            </React.Fragment>
        )
    }
}

ForgotPasswordDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    onAddToast: PropTypes.func.isRequired
};

export default injectIntl(ForgotPasswordDialog);
