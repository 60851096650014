import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl"
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class ShredBookmarkDialog extends Component {
    render = () => {
        return (
            <Modal show={true} onHide={this.props.onClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title><FormattedMessage id="main.shreddingBookmark"/></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p className="text-center">
                        <i className="fas fa-fw fa-5x fa-exclamation-triangle text-danger"/>
                    </p>
                    <p className="lead text-center"><FormattedMessage id="main.shreddingRequest"/></p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="light" onClick={this.props.onClose}><FormattedMessage id="common.cancel"/></Button>
                    <Button variant="danger" onClick={this.props.onShredBookmark}><FormattedMessage id="common.delete"/></Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

ShredBookmarkDialog.propTypes = {
    bookmark: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onShredBookmark: PropTypes.func.isRequired
};

export default ShredBookmarkDialog;
