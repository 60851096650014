import React, {Component} from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

class BookmarkSelector extends Component {

    handleClick = (event) => {
        event.stopPropagation();
        this.handleChange(event);
    };

    handleChange = (event) => {
        this.props.onChange(!this.props.value, event.shiftKey);
    };

    render = () => {
        return (
            <div className={classNames("bg-light", "bookmark-selector", {"bookmark-selector-show": this.props.value})} onClick={this.handleClick}>
                <div className="custom-control custom-checkbox">
                    <input onChange={this.handleChange} type="checkbox" className="custom-control-input clickable" checked={this.props.value}
                           onClick={this.handleChange}/>
                    <label onClick={this.handleChange} className="custom-control-label clickable"/>
                </div>
            </div>
        )
    }
}

BookmarkSelector.propTypes = {
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default BookmarkSelector;
