import React, {Component} from 'react'
import {FormattedMessage} from "react-intl";

class HelpFAQ extends Component {
    render = () => {
        return (
            <div className="help-content">
                <h3>
                    <FormattedMessage id="help.faq.full"/>
                </h3>

                <h4>How to add a bookmark?</h4>
                <p>You can add new bookmarks directly from GrabDuck. It is enough to
                    click on the link "New Bookmark" in the main menu and insert a link for the bookmark. However, for
                    better user experience, we recommend you to use a special extension for your browser. In this case,
                    you can add new bookmarks immediately after reading without visiting Grabduck. The list of available
                    extensions can be found in My Account / Welcome.</p>

                <h4>Should I define tags when adding bookmarks to
                    GrabDuck?</h4>
                <p>Tags are formed automatically based on information contained in
                    the bookmark you are adding. The most frequently used tags are displayed in your Tag Cloud. If
                    desired, you can delete or change existing tags or add your own.</p>

                <h4>Can I search bookmarks by tags?</h4>
                <p>Yes. One possibility service provides is searching by tags. To
                    search by tags, click on the tag in your Tag Cloud or select the tag of a particular document in the
                    natural search results.</p>

                <h4>Can I create my collections on service and make them available to
                    other users (share)?</h4>
                <p>No, service does not provide function to create collections. We
                    strongly believe it is wrong to force our customers to spend time processing and classifying
                    articles they found. As a rule, such work is routine, boring and rarely leads to good results,
                    that's why we are trying to protect you from wasting time on creating collections. Take advantage of
                    our convenient full-text search for materials that you need. For basic classification you can still
                    use the opportunities provided by functionality Tags.</p>
                <p>At present, the service does not allow sharing found materials
                    with other users of the system directly. We are a young service and see our mission in providing our
                    users with high-quality and relevant search results based on their personal bookmarks. Furthermore,
                    as a result of the request, the user also receives selection of recommended materials stored by
                    other users, which allows to achieve share and exchange of information.</p>

                <h4>Can I add materials that I have been offered as
                    recommendations?</h4>
                <p>Yes of course. You can mark any recommended material you liked, as
                    yours so you can quickly find it again.</p>

                <h4>Can other people see the bookmarks I saved?</h4>
                <p>Yes, if the bookmark you have saved may interest another user, it
                    can be shown to her as a recommendation. But one should keep in mind that other users will see only
                    information which is in public access. All your private information related to the document, such as
                    tags or notes is visible and accessible only to you and is not used for generating
                    recommendations.</p>
            </div>
        )
    }
}

export default HelpFAQ;
