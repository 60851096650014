import axios from "axios/index";
import ReturnPromise from "./ReturnPromise";

export const SubscriptionService = {
    getActiveSubscription: function () {
        return ReturnPromise.get(axios.get("/api/v3/users/me/activeSubscription"));
    },

    cancelSubscription: function () {
        return ReturnPromise.get(axios.delete("/api/v3/users/me/activeSubscription"));
    },

    getAllSubscriptions: function () {
        return ReturnPromise.get(axios.get("/api/v3/users/me/subscriptions"));
    },

    createSubscription: function (plan, period, method) {
        return ReturnPromise.get(
            axios.post("/api/v3/users/me/subscriptions", {
                method: method,
                plan: plan,
                period: period
            })
        );
    },

    getPlans: function(formatMessageFn) {
        // const monthlyPrice = 1.39;
        const annuallyPrice = 0.99;
        const oneTimePrice = 1.09;

        const saveValue = Math.round(100 - (annuallyPrice * 100 / oneTimePrice));

        return {
            "BASIC": {
                "title": formatMessageFn("account.plan.basic"),
                "items": [{
                    //     "id": "monthly",
                    //     "billed": formatMessage({id: "account.checkout.billed.monthly"}),
                    //     "currency": "USD",
                    //     "price": monthlyPrice,
                    //     "billingPeriod": 1,
                    //     "renewal": true,
                    //     "help": formatMessage({id: "account.not.in.all.countries"}),
                    // }, {
                    "id": "annually",
                    "billed": formatMessageFn("account.checkout.billedAnnually"),
                    "currency": "USD",
                    "price": annuallyPrice,
                    "billingPeriod": 12,
                    "renewal": true,
                    "help": formatMessageFn("account.notInAllCountries"),
                    "saveValue": saveValue
                }, {
                    "id": "one_time",
                    "billed": formatMessageFn("account.checkout.billedOneTime"),
                    "currency": "USD",
                    "price": oneTimePrice,
                    "billingPeriod": 12,
                    "renewal": false,
                    "help": formatMessageFn("account.yearSubscription"),
                }]
            }
        };
    }
};