import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Modal from "react-bootstrap/Modal";

class ProgressModal extends Component {
    render = () => {
        return (
            <Modal show={this.props.show} centered backdrop="static">
                <Modal.Body>
                    <p className="text-center"><img src="/static/images/progress.gif" width={300} alt="progress"/></p>
                    <p className="lead text-center">{this.props.message}</p>
                </Modal.Body>
            </Modal>
        )
    }
}

ProgressModal.propTypes = {
    show: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired
};

export default ProgressModal;
