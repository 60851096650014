import {Component} from 'react';
import PropTypes from 'prop-types';

class MetaInfo extends Component {
    decodeHtml = (input) => {
        const parser = new DOMParser();
        const dom = parser.parseFromString(
            '<!doctype html><body>' + input,
            'text/html');
        return dom.body.textContent;
    };

    setMetaData = () => {
        const oldTitle = document.getElementsByTagName("title")[0];
        const title = document.createElement("title");
        title.innerHTML = this.props.article.title;
        document.head.replaceChild(title, oldTitle);

        const metaTitle = document.createElement("meta");
        metaTitle.setAttribute("property", "og:title");
        metaTitle.setAttribute("content", this.decodeHtml(this.props.article.title));
        document.head.appendChild(metaTitle);

        const desc = this.decodeHtml(this.props.article.description);
        const metaDescription = document.createElement("meta");
        metaDescription.setAttribute("name", "description");
        metaDescription.setAttribute("content", desc);
        document.head.appendChild(metaDescription);

        const metaOgDescription = document.createElement("meta");
        metaOgDescription.setAttribute("property", "og:description");
        metaOgDescription.setAttribute("content", desc);
        document.head.appendChild(metaOgDescription);

        const metaOgImage = document.createElement("meta");
        metaOgImage.setAttribute("property", "og:image");
        metaOgImage.setAttribute("content", this.props.article.image);
        document.head.appendChild(metaOgImage);

        const metaOgUrl = document.createElement("meta");
        metaOgUrl.setAttribute("property", "og:url");
        metaOgUrl.setAttribute("content", this.props.article.link);
        document.head.appendChild(metaOgUrl);

        const metaOgGDUrl = document.createElement("meta");
        metaOgGDUrl.setAttribute("property", "og:gd-original-link");
        metaOgGDUrl.setAttribute("content", this.props.article.gdLink);
        document.head.appendChild(metaOgGDUrl);


        const links = document.head.getElementsByTagName("link");
        const toDelete = [];
        for (var i = 0; i < links.length; i++) {
            const rel = links[i].getAttribute("rel");
            if (rel === "icon" || rel === "shortcut icon" || rel === "apple-touch-icon") {
                toDelete.push(links[i]);
            }
        }
        toDelete.forEach(element => {
            document.head.removeChild(element);
        });

        const linkIcon = document.createElement("link");
        linkIcon.setAttribute("rel", "shortcut icon");
        linkIcon.setAttribute("type", "image/x-icon");
        linkIcon.setAttribute("href", this.props.article.favicon);
        document.head.appendChild(linkIcon);
    };

    componentDidMount = () => {
        this.setMetaData();
    };

    render = () => {
        return null;
    };
}

MetaInfo.propTypes = {
    article: PropTypes.object.isRequired
};

export default MetaInfo;
