import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Toast from "react-bootstrap/Toast";

class StackedToasts extends Component {
    render = () => {
        return (
            <div style={{position: 'fixed', bottom: 5, left: 5}}>
                {this.props.toasts.map(toast => {
                    let iconClass = undefined;
                    let toastClass = undefined;
                    if (toast.type === "info") {
                        iconClass = "fa-info-circle text-info";
                        toastClass = "bg-info text-white";

                    } else if (toast.type === "error") {
                        iconClass = "fa-times-circle text-danger"
                        toastClass = "bg-danger text-white";

                    } else if (toast.type === "success") {
                        iconClass = "fa-check-circle text-success"
                        toastClass = "bg-success text-white";
                    }

                    return (
                        <Toast key={toast.id} onClose={() => this.props.handleRemove(toast.id)} animation autohide delay={3000} className={toastClass}>
                            <Toast.Header>
                                {iconClass && <i className={"fas  fa-fw " + iconClass} style={{marginRight: 5}}/>}
                                <strong className="mr-auto">{toast.title}</strong>
                            </Toast.Header>
                            <Toast.Body>{toast.description}</Toast.Body>
                        </Toast>
                    );
                })}
            </div>
        )
    }
}

StackedToasts.propTypes = {
    toasts: PropTypes.array.isRequired,
    handleRemove: PropTypes.func.isRequired
};

export default StackedToasts;
