import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import {Popover} from "react-bootstrap";
import SyncService from "../../../services/SyncService";
import AccountIdBasedProvider from "./AccountIdBasedProvider";

class HabrProvider extends Component {
    getListUrl = (userId) => {
        return `${this.props.service.website}/users/${userId}`;
    };

    renderHelp = () => {
        return (
            <Popover id="popover-habr">
                <h6><FormattedMessage id="account.sync.providers.habr.howFindUserId"/></h6>
                <ol>
                    <li><p><FormattedMessage id="account.sync.providers.habr.help1"/></p></li>
                    <li><p><FormattedMessage id="account.sync.providers.habr.help2"/></p></li>
                </ol>
            </Popover>
        );
    };

    checkUserId = (userId) => {
        return SyncService.checkHabrUserId(userId);
    };

    extractUserId = (value) => {
        if (value !== undefined) {
            const pattern = `${this.props.service.website}/(\\w\\w/)?users/(\\w+)/(\\w+/)?`;
            const template = new RegExp(pattern, "i");
            const match = value.match(template);
            return match ? match[2] : value;
        } else {
            return value;
        }
    };

    render = () => {
        const {formatMessage} = this.props.intl;
        return (
            <AccountIdBasedProvider service={this.props.service}
                                    onChange={this.props.onChange}
                                    validate={this.props.validate}

                                    providerId="habrProvider"
                                    providerDescription={formatMessage({id: "account.sync.providers.habr.desc"})}
                                    inputTitle={formatMessage({id: "account.sync.providers.habr.userOrProfile"})}
                                    inputPlaceholder={formatMessage({id: "account.sync.providers.habr.enterUserOrProfile"})}
                                    invalidFeedbackMsg={formatMessage({id: "account.sync.providers.habr.validationFailed"})}

                                    getAccountUrl={this.getListUrl}
                                    renderHelp={this.renderHelp}
                                    checkAccountId={this.checkUserId}
                                    extractAccountId={this.extractUserId}
            />
        )
    }
}

HabrProvider.propTypes = {
    service: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired
};

export default injectIntl(HabrProvider);
