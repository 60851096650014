import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ValidationService from "../services/ValidationService";
import ProgressModal from "./ProgressModal";
import Alert from "react-bootstrap/Alert";
import ContactUsService from "../services/ContactUsService";

class ContactUsForm extends Component {
    state = {
        email: "",
        message: "",

        emailValidationMessage: undefined,
        messageValidationMessage: undefined,

        showProgress: false,
        showError: false
    };

    validateEmail = (email) => {
        const {formatMessage} = this.props.intl;

        if (!email || email.trim().length === 0) {
            return formatMessage({id: "common.validation.fieldRequired"});

        } else if (!ValidationService.validateEmail(email)) {
            return formatMessage({id: "common.validation.validEmail"});

        } else {
            return null;
        }
    };

    validateMessage = (message) => {
        const {formatMessage} = this.props.intl;

        if (!message || message.trim().length === 0) {
            return formatMessage({id: "common.validation.fieldRequired"});

        } else {
            return null;
        }
    };

    handleEmailChange = (event) => {
        let emailValidationMessage = this.validateEmail(event.target.value);
        this.setState({
            email: event.target.value,
            emailValidationMessage: emailValidationMessage
        });
    };

    handleMessageChange = (event) => {
        let messageValidationMessage = this.validateMessage(event.target.value);
        this.setState({
            message: event.target.value,
            messageValidationMessage: messageValidationMessage
        });
    };

    isFormInvalid = (email, message) => {
        return !this.isFormValid(email, message);
    };

    isFormValid = (email, message) => {
        return (this.props.user && this.props.user.id)
            ? this.validateMessage(message) === null
            : this.validateMessage(message) === null && this.validateEmail(email) === null;
    };

    handleHide = () => {
        this.setState({
            email: "",
            message: "",

            emailValidationMessage: undefined,
            messageValidationMessage: undefined,

            showProgress: false,
            showError: false,

        }, this.props.onHide);
    };

    getEmailValidationClass = () => {
        if (this.state.emailValidationMessage === null) {
            return "is-valid";
        } else if (this.state.emailValidationMessage !== undefined) {
            return "is-invalid";
        } else {
            return "";
        }
    };

    getMessageValidationClass = () => {
        if (this.state.messageValidationMessage === null) {
            return "is-valid";
        } else if (this.state.messageValidationMessage !== undefined) {
            return "is-invalid";
        } else {
            return "";
        }
    };

    handleCloseAlert = () => {
        this.setState({showError: false});
    };

    handleSend = () => {
        const {formatMessage} = this.props.intl;

        this.setState({
            showProgress: true,
            showError: false
        });

        ContactUsService
            .sendMessage(this.state.email, this.state.message)
            .then(data => {
                this.setState({
                    showProgress: false,
                }, () => {
                    this.props.onAddToast({
                        id: Date.now(),
                        type: "success",
                        title: formatMessage({id: "common.contactUs"}),
                        description: formatMessage({id: "common.message.wasSent"})
                    });
                    this.handleHide();
                });
            })
            .catch(() => {
                this.setState({
                    showProgress: false,
                    showError: true
                });
            });
    };

    render = () => {
        let showEmailField = !(this.props.user && this.props.user.id) ||
            (this.props.user && this.props.user.id && this.props.user.plan === "DEMO");

        const {formatMessage} = this.props.intl;

        let emailValidationClass = this.getEmailValidationClass();
        let messageValidationClass = this.getMessageValidationClass();

        return (
            <React.Fragment>
                <Modal show={this.props.show && !this.state.showProgress} onHide={this.handleHide} centered>
                    <Modal.Header closeButton>
                        <Modal.Title><FormattedMessage id="common.contactUs"/></Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p><FormattedMessage id="common.contactUs.greeting"/></p>

                        <Alert variant="danger" show={this.state.showError} onClose={this.handleCloseAlert} dismissible>
                            <FormattedMessage id="common.message.cannotBeSent"/>
                        </Alert>

                        <Form>
                            {showEmailField &&
                            <Form.Group controlId="contactUsForm.email">
                                <Form.Label><FormattedMessage id="common.emailAddress"/></Form.Label>
                                <Form.Control type="email"
                                              className={emailValidationClass}
                                              value={this.state.email}
                                              onChange={this.handleEmailChange}
                                />
                                <Form.Text className="invalid-feedback">{this.state.emailValidationMessage}</Form.Text>
                            </Form.Group>
                            }

                            <Form.Group controlId="contactUsForm.message">
                                <Form.Label><FormattedMessage id="common.message"/></Form.Label>
                                <Form.Control as="textarea" rows="3"
                                              className={messageValidationClass}
                                              value={this.state.message}
                                              onChange={this.handleMessageChange}
                                />
                                <Form.Text className="invalid-feedback">{this.state.messageValidationMessage}</Form.Text>
                                <Form.Text className="text-muted"><FormattedMessage id="common.contactUs.onlyEnglish"/></Form.Text>
                            </Form.Group>
                        </Form>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="light" onClick={this.handleHide}><FormattedMessage id="common.cancel"/></Button>
                        <Button variant="primary"
                                onClick={this.handleSend}
                                disabled={this.isFormInvalid(this.state.email, this.state.message)}
                        >
                            <FormattedMessage id="common.send"/>
                        </Button>
                    </Modal.Footer>
                </Modal>
                <ProgressModal show={this.state.showProgress} message={formatMessage({id: "common.sendingMessage"})}/>
            </React.Fragment>
        )
    }
}

ContactUsForm.propTypes = {
    user: PropTypes.object,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    onAddToast: PropTypes.func.isRequired
};

export default injectIntl(ContactUsForm);
