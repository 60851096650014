import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl"
import {Button} from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import SearchService from "../services/SearchService";

class FoundResults extends Component {
    state = {
        rssFeed: null
    };

    UNSAFE_componentWillReceiveProps = () => {
        this.updateRssFeedUrl();
    };

    updateRssFeedUrl = () => {
        let request = SearchService.createRequest();
        request["user"] = this.props.userId;
        request["secret"] = this.props.settings.rssSecret;
        let filter = request.filter;
        delete request.filter;
        var filters = "";
        if (filter !== undefined && filter.length > 0) {
            filters = "&" + filter.map(value => `filter[]=${value}`).join("&");
        }

        this.setState({
            rssFeed: "/bookmarks.rss?" + SearchService.joinSearchWithoutUndefined(request) + filters
        });
    };

    render = () => {
        // const result = SearchService.extractSearch();
        // const query = result.q !== undefined ? result.q : undefined;
        const {privileges, settings} = this.props;
        return (
            <div style={{marginBottom: 16}} className="d-flex justify-content-between">
                <div><FormattedMessage id="main.resultsFound"/><strong>{this.props.results}</strong></div>
                {this.props.query && <mark><strong>&nbsp;{this.props.query}&nbsp;</strong></mark>}
                <OverlayTrigger placement="bottom" overlay={<Tooltip><FormattedMessage id="common.rssFeed"/></Tooltip>}>
                    <Button variant="light"
                            disabled={!privileges.includes('GENERATE_RSS') || settings.rssSecret === undefined || settings.rssSecret === null}
                            href={this.state.rssFeed}
                            size="sm"
                            className="bg-transparent no-focus"
                            style={{borderColor: "transparent"}}>
                        <i className="fas fa-rss"/>
                    </Button>
                </OverlayTrigger>
            </div>
        )
    }
}

FoundResults.propTypes = {
    privileges: PropTypes.array.isRequired,
    results: PropTypes.number.isRequired,
    settings: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
    query: PropTypes.string
};

export default FoundResults;
