import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {ProgressBar} from "react-bootstrap";

class Progress extends Component {
    render = () => {
        const value = Math.round(this.props.value * 100 / this.props.max);

        return (
            <div style={{marginBottom: 8}}>
                {value > 100 && <i className="fas fa-fw fa-times-circle text-danger"/>}
                {value > 90 && value <= 100 && <i className="fas fa-fw fa-exclamation-circle text-warning"/> }
                <p style={{marginBottom: 0}}>{this.props.label}: {this.props.value}/{this.props.max}</p>
                <ProgressBar now={value} />
            </div>
        )
    }
}

Progress.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
};

export default Progress;
