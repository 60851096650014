import React, {Component} from 'react'
import PropTypes from 'prop-types'
import BookmarkBrief from "./BookmarkBrief";
import BookmarkExpanded from "./BookmarkExpanded";
import BookmarksService from "../../services/BookmarksService";
import PlayVideoDialog from "./PlayVideoDialog";
import ShareBookmarkDialog from "./ShareBookmarkDialog";
import {injectIntl} from "react-intl";
import ShredBookmarkDialog from "./ShredBookmarkDialog";

class Bookmark extends Component {
    state = {
        expanded: false,
        showPlay: false,
        showShare: false,
        showShred: false
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.expanded !== this.state.expanded) {
            this.setState({expanded: nextProps.expanded});
        }
    };

    handleExpand = (event) => {
        if (!this.props.selectionMode) {
            const expanded = !this.state.expanded;
            this.setState({expanded: expanded}, () => this.props.onExpand(this.props.bookmark, this.state.expanded));
        }
    };

    handleOpen = (event) => {
        event.stopPropagation();
        BookmarksService.updateHistory(this.props.bookmark.id);
    };

    handlePlay = () => {
        BookmarksService.updateHistory(this.props.bookmark.id);
        this.setState({showPlay: true});
    };

    handleReader = () => {
        window.open(this.props.bookmark.link, '_blank');
        window.focus();
    };

    handleShare = () => {
        this.setState({showShare: true});
    };

    handleShowShredDialog = () => {
        this.setState({showShred: true});
    };

    handleUpdatePrivate = (privateVal, sucessMessage) => {
        BookmarksService
            .updatePrivate(this.props.bookmark.id, privateVal)
            .then((bookmark) => {
                this.props.onBookmarkUpdated(bookmark);
                this.props.onAddToast({
                    id: Date.now(),
                    type: "success",
                    title: sucessMessage,
                    description: this.getBookmarkDescription()
                });

            })
            .catch(() => {
                this.props.onAddToast({
                    id: Date.now(),
                    type: "error",
                    title: sucessMessage,
                    description: this.getBookmarkDescription()
                });
            });
    };

    handleUpdateFlag = (flag, sucessMessage) => {
        BookmarksService
            .updateFlagged(this.props.bookmark.id, flag)
            .then((bookmark) => {
                this.props.onBookmarkUpdated(bookmark);
                this.props.onAddToast({
                    id: Date.now(),
                    type: "success",
                    title: sucessMessage,
                    description: this.getBookmarkDescription()
                });

            })
            .catch(() => {
                this.props.onAddToast({
                    id: Date.now(),
                    type: "error",
                    title: sucessMessage,
                    description: this.getBookmarkDescription()
                });
            });
    };

    handleUpdateFavorites = (favorite, sucessMessage) => {
        BookmarksService
            .updateFavorite(this.props.bookmark.id, favorite)
            .then((bookmark) => {
                this.props.onBookmarkUpdated(bookmark);
                if (!favorite) {
                    this.props.onUserUpdate();
                }
                this.props.onAddToast({
                    id: Date.now(),
                    type: "success",
                    title: sucessMessage,
                    description: this.getBookmarkDescription()
                });
            })
            .catch(() => {
                this.props.onAddToast({
                    id: Date.now(),
                    type: "error",
                    title: sucessMessage,
                    description: this.getBookmarkDescription()
                });
            });
    };

    handleUpdateInbox = (inbox, sucessMessage, removeFromList) => {
        BookmarksService
            .updateInbox(this.props.bookmark.id, inbox)
            .then((bookmark) => {
                if (removeFromList) {
                    this.props.onBookmarkRemoved(this.props.bookmark);
                } else {
                    this.props.onBookmarkUpdated(bookmark);
                }
                this.props.onUserUpdate();
                this.props.onAddToast({
                    id: Date.now(),
                    type: "success",
                    title: sucessMessage,
                    description: this.getBookmarkDescription()
                });
            })
            .catch(() => {
                this.props.onAddToast({
                    id: Date.now(),
                    type: "error",
                    title: sucessMessage,
                    description: this.getBookmarkDescription()
                });
            });
    };

    getBookmarkDescription = () => {
        if (this.props.bookmark && this.props.bookmark.title && this.props.bookmark.title.length > 0) {
            return this.props.bookmark.title;

        } else if (this.props.bookmark && this.props.bookmark.canonicalUrl) {
            return this.props.bookmark.canonicalUrl;
        } else {
            return "OK";
        }
    };

    handleShredBookmark = () => {
        const {formatMessage} = this.props.intl;
        this.setState({showShred: false});

        BookmarksService
            .shred(this.props.bookmark.id)
            .then(() => {
                this.props.onBookmarkRemoved(this.props.bookmark);
                this.props.onAddToast({
                    id: Date.now(),
                    type: "success",
                    title: formatMessage({id: "main.shreddingBookmark"}),
                    description: this.getBookmarkDescription()
                });
            })
            .catch(() => {
                this.props.onAddToast({
                    id: Date.now(),
                    type: "error",
                    title: formatMessage({id: "main.shreddingBookmark"}),
                    description: this.getBookmarkDescription()
                });
            });
    };

    handleBookmarkUpdateAction = (action) => {
        const {formatMessage} = this.props.intl;
        switch (action) {
            case "REMOVE_FROM_HISTORY":
                BookmarksService
                    .removeFromHistory(this.props.bookmark.id)
                    .then(() => {
                        this.props.onBookmarkRemoved(this.props.bookmark);
                        this.props.onAddToast({
                            id: Date.now(),
                            type: "success",
                            title: formatMessage({id: "main.historyRemoved"}),
                            description: this.getBookmarkDescription()
                        });
                    })
                    .catch(() => {
                        this.props.onAddToast({
                            id: Date.now(),
                            type: "error",
                            title: formatMessage({id: "main.historyRemoved"}),
                            description: this.getBookmarkDescription()
                        });
                    });
                break;
            case "ADD_TO_FAVORITES":
                this.handleUpdateFavorites(true, formatMessage({id: "main.favoriteAdded"}));
                break;
            case "REMOVE_FROM_FAVORITES":
                this.handleUpdateFavorites(false, formatMessage({id: "main.favoriteRemoved"}));
                break;
            case "FLAG":
                this.handleUpdateFlag(true, formatMessage({id: "main.bookmarkFlagged"}));
                break;
            case "UNFLAG":
                this.handleUpdateFlag(false, formatMessage({id: "main.bookmarkUnflagged"}));
                break;
            case "MAKE_PRIVATE":
                this.handleUpdatePrivate(true, formatMessage({id: "main.bookmarkMadePrivate"}));
                break;
            case "MAKE_VISIBLE":
                this.handleUpdatePrivate(false, formatMessage({id: "main.bookmarkMadePublic"}));
                break;
            case "MARK_AS_DONE":
                this.handleUpdateInbox(false, formatMessage({id: "main.markedDone"}), true);
                break;
            case "REMOVE_FROM_INBOX":
                this.handleUpdateInbox(false, formatMessage({id: "main.removedFromInbox"}), false);
                break;
            case "MOVE_TO_INBOX":
                this.handleUpdateInbox(true, formatMessage({id: "main.addedToInbox"}), false);
                break;
            case "REFRESH":
                BookmarksService
                    .refresh(this.props.bookmark.id)
                    .then((bookmark) => {
                        this.props.onBookmarkUpdated(bookmark);
                        this.props.onAddToast({
                            id: Date.now(),
                            type: "success",
                            title: formatMessage({id: "main.updateTaskStarted"}),
                            description: this.getBookmarkDescription()
                        });
                    })
                    .catch(() => {
                        this.props.onAddToast({
                            id: Date.now(),
                            type: "error",
                            title: formatMessage({id: "main.updateTaskStarted"}),
                            description: this.getBookmarkDescription()
                        });
                    });
                break;
            default:
        }
    };

    handleSelection = (isSelected, isMultipleSelection) => {
        this.props.bookmark.selected = isSelected;
        this.props.onBookmarkSelected(this.props.bookmark, isMultipleSelection);
    };

    render = () => {
        return (
            <React.Fragment>
                {this.props.bookmark.visible !== false &&
                <div id={this.props.bookmark.id}>
                    {this.state.expanded
                        ? (
                            <BookmarkExpanded bookmark={this.props.bookmark}
                                              onExpand={this.handleExpand}
                                              onOpen={this.handleOpen}
                                              onAddToast={this.props.onAddToast}
                                              privileges={this.props.privileges}
                                              onReader={this.handleReader}
                                              onBookmarkUpdateAction={this.handleBookmarkUpdateAction}
                                              onShareBookmark={this.handleShare}
                                              onShowShredDialog={this.handleShowShredDialog}
                                              onShredBookmark={this.handleShredBookmark}
                            />
                        )
                        : (
                            <BookmarkBrief bookmark={this.props.bookmark}
                                           privileges={this.props.privileges}
                                           onExpand={this.handleExpand}
                                           onOpen={this.handleOpen}
                                           onReader={this.handleReader}
                                           onPlay={this.handlePlay}
                                           onShareBookmark={this.handleShare}
                                           onBookmarkUpdateAction={this.handleBookmarkUpdateAction}
                                           onSelection={this.handleSelection}
                            />
                        )
                    }
                    {this.state.showPlay &&
                    <PlayVideoDialog onClose={() => this.setState({showPlay: false})} embeddedUrl={this.props.bookmark.embed}
                                     title={this.props.bookmark.title}/>
                    }

                    {this.state.showShare &&
                    <ShareBookmarkDialog onClose={() => this.setState({showShare: false})} bookmark={this.props.bookmark}/>
                    }

                    {this.state.showShred &&
                    <ShredBookmarkDialog onClose={() => this.setState({showShred: false})}
                                         bookmark={this.props.bookmark}
                                         onShredBookmark={this.handleShredBookmark}
                    />
                    }
                </div>
                }
            </React.Fragment>
        )

    }
}

Bookmark.propTypes = {
    bookmark: PropTypes.object.isRequired,
    privileges: PropTypes.array.isRequired,
    onExpand: PropTypes.func.isRequired,
    expanded: PropTypes.bool.isRequired,
    onBookmarkRemoved: PropTypes.func.isRequired,
    onBookmarkUpdated: PropTypes.func.isRequired,
    onAddToast: PropTypes.func.isRequired,
    onUserUpdate: PropTypes.func.isRequired,
    onBookmarkSelected: PropTypes.func.isRequired,
    selectionMode: PropTypes.bool.isRequired
};

export default injectIntl(Bookmark);
