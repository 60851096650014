import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import TariffPlan from "./TariffPlan";
import {Button} from "react-bootstrap";
import LinkService from "../../services/LinkService";
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";

class UpgradeSubscription extends Component {
    createData = (name, value) => {
        return {name, value};
    };

    getFreeDetails = () => {
        const {formatMessage} = this.props.intl;
        return [
            this.createData(formatMessage({id: "account.tariff.details.bookmarks"}), 500),
            this.createData(formatMessage({id: "account.tariff.details.integrations"}), 3),
            this.createData(formatMessage({id: "account.tariff.details.fulltext.search"}), formatMessage({id: "common.yes"})),
            this.createData(formatMessage({id: "account.tariff.details.tags"}), formatMessage({id: "common.yes"})),
            this.createData(formatMessage({id: "account.tariff.details.related.content"}), formatMessage({id: "common.yes"})),
            this.createData(formatMessage({id: "account.tariff.details.faster.integration"}), formatMessage({id: "common.no"})),
            this.createData(formatMessage({id: "account.tariff.details.reader"}), formatMessage({id: "common.no"})),
            this.createData(formatMessage({id: "account.tariff.details.privateBookmarks"}), formatMessage({id: "common.no"})),
            this.createData(formatMessage({id: "account.tariff.details.promotingEmails"}), formatMessage({id: "common.yes"})),
        ];
    };
    getBasicDetails = () => {
        const {formatMessage} = this.props.intl;
        return [
            this.createData(formatMessage({id: "account.tariff.details.bookmarks"}), "35K/" + formatMessage({id: "common.unlimited"})),
            this.createData(formatMessage({id: "account.tariff.details.integrations"}), 20),
            this.createData(formatMessage({id: "account.tariff.details.fulltext.search"}), formatMessage({id: "common.yes"})),
            this.createData(formatMessage({id: "account.tariff.details.tags"}), formatMessage({id: "common.yes"})),
            this.createData(formatMessage({id: "account.tariff.details.related.content"}), formatMessage({id: "common.yes"})),
            this.createData(formatMessage({id: "account.tariff.details.faster.integration"}), formatMessage({id: "common.yes"})),
            this.createData(formatMessage({id: "account.tariff.details.reader"}), formatMessage({id: "common.yes"})),
            this.createData(formatMessage({id: "account.tariff.details.privateBookmarks"}), formatMessage({id: "common.yes"})),
            this.createData(formatMessage({id: "account.tariff.details.promotingEmails"}), formatMessage({id: "common.no"})),
        ];
    };

    displayFreeSubscribe = () => {
        return (<span> </span>);
    };

    displayBasicSubscribe = () => {
        return (
            <LinkContainer to={LinkService.accountSubscriptionCheckout("BASIC")}>
                <Button variant="primary">
                    <FormattedMessage id="account.tariff.subscribe"/>
                </Button>
            </LinkContainer>
        );
    };

    render = () => {
        const {formatMessage} = this.props.intl;

        return (
            <div className="help-content">
                <h3 className="account-title"><FormattedMessage id="account.menu.membership"/></h3>
                <Container fluid style={{padding: 0}}>
                    <Row>
                        <Col sm={6} xs={12}>
                            <TariffPlan title={formatMessage({id: "account.plan.free"})}
                                        price={formatMessage({id: "account.tariff.free"})}
                                        details={this.getFreeDetails()}
                                        preferred={false}
                                        displayButton={this.displayFreeSubscribe}
                                        current={this.props.user.plan === "FREE"}/>
                        </Col>
                        <Col sm={6} xs={12}>
                            <TariffPlan title={formatMessage({id: "account.plan.basic"})}
                                        preferred={true}
                                        price={formatMessage({id: "account.tariff.oneDollar"})}
                                        details={this.getBasicDetails()}
                                        displayButton={this.displayBasicSubscribe}
                                        current={this.props.user.plan === "BASIC" || this.props.user.plan === "BASIC2" || this.props.user.plan === "ADMIN"}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

UpgradeSubscription.propTypes = {
    user: PropTypes.object.isRequired
};

export default injectIntl(UpgradeSubscription);
