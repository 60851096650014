import React, {Component} from 'react'
import {FormattedMessage} from "react-intl"
import {Link} from "react-router-dom";
import LinkService from "../../services/LinkService";

class HelpHowToShowResultsNextToISE extends Component {
    render = () => {
        return (
            <div className="help-content">
                <h3>
                    <FormattedMessage id="help.howTo.showResultsNextToISE"/>
                    <small className="text-muted"> / <FormattedMessage id="help.howTo"/></small>
                </h3>

                <p>
                    If you use Google Chrome or Mozilla Firefox for browsing, you can try our Chrome Extension for showing your GrabDuck Search results side by
                    side with your
                    preferred Internet Search Engine. Currently we support Google, DuckDuckGo, Bind and Yahoo. If you need support for another search engine,
                    please contact
                    us.
                </p>
                <p className="text-center"><img
                    style={{border: "1px solid #BDBDBD"}}
                    src="/static/images/help/how-to/gd-results-next-to-google.png"
                    alt="GrabDuck Google Chrome Extension"/>
                </p>
                <p>
                    How does it work? You type your search request in Internet Search Engine (such as Google) and see list of search results based on the
                    request you have typed. With this Extension a new box appears side by side with the search engine results and your previously saved to
                    your GrabDuck account bookmarks will be shown.
                </p>

                <div className="row">
                    <div className="col-6">
                        <h5 className="text-center">
                            <a href="https://chrome.google.com/webstore/detail/search-results-on-grabduc/lmhcjlaiimccmeighbpchjhjkiganhhh"
                               target="_blank"
                               rel="noopener noreferrer">
                                <div><i className="fab fa-chrome fa-fw fa-2x"/></div>

                                <span>Search Results on Grabduck - Google Chrome extension</span> <i
                                className="fas fa-external-link-alt"></i>
                            </a>
                        </h5>
                    </div>
                    <div className="col-6">
                        <h5 className="text-center">
                            <a href="https://addons.mozilla.org/addon/search-results-on-grabduck/"
                               target="_blank"
                               rel="noopener noreferrer">
                                <div><i className="fab fa-firefox fa-fw fa-2x"/></div>
                                <span>Search Results on Grabduck - Mozilla Firefox extension</span> <i
                                className="fas fa-external-link-alt"></i>
                            </a>
                        </h5>
                    </div>
                </div>
                <p>
                    <strong>Hint:</strong> Also might be interesting for you -&nbsp;
                    <Link to={LinkService.helpHowToSavingBookmarks()}>How to save bookmarks</Link>
                </p>
            </div>
        )
    }
}

export default HelpHowToShowResultsNextToISE;
