import React, {Component} from 'react'
import {Route, Switch} from "react-router-dom";
import LinkService from "../services/LinkService";
import LocalStorage from "../services/LocalStorage";
import {Alert, Button} from "react-bootstrap";
import {FormattedMessage} from "react-intl";

class WelcomeAlert extends Component {
    state = {
        showInboxAlert: LocalStorage.getOrDefault("Main.showInboxAlert", true),
        showHistoryAlert: LocalStorage.getOrDefault("Main.showHistoryAlert", true),
        showFavoritesAlert: LocalStorage.getOrDefault("Main.showFavoritesAlert", true),
    };

    handleCloseInbox = () => {
        LocalStorage.set("Main.showInboxAlert", false);
        this.setState({showInboxAlert: false});
    };

    handleCloseHistory = () => {
        LocalStorage.set("Main.showHistoryAlert", false);
        this.setState({showHistoryAlert: false});
    };

    handleCloseFavorites = () => {
        LocalStorage.set("Main.showFavoritesAlert", false);
        this.setState({showFavoritesAlert: false});
    };

    renderInboxAlert = () => {
        return (
            <React.Fragment>
                {this.state.showInboxAlert &&
                <Alert variant="primary" onClose={this.handleCloseInbox} dismissible className="inbox-background">
                    <p><FormattedMessage id="main.welcomeAlert.inbox.info"/></p>
                    <p><strong><FormattedMessage id="common.hint"/></strong>&#32;<FormattedMessage id="main.welcomeAlert.inbox.hint"/></p>
                    <hr/>
                    <div className="d-flex justify-content-end">
                        <Button onClick={this.handleCloseInbox} variant="light">
                            <FormattedMessage id="common.gotIt"/>
                        </Button>
                    </div>
                </Alert>
                }
            </React.Fragment>
        );
    };

    renderHistoryAlert = () => {
        return (
            <React.Fragment>
                {this.state.showHistoryAlert &&
                <Alert variant="primary" onClose={this.handleCloseHistory} dismissible className="history-background">
                    <p><FormattedMessage id="main.welcomeAlert.history.info"/></p>
                    <p><strong><FormattedMessage id="common.hint"/></strong>&#32;<FormattedMessage id="main.welcomeAlert.history.hint"/></p>
                    <hr/>
                    <div className="d-flex justify-content-end">
                        <Button onClick={this.handleCloseHistory} variant="light">
                            <FormattedMessage id="common.gotIt"/>
                        </Button>
                    </div>
                </Alert>
                }
            </React.Fragment>
        );
    };

    renderFavoritesAlert = () => {
        return (
            <React.Fragment>
                {this.state.showFavoritesAlert &&
                <Alert variant="primary" onClose={this.handleCloseFavorites} dismissible className="favorites-background">
                    <p><FormattedMessage id="main.welcomeAlert.favorites.info"/></p>
                    <p><strong><FormattedMessage id="common.hint"/></strong>&#32;<FormattedMessage id="main.welcomeAlert.favorites.hint"/></p>
                    <hr/>
                    <div className="d-flex justify-content-end">
                        <Button onClick={this.handleCloseFavorites} variant="light">
                            <FormattedMessage id="common.gotIt"/>
                        </Button>
                    </div>
                </Alert>
                }
            </React.Fragment>
        );
    };

    render = () => {
        return (
            <Switch>
                <Route path={LinkService.inbox()} render={this.renderInboxAlert}/>
                <Route path={LinkService.history()} render={this.renderHistoryAlert}/>
                <Route path={LinkService.favorites()} render={this.renderFavoritesAlert}/>
            </Switch>
        )
    }
}

export default WelcomeAlert;
