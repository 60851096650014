import React, {Component} from 'react'
import {FormattedMessage} from "react-intl";

class HelpFaqWhatArePermalinks extends Component {
    render = () => {
        return (
            <div className="help-content">
                <h3>
                    <FormattedMessage id="help.faq.whatAreFixedLinks"/>
                    <small className="text-muted"> / <FormattedMessage id="help.faq.full"/></small>
                </h3>

                <p>
                    GrabDuck does not simply persist bookmarks for you, it saves you links to valuable information in the Internet, such as good articles or
                    interesting videos. So when you add a bookmark into your account, GrabDuck tries to process it in a proper way, merging potential duplicates
                    and trying to provide you the real link to the article you will be interested in in the future.
                </p>
                <p>
                    That's why, for example, it makes no harm if you will add the same bookmark several times. Or you can add different
                    versions of the same article (from your desktop computer or from your mobile device). They all will be merged into one at the end in order
                    to provide you the best user experience.
                </p>
                <p>
                    This approach works most of the time without any problems. But sometime you might want an exception. For example your article was published
                    by a very old website that does not follow modern standard of the web development, or you need to save a link to your private account from
                    another system, which is protected by your credentials. In all these cases you might want to preserve the link you're adding. So you need to
                    have a way, how to save the link directly, avoiding post processing GrabDuck will do for you otherwise.
                </p>
                <p>
                    Creating permalink bookmarks is such a way. Permalink bookmark means several things. First, the bookmark's URL will stay untouched and
                    will never be analysed and changed by GrabDuck (but you still can add multiple fixed link bookmarks, with the same link, GrabDuck will take
                    care of them anyway.) And second, they will never appear as recommendations for other users.
                </p>
                <p>
                    There are several ways you can create a permalink bookmark. The most obvious one, when you add a new bookmark just set the according
                    checkbox
                    (available in chrome extension, when run as a scriptlet or adding from the website).
                </p>
                <p className="text-center"><img src="/static/images/help/faq/make-fix-bookmark.png" alt="make permalink bookmark"/></p>
                <p>
                    Another option is to create a new permalink bookmark from already existing bookmark's original links.
                </p>
                <p className="text-center"><img src="/static/images/help/faq/create-fix-link-from-original.png" alt="create permalink from original"/></p>
            </div>
        )
    }
}

export default HelpFaqWhatArePermalinks;
