import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ProgressModal from "../../shell/ProgressModal";
import {SubscriptionService} from "../../services/SubscriptionService";

class CancelSubscriptionDialog extends Component {
    state = {
        showError: false,
        showProgress: false
    };

    handleCancelSubscription = () => {
        this.setState({
            showProgress: true,
            showError: false
        });
        SubscriptionService
            .cancelSubscription()
            .then(() => {
                this.setState({
                    showProgress: false,
                    showError: false
                }, () => {
                    this.props.onCancelSubscription();
                    this.props.onClose();
                });

            })
            .catch(() => {
                this.setState({
                    showProgress: false,
                    showError: true
                });
            });
    };

    render = () => {
        const {formatMessage} = this.props.intl;

        return (
            <React.Fragment>
                <Modal show={this.props.open} onHide={this.props.onClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title><FormattedMessage id="account.subscription.cancelation"/></Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Alert variant="danger" show={this.state.showError} onClose={() => this.setState({showError: false})} dismissible>
                            <FormattedMessage id="account.subscription.cancelling.error"/>
                        </Alert>
                        <p><FormattedMessage id="account.subscription.cancelation.text"/></p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="light" onClick={this.props.onClose}>
                            <FormattedMessage id="account.subscription.cancelation.no"/>
                        </Button>
                        <Button variant="danger" onClick={this.handleCancelSubscription}>
                            <FormattedMessage id="account.subscription.cancelation.yes"/>
                        </Button>
                    </Modal.Footer>
                </Modal>
                <ProgressModal show={this.state.showProgress} message={formatMessage({id: "account.subscription.cancelling"})}/>
            </React.Fragment>
        )
    }
}

CancelSubscriptionDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancelSubscription: PropTypes.func.isRequired
};

export default injectIntl(CancelSubscriptionDialog);
