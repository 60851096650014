import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Card, Col, Container, Row} from "react-bootstrap";
import LocalStorage from "../services/LocalStorage";
import ReaderService from "../services/ReaderService";
import Readability from "./Readability";
import ConfigureToolbar from "./ConfigureToolbar";
import BookmarksService from "../services/BookmarksService";
import {FormattedMessage, injectIntl} from "react-intl";
import MetaInfo from "./MetaInfo";
import GrabDuckDivider from "../shell/GrabDuckDivider";
import ShareLinkBar from "../shell/ShareLinkBar";

class Reader extends Component {
    state = {
        article: null,
        textSize: LocalStorage.getOrDefault("Reader.textSize", 1),
        typography: LocalStorage.getOrDefault("Reader.typography", 0),
        showAddedToHistory: false
    };

    UNSAFE_componentWillMount = () => {
        const link = window.location.pathname.substring("/s/".length);
        ReaderService
            .findByIdV4(link)
            .then(article => {
                if (article.id !== link) {
                    window.location.href = "/s/" + article.id;
                }
                this.setState({
                    article: {
                        article: article.article,
                        body: article.body,
                        defaultImage: "https://grabduck.com/common-images/recent-6.png",
                        description: article.description,
                        documentId: article.bookmarkId,
                        favicon: article.faviconUrl,
                        gdLink: article.grabduckUrl,
                        id: article.id,
                        link: article.canonicalUrl,
                        readTime: article.readTime,
                        readability: article.body !== null && article.body.length > 0,
                        title: article.title
                    }
                });
                setTimeout(() => {
                    this.updateHistory();
                }, 1000);
            })
            .catch(() => {
                window.location.href = "/404";
            });
    };

    updateHistory = () => {
        const {formatMessage} = this.props.intl;

        if (this.props.user) {
            BookmarksService
                .updateHistory(this.state.article.documentId)
                .then(() => {
                    this.props.onAddToast({
                        id: Date.now(),
                        type: "success",
                        title: formatMessage({id: "main.reader"}),
                        description: formatMessage({id: "reader.kindle.articleWasAddedToHistory"})
                    })
                })
                .catch(() => {
                });
        }
    };

    onTextSizeChanged = (textSize) => {
        LocalStorage.set("Reader.textSize", textSize);
        this.setState({textSize});
    };

    onTypographyChanged = (typography) => {
        LocalStorage.set("Reader.typography", typography);
        this.setState({typography});
    };

    render = () => {
        return (
            <Container>
                <Row>
                    <Col sm={12} md={{span: 10, offset: 1}} lg={{span: 10, offset: 1}} xl={{span: 8, offset: 2}}>
                        {this.state.article &&
                        <Card className="reader-card">
                            <MetaInfo article={this.state.article}/>
                            <ConfigureToolbar user={this.props.user}
                                              textSize={this.state.textSize}
                                              typography={this.state.typography}
                                              article={this.state.article}
                                              onTextSizeChanged={this.onTextSizeChanged}
                                              onTypographyChanged={this.onTypographyChanged}
                                              onAddToast={this.props.onAddToast}
                            />
                            <Card.Body>
                                <Readability textSize={this.state.textSize}
                                             typography={this.state.typography}
                                             theme={this.state.theme}
                                             article={this.state.article}
                                />
                                <GrabDuckDivider/>
                                <ShareLinkBar article={this.state.article}/>
                            </Card.Body>
                        </Card>
                        }

                        <p className="text-muted text-center"><a href="/">GrabDuck</a> <FormattedMessage id="reader.footerMessage"/></p>
                    </Col>
                </Row>
            </Container>
        )
    }
}

Reader.propTypes = {
    user: PropTypes.object,
    onAddToast: PropTypes.func.isRequired
};

export default injectIntl(Reader);
