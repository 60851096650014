import React, {Component} from 'react'
import {Form, Row} from "react-bootstrap";
import {injectIntl} from "react-intl";
import SearchService from "../../services/SearchService";
import {withRouter} from "react-router-dom";

class FavoriteFilters extends Component {
    state = {
        favoritesFilter: null,
    };

    UNSAFE_componentWillMount = () => {
        const search = SearchService.extractSearch();
        const filter = (search.filter || "").toLowerCase();
        const knownFilters = new Set(["untagged", "failed", "public", "private"]);
        this.setState({favoritesFilter: knownFilters.has(filter) ? filter : "all"});
    };

    UNSAFE_componentWillReceiveProps = () => {
        this.UNSAFE_componentWillMount();
    };

    handleChangeFilter = (event) => {
        this.props.history.push(SearchService.addFavoriteFilter(event.target.value));
    };

    render = () => {
        const {formatMessage} = this.props.intl;
        return (
            <Row noGutters className="justify-content-around mb-3">
                {["all", "untagged", "failed", "public", "private"].map(filter => {
                    return (
                        <Form.Check
                            key={filter} custom type="radio" name="favoritesFilter"
                            checked={this.state.favoritesFilter === filter}
                            value={filter} id={`favoritesFilter.${filter}`}
                            label={formatMessage({id: `main.favoritesFilter.${filter}`})}
                            onChange={this.handleChangeFilter}
                        />
                    );
                })}
            </Row>
        )
    }
}

export default withRouter(injectIntl(FavoriteFilters));
