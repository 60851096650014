import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ProgressModal from "../../shell/ProgressModal";
import UserService from "../../services/UserService";
import InputGroup from 'react-bootstrap/InputGroup'

class ChangePasswordDialog extends Component {
    state = {
        currentPassword: "",
        newPassword: "",

        validationMessage: {},

        showProgress: false,
        showError: false,

        showCurrentPassword: false,
        showNewPassword: false
    };

    getValidationClass = (field) => {
        if (this.state.validationMessage[field] === null) {
            return "is-valid";
        } else if (this.state.validationMessage[field] !== undefined) {
            return "is-invalid";
        } else {
            return "";
        }
    };

    validateNotEmpty = (name) => {
        const {formatMessage} = this.props.intl;

        if (!name || name.trim().length === 0) {
            return formatMessage({id: "common.validation.fieldRequired"});

        } else {
            return null;
        }
    };

    handleTextFieldChange = (prop, value, validateFn) => {
        const validationMessage = this.state.validationMessage;
        validationMessage[prop] = validateFn(value);

        this.setState({
            [prop]: value,
            validationMessage: validationMessage
        });
    };

    isFormInvalid = (currentPassword, newPassword) => {
        return this.validateNotEmpty(currentPassword) !== null || this.validateNotEmpty(newPassword) !== null;
    };

    handleChangePassword = () => {
        const {formatMessage} = this.props.intl;

        this.setState({
            showProgress: true,
            showError: false
        });
        UserService
            .changePassword(this.state.currentPassword, this.state.newPassword)
            .then(() => {
                this.setState({
                    showProgress: false,
                    showError: false
                }, () => {
                    this.props.onClose();
                    this.props.onAddToast({
                        id: Date.now(),
                        type: "success",
                        title: formatMessage({id: "welcome.changePassword"}),
                        description: formatMessage({id: "common.passwordChanged"})
                    });
                    this.props.onLogout();
                });
            })
            .catch(() => {
                this.setState({
                    showProgress: false,
                    showError: true
                });
            });
    };

    render = () => {
        const {formatMessage} = this.props.intl;

        const validationClass = {
            currentPassword: this.getValidationClass("currentPassword"),
            newPassword: this.getValidationClass("newPassword"),
        };

        return (
            <React.Fragment>
                <Modal show={this.props.open} onHide={this.props.onClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title><FormattedMessage id="welcome.changePassword"/></Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Alert variant="danger" show={this.state.showError} onClose={() => this.setState({showError: false})} dismissible>
                            <FormattedMessage id="common.passwordChangeError"/>
                        </Alert>
                        <Form>
                            <Form.Group controlId="changePasswordFormCurrentPassword" className={validationClass.currentPassword}>
                                <Form.Label><FormattedMessage id="account.currentPassword"/></Form.Label>
                                <InputGroup>
                                    <Form.Control type={this.state.showCurrentPassword ? "text" : "password"}
                                                  placeholder={formatMessage({id: "welcome.typePassword"})}
                                                  value={this.state.currentPassword}
                                                  className={validationClass.currentPassword}
                                                  onChange={(e) => this.handleTextFieldChange("currentPassword", e.target.value, this.validateNotEmpty)}
                                    />
                                    <InputGroup.Append>
                                        <Button variant="outline-secondary"
                                                onClick={() => this.setState({showCurrentPassword: !this.state.showCurrentPassword})}>
                                            {this.state.showCurrentPassword ? (<i className="fas fa-eye-slash"/>) : (<i className="fas fa-eye"/>)}
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                                <Form.Text className="invalid-feedback">{this.state.validationMessage.currentPassword}</Form.Text>
                            </Form.Group>

                            <Form.Group controlId="changePasswordFormNewPassword" className={validationClass.newPassword}>
                                <Form.Label><FormattedMessage id="account.newPassword"/></Form.Label>
                                <InputGroup>
                                    <Form.Control type={this.state.showNewPassword ? "text" : "password"}
                                                  placeholder={formatMessage({id: "welcome.typePassword"})}
                                                  value={this.state.newPassword}
                                                  className={validationClass.newPassword}
                                                  onChange={(e) => this.handleTextFieldChange("newPassword", e.target.value, this.validateNotEmpty)}
                                    />
                                    <InputGroup.Append>
                                        <Button variant="outline-secondary"
                                                onClick={() => this.setState({showNewPassword: !this.state.showNewPassword})}>
                                            {this.state.showNewPassword ? (<i className="fas fa-eye-slash"/>) : (<i className="fas fa-eye"/>)}
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                                <Form.Text className="invalid-feedback">{this.state.validationMessage.newPassword}</Form.Text>
                            </Form.Group>
                        </Form>

                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="light" onClick={this.props.onClose}><FormattedMessage id="common.cancel"/></Button>
                        <Button variant="primary"
                                onClick={this.handleChangePassword}
                                disabled={this.isFormInvalid(this.state.currentPassword, this.state.newPassword)}>
                            <FormattedMessage id="common.change"/>
                        </Button>
                    </Modal.Footer>
                </Modal>
                <ProgressModal show={this.state.showProgress} message={formatMessage({id: "common.changingPassword"})}/>
            </React.Fragment>
        )
    }
}

ChangePasswordDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAddToast: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired
};

export default injectIntl(ChangePasswordDialog);
