import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl";
import {Button, Image, Table} from "react-bootstrap";
import SyncProviderDialog from "./SyncProviderDialog";
import DeleteSyncDialog from "./DeleteSyncDialog";

class ConfiguredServices extends Component {
    state = {
        showConfigDialog: {},
        deleteSync: null
    };

    renderFirstSteps = () => {
        return (
            <React.Fragment>
                <p className="lead"><FormattedMessage id="account.sync.firstStepsDesc"/></p>
                <div className="d-flex justify-content-center">
                    <Button variant="primary" onClick={this.props.onSelectAllServices}>
                        <FormattedMessage id="account.sync.addFirstIntegration"/>
                    </Button>
                </div>
            </React.Fragment>
        );
    };

    renderErrorBadge = (service) => {
        if (service.status === 'FAILED' || service.status === 'STOPPED') {
            return <span className="badge badge-danger"><FormattedMessage id={`account.sync.status.${service.status.toLowerCase()}`}/></span>;

        } else if (service.status === 'DISABLED') {
            return <span className="badge badge-warning"><FormattedMessage id={`account.sync.status.${service.status.toLowerCase()}`}/></span>;

        } else {
            return null;
        }
    };

    renderIcon = (service) => {
        const iconUrl = service.iconImageUrl.replace("/common-images/", `${process.env.PUBLIC_URL}/static/images/`); // "https://grabduck.com/static/images/");
        return (<Image src={iconUrl} rounded style={{width: 32}}/>)
    };

    changeVisibilityForConfigDialog = (serviceId, visibility) => {
        const showConfigDialog = this.state.showConfigDialog;
        showConfigDialog[serviceId] = visibility;
        this.setState({showConfigDialog: showConfigDialog});
    };

    generateTitle = (title, details) => {
        return `${details} - ${title}`;
    };

    showDeleteSyncDialog = (sync) => {
        this.setState({
            deleteSync: {
                id: sync.id,
                title: this.generateTitle(sync.title, sync.details)
            }
        });
    };

    renderConfiguredServices = () => {
        const {formatMessage, formatRelative} = this.props.intl;
        return (
            <Table hover>
                <tbody>
                {this.props.services.map(service => {
                    const title = this.generateTitle(service.title, service.details);
                    const date = service.updated > 0
                        ? formatRelative(new Date(service.updated))
                        : formatMessage({id: "account.sync.never"});
                    const status = formatMessage({id: "account.sync.lastUpdated"}, {date: date});
                    return (
                        <React.Fragment key={service.id}>
                            <tr className="clickable" onClick={() => this.changeVisibilityForConfigDialog(service.id, true)}>
                                <td style={{paddingRight: 0}}>{this.renderIcon(service)}</td>
                                <td>
                                    <span>{title}</span> {this.renderErrorBadge(service)}<br/>
                                    <small className="text-muted">{status}</small>
                                </td>
                                <td>
                                    <Button variant="light" className="text-black-50 no-focus" onClick={(e) => {
                                        e.stopPropagation();
                                        this.showDeleteSyncDialog(service);
                                    }}>
                                        <i className="fas fa-trash"/>
                                    </Button>
                                </td>
                            </tr>
                            <SyncProviderDialog show={this.state.showConfigDialog[service.id] === true}
                                                onHide={() => this.changeVisibilityForConfigDialog(service.id, false)}
                                                service={service}
                                                onAddToast={this.props.onAddToast}
                                                onConfiguredServicesChange={this.props.onConfiguredServicesChange}
                            />
                        </React.Fragment>
                    );
                })}
                </tbody>
            </Table>
        );
    };

    render = () => {
        const freeSpots = Math.max(this.props.maxAllowed - this.props.services.length, 0);

        return (
            <React.Fragment>
                <div className="d-flex justify-content-end">
                    <p><FormattedMessage id="account.sync.freeSpots" values={{spots: freeSpots}}/></p>
                </div>
                {this.props.services.length === 0 && this.renderFirstSteps()}
                {this.props.services.length > 0 && this.renderConfiguredServices()}
                <DeleteSyncDialog sync={this.state.deleteSync}
                                  onHide={() => this.setState({deleteSync: null})}
                                  onAddToast={this.props.onAddToast}
                                  onConfiguredServicesChange={this.props.onConfiguredServicesChange}
                />
            </React.Fragment>
        )
    }
}

ConfiguredServices.propTypes = {
    services: PropTypes.array.isRequired,
    maxAllowed: PropTypes.number.isRequired,
    onSelectAllServices: PropTypes.func.isRequired,
    onAddToast: PropTypes.func.isRequired,
    onConfiguredServicesChange: PropTypes.func.isRequired,
};

export default injectIntl(ConfiguredServices);
