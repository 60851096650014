import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl"
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

class ShareLinkBar extends Component {
    openInNewTab = (url) => {
        const win = window.open(url, '_blank');
        win.focus();
    };

    shareOnFacebook = () => {
        this.openInNewTab("http://www.facebook.com/sharer.php?u=" + window.encodeURIComponent(this.props.article.gdLink));
    };

    shareOnTwitter = () => {
        this.openInNewTab("https://twitter.com/intent/tweet?text=" +
            window.encodeURIComponent(this.props.article.title + " - " + this.props.article.gdLink));
    };

    shareViaEmail = () => {
        window.open("mailto:?subject=" + window.encodeURIComponent(this.props.article.title) +
            "&body=" + window.encodeURIComponent(this.props.article.title + " - " + this.props.article.gdLink), "_self");
    };

    render = () => {
        return (
            <div className="d-flex justify-content-center" style={{marginTop: 16, marginBotton: 16}}>
                <OverlayTrigger placement="top" overlay={<Tooltip><FormattedMessage id="common.share.facebook"/></Tooltip>}>
                    <Button variant="light" onClick={this.shareOnFacebook} className="no-focus text-black-50" style={{marginLeft: 24, marginRight: 24}}>
                        <i className={["fab", "fa-fw", "fa-facebook-f"].join(" ")}/>
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip><FormattedMessage id="common.share.twitter"/></Tooltip>}>
                    <Button variant="light" onClick={this.shareOnTwitter} className="no-focus text-black-50" style={{marginLeft: 24, marginRight: 24}}>
                        <i className={["fab", "fa-fw", "fa-twitter"].join(" ")}/>
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip><FormattedMessage id="common.share.email"/></Tooltip>}>
                    <Button variant="light" onClick={this.shareViaEmail} className="no-focus text-black-50" style={{marginLeft: 24, marginRight: 24}}>
                        <i className={["fas", "fa-fw", "fa-envelope"].join(" ")}/>
                    </Button>
                </OverlayTrigger>
            </div>
        )
    }
}

ShareLinkBar.propTypes = {
    article: PropTypes.object.isRequired
};

export default ShareLinkBar;
