import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import SetupCompletionRate from "./SetupCompletionRate";
import LinkService from "../services/LinkService";
import IconButton from "./IconButton";
import UserService from "../services/UserService";
import {withRouter} from "react-router-dom";

class SetupProgress extends Component {
    getProgressMenu = () => {
        const {formatMessage} = this.props.intl;
        return [{
            key: "createAccount",
            title: formatMessage({id: "welcome.createAccount"}),
            icon: "fas fa-user-astronaut",
        }, {
            key: "activateAccount",
            title: formatMessage({id: "account.activateAccount"}),
            icon: "fas fa-power-off",
        }, {
            key: "setupIntegrations",
            title: formatMessage({id: "account.setupIntegrations"}),
            icon: "fas fa-sync",
            link: LinkService.accountSync()
        }, {
            key: "importBookmarks",
            title: formatMessage({id: "help.howTo.importBookmarks"}),
            icon: "fas fa-cloud-upload-alt",
            link: LinkService.accountImportExport()
        }, {
            key: "installExtensions",
            title: formatMessage({id: "account.installExtensions"}),
            icon: "fas fa-layer-group",
            link: LinkService.helpHowToSavingBookmarks()
        }, {
            key: "readManual",
            title: formatMessage({id: "account.readHelp"}),
            icon: "fas fa-question",
            link: LinkService.help()
        }, {
            key: "startMembership",
            title: formatMessage({id: "account.startMembership"}),
            icon: "fas fa-id-card-alt",
            link: LinkService.accountSubscriptionUpgrade()
        }]
    };

    handleClick = (item) => {
        if (item.link !== undefined) {
            if (item.done) {
                this.props.history.push(item.link);

            } else {
                UserService
                    .updateSetupProgress(item.key, true)
                    .then(() => {
                        this.props.onUserUpdate();
                        this.props.history.push(item.link);
                    })
                    .catch(() => {
                        this.props.onUserUpdate();
                        this.props.history.push(item.link);
                    });
            }
        }
    };

    handleCloseClick = () => {
        UserService
            .updateSetupProgress("showSetupProgress", false)
            .then(() => {
                this.props.onUserUpdate();
            })
            .catch(() => {
            });
    };

    render = () => {
        const buttons = this
            .getProgressMenu()
            .map(item => {
                item.done = this.props.setupProgress[item.key];
                return item;
            })
            .map(item => {
                return (
                    <Col key={item.key} md={3} sm={4} xm={6} style={{padding: 8}}>
                        <IconButton title={item.title} item={item} onClick={this.handleClick}/>
                    </Col>
                );
            });

        return (
            <Card className="component-margin">
                <Card.Header style={{padding: 0}}><SetupCompletionRate percentage={this.props.setupProgress.completion} /></Card.Header>
                <Card.Body>
                    <Container fluid={true}>
                        <Row noGutters={true}>{buttons}</Row>
                        {this.props.closable &&
                        <Row noGutters={true} className="justify-content-end">
                            <Button variant="link" onClick={this.handleCloseClick}>
                                <FormattedMessage id="common.close"/>
                            </Button>
                        </Row>
                        }
                    </Container>
                </Card.Body>
            </Card>
        )
    }
}

SetupProgress.propTypes = {
    setupProgress: PropTypes.object.isRequired,
    onUserUpdate: PropTypes.func.isRequired,
    closable: PropTypes.bool.isRequired
};

export default withRouter(injectIntl(SetupProgress));
