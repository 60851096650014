import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {Alert, Form} from "react-bootstrap";
import TagsField from "../shell/TagsField";
import LinkService from "../services/LinkService";
import {Link} from "react-router-dom";
import UrlService from "../services/UrlService";
import BookmarksService from "../services/BookmarksService";
import ProgressModal from "../shell/ProgressModal";

class NewBookmarkDialog extends Component {
    state = {
        url: "",
        tags: [],
        readLater: true,
        fixedLink: false,

        urlValidationMessage: undefined,
        showError: false,
        showProgress: false
    };

    handleHide = () => {
        this.setState({
            url: "",
            tags: [],
            readLater: true,
            fixedLink: false,
            urlValidationMessage: undefined,
            showProgress: false,
            showError: false
        });
        this.props.onHide();
    };

    handleUrlChange = (event) => {
        const urlValidationMessage = this.validateUrl(event.target.value);
        this.setState({
            url: event.target.value,
            urlValidationMessage: urlValidationMessage
        });
    };

    validateUrl = (url) => {
        const {formatMessage} = this.props.intl;

        if (!UrlService.validateURL(url)) {
            return formatMessage({id: "common.validation.validUrl"});

        } else {
            return null;
        }
    };

    isFormInvalid = (url) => {
        return this.validateUrl(url) !== null;
    };

    getValidationClass = (prop) => {
        if (prop === null) {
            return "is-valid";
        } else if (prop !== undefined) {
            return "is-invalid";
        } else {
            return "";
        }
    };

    handleSave = () => {
        const {formatMessage} = this.props.intl;
        this.setState({showError: false, showProgress: true});

        BookmarksService
            .createBookmark(null, this.state.url, this.state.tags, this.state.readLater, this.state.fixedLink)
            .then(() => {
                this.handleHide();
                this.setState({showProgress: false});
                this.props.onAddToast({
                    id: Date.now(),
                    type: "success",
                    title: formatMessage({id: "addBookmark.addBookmark"}),
                    description: formatMessage({id: "addBookmark.newBookmarkAdded"})
                });
            })
            .catch(() => {
                this.setState({showProgress: false, showError: true});
            });
    };

    render = () => {
        const {formatMessage} = this.props.intl;
        const {url} = this.state;
        const urlValidationClass = this.getValidationClass(this.state.urlValidationMessage);

        return (
            <React.Fragment>
                <Modal show={this.props.show} onHide={this.handleHide} centered>
                    <Modal.Header closeButton>
                        <Modal.Title><FormattedMessage id="addBookmark.newBookmark"/></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Alert variant="danger" show={this.state.showError} onClose={() => this.setState({showError: false})} dismissible>
                            <FormattedMessage id="addBookmark.bookmark.failed"/>
                        </Alert>
                        <Form>
                            <Form.Group controlId="addBookmarkForm.url">
                                <Form.Label><FormattedMessage id="main.internetAddress"/></Form.Label>
                                <Form.Control type="url"
                                              className={urlValidationClass}
                                              placeholder={formatMessage({id: "addBookmark.enterWebAddress"})}
                                              value={url}
                                              onChange={this.handleUrlChange}
                                />
                                <Form.Text className="invalid-feedback">{this.state.urlValidationMessage}</Form.Text>
                            </Form.Group>

                            <TagsField tags={this.state.tags} onChange={(tags) => this.setState({tags: tags})}/>

                            {/*<Form.Group controlId="addBookmarkForm.readLater">*/}
                            {/*    <Form.Check custom type="checkbox" label={formatMessage({id: "common.moveToInbox"})}*/}
                            {/*                checked={this.state.readLater}*/}
                            {/*                onChange={e => this.setState({readLater: e.target.checked})}/>*/}
                            {/*</Form.Group>*/}

                            <Form.Group controlId="addBookmarkForm.additionalOptions">
                                <div className="form-check form-check-inline">
                                    <Form.Check
                                        custom
                                        type="checkbox"
                                        checked={this.state.readLater}
                                        onChange={(e) => this.setState({readLater: e.target.checked})}
                                        id="readLater"
                                        label={formatMessage({id: "common.moveToInbox"})}
                                    />
                                </div>
                                <div className="form-check form-check-inline">
                                    <Form.Check
                                        custom
                                        type="checkbox"
                                        checked={this.state.fixedLink}
                                        onChange={(e) => this.setState({fixedLink: e.target.checked})}
                                        id="fixedLink"
                                        label={formatMessage({id: "common.makeFixLink"})}
                                    />
                                </div>
                                {/*<div>*/}
                                {/*    <Form.Check custom type="checkbox" label={formatMessage({id: "common.moveToInbox"})}*/}
                                {/*                checked={this.state.readLater} inline name="readLater"*/}
                                {/*                onChange={e => this.setState({readLater: e.target.checked})}*/}
                                {/*    />*/}

                                {/*    <Form.Check custom type="checkbox" label={formatMessage({id: "common.makeFixLink"})}*/}
                                {/*                checked={this.state.fixedLink} inline name="fixedLink"*/}
                                {/*                onChange={e => this.setState({fixedLink: e.target.checked})}*/}
                                {/*    />*/}
                                {/*</div>*/}

                            </Form.Group>
                        </Form>
                        <p>
                            <strong><FormattedMessage id="common.hint"/>&#32;</strong>
                            <FormattedMessage id="main.newBookmark.installExt"/>
                            <Link to={LinkService.helpHowToSavingBookmarks()}>
                                <FormattedMessage id="help.howTo.savingBookmarks"/>
                            </Link>
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleHide} variant="light">
                            <FormattedMessage id="common.cancel"/>
                        </Button>

                        <Button variant="primary"
                                disabled={this.isFormInvalid(this.state.url)}
                                onClick={this.handleSave}
                        >
                            <FormattedMessage id="addBookmark.addBookmark"/>
                        </Button>
                    </Modal.Footer>
                </Modal>
                <ProgressModal show={this.state.showProgress} message={formatMessage({id: "addBookmark.bookmark.adding"})}/>
            </React.Fragment>
        )
    }
}

NewBookmarkDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    onAddToast: PropTypes.func.isRequired
};

export default injectIntl(NewBookmarkDialog);
