import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import {Form} from "react-bootstrap";
import ChipInput from "./ChipInput";
import TagsService from "../services/TagsService";

class TagsField extends Component {
    prepareChip = (chip) => {
        return chip.replace(/\s\s+/g, ' ').replace(new RegExp(" ", 'g'), "-").toLowerCase();
    };

    handleSubmitTag = (tag) => {
        const tags = this.props.tags.slice();
        tags.push(tag);
        this.props.onChange([...new Set(tags)]);
    };

    handleRemoveTag = (index) => {
        const tags = this.props.tags.slice();
        tags.splice(index, 1);
        this.props.onChange(tags);
    };

    getSuggestions = (input) => {
        return TagsService.getMatches(input);
        // .then(suggestions => Promise.resolve(suggestions.map(suggestion => suggestion.title)));
    };

    render = () => {
        const {formatMessage} = this.props.intl;
        return (
            <Form.Group controlId="tagsField">
                <Form.Label><FormattedMessage id="common.tags"/></Form.Label>
                <ChipInput chips={this.props.tags}
                           chipInputName="tagInput"
                           editPlaceholder={formatMessage({id: "account.sync.newTag"})}
                           onSubmit={this.handleSubmitTag}
                           onRemove={this.handleRemoveTag}
                           prepareChip={this.prepareChip}
                           getSuggestions={this.getSuggestions}
                />
                <Form.Text className="text-muted">{this.props.fieldDescription}</Form.Text>
            </Form.Group>
        )
    }
}

TagsField.propTypes = {
    tags: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    fieldDescription: PropTypes.string
};

export default injectIntl(TagsField);
