import React, {Component} from 'react'
import {FormattedMessage} from "react-intl";

class RecommendationHr extends Component {
    render = () => {
        return (
            <div>
                <small className="reco-hr text-muted"><FormattedMessage id="account.recommendations"/></small>
                <hr/>
            </div>
        )
    }
}

export default RecommendationHr;
