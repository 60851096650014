import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl"
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import HelpPrivacyGDPRShort from "../help/privacy/HelpPrivacyGDPRShort";

class ConsentForm extends Component {
    render = () => {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><FormattedMessage id="welcome.consent"/></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <HelpPrivacyGDPRShort/>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="light" onClick={this.props.onHide}><FormattedMessage id="common.gotIt"/></Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

ConsentForm.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
};

export default ConsentForm;
