import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ProgressModal from "../../shell/ProgressModal";
import Alert from "react-bootstrap/Alert";
import SyncService from "../../services/SyncService";

class DeleteSyncDialog extends Component {
    state = {
        showProgress: false,
        showError: false
    };


    handleDeleteSync = () => {
        const {formatMessage} = this.props.intl;

        this.setState({
            showProgress: true,
            showError: false
        });
        SyncService
            .removeSync(this.props.sync)
            .then(response => {
                this.setState({
                    showProgress: false,
                    showSuccess: true
                });
                this.props.onAddToast({
                    id: Date.now(),
                    type: "success",
                    title: formatMessage({id: "account.sync.delete.title"}),
                    description: formatMessage({id: "account.sync.successfullyDeleted"}, {integration: this.props.sync.title})
                });
                this.props.onHide();
                this.props.onConfiguredServicesChange();

            })
            .catch(() => {
                this.setState({
                    showProgress: false,
                    showError: true
                });
            });
    };

    render = () => {
        const {formatMessage} = this.props.intl;

        return (
            <Modal show={this.props.sync} onHide={this.props.onHide} centered>
                <Modal.Header closeButton>
                    <Modal.Title><FormattedMessage id="account.sync.delete.title"/></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Alert variant="danger" show={this.state.showError} onClose={() => this.setState({showError: false})} dismissible>
                        <FormattedMessage id="account.sync.errorWhileDeleting"/>
                    </Alert>

                    {this.props.sync && <strong>{this.props.sync.title}</strong>}<br/>
                    <FormattedMessage id="account.sync.delete.desc"/>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="light" onClick={this.props.onHide}><FormattedMessage id="common.cancel"/></Button>
                    <Button variant="danger" onClick={this.handleDeleteSync}><FormattedMessage id="common.delete"/></Button>
                </Modal.Footer>

                <ProgressModal show={this.state.showProgress} message={formatMessage({id: "account.sync.deletingIntegration"})}/>
            </Modal>
        )
    }
}

DeleteSyncDialog.propTypes = {
    sync: PropTypes.object,
    onHide: PropTypes.func.isRequired,
    onAddToast: PropTypes.func.isRequired,
    onConfiguredServicesChange: PropTypes.func.isRequired,
};

export default injectIntl(DeleteSyncDialog);
