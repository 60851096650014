import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button} from "react-bootstrap";
import LinkService from "../../services/LinkService";
import {FormattedMessage} from "react-intl";

class Toolbelt extends Component {
    handleBookmarkUpdateAction = (event, action) => {
        event.stopPropagation();
        this.props.onBookmarkUpdateAction(action);
    };

    handleShareBookmark = (event) => {
        event.stopPropagation();
        this.props.onShareBookmark();
    };

    handleShowShredDialog = (event) => {
        event.stopPropagation();
        this.props.onShowShredDialog();
    };

    render = () => {
        const {bookmark, privileges} = this.props;
        const isInbox = window.location.pathname.startsWith(LinkService.inbox());
        const isHistory = window.location.pathname.startsWith(LinkService.history());
        return (
            <div className="d-flex justify-content-around">
                {bookmark.permalink &&
                <Button variant="light" disabled>
                    <i className="fa-fw fas fa-link text-black-50"/><br/>
                    <FormattedMessage id="main.fixedLink"/>
                </Button>
                }

                {!bookmark.favorite &&
                <Button variant="light" onClick={e => this.handleBookmarkUpdateAction(e, "ADD_TO_FAVORITES")}>
                    <i className="fa-fw fas fa-star text-black-50"/><br/>
                    <FormattedMessage id="main.addToFavs"/>
                </Button>
                }

                {bookmark.favorite &&
                <Button variant="light" onClick={e => this.handleBookmarkUpdateAction(e, "REMOVE_FROM_FAVORITES")}>
                    <i className="fa-fw fas fa-trash text-black-50"/><br/>
                    <FormattedMessage id="main.removeFromFavs"/>
                </Button>
                }

                {bookmark.favorite && !bookmark.readLater &&
                <Button variant="light" onClick={e => this.handleBookmarkUpdateAction(e, "MOVE_TO_INBOX")}>
                    <i className="fa-fw fas fa-inbox text-black-50"/><br/>
                    <FormattedMessage id="main.moveToInbox"/>
                </Button>
                }

                {bookmark.favorite && bookmark.readLater && !isInbox &&
                <Button variant="light" onClick={e => this.handleBookmarkUpdateAction(e, "REMOVE_FROM_INBOX")}>
                    <i className="fa-fw fas fa-inbox text-info"/><br/>
                    <FormattedMessage id="main.removeFromInbox"/>
                </Button>
                }

                {bookmark.favorite && bookmark.readLater && !bookmark.flagged && isInbox &&
                <Button variant="light" onClick={e => this.handleBookmarkUpdateAction(e, "FLAG")}>
                    <i className="fa-fw fas fa-flag text-black-50"/><br/>
                    <FormattedMessage id="main.flagBookmark"/>
                </Button>
                }

                {bookmark.favorite && bookmark.readLater && bookmark.flagged && isInbox &&
                <Button variant="light" onClick={e => this.handleBookmarkUpdateAction(e, "UNFLAG")}>
                    <i className="fa-fw fas fa-flag text-info"/><br/>
                    <FormattedMessage id="main.unflagBookmark"/>
                </Button>
                }

                {bookmark.favorite && bookmark.readLater && isInbox &&
                <Button variant="light" onClick={e => this.handleBookmarkUpdateAction(e, "MARK_AS_DONE")}>
                    <i className="fa-fw fas fa-check text-black-50"/><br/>
                    <FormattedMessage id="main.markAsDone"/>
                </Button>
                }

                {!bookmark.private &&
                <Button variant="light" onClick={this.handleShareBookmark}>
                    <i className="fa-fw fas fa-share-alt text-black-50"/><br/>
                    <FormattedMessage id="main.share"/>
                </Button>
                }


                {bookmark.favorite && !bookmark.private &&
                <Button variant="light"
                        onClick={e => this.handleBookmarkUpdateAction(e, "MAKE_PRIVATE")}
                        disabled={!privileges.includes('MAKE_BOOKMARK_PRIVATE')}
                >
                    <i className="fa-fw fas fa-lock text-black-50"/><br/>
                    <FormattedMessage id="main.makeBookmarkPrivate"/>
                </Button>
                }

                {bookmark.favorite && bookmark.private &&
                <Button variant="light" onClick={e => this.handleBookmarkUpdateAction(e, "MAKE_VISIBLE")}>
                    <i className="fa-fw fas fa-lock-open text-info"/><br/>
                    <FormattedMessage id="main.makeBookmarkVisible"/>
                </Button>
                }


                {isHistory &&
                <Button variant="light" onClick={e => this.handleBookmarkUpdateAction(e, "REMOVE_FROM_HISTORY")}>
                    <FormattedMessage id="main.removeFromHistory"/>
                </Button>
                }

                {bookmark.favorite &&
                <Button variant="light" onClick={e => this.handleBookmarkUpdateAction(e, "REFRESH")}>
                    <FormattedMessage id="main.refreshBookmark"/>
                </Button>
                }

                {bookmark.favorite && bookmark.shared === 1 &&
                <Button variant="outline-danger" onClick={this.handleShowShredDialog}>
                    <FormattedMessage id="main.wipeBookmark"/>
                </Button>
                }
            </div>
        )
    }
}

Toolbelt.propTypes = {
    bookmark: PropTypes.object.isRequired,
    privileges: PropTypes.array.isRequired,
    onBookmarkUpdateAction: PropTypes.func.isRequired,
    onShareBookmark: PropTypes.func.isRequired,
    onShredBookmark: PropTypes.func.isRequired,
    onShowShredDialog: PropTypes.func.isRequired
};

export default Toolbelt;
