import React, {Component} from 'react'
import {injectIntl} from "react-intl"
import SearchService from "../services/SearchService";
import WordsCloud from "./WordsCloud";
import PropTypes from "prop-types";

class SourcesCloud extends Component {
    hasWhiteSpace = (s) => {
        return s.indexOf(' ') >= 0;
    }

    render = () => {
        const {formatMessage} = this.props.intl;
        let sources = [];
        if (SearchService.extractSearch().source === undefined) {
            sources = (this.props.tags || [])
                .map(tag => {
                    //     if (!this.hasWhiteSpace(tag.title)) {
                    tag.title = formatMessage({id: 'sources.' + tag.name});
                    //     }
                    //
                    return tag;
                });
        }

        return (
            <WordsCloud words={sources}
                        activeWords={[]}
                        emptyTitle={formatMessage({id: 'main.sources.empty.title'})}
                        emptyDesc={formatMessage({id: 'main.sources.empty.desc'})}
                        progress={this.props.tags === null || this.props.tags === undefined}
            />
        )
    }
}

SourcesCloud.propTypes = {
    tags: PropTypes.array
};


export default injectIntl(SourcesCloud);
