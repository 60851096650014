import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Navbar} from "react-bootstrap";
import SendToKindle from "./SendToKindle";
import Button from "react-bootstrap/Button";
import Selector from "./Selector";
import {injectIntl} from "react-intl";

class ConfigureToolbar extends Component {
    state = {
        showConfig: false
    };

    render = () => {
        const {formatMessage} = this.props.intl;

        return (
            <React.Fragment>
                <Navbar variant={this.state.showConfig ? "light" : ""} bg={this.state.showConfig ? "light" : ""}>
                    {!this.state.showConfig &&
                    <React.Fragment>
                        {this.props.article.article &&
                        <SendToKindle documentId={this.props.article.documentId}
                                      onAddToast={this.props.onAddToast}
                                      user={this.props.user}/>
                        }
                        <div className="mx-auto order-0 d-none d-sm-inline"/>
                        <Button variant="light" onClick={() => this.setState({showConfig: true})}><i className="fas fa-fw fa-cog"/></Button>
                    </React.Fragment>
                    }

                    {this.state.showConfig &&
                    <React.Fragment>
                        <Selector title={formatMessage({id: 'reader.config.textSize'})}
                                  value={this.props.textSize}
                                  onChange={this.props.onTextSizeChanged}
                                  options={[
                                      formatMessage({id: 'reader.config.textSize.small'}),
                                      formatMessage({id: 'reader.config.textSize.medium'}),
                                      formatMessage({id: 'reader.config.textSize.large'})
                                  ]}
                        />
                        <Selector title={formatMessage({id: 'reader.config.typography'})}
                                  value={this.props.typography}
                                  onChange={this.props.onTypographyChanged}
                                  options={[
                                      formatMessage({id: 'reader.config.typography.serif'}),
                                      formatMessage({id: 'reader.config.typography.sans'})
                                  ]}
                        />
                        <div className="mx-auto order-0 d-none d-sm-inline"/>
                        <Button variant="light" onClick={() => this.setState({showConfig: false})}><i className="fas fa-fw fa-times"/></Button>
                    </React.Fragment>
                    }

                </Navbar>
            </React.Fragment>
        )
    }
}

ConfigureToolbar.propTypes = {
    user: PropTypes.object,
    article: PropTypes.object.isRequired,
    textSize: PropTypes.number.isRequired,
    typography: PropTypes.number.isRequired,
    onTextSizeChanged: PropTypes.func.isRequired,
    onTypographyChanged: PropTypes.func.isRequired,
    onAddToast: PropTypes.func.isRequired
};

export default injectIntl(ConfigureToolbar);
