const ReturnPromise = {
    get: function (httpPromise, ignore403 = false) {
        return httpPromise
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response.data;
                }

                return Promise.reject(response);
            })
            .catch(error => {
                if (error.response && error.response.status === 403 && !ignore403) {
                    window.location = "/";
                }

                return Promise.reject(error);
            });
    }
};

export default ReturnPromise;