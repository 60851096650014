import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {ProgressBar} from "react-bootstrap";
import {injectIntl} from "react-intl";

class SetupCompletionRate extends Component {
    render = () => {
        const {formatMessage} = this.props.intl;

        let variant = "";
        if (this.props.percentage <= 33) {
            variant = "danger";
        } else if (this.props.percentage > 33 && this.props.percentage <= 66) {
            variant = "warning";
        } else {
            variant = "success";
        }

        return (
            <ProgressBar now={this.props.percentage}
                         label={`${formatMessage({id: "common.setupProgress"})}: ${this.props.percentage}%`}
                         variant={variant} style={{borderRadius: 0, height: 20}}/>
        )
    }
}

SetupCompletionRate.propTypes = {
    percentage: PropTypes.number.isRequired
};

export default injectIntl(SetupCompletionRate);
