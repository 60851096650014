import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Alert, Button, Col, Container, Form, Navbar, Row} from "react-bootstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import TagsField from "../shell/TagsField";
import BookmarksService from "../services/BookmarksService";
import ProgressModal from "../shell/ProgressModal";
import AlertDialog from "../shell/AlertDialog";

class AddBookmark extends Component {
    state = {
        statusMessage: null,
        showError: false,
        showSuccess: false,
        bookmarkInfoLoaded: false,

        title: "",
        url: "",
        id: null,
        tags: [],
        readLater: true,
        fixedLink: false,

        titleValidationClass: "",
        titleValidationMessage: null
    };

    search = () => {
        if (window.location.search === undefined || window.location.search.trim().length === 0) {
            return {};
        }
        const result = {};
        window.location.search
            .substring(1)
            .split("&")
            .map(item => {
                return item.split("=");
            })
            .forEach(value => {
                result[value[0].toLowerCase()] = decodeURIComponent(value[1]);
            });

        return result;
    };

    UNSAFE_componentWillMount = () => {
        const {formatMessage} = this.props.intl;
        // document.title = formatMessage({id: "addBookmark.saveToGrabDuck"});

        let search = this.search();

        this.setState({
            statusMessage: formatMessage({id: "addBookmark.wait"}),
            title: search.title || "",
            url: search.url || "",
            id: null
        }, () => {
            BookmarksService
                .checkBookmarkByUrlV4(this.state.url)
                .then(data => {
                    this.setState({
                        statusMessage: null,
                        bookmarkInfoLoaded: true,

                        title: data.title,
                        url: data.canonicalUrl,
                        id: data.id,
                        tags: data.tags
                    }, () => {
                        document.title = formatMessage({id: "addBookmark.saveToGrabDuck"}) + " - " + this.getNavbarTitleSuffix();
                    });
                })
                .catch(() => {
                    document.title = formatMessage({id: "addBookmark.saveToGrabDuck"}) + " - " + this.getNavbarTitleSuffix();
                    this.setState({statusMessage: null, bookmarkInfoLoaded: true});
                });
        });

    };

    validateTitleInput = (input) => {
        const {formatMessage} = this.props.intl;

        if (input.trim().length > 0) {
            this.setState({
                titleValidationClass: "is-valid",
                titleValidationMessage: ""
            })
        } else {
            this.setState({
                titleValidationClass: "is-invalid",
                titleValidationMessage: formatMessage({id: "common.validation.fieldRequired"})
            })
        }
    };

    handleTitleChange = (event) => {
        this.validateTitleInput(event.target.value);
        this.setState({title: event.target.value});
    };

    // handleChangeToInbox = (event) => {
    //     this.setState({readLater: event.target.checked});
    // };

    handleTagsChange = (tags) => {
        this.setState({tags});
    };

    isFormInvalid = (title, url) => {
        return !(title.trim().length > 0 && url.trim().length > 0);
    };

    createOrUpdate = () => {
        if (this.state.id === null) {
            return BookmarksService.createBookmark(this.state.title, this.state.url, this.state.tags, this.state.readLater, this.state.fixedLink);
        } else {
            return BookmarksService.updateBookmarkV4(this.state.id, {
                title: this.state.title,
                tags: this.state.tags,
                readLater: this.state.readLater
            });
        }
    };

    handleSave = () => {
        const {formatMessage} = this.props.intl;
        this.setState({
            showError: false,
            statusMessage: this.state.id !== null ? formatMessage({id: "addBookmark.bookmark.updating"}) : formatMessage({id: "addBookmark.bookmark.adding"})
        });

        this
            .createOrUpdate()
            .then(() => {
                this.setState({
                    statusMessage: null,
                    showSuccess: true

                }, () => {
                    setTimeout(() => {
                        window.close();
                    }, 3000);
                });

            })
            .catch(() => {
                this.setState({statusMessage: null, showError: true});
            });
    };

    getNavbarTitleSuffix = () => {
        const {formatMessage} = this.props.intl;
        return this.state.id !== null ? formatMessage({id: "addBookmark.editBookmark"}) : formatMessage({id: "addBookmark.newBookmark"})
    };

    renderNavbar = () => {
        return (
            <Navbar variant="light" bg="transparent" style={{marginBottom: 16, paddingLeft: 0, paddingRight: 0}}>
                <Navbar.Brand>
                    <FormattedMessage id="addBookmark.saveToGrabDuck"/>
                    {this.state.bookmarkInfoLoaded &&
                    <React.Fragment>
                        &nbsp;-&nbsp;{this.getNavbarTitleSuffix()}
                    </React.Fragment>
                    }
                </Navbar.Brand>
                <div className="mx-auto order-0 d-none d-sm-inline"/>
                {this.props.user && <Navbar.Text>{this.props.user.userName}</Navbar.Text>}
            </Navbar>
        );
    };

    renderForm = () => {
        const {formatMessage} = this.props.intl;
        return (
            <Form>
                <Form.Group controlId="addBookmarkForm.title">
                    <Form.Label><FormattedMessage id="common.title"/></Form.Label>
                    <Form.Control type="text" placeholder={formatMessage({id: "common.typeTitle"})}
                                  value={this.state.title}
                                  className={this.state.titleValidationClass}
                                  onChange={this.handleTitleChange}
                    />
                    <Form.Text className="invalid-feedback">{this.state.titleValidationMessage}</Form.Text>
                </Form.Group>

                <Form.Group controlId="addBookmarkForm.url">
                    <Form.Label><FormattedMessage id="main.internetAddress"/></Form.Label>
                    <Form.Control type="text" defaultValue={this.state.url} readOnly/>
                </Form.Group>

                <TagsField tags={this.state.tags}
                           onChange={this.handleTagsChange}
                />

                {/*<Form.Group controlId="addBookmarkForm.readLater">*/}
                {/*    <Form.Check custom type="checkbox" label={formatMessage({id: "common.moveToInbox"})}*/}
                {/*                checked={this.state.readLater}*/}
                {/*                onChange={this.handleChangeToInbox}/>*/}
                {/*</Form.Group>*/}

                <Form.Group controlId="addBookmarkForm.additionalOptions">
                    <div className="form-check form-check-inline">
                        <Form.Check
                            custom
                            type="checkbox"
                            checked={this.state.readLater}
                            onChange={(e) => this.setState({readLater: e.target.checked})}
                            id="readLater"
                            label={formatMessage({id: "common.moveToInbox"})}
                        />
                    </div>
                    {this.state.id === null &&
                    <div className="form-check form-check-inline">
                        <Form.Check
                            custom
                            type="checkbox"
                            checked={this.state.fixedLink}
                            onChange={(e) => this.setState({fixedLink: e.target.checked})}
                            id="fixedLink"
                            label={formatMessage({id: "common.makeFixLink"})}
                        />
                    </div>
                    }
                </Form.Group>

                <div className="d-flex justify-content-end">
                    <Button variant="light" type="button" style={{marginLeft: 8, marginBottom: 16}} onClick={window.close}>
                        <FormattedMessage id="common.cancel"/>
                    </Button>
                    <Button variant="primary" type="button" style={{marginLeft: 8, marginBottom: 16}}
                            onClick={this.handleSave}
                            disabled={this.isFormInvalid(this.state.title, this.state.url)}>
                        {this.state.id !== null ? formatMessage({id: "common.save"}) : formatMessage({id: "addBookmark.addBookmark"})}
                    </Button>
                </div>
            </Form>
        );
    };

    render = () => {
        const {formatMessage} = this.props.intl;
        return (
            <Container>
                <Row>
                    <Col sm={12} md={{span: 8, offset: 2}} lg={{span: 8, offset: 2}} xl={{span: 6, offset: 3}}>
                        {this.renderNavbar()}
                        <Alert variant="danger" show={this.state.showError} onClose={() => this.setState({showError: false})} dismissible>
                            <FormattedMessage id="addBookmark.bookmark.failed"/>
                        </Alert>
                        {this.props.user && this.renderForm()}
                        {this.state.statusMessage !== null && <ProgressModal show={true} message={this.state.statusMessage}/>}
                        <AlertDialog show={this.state.showSuccess}
                                     onHide={window.close}
                                     message={
                                         this.state.id !== null
                                             ? formatMessage({id: "addBookmark.bookmarkUpdated"})
                                             : formatMessage({id: "addBookmark.newBookmarkAdded"})
                                     }
                                     title={this.state.id !== null ? formatMessage({id: "common.save"}) : formatMessage({id: "addBookmark.addBookmark"})}
                                     type="success"/>
                    </Col>
                </Row>
            </Container>
        )
    }
}

AddBookmark.propTypes = {
    user: PropTypes.object
};

export default injectIntl(AddBookmark);
