import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Bookmark from "./bookmark/Bookmark";
import classNames from 'classnames'
import BulkBookmarksEdit from "./BulkBookmarksEdit";
import {injectIntl} from "react-intl";
import BookmarksService from "../services/BookmarksService";
import ProgressModal from "../shell/ProgressModal";
import RecommendationHr from "./RecommendationHr";

class BookmarksList extends Component {
    state = {
        expandedBookmarkId: null,
        lastSelectedBookmark: null,
        selectedCount: null,
        selectedBookmarksCount: {},
        showProgress: false
    };

    scrollToId = (elementId) => {
        let bookmark = document.getElementById(elementId);
        bookmark.scrollIntoView({
            behavior: "auto",
            block: "center",
            inline: "nearest"
        });
        bookmark.classList.add("pulse");
        setTimeout(() => {
            bookmark.classList.remove("pulse");
        }, 2000);
    };

    handleExpand = (bookmark, expanded) => {
        if (!expanded && bookmark.id === this.state.expandedBookmarkId) {
            this.setState({expandedBookmarkId: null});
        }
        if (expanded) {
            this.setState({expandedBookmarkId: bookmark.id});
        }
        this.scrollToId(bookmark.id)
    };

    removeBookmarkFromList = (bookmark) => {
        //TODO: update next request, so it does not get the same bookmark second time
        bookmark.visible = false;
        bookmark.selected = false;
        // const i = this.props.bookmarks.map(b => b.id).indexOf(bookmark.id);
        // if (i > -1) {
        //     const bookmarks = this.props.bookmarks;
        //     bookmarks.splice(i, 1);
        //     // this.props.onBookmarksUpdated(bookmarks);
        // }
    };

    updateBookmark = (bookmark) => {
        const i = this.props.bookmarks.map(b => b.id).indexOf(bookmark.id);
        if (i > -1) {
            const bookmarks = this.props.bookmarks;
            bookmarks[i] = bookmark;
            this.props.onBookmarksUpdated(bookmarks);
        }
    };

    findBookmarkIndex = (bookmark) => {
        return this.props.bookmarks.map(b => b.id).indexOf(bookmark.id);
    };

    handleSelectBookmark = (bookmark, isMultipleSelection) => {
        const i = this.findBookmarkIndex(bookmark);
        if (i > -1) {
            const bookmarks = this.props.bookmarks;

            if (isMultipleSelection) {
                ///////
                if (window.getSelection) {
                    if (window.getSelection().empty) {  // Chrome
                        window.getSelection().empty();
                    } else if (window.getSelection().removeAllRanges) {  // Firefox
                        window.getSelection().removeAllRanges();
                    }
                } else if (document.selection) {  // IE?
                    document.selection.empty();
                }
                //////////////


                const firstIndex = this.state.lastSelectedBookmark
                    ? Math.max(this.findBookmarkIndex(this.state.lastSelectedBookmark), 0)
                    : 0;
                const beginIndex = Math.min(firstIndex, i);
                const endIndex = Math.max(firstIndex, i);

                for (let c = beginIndex; c <= endIndex; c++) {
                    bookmarks[c].selected = bookmark.selected;
                }

            } else {
                bookmarks[i] = bookmark;
            }

            this.setState({
                // bookmarks: bookmarks,
                lastSelectedBookmark: bookmark,
                expandedBookmarkId: null
            });
            this.calcCount();
        }
        // this.updateSelectionMode();
    };

    handleSelectNone = () => {
        this.props.bookmarks.forEach(bookmark => {
            if (bookmark.selected) {
                bookmark.selected = false;
            }
        });
        this.calcCount();
    };

    handleSelectAll = () => {
        this.props.bookmarks.forEach(bookmark => {
            if (!bookmark.selected) {
                bookmark.selected = true;
            }
        });
        this.calcCount();
    };

    handleSelectRevert = () => {
        this.props.bookmarks.forEach(bookmark => {
            if (bookmark.selected) {
                bookmark.selected = false;
            } else {
                bookmark.selected = true;
            }
        });
        this.calcCount();
    };

    calcCount = () => {
        const selected = this.props.bookmarks.filter(bookmark => bookmark.selected);
        const selectedCount = selected.length;
        const notFavorites = selected.filter(bookmark => !bookmark.favorite).length;
        const favorites = selected.filter(bookmark => bookmark.favorite).length;
        const inbox = selected.filter(bookmark => bookmark.readLater).length;
        const publicNum = selected.filter(bookmark => !bookmark.private).length;
        const privateNum = selected.filter(bookmark => bookmark.private).length;
        this.setState({
            selectedCount: selectedCount,
            selectedBookmarksCount: {
                notFavorites: notFavorites,
                favorites: favorites,
                inbox: inbox,
                public: publicNum,
                private: privateNum
            }
        });
        this.props.onShowBulkEditPanel(selectedCount > 0);
    };

    findBookmarkIndex = (bookmark) => {
        return this.props.bookmarks.map(b => b.id).indexOf(bookmark.id);
    };

    multipleSelectionOp = (filterFn, performOperationFn, finishedMsg, errorMsg, remove) => {
        const {formatMessage} = this.props.intl;
        this.setState({showProgress: true});
        const bookmarkIds = this.props.bookmarks.filter(b => b.selected).filter(b => filterFn(b)).map(b => b.id);
        performOperationFn(bookmarkIds)
            .then(data => {
                if (data.bookmarks !== undefined) {
                    const bookmarks = this.props.bookmarks;
                    data
                        .bookmarks
                        .map(b => {
                            b.selected = true;
                            if (remove === true) {
                                b.visible = false;
                                b.selected = false;
                            }
                            b.loaded = Date.now();
                            return b;
                        })
                        .forEach(b => {
                            const i = this.findBookmarkIndex(b);
                            if (i > -1) {
                                bookmarks[i] = b;
                            }
                        });
                    this.props.onBookmarksUpdated(bookmarks);
                }
                this.props.onAddToast({
                    id: Date.now(),
                    type: "success",
                    title: formatMessage({id: "main.bulkEditBookmark"}),
                    description: formatMessage({id: finishedMsg})
                });
                this.calcCount();
                this.setState({showProgress: false});
            })
            .catch(() => {
                this.props.onAddToast({
                    id: Date.now(),
                    type: "error",
                    title: formatMessage({id: "main.bulkEditBookmark"}),
                    description: formatMessage({id: errorMsg}),
                });
                this.calcCount();
                this.setState({showProgress: false});
            });
    };

    handleBulkAddToFavs = () => {
        this.multipleSelectionOp(b => b.favorite === false && b.private !== true,
            BookmarksService.bulkAdd, "main.multipleOps.favsAdd.finished", "main.multipleOps.error");
    };

    handleBulkDelFromFavs = () => {
        this.multipleSelectionOp(b => b.favorite === true,
            BookmarksService.bulkDelete, "main.multipleOps.favsRemoval.finished", "main.multipleOps.error");
        setTimeout(() => {
            this.props.onUserUpdate()
        }, 1000);
    };

    handleBulkMovToInbox = () => {
        this.multipleSelectionOp(b => b.favorite === true && b.readLater === undefined,
            BookmarksService.bulkToInbox, "main.multipleOps.favsToInbox.finished", "main.multipleOps.error");
        setTimeout(() => {
            this.props.onUserUpdate()
        }, 1000);
    };

    handleBulkMrkAsDone = () => {
        this.multipleSelectionOp(b => b.favorite === true && b.readLater !== undefined,
            BookmarksService.bulkFromInbox, "main.multipleOps.favsFromInbox.finished", "main.multipleOps.error");
        setTimeout(() => {
            this.props.onUserUpdate()
        }, 1000);
    };

    handleBulkMakePublic = () => {
        this.multipleSelectionOp(b => b.favorite === true && b.private === true,
            BookmarksService.bulkPublic, "main.bookmarkMadePublic", "main.multipleOps.error");
    };

    handleBulkMakePrivate = () => {
        this.multipleSelectionOp(b => b.favorite === true && b.private === false,
            BookmarksService.bulkPrivate, "main.bookmarkMadePrivate", "main.multipleOps.error");
    };

    render = () => {
        const {formatMessage} = this.props.intl;
        return (
            <div className={classNames({"bookmarks-selected": this.state.selectedCount > 0})}>
                {this.state.selectedCount > 0 &&
                <BulkBookmarksEdit
                    privileges={this.props.privileges || []}
                    selectedNumber={this.state.selectedCount}
                    selectedBookmarksCount={this.state.selectedBookmarksCount}

                    onSelectNone={this.handleSelectNone}
                    onSelectAll={this.handleSelectAll}
                    onSelectRevert={this.handleSelectRevert}

                    onActionAddToFavs={this.handleBulkAddToFavs}
                    onActionDelFromFavs={this.handleBulkDelFromFavs}
                    onActionMovToInbox={this.handleBulkMovToInbox}
                    onActionMrkAsDone={this.handleBulkMrkAsDone}
                    onActionMakePublic={this.handleBulkMakePublic}
                    onActionMakePrivate={this.handleBulkMakePrivate}
                />
                }

                {this.props.bookmarks.map((bookmark, index) => {
                    return (
                        <React.Fragment key={bookmark.id}>
                            {index > 0 && index < this.props.bookmarks.length &&
                            bookmark.recommendation === true && this.props.bookmarks[index - 1].recommendation !== true &&
                            <RecommendationHr/>
                            }
                            <Bookmark key={bookmark.id}
                                      bookmark={bookmark}
                                      onExpand={this.handleExpand}
                                      expanded={bookmark.id === this.state.expandedBookmarkId}
                                      privileges={this.props.privileges}
                                      onBookmarkRemoved={this.removeBookmarkFromList}
                                      onBookmarkUpdated={this.updateBookmark}
                                      onAddToast={this.props.onAddToast}
                                      onUserUpdate={this.props.onUserUpdate}
                                      onBookmarkSelected={this.handleSelectBookmark}
                                      selectionMode={this.state.selectedCount > 0}
                            />
                            {index > 0 && index < (this.props.bookmarks.length - 1) &&
                            bookmark.recommendation === true && this.props.bookmarks[index + 1].recommendation !== true &&
                            <hr/>
                            }
                        </React.Fragment>
                    )
                })}

                <ProgressModal show={this.state.showProgress} message={formatMessage({id: "common.processing"})}/>
            </div>
        )
    }
}

BookmarksList.propTypes = {
    bookmarks: PropTypes.array.isRequired,
    privileges: PropTypes.array.isRequired,
    onAddToast: PropTypes.func.isRequired,
    onBookmarksUpdated: PropTypes.func.isRequired,
    onUserUpdate: PropTypes.func.isRequired,
    onShowBulkEditPanel: PropTypes.func.isRequired,
};

export default injectIntl(BookmarksList);
