import SearchService from "./SearchService";

const LinkService = {
    home: function (user) {
        if (user) {
            if (user.inboxCount > 0) {
                return LinkService.inbox();

            } else if (user.historyCount > 0) {
                return LinkService.history();

            } else {
                return LinkService.favorites();
            }

        } else {
            return LinkService.welcome();
        }
    },
    inbox: function () {
        return "/inbox";
    },
    welcome: function () {
        return "/welcome";
    },
    history: function () {
        return "/last";
    },
    help: function () {
        return "/help";
    },
    favorites: function () {
        return "/favorites";
    },
    search: function () {
        return "/search";
    },
    addBookmark: function () {
        return "/add";
    },
    reader: function () {
        return "/s/";
    },

    // ACCOUNT LINKS
    account: function () {
        return "/account";
    },
    accountSummary: function () {
        return "/account/summary";
    },
    accountSubscription: function () {
        return "/account/subscription";
    },

    accountSettings: function () {
        return "/account/settings";
    },
    accountImportExport: function () {
        return "/account/import-bookmarks";
    },
    accountSync: function () {
        return "/account/sync";
    },
    accountSubscriptionUpgrade: function () {
        return "/account/subscription/upgrade";
    },
    accountSubscriptionCheckout: function (plan) {
        return `/account/subscription/checkout?plan=${plan}`;
    },
    accountSubscriptionCheckoutBase: function () {
        return "/account/subscription/checkout";
    },

    // HELP LINKS
    helpPrivacyPolicy: function () {
        return "/help/privacy/policy";
    },
    helpTOS: function () {
        return "/help/terms-of-service";
    },
    helpPrivacyGDPR: function () {
        return "/help/privacy/gdpr";
    },
    helpPrivacyCookieInfo: function () {
        return "/help/privacy/cookie-information";
    },
    helpAbout: function () {
        return "/help/about";
    },
    helpTour: function () {
        return "/help/tour";
    },

    helpHowToSearchingBookmarks: function () {
        return "/help/how-to/searching-bookmarks";
    },

    helpHowToSavingBookmarks: function () {
        return "/help/how-to/saving-bookmarks";
    },
    helpHowToShowResultsNextToISE: function () {
        return "/help/how-to/show-results-next-to-ise";
    },
    helpHowToImportBookmarks: function () {
        return "/help/how-to/import-bookmarks";
    },
    helpFAQSeeFailed: function () {
        return "/help/faq/see-failed";
    },
    helpFAQRemoveMany: function () {
        return "/help/faq/remove-many";
    },
    helpFAQWhatArePermalinks: function () {
        return "/help/faq/what-are-permalinks";
    },
    helpFAQ: function () {
        return "/help/faq";
    },

    showInvoice: function (subscriptionId) {
        return "/users/me/subscriptions/" + subscriptionId + "/invoice.pdf";
    },

    goToMobile: function (force = true) {
        const currentParams = JSON.parse(JSON.stringify(SearchService.extractSearch()));
        delete currentParams.m;
        if (force) {
            currentParams.m = true;
        }
        let params = SearchService.joinSearchWithoutUndefined(currentParams);
        if (params.length === 0) {
            window.location.href = `http://m.grabduck.com${window.location.pathname}`;
        } else {
            window.location.href = `http://m.grabduck.com${window.location.pathname}?${params}`;
        }
    }
};

export default LinkService;
