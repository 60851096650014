import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedDate, FormattedMessage, FormattedRelative, injectIntl} from "react-intl"

class BookmarkProperties extends Component {
    render = () => {
        const {formatMessage} = this.props.intl;
        var sources = null
        if (this.props.bookmark.sources) {
            let integrationCounts = this.props.bookmark.sources.filter(source => source.startsWith("INTEGRATION")).length
            sources = this.props.bookmark.sources
                .filter(source => source !== "UNKNOWN")
                .filter(source => integrationCounts <= 1 || source !== "INTEGRATION")
                .map(source => formatMessage({id: "sources." + source}))
                .join(", ")
        }

        return (
            <div className="bookmark-block container-fluid">
                <div className="row">
                    {this.props.bookmark.createdAt &&
                    <div className="col-6">
                        <small>
                            <FormattedMessage id="main.addedToFavorited"/><span>: </span>
                            <FormattedDate value={this.props.bookmark.createdAt} year='numeric' month='short' day='numeric'/>
                            <span className="text-muted">&#32;(<FormattedRelative value={this.props.bookmark.createdAt}/>)</span>
                        </small>
                    </div>
                    }

                    {this.props.bookmark.refreshedAt &&
                    <div className="col-6">
                        <small>
                            <FormattedMessage id="common.refreshed"/><span>: </span>
                            <FormattedDate value={this.props.bookmark.refreshedAt} year='numeric' month='short' day='numeric'/>
                            <span className="text-muted">&#32;(<FormattedRelative value={this.props.bookmark.refreshedAt}/>)</span>
                        </small>
                    </div>
                    }


                    {this.props.bookmark.accessedAt &&
                    <div className="col-6">
                        <small>
                            <FormattedMessage id="common.lastAccessed"/><span>: </span>
                            <FormattedDate value={this.props.bookmark.accessedAt} year='numeric' month='short' day='numeric'/>
                            <span className="text-muted">&#32;(<FormattedRelative value={this.props.bookmark.accessedAt}/>)</span>
                        </small>
                    </div>
                    }

                    {this.props.bookmark.readLater &&
                    <div className="col-6">
                        <small>
                            <FormattedMessage id="common.addedToReadLater"/><span>: </span>
                            <FormattedDate value={this.props.bookmark.readLater} year='numeric' month='short' day='numeric'/>
                            <span className="text-muted">&#32;(<FormattedRelative value={this.props.bookmark.readLater}/>)</span>
                        </small>
                    </div>
                    }

                    {this.props.bookmark.status &&
                    <div className="col-6">
                        <small>
                            <FormattedMessage id="account.invoices.tableHeader.status"/><span>: </span>
                            <FormattedMessage id={"main.bookmarkStatus." + this.props.bookmark.status.toLowerCase()}/>
                        </small>
                    </div>
                    }


                    {this.props.bookmark.shared > 1 &&
                    <div className="col-6">
                        <small>
                            <FormattedMessage id="common.sharedTimes" values={{num: this.props.bookmark.shared}}/>
                        </small>
                    </div>
                    }

                    {sources &&
                    <div className="col-6">
                        <small>
                            <FormattedMessage id="sources.title"/><span>: </span>
                            {sources}
                        </small>
                    </div>
                    }
                </div>
            </div>
        )
    }
}

BookmarkProperties.propTypes = {
    bookmark: PropTypes.object.isRequired
};

export default injectIntl(BookmarkProperties);
