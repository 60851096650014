import React, {Component} from 'react'
import PropTypes from 'prop-types'

class EmbedResponsive extends Component {
    render = () => {
        return (
            <div className="embed-responsive embed-responsive-16by9">
                <iframe className="embed-responsive-item"
                        src={this.props.link}
                        title={this.props.title}
                        allowFullScreen/>
            </div>
        )
    }
}

EmbedResponsive.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
};

export default EmbedResponsive;
