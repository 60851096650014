import React, {Component} from 'react'
import {FormattedDate, FormattedMessage} from "react-intl"
import {SubscriptionService} from "../../services/SubscriptionService";
import {Button, Table} from "react-bootstrap";
import LinkService from "../../services/LinkService";

class Invoices extends Component {
    state = {
        subscriptions: null
    };

    loadSubscriptions = () => {
        SubscriptionService
            .getAllSubscriptions()
            .then(list => {
                this.setState({subscriptions: list.items})
            })
            .catch(() => {
            });
    };

    UNSAFE_componentWillMount = () => {
        this.loadSubscriptions();
    };

    showInvoices = () => {
        return (
            <Table bordered>
                <thead>
                    <tr>
                        <th><FormattedMessage id="account.invoices.tableHeader.id"/></th>
                        <th><FormattedMessage id="account.invoices.tableHeader.type"/></th>
                        <th><FormattedMessage id="account.invoices.tableHeader.start"/></th>
                        <th><FormattedMessage id="account.invoices.tableHeader.finish"/></th>
                        <th><FormattedMessage id="account.invoices.tableHeader.status"/></th>
                        <th><FormattedMessage id="account.invoices.tableHeader.invoice"/></th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.subscriptions.map(subscription => (
                        <tr key={subscription.id}>
                            <td>{subscription.invoiceId}</td>
                            <td><FormattedMessage id={`account.subscriptionTypes.${subscription.type.toLowerCase()}`}/></td>
                            <td><FormattedDate value={subscription.created} year='numeric' month='short' day='numeric'/></td>
                            <td><FormattedDate value={subscription.finished} year='numeric' month='short' day='numeric'/></td>
                            <td><FormattedMessage id={`account.subscriptionStatuses.${subscription.status.toLowerCase()}`}/></td>
                            <td>
                                <Button variant="link" target="_blank" href={LinkService.showInvoice(subscription.id)}>
                                    <FormattedMessage id="account.subscription.viewInvoice"/>
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    };

    render = () => {
        return (
            <React.Fragment>
                <p className="lead"><FormattedMessage id="account.invoices"/></p>
                {this.state.subscriptions && this.state.subscriptions.length === 0 &&
                <p className="lead text-center"><FormattedMessage id="account.invoices.nothingFound"/></p>
                }
                {this.state.subscriptions && this.state.subscriptions.length > 0 && this.showInvoices()}
            </React.Fragment>
        )
    }
}

export default Invoices;
