import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl"
import {Button, Col, Container, Jumbotron, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import LinkService from "../services/LinkService";

class WelcomeToGrabDuck extends Component {
    handleRegisterClick = () => {
        const element = document.getElementById(this.props.registerId);
        element.scrollIntoView(true);
    };

    render = () => {
        return (
            <Container fluid={true} style={{marginTop: 16}}>
                <Row noGutters={false} className="justify-content-center">
                    <Col lg={5} md={12}>
                        <Jumbotron className="bg-transparent">
                            <h1 className="text-center"><FormattedMessage id="welcome.welcomeToGD"/></h1>
                            <p className="lead"><FormattedMessage id="welcome.gdOnlyBookmarkingService"/></p>
                            <p className="lead"><FormattedMessage id="welcome.helpsSaveAllInteresting"/></p>
                            <p className="lead"><FormattedMessage id="welcome.collectYouInternet"/></p>

                            <p>
                                If you're curious what GrabDuck is, our recommendation would be to start from reading
                                &nbsp;<Link to={LinkService.helpAbout()}><FormattedMessage id="help.aboutTheService"/></Link>&nbsp;
                                and then take a short
                                &nbsp;<Link to={LinkService.helpTour()}><FormattedMessage id="help.tourOfGrabDuck"/></Link>. After that you are ready to get
                                started!
                            </p>

                            <p className="text-center">
                                <Button variant="primary" size="lg" onClick={this.handleRegisterClick}>
                                    <FormattedMessage id="welcome.getStarted"/>
                                </Button>
                            </p>
                        </Jumbotron>
                    </Col>
                    <Col lg={6} className="d-none d-lg-block d-md-none">
                        <img alt="GrabDuck Happy Customer" src="/static/images/happy-customer.png" style={{width: "100%"}}/>
                    </Col>
                </Row>
            </Container>
        )
    }
}

WelcomeToGrabDuck.propTypes = {
    registerId: PropTypes.string.isRequired
};

export default WelcomeToGrabDuck;
