import React, {Component} from 'react'
import PropTypes from 'prop-types'
import UserService from "../services/UserService";
import PaypalService from "../services/PaypalService";
import AuthService from "../services/AuthService";
import {SubscriptionService} from "../services/SubscriptionService";

class PaypalButton extends Component {
    loadScript = (onLoad) => {
        if (window.paypal === undefined) {
            const element = document.getElementsByTagName("script")[0];
            const fjs = element;
            let js = element;
            js = document.createElement("script");
            // js.id = id;
            js.src = "https://www.paypalobjects.com/api/checkout.js";
            if (fjs && fjs.parentNode) {
                fjs.parentNode.insertBefore(js, fjs)
            } else {
                document.head.appendChild(js)
            }
            js.onload = onLoad;
        } else {
            onLoad();
        }

    };

    onLoad = () => {
        const paypalEnv = process.env.NODE_ENV === "development" ? "sandbox" : "production";
        const itself = this;
        window.paypal.Button.render({
            env: paypalEnv,

            style: {
                label: 'pay',
                size: 'medium',
                shape: 'rect',
                color: 'blue'
            },

            commit: true,

            payment: function () {
                const user = itself.props.user;
                const plan = itself.props.billingPlan;

                if (user !== undefined && user !== null) {
                    return UserService
                        .createUser(
                            user.username, user.email, user.password, user.avatar, user.connections.googlePlus,
                            plan, "ONE_TIME", "PAYPAL"
                        )
                        .then(data => {
                            AuthService.login(data.auth.accessToken, data.auth.expires);
                            return data.subscription.paymentId;
                        });

                } else {
                    return SubscriptionService
                        .createSubscription("BASIC", "ONE_TIME", "PAYPAL")
                        .then(function (data) {
                            return data.paymentId;
                        });
                }
            },

            onAuthorize: function (data) {
                return PaypalService
                    .executePayment(data.paymentID, data.payerID, "basic")
                    .then(function () {
                        window.location.href = "/account/summary?checkout=true";
                    });
            }

        }, '#paypal-button');
    };

    componentDidMount = () => {
        this.loadScript(this.onLoad);
    };

    render = () => {
        return (<div id="paypal-button"></div>);
    }
}

PaypalButton.propTypes = {
    billingPlan: PropTypes.string.isRequired,
    user: PropTypes.object
};

export default PaypalButton;
