import React, {Component} from 'react'
import PropTypes from 'prop-types'
import LoadingProgress from "./LoadingProgress";
import {Link} from "react-router-dom";

var fontStart = 8;
var fontFinish = 20;
var colorStart = '#2C3E50';
var colorEnd = '#18BC9C';

var compareWeights = function (a, b) {
    return a - b;
};

var colorIncrement = function (colorStart, colorEnd, range) {
    return toRGB(colorEnd)
        .map(function (n, i) {
            return (n - toRGB(colorStart)[i]) / range;
        });
};

var toRGB = function (code) {
    if (code.length === 4) {
        code = code.replace(/(\w)(\w)(\w)/gi, "$1$1$2$2$3$3");
    }
    var hex = /(\w{2})(\w{2})(\w{2})/.exec(code);
    return [parseInt(hex[1], 16), parseInt(hex[2], 16), parseInt(hex[3], 16)];
};

var tagColor = function (colorStart, colorEnd, increment, weighting) {
    var rgb = toRGB(colorStart).map(function (n, i) {
        var ref = Math.round(n + (increment[i] * weighting));
        if (ref > 255) {
            ref = 255;
        } else {
            if (ref < 0) {
                ref = 0;
            }
        }
        return ref;
    });
    return toHex(rgb);
};

var toHex = function (ary) {
    return "#" + ary.map(function (i) {
        var hex = i.toString(16);
        hex = (hex.length === 1) ? "0" + hex : hex;
        return hex;
    }).join("");
};

class WordsCloud extends Component {
    calcWeightsExist = (tags, activeTags) => {
        const res = [];
        var tagWeights = [];
        tags.forEach(function (item) {
            if (activeTags.indexOf(item.title) === -1) {
                tagWeights.push(item.count);
            }
        });
        tagWeights.sort(compareWeights); //= jQuery.makeArray(tagWeights).sort(compareWeights);
        var lowest = tagWeights[0];
        var highest = tagWeights.pop();
        var range = highest - lowest;
        if (range === 0) {
            range = 1;
        }
        var fontIncr = (fontFinish - fontStart) / range;
        var colorIncr = colorIncrement(colorStart, colorEnd, range);

        tags.forEach(function (item) {
            if (activeTags.indexOf(item.title) === -1) {

                var weighting = item.count - lowest;
                var fontSize = fontStart + (weighting * fontIncr);
                var color = tagColor(colorStart, colorEnd, colorIncr, weighting);

                item.style = {"fontSize": fontSize + "pt", 'color': color};
                res.push(item);
            }
        });
        return res;
    };

    render = () => {
        const tags = this.calcWeightsExist(this.props.words, this.props.activeWords);

        return (
            <React.Fragment>
                {tags.length === 0 && !this.props.progress &&
                <React.Fragment>
                    <p className="lead text-center">{this.props.emptyTitle}</p>
                    <p className="text-center">{this.props.emptyDesc}</p>
                </React.Fragment>
                }

                {this.props.progress &&
                <div style={{marginBottom: 50}}>
                    <LoadingProgress show={true}/>
                </div>
                }

                {tags.length > 0 && !this.props.progress &&
                <p className="text-center">
                    {tags.map(tag => {
                        return (
                            <React.Fragment key={tag.title}>
                                <Link to={tag.link} style={tag.style}>{tag.title}</Link>
                                <span> </span>
                            </React.Fragment>
                        )
                    })}
                </p>
                }
            </React.Fragment>
        )
    }
}

WordsCloud.propTypes = {
    words: PropTypes.array.isRequired,
    activeWords: PropTypes.array.isRequired,
    emptyTitle: PropTypes.string.isRequired,
    emptyDesc: PropTypes.string.isRequired,
    progress: PropTypes.bool.isRequired
};

export default WordsCloud;
