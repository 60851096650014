import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Media} from "react-bootstrap";
import UrlService from "../../services/UrlService";
import DefaultFavicon from "../default-favicon.png";
import classNames from 'classnames'
import BookmarkIndicators from "./BookmarkIndicators";
import QuickAccessPanel from "./QuickAccessPanel";
import BookmarkSelector from "./BookmarkSelector";

class BookmarkBrief extends Component {
    state = {
        favIconError: false,
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (this.props.bookmark.faviconUrl !== nextProps.bookmark.faviconUrl) {
            this.setState({favIconError: false})
        }
    };

    // UNSAFE_componentWillMount = () => {
    //
    // };

    setErrorFavIcon = () => {
        this.setState({favIconError: true});
    };

    handleSelection = (checked, shiftKey) => {
        this.props.onSelection(checked, shiftKey);
    };

    shortenText = (text, maxLength) => {
        if (text === undefined || text.length <= maxLength) {
            return text;
        }
        //trim the string to the maximum length
        let trimmedString = text.substr(0, maxLength);
        //re-trim if we are in the middle of a word
        return trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))) + "…";
    };

    render = () => {
        const {bookmark} = this.props;
        let bookmarkTitle = this.props.bookmark.canonicalUrl;
        if (this.props.bookmark.title) {
            bookmarkTitle = this.props.bookmark.title;
        }

        return (
            <Media
                className={classNames("default-cursor", "bookmark-card", "bookmark-brief", {
                    "bg-selected": bookmark.selected,
                    "bg-light": !bookmark.favorite,
                    "bg-to-extraction": bookmark.status === "TO_EXTRACTION",
                    "bg-failed": bookmark.status === "FAILED"
                })}>
                <div className="bookmark-icon-placeholder">
                    <img className="bookmark-icon mr-3"
                         onError={this.setErrorFavIcon}
                         src={!this.state.favIconError ? bookmark.faviconUrl : DefaultFavicon}
                         alt={bookmarkTitle}/>
                    <BookmarkSelector value={bookmark.selected || false} onChange={this.handleSelection}/>
                </div>
                <Media.Body className="bookmark-body">
                    <h6 className="bookmark-paragraph">
                        <a href={bookmark.canonicalUrl} rel="noopener noreferrer" onClick={this.props.onOpen} target="_blank">{bookmarkTitle}</a>
                    </h6>
                    <p className="bookmark-paragraph text-secondary">{UrlService.decodeUrl(UrlService.punyCode(bookmark.canonicalUrl))}</p>
                    <p style={{marginBottom: 0}}>{this.shortenText(bookmark.description, 200)}</p>
                </Media.Body>
                <QuickAccessPanel bookmark={bookmark}
                                  privileges={this.props.privileges}
                                  onReader={this.props.onReader}
                                  onPlay={this.props.onPlay}
                                  onShareBookmark={this.props.onShareBookmark}
                                  onBookmarkUpdateAction={this.props.onBookmarkUpdateAction}
                                  onDetails={this.props.onExpand}
                />
                <BookmarkIndicators bookmark={bookmark}/>
            </Media>

        )
    }
}

BookmarkBrief.propTypes = {
    bookmark: PropTypes.object.isRequired,
    privileges: PropTypes.array.isRequired,
    onExpand: PropTypes.func.isRequired,
    onSelection: PropTypes.func.isRequired,

    onOpen: PropTypes.func.isRequired,
    onReader: PropTypes.func.isRequired,
    onPlay: PropTypes.func.isRequired,
    onShareBookmark: PropTypes.func.isRequired,
    onBookmarkUpdateAction: PropTypes.func.isRequired
};

export default BookmarkBrief;
