import LinkService from "./LinkService";
import ReturnPromise from "./ReturnPromise";
import axios from "axios";

const SearchService = {
    createRequest: function (isReco = false) {
        let search = SearchService.extractSearch();
        let pathname = window.location.pathname;

        let filters = [];
        if (search.filter === "untagged") {
            filters.push("untagged")
        }
        if (search.filter === "failed") {
            filters.push("failed")
        }
        if (search.filter === "public") {
            filters.push("public")
        }
        if (search.filter === "private") {
            filters.push("private")
        }
        if (pathname === LinkService.inbox()) {
            filters.push("readLater")
        }
        if (pathname === LinkService.history()) {
            filters.push("history")
        }
        if (pathname === LinkService.inbox() && search.flagged === "true") {
            filters.push("flagged")
        }
        if (isReco) {
            filters.push("reco");
        }
        var source;
        if (search.source) {
            source = this.convertToValidSource(search.source);
        }

        let result = {
            q: search.q,
            tags: search.tags,
            website: search.website,
            source: source,
            spell: search.spell,
            filter: filters
        };

        return result;

    },

    convertToValidSource: function (source) {
        return source.replace(/-/g, '_').toUpperCase()
    },

    convertToValidSourceReverted: function (source) {
        return source.replace(/_/g, '-').toLowerCase()
    },

    extractSearch: function () {
        const result = {};
        window.location.search
            .substring(1)
            .split("&")
            .filter(item => item.length > 0)
            .map(item => {
                return item.split("=");
            })
            .forEach(value => {
                result[value[0].toLowerCase()] = value[1];
            });

        if (result.tags) {
            result.tags = result.tags.split(",").map(value => decodeURIComponent(value)).join(",");
        }
        if (result.q) {
            result.q = decodeURIComponent(result.q);
        }

        return result;
    },

    joinSearch: function (newSearch) {
        const s = [];
        for (const key in newSearch) {
            if (newSearch[key] !== undefined) {
                s.push(key + "=" + newSearch[key]);
            }
            // else {
            //     s.push(key);
            // }
        }
        return s.join("&");
    },

    joinSearchWithoutUndefined: function (newSearch) {
        const s = [];
        for (const key in newSearch) {
            if (newSearch[key] !== undefined && newSearch[key] !== null) {
                s.push(key + "=" + newSearch[key]);
            }
        }
        return s.join("&");
    },

    addShowFlaggedOnly: function (showFlaggedOnly) {
        const params = JSON.parse(JSON.stringify(this.extractSearch()));
        params.flagged = showFlaggedOnly;
        return `${LinkService.inbox()}?${this.joinSearch(params)}`;
    },

    addFavoriteFilter: function (filter) {
        const params = JSON.parse(JSON.stringify(this.extractSearch()));
        if (filter === "all") {
            delete params.filter;
        } else {
            params.filter = filter;
        }
        return `${LinkService.favorites()}?${this.joinSearch(params)}`;
    },

    extractTags: function (search) {
        return (search.tags || "").split(",").filter(tag => tag.length > 0);
    },

    addQuery: function (query) {
        if (query.trim().length === 0) {
            return null;
        }
        const search = this.extractSearch();
        search.q = query.trim();
        return `${window.location.pathname}?${this.joinSearch(search)}`;
    },

    startSearch: function (search) {
        if (search === null || search === undefined) {
            return null;
        }

        return `${LinkService.favorites()}?${this.joinSearch(search)}`;
    },

    removeQuery: function () {
        const search = this.extractSearch();
        if (search.q === undefined) {
            return null;
        }
        delete search.q;
        const joinSearch = this.joinSearch(search);
        if (joinSearch.length > 0) {
            return `${window.location.pathname}?${joinSearch}`;
        } else {
            return "/";
        }
    },

    // getSuggestions: function (query) {
    //     return new Promise(function (resolve, reject) {
    //         const url = encodeURI("https://suggestqueries.google.com/complete/search?client=youtube&q=" + query);
    //         jsonp(url, {name: 'callback'}, (error, data) => {
    //             if (error) {
    //                 reject();
    //             } else {
    //                 resolve(data[1].map(item => item[0]));
    //             }
    //         });
    //     });
    // },

    getSuggestions: function (query) {
        return ReturnPromise.get(axios.get("/api/v4/suggestions/queries", {
            params: {
                q: query
            }
        }));
    },

    addWebsiteV4: function (website) {
        let search = SearchService.extractSearch();
        search.website = website;
        return `${window.location.pathname}?${this.joinSearch(search)}`;
    },

    addTagV4: function (tag) {
        let search = SearchService.extractSearch();
        const tags = this.extractTags(search);
        if (!tags.includes(tag)) {
            tags.push(tag);
            search.tags = tags.join(",");
        }

        return `${window.location.pathname}?${this.joinSearch(search)}`;
    },

    addSource: function (source) {
        let search = SearchService.extractSearch();
        search.source = this.convertToValidSourceReverted(source);
        return `${window.location.pathname}?${this.joinSearch(search)}`;
    },

    removeTag: function (tag) {
        const search = this.extractSearch();
        const tags = this.extractTags(search);
        if (tags.includes(tag)) {
            const tagToDelete = tags.indexOf(tag);
            tags.splice(tagToDelete, 1);
            const s = tags.join(",");
            if (s.length > 0) {
                search.tags = s;
            } else {
                delete search.tags;
            }
            return SearchService.getNewPath(search);

        } else {
            return undefined;
        }
    },

    removeWebsite: function (website) {
        return SearchService.removeSingleValueParam("website", website);
    },

    removeSource: function (source) {
        return SearchService.removeSingleValueParam("source", source);
    },

    removeSingleValueParam: function (param, value) {
        const search = this.extractSearch();
        if (search[param] === value) {
            delete search[param];
            return SearchService.getNewPath(search);
        } else {
            return undefined;
        }
    },

    getNewPath: function (search) {
        const joinSearch = this.joinSearch(search);
        if (joinSearch.length > 0) {
            return `${window.location.pathname}?${joinSearch}`;
        } else {
            return window.location.pathname;
        }
    }
};

export default SearchService;