import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import Form from "react-bootstrap/Form";
import {Button} from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import ValidationService from "../services/ValidationService";
import UserService from "../services/UserService";
import Alert from "react-bootstrap/Alert";
import ProgressModal from "../shell/ProgressModal";

class AccountSettings extends Component {
    state = {
        showProgress: false,
        showError: false,

        changed: false,
        validationMessage: {},

        makeAllBookmarkPrivate: undefined,
        applyAutoGeneratedTags: undefined,
        kindleEmail: undefined,
        languages: undefined,
        recoPercentage: undefined,
        bookmarksEmail: undefined
    };

    handleFieldChange = (prop, value, validateFn) => {
        const validationMessage = this.state.validationMessage;
        validationMessage[prop] = validateFn(value);

        this.setState({
            changed: true,
            [prop]: value,
            validationMessage: validationMessage
        });
    };


    UNSAFE_componentWillMount() {
        this.resetData();
    };

    resetData = () => {
        const {user} = this.props;
        const validationMessage = this.state.validationMessage;

        validationMessage["kindleEmail"] = this.validateNotRequiredEmail(user.settings.kindleEmail);
        validationMessage["bookmarksEmail"] = this.validateEmail(user.settings.bookmarksEmail);

        this.setState({
            makeAllBookmarkPrivate: user.settings.makeAllBookmarkPrivate,
            applyAutoGeneratedTags: user.settings.applyAutoGeneratedTags,
            kindleEmail: user.settings.kindleEmail,
            languages: user.settings.languages,
            recoPercentage: user.settings.recoPercentage,
            bookmarksEmail: user.settings.bookmarksEmail,
            validationMessage: validationMessage,
            changed: false
        });
    };

    validateNotRequiredEmail = (email) => {
        return this.validateEmail(email, false);
    };

    validateEmail = (email, required = true) => {
        const {formatMessage} = this.props.intl;

        if (required) {
            if (!email || email.trim().length === 0) {
                return formatMessage({id: "common.validation.fieldRequired"});

            } else if (!ValidationService.validateEmail(email)) {
                return formatMessage({id: "common.validation.validEmail"});

            } else {
                return null;
            }
        } else {
            if (email && email.trim().length > 0 && !ValidationService.validateEmail(email)) {
                return formatMessage({id: "common.validation.validEmail"});
            } else {
                return null;
            }
        }

    };

    getValidationClass = (field) => {
        if (this.state.validationMessage[field] === null) {
            return "is-valid";
        } else if (this.state.validationMessage[field] !== undefined) {
            return "is-invalid";
        } else {
            return "";
        }
    };

    handleGenerateBookmarksEmail = () => {
        UserService
            .getAvailableBookmarksEmail()
            .then(data => {
                this.handleFieldChange("bookmarksEmail", data.email, this.validateEmail);
            })
            .catch(() => {
            });
    };

    handleLangChange = (langTag, checked) => {
        const languages = this.state.languages.map(l => (l.langTag === langTag) ? {langTag: l.langTag, enabled: checked} : l);
        this.setState({languages: languages, changed: true});
    };

    formInvalid = (changed, bookmarksEmail, kindleEmail) => {
        return changed === false || this.validateEmail(bookmarksEmail) !== null || this.validateNotRequiredEmail(kindleEmail) !== null;
    };

    handleSave = () => {
        const {formatMessage} = this.props.intl;

        this.setState({
            showProgress: true,
            showError: false
        });
        UserService
            .saveSettings(
                this.state.makeAllBookmarkPrivate,
                this.state.applyAutoGeneratedTags,
                this.state.kindleEmail,
                this.state.languages,
                this.state.recoPercentage,
                this.state.bookmarksEmail,
                this.props.user.settings.rssSecret)
            .then(data => {
                this.setState({
                    showProgress: false,
                    changed: false,
                }, () => {
                    this.props.onUserUpdate();
                    this.props.onAddToast({
                        id: Date.now(),
                        type: "success",
                        title: formatMessage({id: "common.topMenuTitle.account"}) + " / " + formatMessage({id: "account.menu.settings"}),
                        description: formatMessage({id: "common.successfullySaved"})
                    });
                });

            })
            .catch(() => {
                this.setState({
                    showProgress: false,
                    showError: true
                });
            });
    };

    render = () => {
        const {formatMessage} = this.props.intl;

        const langs = {};
        this.state.languages.forEach(l => {
            langs[l.langTag] = l.enabled;
        });

        const validationClass = {
            kindleEmail: this.getValidationClass("kindleEmail"),
            bookmarksEmail: this.getValidationClass("bookmarksEmail")
        };

        return (
            <div className="help-content">
                <h3 className="account-title"><FormattedMessage id="account.menu.settings"/></h3>
                <Form>
                    <Form.Group controlId="userSettings.bookmarksPrivacy" className="settings-block">
                        <Form.Label className="lead"><FormattedMessage id="account.bookmarksPrivacy"/></Form.Label>
                        <Form.Check
                            custom
                            type="checkbox"
                            checked={this.state.makeAllBookmarkPrivate}
                            onChange={(e) => this.handleFieldChange("makeAllBookmarkPrivate", e.target.checked, () => null)}
                            id="userSettings.bookmarksPrivacy.check"
                            label={formatMessage({id: "account.bookmarksPrivacy.privateByDefault"})}
                        />
                        <Form.Text className="text-muted">
                            <FormattedMessage id="account.bookmarksPrivacy.privateByDefault.desc"/>
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="userSettings.tagsDiscovery" className="settings-block">
                        <Form.Label className="lead"><FormattedMessage id="account.tagsDiscovery"/></Form.Label>
                        <Form.Check
                            custom
                            type="checkbox"
                            checked={this.state.applyAutoGeneratedTags}
                            onChange={(e) => this.handleFieldChange("applyAutoGeneratedTags", e.target.checked, () => null)}
                            id="userSettings.tagsDiscovery.check"
                            label={formatMessage({id: "account.settings.applyAutoGeneratedTags"})}
                        />
                        <Form.Text className="text-muted">
                            <FormattedMessage id="account.settings.applyAutoGeneratedTags.desc"/>
                        </Form.Text>
                    </Form.Group>


                    <Form.Group controlId="userSettings.recommendations" className="settings-block">
                        <Form.Label className="lead"><FormattedMessage id="account.recommendations"/></Form.Label>
                        <Form.Text><FormattedMessage id="account.settings.howManyRecosShow" values={{recoPercentage: this.state.recoPercentage}}/></Form.Text>
                        <Form.Control type="range" min="0" max="80" step="20"
                                      value={this.state.recoPercentage} className="custom-range"
                                      onChange={(e) => this.handleFieldChange("recoPercentage", e.target.value, () => null)}
                        />
                        <Form.Text className="text-muted"><FormattedMessage id="account.settings.howManyRecosShow.desc"/></Form.Text>
                    </Form.Group>


                    <Form.Group controlId="userSettings.setupKindle" className="settings-block">
                        <Form.Label className="lead"><FormattedMessage id="account.setupKindle"/></Form.Label>
                        <ol>
                            <li><FormattedMessage id="account.settings.kindle.onAmazonGoto"/> <a href="https://www.amazon.com/myk" target="_blank"
                                                                                                 rel="noopener noreferrer"><FormattedMessage
                                id="account.settings.kindle.manageDevicesScreen"/></a></li>
                            <li><FormattedMessage id="account.settings.kindle.findPersonalDocSettings"/></li>
                            <li><FormattedMessage id="account.settings.kindle.addApprovelEmail"/></li>
                            <li><FormattedMessage id="account.settings.kindle.findKindleEmail"/></li>
                        </ol>
                        <Form.Text><FormattedMessage id="account.settings.kindle.email"/></Form.Text>
                        <Form.Control type="email"
                                      value={this.state.kindleEmail}
                                      className={validationClass.kindleEmail}
                                      placeholder={formatMessage({id: "welcome.enterEmail"})}
                                      onChange={(e) => this.handleFieldChange("kindleEmail", e.target.value, this.validateNotRequiredEmail)}
                        />
                        <Form.Text className="invalid-feedback">{this.state.validationMessage.kindleEmail}</Form.Text>
                        <Form.Text className="text-muted"><FormattedMessage id="account.settings.setupKindle.desc"/></Form.Text>
                    </Form.Group>


                    <Form.Group controlId="userSettings.emailBookmarksTo" className={`settings-block ${validationClass.bookmarksEmail}`}>
                        <Form.Label className="lead"><FormattedMessage id="account.emailBookmarksTo"/></Form.Label>
                        <Form.Text><FormattedMessage id="account.settings.grabduckEmail"/></Form.Text>
                        <InputGroup>
                            <Form.Control type="email" readOnly
                                          className={validationClass.bookmarksEmail}
                                          placeholder={formatMessage({id: "welcome.typePassword"})}
                                          value={this.state.bookmarksEmail}/>
                            <InputGroup.Append>
                                <Button variant="outline-secondary" onClick={this.handleGenerateBookmarksEmail}>
                                    <i className="fas fa-sync"/>
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                        <Form.Text className="invalid-feedback">{this.state.validationMessage.bookmarksEmail}</Form.Text>
                        <Form.Text className="text-muted"><FormattedMessage id="account.settings.grabduckEmail.desc"/></Form.Text>
                    </Form.Group>


                    <Form.Group controlId="userSettings.userLangs" className="settings-block">
                        <Form.Label className="lead"><FormattedMessage id="account.userLangs"/></Form.Label>

                        <Form.Group>
                            {this.state.languages.map(lang => {
                                return (
                                    <div className="form-check form-check-inline" key={lang.langTag}>
                                        <Form.Check
                                            custom
                                            type="checkbox"
                                            checked={langs[lang.langTag]}
                                            onChange={(e) => this.handleLangChange(lang.langTag, e.target.checked)}
                                            id={`userSettings.tagsDiscovery.check.${lang.langTag}`}
                                            label={formatMessage({id: `account.lang.${lang.langTag}`})}
                                        />
                                    </div>
                                );
                            })}
                        </Form.Group>

                        <Form.Text className="text-muted"><FormattedMessage id="account.settings.userLangs.desc"/></Form.Text>
                    </Form.Group>
                </Form>

                <Alert variant="danger" show={this.state.showError} onClose={() => this.setState({showError: false})} dismissible>
                    <FormattedMessage id="common.errorWhileSavingData"/>
                </Alert>

                <ProgressModal show={this.state.showProgress} message={formatMessage({id: "common.saving"})}/>

                <div className="d-flex justify-content-end" style={{marginTop: 45, marginBotton: 45}}>
                    <Button variant="secondary" type="button" onClick={this.resetData} style={{marginLeft: 10}}>
                        <FormattedMessage id="common.reset"/>
                    </Button>
                    <Button variant="primary" type="button"
                            disabled={this.formInvalid(this.state.changed, this.state.bookmarksEmail, this.state.kindleEmail)}
                            onClick={this.handleSave} style={{marginLeft: 10}}>
                        <FormattedMessage id="common.save"/>
                    </Button>
                </div>
            </div>
        )
    }
}

AccountSettings.propTypes = {
    user: PropTypes.object.isRequired,
    onUserUpdate: PropTypes.func.isRequired,
    onAddToast: PropTypes.func.isRequired,
};

export default injectIntl(AccountSettings);
