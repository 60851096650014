import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl"
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";
import LinkService from "../services/LinkService";
import Button from "react-bootstrap/Button";

class FooterLinks extends Component {
    render = () => {
        const extClass = this.props.light ? "text-light" : ""
        return (
            <ul className="d-flex justify-content-center footer-links">
                <li>
                    <LinkContainer to={LinkService.helpTOS()}>
                        <Button variant="link" className={extClass}>
                            <FormattedMessage id="help.termsOfService"/>
                        </Button>
                    </LinkContainer>
                </li>
                <li className={"footer-links-divider " + extClass}>·</li>
                <li>
                    <LinkContainer to={LinkService.helpPrivacyPolicy()}>
                        <Button variant="link" className={extClass}>
                            <FormattedMessage id="help.privacyPolicy"/>
                        </Button>
                    </LinkContainer>
                </li>
                <li className={"footer-links-divider " + extClass}>·</li>
                <li>
                    <Button variant="link" className={extClass} onClick={LinkService.goToMobile}>
                        <FormattedMessage id="common.mobileWebsite"/>
                    </Button>
                </li>
            </ul>
        )
    }
}

FooterLinks.propTypes = {
    light: PropTypes.bool.isRequired
};

export default FooterLinks;
