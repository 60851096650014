import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl"
import axios from "axios/index";

class Copyright extends Component {
    state = {
        appVersion: "",
        showNewVersionAvailable: false
    };

    componentDidMount = () => {
        this
            .getAppVersion()
            .then(appInfo => {
                this.setState({appVersion: this.getVersion(appInfo)})
            })
            .catch(() => {
            });

        this.timerID = setInterval(() => this.checkVersion(), 1000 * 60 * 60 * 6);
    };

    componentWillUnmount = () => {
        clearInterval(this.timerID);
    };

    getAppVersion = () => {
        return axios
            .get("/static/app-info.json")
            .then(response => {
                if (response.status === 200) {
                    return response.data;
                }
                return Promise.reject(response);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    };

    getVersion = (appInfo) => {
        return "2." + appInfo.version + "-" + appInfo.built;
    };

    checkVersion = () => {
        this
            .getAppVersion()
            .then(appInfo => {
                if (this.getVersion(appInfo) !== this.state.appVersion) {
                    this.setState({showNewVersionAvailable: true});
                }
            })
            .catch(() => {
            });
    };

    closeNewVersionAvailable = () => {
        this.setState({showNewVersionAvailable: false});
    };

    render = () => {
        const currentYear = new Date().getFullYear();
        const textAlign = this.props.textAlign || "inherit";

        return (
            <p style={{textAlign: textAlign}}>
                &copy;<span>2014-{currentYear}</span>,&nbsp;<FormattedMessage id="common.econorCompany"/><br/>
                <FormattedMessage id="common.handcraftedInGermany"/><br/>
                {this.state.appVersion}
            </p>
        )
    }
}

Copyright.propTypes = {
    textAlign: PropTypes.string
};

export default Copyright;
