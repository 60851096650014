import React, {Component} from 'react'
import PropTypes from 'prop-types'
import WelcomeToGrabDuck from "./WelcomeToGrabDuck";
import CreateAccount from "./CreateAccount";
import Login from "./Login";

class Welcome extends Component {
    state = {
        googleUser: null
    };

    handleCreateAccount = (user) => {
        this.setState({
            googleUser: user
        }, () => {
            const element = document.getElementById(this.props.registerId);
            element.scrollIntoView(true);
        });
    };

    render = () => {
        return (
            <React.Fragment>
                <WelcomeToGrabDuck registerId={this.props.registerId}/>
                <hr style={{marginTop: 0}}/>
                <CreateAccount registerId={this.props.registerId} onWireWithGoogle={this.state.googleUser}/>
                <hr/>
                <Login loginId={this.props.loginId}
                       onLogin={this.props.onLogin}
                       onCreateAccount={this.handleCreateAccount}
                       onAddToast={this.props.onAddToast}
                />
            </React.Fragment>
        )
    }
}

Welcome.propTypes = {
    loginId: PropTypes.string.isRequired,
    registerId: PropTypes.string.isRequired,
    onLogin: PropTypes.func.isRequired,
    onAddToast: PropTypes.func.isRequired
};

export default Welcome;
