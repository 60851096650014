import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl"
import Button from "react-bootstrap/Button";
import {Collapse, Dropdown} from "react-bootstrap";
import LocalStorage from "../services/LocalStorage";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import classNames from 'classnames'

class NavListItem extends Component {
    state = {
        open: false
    };

    UNSAFE_componentWillMount = () => {
        this.setState({open: LocalStorage.getOrDefault(this.props.listId, false)});
    };

    handleListOpen = () => {
        this.setState({open: !this.state.open}, () => LocalStorage.set(this.props.listId, this.state.open));
    };

    render = () => {
        const {open} = this.state;
        const iconClasses = {
            "fa-lg": (this.props.size === "lg"),
            "fas fa-folder-open": (this.props.icon === undefined && this.state.open),
            "fas fa-folder": (this.props.icon === undefined && !this.state.open),
            [this.props.icon]: this.props.icon,
            [this.props.iconClass]: this.props.iconClass,
            "text-black-50": !this.props.iconClass
        };

        return (
            <React.Fragment>
                {!this.props.sidebarToggled &&
                <React.Fragment>
                    <Button block
                            className={classNames("btn", "btn-flat-light", "text-left", "gd-nav-item", "gd-nav-list-item", "no-focus", {"btn-lg": (this.props.size === "lg")})}
                            onClick={this.handleListOpen}
                            aria-expanded={open}>
                        <i className={classNames("fa-fw", iconClasses)} style={{marginRight: 16}}
                        />
                        <FormattedMessage id={this.props.titleId}/>
                    </Button>
                    <Collapse in={open}>
                        <div style={{marginLeft: 16, width: "calc(100% - 16px)"}}>
                            {this.props.children}
                        </div>
                    </Collapse>
                </React.Fragment>
                }

                {this.props.sidebarToggled &&
                <OverlayTrigger placement="right" overlay={<Tooltip><FormattedMessage id={this.props.titleId}/></Tooltip>}>
                    <Dropdown className={classNames("btn-block", "gd-nav-item")}>
                        <Dropdown.Toggle variant="success"
                                         className={classNames("btn", "btn-flat-light", "btn-block", "gd-nav-item", "no-focus", {"btn-lg": (this.props.size === "lg")})}>
                            <i className={classNames("fa-fw", iconClasses)} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {this.props.children}
                        </Dropdown.Menu>
                    </Dropdown>
                </OverlayTrigger>
                }
            </React.Fragment>


        )
    }
}

NavListItem.propTypes = {
    listId: PropTypes.string.isRequired,
    titleId: PropTypes.string.isRequired,
    sidebarToggled: PropTypes.bool.isRequired,
    size: PropTypes.oneOf(['sm', 'lg']),
    icon: PropTypes.string,
    iconClass: PropTypes.string,
};

export default NavListItem;
