import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import Button from "react-bootstrap/Button";
import CreateAccountToSendToKindleDialog from "./CreateAccountToSendToKindleDialog";
import ConfigureKindleEmailDialog from "./ConfigureKindleEmailDialog";
import ProgressModal from "../shell/ProgressModal";
import ReaderService from "../services/ReaderService";

class SendToKindle extends Component {
    state = {
        showCreateAccountToSendToKindle: false,
        showConfigureKindleEmail: false,
        showSendToKindleProgress: false
    };

    handleSend = () => {
        const {formatMessage} = this.props.intl;

        if (this.props.user) {
            if (this.props.user.settings.kindleEmail === undefined || this.props.user.settings.kindleEmail.trim().length === 0) {
                this.setState({showConfigureKindleEmail: true});
            } else {
                this.setState({showSendToKindleProgress: true});
                ReaderService
                    .sendToKindle(this.props.documentId)
                    .then(() => {
                        this.setState({
                            showSendToKindleProgress: false,
                        }, () => {
                            this.props.onAddToast({
                                id: Date.now(),
                                type: "success",
                                title: formatMessage({id: "reader.kindle.send"}),
                                description: formatMessage({id: "reader.kindle.articleWasSent"})
                            })
                        });
                    })
                    .catch(() => {
                        this.setState({
                            showSendToKindleProgress: false
                        }, () => {
                            this.props.onAddToast({
                                id: Date.now(),
                                type: "error",
                                title: formatMessage({id: "reader.kindle.send"}),
                                description: formatMessage({id: "reader.kindle.articleWasSent.failed"})
                            })
                        });
                    });
            }
        } else {
            this.setState({showCreateAccountToSendToKindle: true});
        }
    };

    render = () => {
        const {formatMessage} = this.props.intl;

        return (
            <React.Fragment>
                <Button variant="light" onClick={this.handleSend} style={{paddingTop: 7, paddingBottom: 8}}>
                    <FormattedMessage id="reader.kindle.send"/>
                </Button>

                <ProgressModal show={this.state.showSendToKindleProgress} message={formatMessage({id: "reader.kindle.sending"})}/>
                <CreateAccountToSendToKindleDialog show={this.state.showCreateAccountToSendToKindle}
                                                   onHide={() => this.setState({showCreateAccountToSendToKindle: false})}/>
                <ConfigureKindleEmailDialog onHide={() => this.setState({showConfigureKindleEmail: false})}
                                            show={this.state.showConfigureKindleEmail}/>
            </React.Fragment>
        )
    }
}

SendToKindle.propTypes = {
    user: PropTypes.object,
    documentId: PropTypes.string.isRequired,
    onAddToast: PropTypes.func.isRequired
};

export default injectIntl(SendToKindle);
