import React, {Component} from 'react'

class HelpAboutWhyPay extends Component {
    render = () => {
        return (
            <React.Fragment>
                <p>In short, if you are not paying for the product, you are the product being sold. <span role="img" aria-label="sold product">🎁😞</span></p>
                <p>There is always a question, why would you pay for something in Internet when it's always possible to have
                    everything for free? <span role="img" aria-label="thinking">🤔</span> And it's a good question! Finally, it all boils down to one thing:
                    running a web service costs money 💸. So think about it
                    - if you do not pay for your bookmarking, then someone else does, and their interests may not be aligned with yours! 😈</p>
                <p>So we think paying for services in general is not such a bad idea <span role="img" aria-label="cool idea">👍</span>. After all, as soon as
                    you paid us for
                    storing your data, we've got new obligations 😓 - we cannot allow the service to fall or be unavailable, we are obliged to guarantee you a
                    stable <span role="img" aria-label="stable">🏋️‍</span>️, fast <span role="img" aria-label="fast">🏃‍♀</span>️ and clean experience, which
                    is definitely a good thing for you!</p>
                <p>As for now GrabDuck is fully supported by user payments, and therefore the service will never show ads,
                    and we will never share your data with other third parties. Ours users pay around eleven dollars a year. It's not a big deal - it costs even
                    less than ridiculously overpriced Starbucks tall latte with all the bells and whistles you can find in Russia (yes we did a small research
                    on that <span role="img" aria-label="research">👩‍🔬</span> ).</p>
                <p>But finally we think you have to answer this question first to yourself - just think about how valuable
                    could be the information you're planning to keep with us <span role="img" aria-label="valuable information">🧐</span>, or how important could be your time savings <span role="img"
                                                                                                                                   aria-label="time savings">⌚</span> with
                    getting back faster to the data
                    you need. Is it worth a cup or two of big latte per year? <span role="img" aria-label="two big latte">☕☕</span> So it's your decision!</p>
                <p>Our hope is that although this approach will not be accepted by a significant part of the audience, it
                    will turn into loyal fans <span role="img" aria-label="loyal fans">🤩</span> those who share this point of view with us. For those we are
                    ready to work tirelessly day and night <span role="img" aria-label="tirelessly working">👨‍💻🔨</span> to provide a
                    better user experience.</p>
            </React.Fragment>
        )
    }
}

export default HelpAboutWhyPay;
