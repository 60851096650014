import axios from "axios/index";
import ReturnPromise from "./ReturnPromise";

const host = process.env.NODE_ENV === "development" ? "http://localhost:8083" : "";
const executePaymentUrl = host + "/api/v3/payPalPaymentJobs";

export const PaypalService = {
    executePayment: function (paymentID, payerID, plan) {
        return ReturnPromise.get(
            axios.post(executePaymentUrl + "/" + plan.toLowerCase(), {
                paymentID: paymentID,
                payerID: payerID
            })
        );
    }
};

export default PaypalService;