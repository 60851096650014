import React, {Component} from 'react'
import {injectIntl} from "react-intl"
import SearchService from "../services/SearchService";
import WordsCloud from "./WordsCloud";
import PropTypes from "prop-types";

class WebsitesCloud extends Component {
    state = {
        activeTags: []
    };

    parseActiveTags = () => {
        const result = SearchService.extractSearch();
        if (!result.website) {
            return [];
        }

        return result.website.split(",");
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        this.setState({
            activeTags: this.parseActiveTags()
        });
    };

    render = () => {
        const {formatMessage} = this.props.intl;
        return (
            <WordsCloud words={this.props.tags || []}
                        activeWords={this.state.activeTags}
                        emptyTitle={formatMessage({id: 'main.websites.empty.title'})}
                        emptyDesc={formatMessage({id: 'main.websites.empty.desc'})}
                        progress={this.props.tags === null || this.props.tags === undefined}
            />
        )
    }
}

WebsitesCloud.propTypes = {
    tags: PropTypes.array
};


export default injectIntl(WebsitesCloud);
