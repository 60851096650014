import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Badge, Button} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import TagsField from "../../shell/TagsField";
import {FormattedMessage, injectIntl} from "react-intl";
import Alert from "react-bootstrap/Alert";
import RecommendedTags from "./RecommendedTags";

class BookmarkTags extends Component {
    state = {
        editMode: false,
        showError: false,
        changed: false,
        tags: []
    };

    handleEditTags = (event) => {
        event.stopPropagation();


        if (this.props.editable) {
            this.setState({
                editMode: true,
                tags: JSON.parse(JSON.stringify(this.props.tags))

            });
        }
    };

    handleCancel = () => {
        this.setState({editMode: false, tags: []});
    };

    handleChangeTags = (tags) => {
        this.setState({
            tags: tags,
            changed: true
        });
    };

    handleSave = () => {
        const {formatMessage} = this.props.intl;
        this.props
            .onTagsUpdate(this.state.tags)
            .then(() => {
                this.props.onAddToast({
                    id: Date.now(),
                    type: "success",
                    title: formatMessage({id: "main.editBookmark"}),
                    description: formatMessage({id: "addBookmark.bookmarkUpdated"})
                });
                this.handleCancel();

            })
            .catch(() => {
                this.setState({showError: true});
            });
    };

    addTag = (tag) => {
        const tags = this.state.tags;
        tags.push(tag);
        this.handleChangeTags(tags);
    };

    render = () => {
        return (
            <div className="bookmark-block">
                {!this.state.editMode &&
                <p className="bookmark-tags" onClick={this.handleEditTags}>
                    {this.props.tags.map(tag => {
                            return (<Badge key={`#${tag}`} variant="light">#{tag}</Badge>)
                        }
                    )}
                    <Badge variant="light">@{this.props.website}</Badge>
                </p>
                }
                {this.state.editMode &&
                <Form onClick={e => e.stopPropagation()} className="border border-primary rounded non-clickable" style={{padding: "0.5rem"}}>
                    <Alert variant="danger" show={this.state.showError} onClose={() => this.setState({showError: false})} dismissible>
                        <FormattedMessage id="addBookmark.bookmark.updateFailed"/>
                    </Alert>

                    <TagsField tags={this.state.tags} onChange={this.handleChangeTags}/>

                    <RecommendedTags bookmarkId={this.props.bookmarkId}
                                     tags={this.state.tags}
                                     onAddTag={this.addTag}/>

                    <div className="d-flex justify-content-end">
                        <Button variant="light" size="sm" type="button" style={{marginLeft: 8}} onClick={this.handleCancel}>
                            <FormattedMessage id="common.cancel"/>
                        </Button>
                        <Button variant="primary"
                                size="sm"
                                type="button"
                                disabled={!this.state.changed}
                                style={{marginLeft: 8}}
                                onClick={this.handleSave}>
                            <FormattedMessage id="common.save"/>
                        </Button>
                    </div>
                </Form>
                }
            </div>
        )
    }
}

BookmarkTags.propTypes = {
    bookmarkId: PropTypes.string.isRequired,
    tags: PropTypes.array.isRequired,
    website: PropTypes.string.isRequired,
    onTagsUpdate: PropTypes.func.isRequired,
    onAddToast: PropTypes.func.isRequired,
    editable: PropTypes.bool.isRequired
};

export default injectIntl(BookmarkTags);
