import React, {Component} from 'react'
import {FormattedMessage} from "react-intl"
import HelpAboutWhyPay from "./HelpAboutWhyPay";

class HelpAbout extends Component {
    render = () => {
        return (
            <div className="help-content">
                <h3>
                    <FormattedMessage id="help.aboutTheService"/>
                </h3>

                <p>GrabDuck <span role="img" aria-label="duck">🦆</span> is a full-text bookmarking service that offers already known behavioural patterns <span
                    role="img" aria-label="behavioural patterns">👣</span> learnt from
                    bigger sisters — the search engines like Google <span role="img" aria-label="robot">🤖</span> or Bing. We see GrabDuck’s main job as
                    providing the user with the own “mini-internet” — a
                    small set of valuable and relevant materials which belong to their own Internet.</p>
                <p>The service focuses on content that has value and tries to not just directly save a bookmark given by the
                    user, but rather keep the real content that the user may need later and show it when necessary.</p>
                <p>GrabDuck is not a social network (and will never be) and therefore, although the results of search queries
                    can show recommendations from other users, you have no way to determine who owns this or that bookmark or even follow the user whose
                    bookmarks you like.</p>
                <p>If you're interested in knowing more about the GrabDuck, check these articles on Medium: <a
                    href="https://hackernoon.com/meet-grabduck-dfded819d955">Meet GrabDuck</a> and <a
                    href="https://hackernoon.com/how-we-came-up-with-the-name-grabduck-30a3acbfef13">How we came up with the name GrabDuck</a>.</p>


                <h4>Advantages</h4>
                <p>GrabDuck offers already known from search engines approach how to search for information you need. The
                    whole service was created with a simplicity in mind so you do not need to learn how to use the service and can start working with it right
                    away.</p>
                <p>GrabDuck propagates more streamlined and "invisible" approach. We believe the best tool is the one you do
                    not notice, it just helps you do your work. Therefore we abandoned the main sinkers of time in today’s web services, such as high resolution
                    images, auto playable videos and content streams which eat away at your free time.</p>
                <p>GrabDuck periodically looks at your bookmarks and checks if they are still alive. So you can very easily
                    keep your collection clean and remove the stuff you do not need anymore.</p>
                <p>We're always trying to save bookmark's content as a part of the bookmark, so if one day your favourite
                    website goes down you still have a chance to grab an article you need from GrabDuck in a form of readable local copy.</p>
                <p>GrabDuck is a small service, and comparing to other IT-giants or even mid-sized companies has quite a
                    small set of customers. That's why we're concerned about clean and simple experience of each of our customers and all our users receive
                    prompt support straight directly from the developer.</p>
                <p>After 5 years of working the service is alive and we still intend to run it further for many years in its
                    current form.</p>

                <h4><FormattedMessage id="help.whyPay"/></h4>
                <HelpAboutWhyPay/>

                <h4>Technology</h4>
                <p>Some people ask us about the technologies we use under the GrabDuck's hood. So here you are. Initially
                    GrabDuck was written in Java. But now we're slowly migrating to Kotlin. The Service uses MongoDB for data storage and Solr for doing search
                    stuff.</p>
                <p>The service runs on two Linode servers located in Newark, US and Frankfurt, DE in order to be closer to
                    end customers and provide better user experience.</p>

                <h4>Contact Us</h4>
                <p>We are always happy to help you make the most of our service. Also, feel free to make a suggestion to help
                    us improve for the future. For contacting us use the special form from left hand side at bottom or ask us on <a
                        href="https://twitter.com/GrabDuck">Twitter</a>.</p>
            </div>
        )
    }
}

export default HelpAbout;
