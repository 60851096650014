import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {injectIntl} from "react-intl"
import NavLinkItem from "../shell/NavLinkItem";
import LinkService from "../services/LinkService";
import NavListItem from "../shell/NavListItem";
import TagsCloud from "./TagsCloud";
import WebsitesCloud from "./WebsitesCloud";
import SearchService from "../services/SearchService";
import BookmarksService from "../services/BookmarksService";
import SourcesCloud from "./SourcesCloud";

class MainSidebar extends Component {
    state = {
        lastParams: null,
        lastReload: 0,

        tags: null,
        websites: null,
        sources: null
    };

    loadTagsData = () => {
        // const search = SearchService.extractSearch();
        let params = SearchService.createRequest();
        params["offset"] = 0;
        params["limit"] = 0;
        params["expand-facets"] = true;

        BookmarksService
            .queryV4(params)
            .then(data => {
                this.setState({
                    websites: data.facets.websites
                        .map(tag => {
                            tag.link = SearchService.addWebsiteV4(tag.name);
                            tag.title = tag.name;
                            return tag;
                        }),
                    tags: data.facets.tags
                        .map(tag => {
                            tag.link = SearchService.addTagV4(tag.name);
                            tag.title = tag.name;
                            return tag;
                        }),
                    sources: data.facets.sources
                        .filter(tag => tag.name !== "UNKNOWN")
                        .map(tag => {
                            tag.link = SearchService.addSource(tag.name);
                            tag.title = tag.name;
                            return tag;
                        })
                });
            });
    };

    UNSAFE_componentWillMount = () => {
        const params = JSON.stringify(SearchService.createRequest());
        this.setState({lastParams: params});
        this.loadTagsData();
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.reload !== null && this.state.lastReload !== nextProps.reload) {
            this.setState({lastReload: nextProps.reload});
            this.loadTagsData();
        }

        const params = JSON.stringify(SearchService.createRequest());
        if (this.state.lastParams !== params) {
            this.setState({lastParams: params}, () => {
                this.loadTagsData();
            });
        }
    };

    getSearchParams = () => {
        const search = SearchService.extractSearch();
        const params = {limit: 70};
        if (search.q !== undefined) {
            params.q = search.q;
        }
        if (search.spell !== undefined) {
            params.spell = search.spell;
        }
        if (search.tags !== undefined) {
            params.tags = search.tags;
        }
        if (search.website !== undefined) {
            params.website = search.website;
        }
        if (search.source !== undefined) {
            params.source = search.source;
        }
        return params;
    };

    render = () => {
        return (
            <React.Fragment>
                <NavLinkItem link={LinkService.inbox()}
                             titleId="main.menu.inbox"
                             sidebarToggled={this.props.sidebarToggled}
                             icon="fas fa-inbox"
                             iconClass="inboxColor"
                             size="lg"
                             badgeNumber={this.props.user.inboxCount > 0 ? this.props.user.inboxCount : null}
                />
                <NavLinkItem link={LinkService.history()}
                             titleId="main.menu.history"
                             sidebarToggled={this.props.sidebarToggled}
                             icon="fas fa-history"
                             iconClass="historyColor"
                             size="lg"
                />
                <NavLinkItem link={LinkService.favorites()}
                             titleId="main.menu.favorites"
                             sidebarToggled={this.props.sidebarToggled}
                             icon="fas fa-star"
                             iconClass="favoritesColor"
                             size="lg"
                />
                <NavListItem listId="main.tagsCloud"
                             titleId="main.menu.tagsCloud"
                             size="lg"
                             icon="fas fa-hashtag"
                             sidebarToggled={this.props.sidebarToggled}>
                    <TagsCloud tags={this.state.tags}/>
                </NavListItem>
                <NavListItem listId="main.websitesCloud"
                             titleId="main.menu.websitesCloud"
                             size="lg"
                             icon="fas fa-at"
                             sidebarToggled={this.props.sidebarToggled}>
                    <WebsitesCloud tags={this.state.websites}/>
                </NavListItem>
                <NavListItem listId="main.sourcesCloud"
                             titleId="main.menu.sourcesCloud"
                             size="lg"
                             icon="fas fa-asterisk"
                             sidebarToggled={this.props.sidebarToggled}>
                    <SourcesCloud tags={this.state.sources}/>
                </NavListItem>
            </React.Fragment>
        )
    }
}

MainSidebar.propTypes = {
    user: PropTypes.object.isRequired,
    sidebarToggled: PropTypes.bool.isRequired,
    reload: PropTypes.number.isRequired
};

export default injectIntl(MainSidebar);
