import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl"
import {Card, Table} from "react-bootstrap";

class TariffPlan extends Component {
    render = () => {
        return (
            <Card border={this.props.preferred ? "primary" : null}>
                <Card.Body>
                    <Card.Title>{this.props.title}</Card.Title>
                    <Card.Subtitle className="text-muted"><p>{this.props.price}</p></Card.Subtitle>
                    <Card.Text>
                        <Table striped>
                            <tbody>
                                {this.props.details.map(n => {
                                    return (
                                        <tr key={n.name}>
                                            <td>{n.name}</td>
                                            <td style={{width: "30%"}} className="text-right">{n.value}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Card.Text>
                    <p className="text-center" style={{padding: 0, margin: 0}}>
                        {this.props.current &&
                        <span className="text-info"><FormattedMessage id="account.tariff.youAreHere"/></span>
                        }
                        {!this.props.current && this.props.displayButton()}
                        {!this.props.current && !this.props.preferred && <span className="text-hide">·</span>}
                    </p>

                </Card.Body>
            </Card>
        )
    }
}

TariffPlan.propTypes = {
    title: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    details: PropTypes.array.isRequired,
    current: PropTypes.bool.isRequired,
    displayButton: PropTypes.func.isRequired,
    preferred: PropTypes.bool.isRequired
};

export default TariffPlan;
