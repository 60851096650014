import React, {Component} from 'react'
import {FormattedMessage, injectIntl} from "react-intl";
import SearchService from "../../services/SearchService";
import LinkService from "../../services/LinkService";
import BookmarksService from "../../services/BookmarksService";
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";
import Button from "react-bootstrap/Button";

class OtherViewPanel extends Component {
    state = {
        results: {
            inbox: {
                key: "inbox",
                icon: "fa-inbox inboxColor",
                title: "main.menu.inbox",
                count: 0,
                link: null
            },
            history: {
                key: "history",
                icon: "fa-history historyColor",
                title: "main.menu.history",
                count: 0,
                link: null
            },
            favorites: {
                key: "favorites",
                icon: "fa-star favoritesColor",
                title: "main.menu.favorites",
                count: 0,
                link: null
            }
        },
        lastParams: null
    };

    getOtherViewParams = () => {
        const params = SearchService.createRequest();
        params.filter = (params.filter || []).filter(value =>
            value !== "untagged" &&
            value !== "failed" &&
            value !== "public" &&
            value !== "private" &&
            value !== "flagged" &&
            value !== "reco"
        );
        return params;
    };

    getOtherViewParamsWithoutSections = () => {
        const params = this.getOtherViewParams();
        params.filter = (params.filter || []).filter(value =>
            value !== "readLater" &&
            value !== "history"
        );
        params["offset"] = 0;
        params["limit"] = 0;
        params["expand-facets"] = false;
        return params;
    };

    loadStats = (section, filter, sectionPath) => {
        const params = this.getOtherViewParamsWithoutSections();

        if (
            (window.location.pathname.startsWith(sectionPath)) ||
            (params.q === undefined && params.tags === undefined && params.website === undefined)
        ) {
            this.setCountAndLink(section, 0, null);
            return;
        }

        if (filter !== undefined && filter !== null) {
            params.filter.push(filter);
        }

        const currentParams = JSON.parse(JSON.stringify(SearchService.extractSearch()));
        const link = `${sectionPath}?${SearchService.joinSearchWithoutUndefined({
            q: currentParams.q,
            tags: currentParams.tags,
            website: currentParams.website,
            spell: currentParams.spell
        })}`;
        BookmarksService.queryV4(params).then(bookmarks => this.setCountAndLink(section, bookmarks.total, link));
    };

    setCountAndLink = (section, count, link) => {
        const results = this.state.results;
        results[section].count = count;
        results[section].link = link;
        this.setState({results: results});
    };

    loadOtherViewsStats = () => {
        this.loadStats("inbox", "readLater", LinkService.inbox());
        this.loadStats("history", "history", LinkService.history());
        this.loadStats("favorites", undefined, LinkService.favorites());
    };

    UNSAFE_componentWillMount = () => {
        const params = JSON.stringify(this.getOtherViewParams());
        this.setState({lastParams: params});
        this.loadOtherViewsStats();
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        const params = JSON.stringify(this.getOtherViewParams());
        if (this.state.lastParams !== params) {
            this.setState({lastParams: params}, () => {
                this.loadOtherViewsStats();
            });
        }
    };

    render = () => {
        const {formatMessage} = this.props.intl;

        const showOthersViews = ["inbox", "history", "favorites"]
            .map(key => this.state.results[key].count || 0)
            .reduce((acc, num) => acc + num, 0) > 0;


        return (
            <React.Fragment>
                {showOthersViews &&
                <p>
                    <span className="text-secondary align-middle">{formatMessage({id: "main.resultInOtherSections"})}: </span>
                    {
                        ["inbox", "history", "favorites"]
                            .map(key => this.state.results[key])
                            .filter(section => (section.count || 0) > 0)
                            .map(section => {
                                return (
                                    <LinkContainer to={section.link} key={section.key}>
                                        <Button variant="link">
                                            <i className={`fa-fw fas ${section.icon}`} style={{marginRight: 8}}/>
                                            <span style={{marginRight: 8}}><FormattedMessage id={section.title}/></span>
                                            <span className="badge badge-secondary">{section.count}</span>
                                        </Button>
                                    </LinkContainer>
                                )
                            })
                    }
                </p>
                }
            </React.Fragment>
        )
    }
}

export default injectIntl(OtherViewPanel);
