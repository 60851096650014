import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import {Table} from "react-bootstrap";
import UrlService from "../../services/UrlService";
import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import BookmarksService from "../../services/BookmarksService";

class OriginalUrls extends Component {
    state = {
        fixedLinks: {}
    }

    onOpen = (e) => {
        e.stopPropagation();
    };

    makeFixedBookmark = (link) => {
        const {formatMessage} = this.props.intl;

        BookmarksService
            .createBookmark(null, link, null, true, true)
            .then(() => {
                const fixedLinks = this.state.fixedLinks;
                fixedLinks[link] = true;
                this.setState({fixedLinks: fixedLinks});
                this.props.onAddToast({
                    id: Date.now(),
                    type: "success",
                    title: formatMessage({id: "main.fixedLinkBookmark"}),
                    description: formatMessage({id: "main.fixedLinkBookmark.created"})
                });
            })
            .catch(() => {
                this.props.onAddToast({
                    id: Date.now(),
                    type: "error",
                    title: formatMessage({id: "main.fixedLinkBookmark"}),
                    description: formatMessage({id: "main.fixedLinkBookmark.failed"})
                });
            });
    }

    render = () => {
        return (
            <div className="bookmark-block">
                <Table bordered size="sm">
                    <thead>
                    <tr>
                        <th><FormattedMessage id="main.originalLink"/></th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.urls.map((link, index) => {
                        return (
                            <tr key={index}>
                                <td style={{wordWrap: "break-word", maxWidth: 200}}>
                                    <a href={link} onClick={this.onOpen} target="_blank" rel="noopener noreferrer">
                                        {UrlService.decodeUrl(UrlService.punyCode(link))}
                                    </a>
                                    <OverlayTrigger placement="top" overlay={<Tooltip><FormattedMessage id="main.createFixedLinkBookmark"/></Tooltip>}>
                                        <Button variant="light"
                                                size="sm"
                                                className="float-right"
                                                disabled={this.state.fixedLinks[link] === true}
                                                onClick={() => this.makeFixedBookmark(link)}
                                        >
                                            <i className="fa-fw fas fa-link text-black-50 button-icon"/>
                                        </Button>
                                    </OverlayTrigger>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </Table>
            </div>
        )
    }
}

OriginalUrls.propTypes = {
    urls: PropTypes.array.isRequired,
    onAddToast: PropTypes.func.isRequired
};

export default injectIntl(OriginalUrls);
