import React, {Component} from 'react'

class TabContainer extends Component {
    render = () => {
        return (
            <div style={{margin: 24}}>{this.props.children}</div>
        )
    }
}

export default TabContainer;
