import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl"
import Modal from "react-bootstrap/Modal";
import ShareLinkBar from "../../shell/ShareLinkBar";
import {Button, Form, FormControl, InputGroup} from "react-bootstrap";

class ShareBookmarkDialog extends Component {
    state = {
        showLinkCopied: false
    };

    copyLink = () => {
        const copyText = document.getElementById("bookmark-link");
        copyText.select();
        document.execCommand("copy");

        this.setState({showLinkCopied: true});
        setTimeout(() => {
            this.setState({showLinkCopied: false});
        }, 5000);
    };

    render = () => {
        const article = {
            title: this.props.bookmark.title,
            gdLink: this.props.bookmark.link
        };

        return (
            <Modal show={true} onHide={this.props.onClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title><FormattedMessage id="main.shareBookmark"/></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p className="lead text-center">{this.props.bookmark.title}</p>
                    <ShareLinkBar article={article}/>

                    <Form style={{marginTop: 24}}>
                        <InputGroup>
                            <FormControl
                                id="bookmark-link"
                                value={this.props.bookmark.link}
                                aria-label="Bookmark's url"
                                aria-describedby="basic-addon2"
                            />
                            <InputGroup.Append>
                                <Button variant="outline-secondary" onClick={this.copyLink}>
                                    <i className="fas fa-copy"/>
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                        {this.state.showLinkCopied &&
                        <Form.Text className="text-success text-center"><FormattedMessage id="main.linkCopied"/></Form.Text>
                        }
                        {!this.state.showLinkCopied &&
                        <Form.Text className="text-muted text-center"><FormattedMessage id="main.clickForCopyingLink"/></Form.Text>
                        }
                    </Form>
                </Modal.Body>
            </Modal>
        )
    }
}

ShareBookmarkDialog.propTypes = {
    bookmark: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ShareBookmarkDialog;
