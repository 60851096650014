import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Col, Form, FormControl, Row} from "react-bootstrap";

function createUUID() {
    let dt = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
    });
}

class ChipInput extends Component {
    formControlRef = React.createRef();

    state = {
        listId: createUUID(),
        focused: false,
        exitingIndex: -1,
        input: "",
        suggestions: [],
        lastRequestedValue: null,
    };

    tick = () => {
        if (this.state.lastRequestedValue !== this.state.input && this.state.input.length >= 3) {
            this
                .props.getSuggestions(this.state.input)
                .then(suggestions => this.setState({suggestions: suggestions, lastRequestedValue: this.state.input}))
                .catch(() => this.setState({suggestions: [], lastRequestedValue: this.state.input}));

        } else if (this.state.input.length === 0) {
            this.setState({suggestions: []});
        }
    };

    removeChip = (index) => {
        this.setState({exitingIndex: index});
        // setTimeout(() => {
        this.props.onRemove(index);
        this.setState({exitingIndex: null});
        this.formControlRef.current.focus();
        // }, 250);
    };

    editChip = (index) => {
        const chips = this.props.chips.slice();
        const editChipValue = chips[index];
        chips.splice(index, 1);
        this.removeChip(index);
        this.setState({input: editChipValue});
    };

    handleChangeInput = (input) => {
        const value = this.props.prepareChip ? this.props.prepareChip(input) : input;
        if (Math.abs(this.state.input.length - value.length) > 1 && value.length > 0) {
            this.handleSubmit(value);
        } else {
            this.setState({input: value});
        }
    };

    handleSubmit = (input) => {
        if (input.trim().length > 0) {
            this.props.onSubmit(input);
            this.setState({input: ""});
        }
    };

    componentDidMount() {
        if (this.props.getSuggestions) {
            this.timerID = setInterval(() => this.tick(), 300);
        }
    };

    componentWillUnmount() {
        if (this.timerID) {
            clearInterval(this.timerID);
        }
    };

    render = () => {
        return (
            <div
                className={[
                    this.props.classes,
                    'chip-input',
                    'rounded',
                    'border',
                    this.state.focused ? 'border-primary' : ''
                ].join(' ')}
            >
                <Row className="align-items-center" noGutters>
                    {this.props.chips.map((chip, index) => (
                        <Col xs="auto" className="chip-wrapper" key={index}>
                            <span
                                className={["tags-field-tag", "clickable", "badge", "badge-light", 'show', "chip", this.state.exitingIndex === index ? 'hide' : ''].join(' ')}>
                                <span onClick={() => this.editChip(index)}>{chip}</span>
                                <span className="close-tag" onClick={() => this.removeChip(index)}>×</span>
                            </span>
                        </Col>
                    ))}

                    <Col xs>
                        {/* The input, from which value is read and chip is added accordingly */}
                        <Form
                            className="custom-form-control"
                            onSubmit={(e) => {
                                e.preventDefault();
                                this.handleSubmit(this.state.input);
                            }}
                            noValidate
                        >
                            <FormControl
                                style={{height: "auto", minWidth: 100}}
                                ref={this.formControlRef}
                                value={this.state.input}
                                onChange={(e) => this.handleChangeInput(e.target.value)}
                                list={this.state.listId}
                                name={this.props.chipInputName}
                                placeholder={this.props.editPlaceholder}
                                aria-label="Chip Input"
                                className={['m-0', 'border-0', 'no-focus', 'mb-0'].join(' ')}
                                onFocus={() => this.setState({focused: true})}
                                onBlur={() => this.setState({focused: false})}
                            />
                            {/*{this.state.suggestions &&*/}
                            <datalist id={this.state.listId}>
                                {this.state.suggestions.map((item, index) => {
                                    return <option key={index} value={item}/>
                                })}
                            </datalist>
                            {/*}*/}
                        </Form>
                    </Col>
                </Row>
            </div>
        )
    }
}

ChipInput.propTypes = {
    chipInputName: PropTypes.string.isRequired,
    chips: PropTypes.array.isRequired,
    editPlaceholder: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    getSuggestions: PropTypes.func,
    prepareChip: PropTypes.func
};

export default ChipInput;
