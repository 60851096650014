import React, {Component} from 'react'
import PropTypes from 'prop-types'

class LoadingProgress extends Component {
    render = () => {
        return (
            <React.Fragment>
                {this.props.show &&
                <div className="spinner">
                    <div className="bounce1"/>
                    <div className="bounce2"/>
                    <div className="bounce3"/>
                    <div className="bounce4"/>
                </div>
                }
            </React.Fragment>
        )
    }
}

LoadingProgress.propTypes = {
    show: PropTypes.bool.isRequired
};

export default LoadingProgress;
