import axios from "axios/index";
import ReturnPromise from "./ReturnPromise";

const BookmarksService = {
    exportBookmarks: function () {
        return ReturnPromise.get(axios.get("/api/v4/bookmarks", {params: {output: 'base64'}}));
    },

    importBookmarks: function (file) {
        const data = new FormData();
        data.append('Files', file);
        return ReturnPromise.get(axios.post('/api/v3/import-requests', data));
    },

    updateHistory: function (documentId) {
        return this.updateBookmarkV4(documentId, {accessed: true});
    },

    updateBookmarkV4: function (documentId, updateObj) {
        return ReturnPromise.get(axios.put(`/api/v4/bookmarks/${documentId}`, updateObj));
    },

    checkBookmarkByUrlV4: function (url) {
        return ReturnPromise.get(axios.post("/api/v4/bookmarks?url=true", {url: url}));
    },

    createBookmark: function (title, url, tags, readLater, permalink) {
        return ReturnPromise.get(axios.post('/api/v4/bookmarks', {
            title: title,
            url: url,
            tags: tags,
            readLater: readLater,
            permalink: permalink
        }));
    },

    findByUrl: function (url) {
        return ReturnPromise.get(axios.get(url));
    },

    removeFromHistory: function (documentId) {
        return this.updateBookmarkV4(documentId, {accessed: false});
    },

    updatePrivate: function (documentId, isPrivate) {
        return this.updateBookmarkV4(documentId, {private: isPrivate});
    },

    updateFlagged: function (documentId, isFlagged) {
        return this.updateBookmarkV4(documentId, {flagged: isFlagged});
    },

    updateFavorite: function (documentId, favorite) {
        return this.updateBookmarkV4(documentId, {favorite: favorite});
    },

    updateInbox: function (documentId, isReadLater) {
        return this.updateBookmarkV4(documentId, {readLater: isReadLater});
    },

    refresh: function (documentId) {
        return this.updateBookmarkV4(documentId, {refresh: true});
    },

    shred: function (documentId) {
        return ReturnPromise.get(axios.delete(`/api/v4/bookmarks/${documentId}`), true);
    },

    bulkDelete: function (bookmarkIds) {
        return ReturnPromise.get(
            axios.post('/api/v4/bulk-requests', {ids: bookmarkIds, type: "REMOVE_FROM_FAVORITES"}));
    },

    bulkAdd: function (bookmarkIds) {
        return ReturnPromise.get(
            axios.post('/api/v4/bulk-requests', {ids: bookmarkIds, type: "ADD_TO_FAVORITES"}));
    },

    bulkToInbox: function (bookmarkIds) {
        return ReturnPromise.get(
            axios.post('/api/v4/bulk-requests', {ids: bookmarkIds, type: "ADD_TO_INBOX"}));
    },

    bulkFromInbox: function (bookmarkIds) {
        return ReturnPromise.get(
            axios.post('/api/v4/bulk-requests', {ids: bookmarkIds, type: "REMOVE_FROM_INBOX"}));
    },

    bulkPrivate: function (bookmarkIds) {
        return ReturnPromise.get(
            axios.post('/api/v4/bulk-requests', {ids: bookmarkIds, type: "MAKE_PRIVATE"}));
    },

    bulkPublic: function (bookmarkIds) {
        return ReturnPromise.get(
            axios.post('/api/v4/bulk-requests', {ids: bookmarkIds, type: "MAKE_PUBLIC"}));
    },

    updateBookmarkProps: function (documentId, title, tags) {
        return this.updateBookmarkV4(documentId, {title: title, tags: tags});
    },

    getTagRecommendations: function (bookmarkId) {
        return ReturnPromise.get(axios.get(`/api/v4/tagRecommendations/${bookmarkId}`));
    },

    queryV4: function (request) {
        return ReturnPromise.get(axios.get("/api/v4/bookmarks", {params: request}));
    },
};

export default BookmarksService;