import React, {Component} from 'react'
import PropTypes from 'prop-types'
import SetupProgress from "../shell/SetupProgress";
import WelcomeAlert from "./WelcomeAlert";
import LoadingProgress from "./LoadingProgress";
import BookmarksService from "../services/BookmarksService";
import SearchService from "../services/SearchService";
import BookmarksList from "./BookmarksList";
import {FormattedMessage} from "react-intl";
import FoundResults from "./FoundResults";
import SearchPanel from "./search/SearchPanel";
import GrabDuckDivider from "../shell/GrabDuckDivider";
import InfiniteScroll from "./InfiniteScroll";
import TagsPanel from "./search/TagsPanel";
import Spellcheck from "./search/Spellcheck";
import OtherViewPanel from "./search/OtherViewPanel";
import BookmarkUpdatesService from "../services/BookmarkUpdatesService";

class Main extends Component {
    state = {
        showProgress: false,
        showBulkPanel: false,
        nextUrl: null,
        nextRecoUrl: null,
        bookmarks: null,
        results: null,
        updateId: null,
        isSpellcheck: false,
        spellcheckQuery: "",
        originalQuery: "",
        endOfSearch: false,

        lastSearch: null,
        lastPathname: null,
        lastReload: 0,
        bookmarksCount: 24
    };

    updateResults = () => {
        BookmarkUpdatesService
            .getBookmarkUpdates(this.state.updateId)
            .then((data) => {
                let bookmarks = this.state.bookmarks;

                // DELETE
                data.items
                    .filter(item => item.operation === "DELETE")
                    .forEach(item => {
                        this.deleteBookmarkFromList(bookmarks, item.reference.id)
                    });

                // UPDATE
                data.items
                    .filter(item => item.operation === "UPDATE")
                    .forEach(item => {
                        const bookmarkId = item.reference.id
                        const i = bookmarks.map(b => b.id).indexOf(bookmarkId);
                        if (i > -1) {
                            item.reference.visible = bookmarks[i].visible;
                            item.reference.selected = bookmarks[i].selected;
                            bookmarks[i] = item.reference;
                        }
                    });

                // INSERT_TOP
                data.items
                    .filter(item => item.operation === "INSERT_TOP")
                    .forEach(item => {
                        bookmarks.unshift(item.reference);
                    });

                // MOVE_TOP
                data.items
                    .filter(item => item.operation === "MOVE_TOP")
                    .forEach(item => {
                        const bookmark = this.deleteBookmarkFromList(bookmarks, item.reference.id);
                        if (bookmark !== null) {
                            this.insertBookmarkAt(bookmarks, bookmark, 0);
                        }
                    });

                // INSERT_AFTER
                let insertAfter = data.items.filter(item => item.operation === "INSERT_AFTER")
                while (insertAfter.length > 0) {
                    const referenceIds = new Set(insertAfter.map(item => item.reference.id));
                    const saveAfterIds = new Set(
                        insertAfter
                            .map(item => item.after.id)
                            .filter(id => !referenceIds.has(id))
                    );
                    insertAfter
                        .filter(item => saveAfterIds.has(item.after.id))
                        .forEach(item => {
                            // const bookmark = this.deleteBookmarkFromList(bookmarks, item.reference.id);
                            // if (bookmark !== null) {
                            const i = bookmarks.map(b => b.id).indexOf(item.after.id);
                            if (i > -1) {
                                this.insertBookmarkAt(bookmarks, item.reference, i + 1);
                            }
                            // }
                        });
                    insertAfter = insertAfter.filter(item => !saveAfterIds.has(item.after.id))
                }

                // MOVE_AFTER
                let moveAfter = data.items.filter(item => item.operation === "MOVE_AFTER")
                while (moveAfter.length > 0) {
                    const referenceIds = new Set(moveAfter.map(item => item.reference.id));
                    const saveAfterIds = new Set(
                        moveAfter
                            .map(item => item.after.id)
                            .filter(id => !referenceIds.has(id))
                    );
                    moveAfter
                        .filter(item => saveAfterIds.has(item.after.id))
                        .forEach(item => {
                            const bookmark = this.deleteBookmarkFromList(bookmarks, item.reference.id);
                            if (bookmark !== null) {
                                const i = bookmarks.map(b => b.id).indexOf(item.after.id);
                                if (i > -1) {
                                    this.insertBookmarkAt(bookmarks, bookmark, i + 1);
                                }
                            }
                        });
                    moveAfter = moveAfter.filter(item => !saveAfterIds.has(item.after.id))
                }

                this.setState({
                    results: data.total,
                    bookmarks: bookmarks
                });
            })
            .catch(() => {
            });
    };

    deleteBookmarkFromList = (bookmarks, bookmarkId) => {
        const i = bookmarks.map(b => b.id).indexOf(bookmarkId);
        if (i > -1) {
            const bookmark = bookmarks[i]
            bookmarks.splice(i, 1);
            return bookmark

        } else {
            return null;
        }
    };

    insertBookmarkAt = (bookmarks, bookmark, position) => {
        bookmarks.splice(position, 0, bookmark);
    };

    startNewSearch = () => {
        // this.props.onSelectedCountChanged({total: 0, favorites: 0, notFavorites: 0, inbox: 0});
        this.setState({
            bookmarks: null,
            nextUrl: null,
            nextRecoUrl: null,
            endOfSearch: false,
            results: null,
            isSpellcheck: false,
            showBulkPanel: false
        }, this.loadBookmarks);
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.reload !== null && this.state.lastReload !== nextProps.reload) {
            this.setState({lastReload: nextProps.reload});
            this.updateResults();
        }

        if (nextProps.location.search !== this.state.lastSearch || nextProps.location.pathname !== this.state.lastPathname) {
            if (this.state.lastSearch !== null) {

                this.startNewSearch();
            }
            this.setState({lastSearch: nextProps.location.search, lastPathname: nextProps.location.pathname});
        }
    };

    UNSAFE_componentWillMount = () => {
        this.loadBookmarks();
        this.setState({lastSearch: this.props.location.search, lastPathname: this.props.location.pathname});
    };

    doLoadBookmarks = () => {
        if (this.state.nextUrl === null || this.state.bookmarks === null) {
            let request = SearchService.createRequest();
            request["offset"] = 0;
            request["limit"] = this.state.bookmarksCount;
            request["expand-facets"] = false;
            return BookmarksService
                .queryV4(request)
                .then(bookmarks => {
                    return this.doLoadRecommendations(bookmarks)
                });

        } else {
            return BookmarksService
                .findByUrl(this.state.nextUrl)
                .then(bookmarks => {
                    return this.doLoadRecommendations(bookmarks)
                });
        }
    };

    doLoadRecommendations = (bookmarks) => {
        if (this.state.nextRecoUrl === null) {
            let limit = Math.round(this.state.bookmarksCount * this.props.user.settings.recoPercentage / 100);
            if (limit > 0) {
                let recoRequest = SearchService.createRequest(true);
                recoRequest["offset"] = 0;
                recoRequest["limit"] = limit;
                recoRequest["expand-facets"] = false;

                return BookmarksService
                    .queryV4(recoRequest)
                    .then(recos => {
                        return this.addRecos(bookmarks, recos);
                    });
            } else {
                return bookmarks;
            }
        } else {
            return BookmarksService
                .findByUrl(this.state.nextRecoUrl)
                .then(recos => {
                    return this.addRecos(bookmarks, recos);
                });
        }
    };

    addRecos = (bookmarks, recos) => {
        this.setState({nextRecoUrl: recos.hasNext ? recos.next : null});
        let numRecosToShow = Math.min(recos.items.length, Math.round(bookmarks.items.length * this.props.user.settings.recoPercentage / 100));
        recos.items.slice(0, numRecosToShow).forEach(reco => {
            reco.recommendation = true;
            bookmarks.items.push(reco);
        });

        return bookmarks;
    };

    loadBookmarks = () => {
        this.setState({showProgress: true});
        this
            .doLoadBookmarks()
            .then(data => {
                if (this.state.nextUrl === null || this.state.bookmarks === null) {
                    this.setState({
                        isSpellcheck: data.spellcheckQuery !== undefined,
                        spellcheckQuery: data.spellcheckQuery,
                        originalQuery: data.originalQuery,
                        results: data.total,
                        updateId: data.updateId
                    });
                }

                const bookmarks = this.state.bookmarks || [];
                const bookmarkIds = new Set(bookmarks.map(b => b.id));

                data.items
                    .map(b => {
                        b.loaded = Date.now();
                        b.visible = true;
                        return b;
                    })
                    .forEach(b => {
                        if (!(b.id in bookmarkIds)) {
                            bookmarks.push(b)
                        }
                    });
                this.setState({
                    bookmarks: bookmarks,
                    nextUrl: data.hasNext ? data.next : null,
                    endOfSearch: !data.hasNext
                });
                this.setState({showProgress: false});
            })
            .catch(() => {
                this.setState({showProgress: false});
            });
    };

    loadNext = () => {
        if (this.state.nextUrl !== null) {
            this.loadBookmarks();
        }
    };

    handleBookmarksUpdated = (bookmarks) => {
        this.setState({bookmarks: bookmarks});
    };

    render = () => {
        return (
            <div style={{marginTop: 16, marginBottom: 16, marginLeft: "auto", marginRight: "auto", maxWidth: 800}}>
                {this.props.user.setupProgress.showSetupProgress &&
                <SetupProgress onUserUpdate={this.props.onUserUpdate} setupProgress={this.props.user.setupProgress} closable={true}/>
                }

                <WelcomeAlert/>

                {!this.state.showBulkPanel &&
                <OtherViewPanel onRoot={this.props.onRoot}/>
                }

                {!this.state.showBulkPanel &&
                <SearchPanel onRoot={this.props.onRoot} location={this.props.location}/>
                }

                <TagsPanel onRoot={this.props.onRoot}/>

                {this.state.isSpellcheck && <Spellcheck spellcheckQuery={this.state.spellcheckQuery}/>}

                {this.state.results != null && this.state.results > 0 && !this.state.showBulkPanel &&
                <FoundResults privileges={this.props.user.privileges || []}
                              settings={this.props.user.settings || {}}
                              userId={this.props.user.id}
                              results={this.state.results}
                              query={this.state.isSpellcheck ? this.state.spellcheckQuery : this.state.originalQuery}
                />
                }

                {this.state.bookmarks && this.state.bookmarks.length > 0 &&
                <BookmarksList bookmarks={this.state.bookmarks}
                               privileges={this.props.user.privileges || []}
                               onAddToast={this.props.onAddToast}
                               onBookmarksUpdated={this.handleBookmarksUpdated}
                               onUserUpdate={this.props.onUserUpdate}
                               onShowBulkEditPanel={(showBulkPanel) => this.setState({showBulkPanel: showBulkPanel})}
                />
                }

                {this.state.bookmarks && this.state.bookmarks.length === 0 &&
                <React.Fragment>
                    <p className="lead text-center" style={{marginTop: "4rem", marginBottom: 0}}><FormattedMessage id="common.nothingWasFound"/></p>
                    <p className="text-center"><FormattedMessage id="main.noBookmarksDesc"/></p>
                </React.Fragment>
                }

                <LoadingProgress show={this.state.showProgress}/>

                {this.state.endOfSearch && this.state.results > 0 && <GrabDuckDivider/>}

                <InfiniteScroll distance={1} disabled={this.state.showProgress} onBottom={this.loadNext}/>
            </div>

        )
    }
}

Main.propTypes = {
    location: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    onUserUpdate: PropTypes.func.isRequired,
    onAddToast: PropTypes.func.isRequired,
    reload: PropTypes.number.isRequired,
    onRoot: PropTypes.func.isRequired
};

export default Main;
