import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import {FormattedMessage, injectIntl} from "react-intl";
import LinkService from "../services/LinkService";
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer'
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {withRouter} from "react-router-dom";
import AvatarCard from "./AvatarCard";
import axios from "axios";

class TopNavbar extends Component {
    state = {
        lastUpdate: new Date()
    };

    scrollToId = (elementId) => {
        document
            .getElementById(elementId)
            .scrollIntoView(true);
    };

    handleRegisterClick = () => {
        const itself = this;
        const pathname = this.props.locationPathname.toLowerCase();
        if (pathname.startsWith(LinkService.welcome())) {
            this.scrollToId(this.props.registerId);
        } else {
            this.props.history.push(LinkService.welcome());
            setTimeout(() => {
                itself.scrollToId(this.props.registerId);
            }, 300);
        }
    };

    handleLoginClick = () => {
        const itself = this;
        const pathname = this.props.locationPathname.toLowerCase();
        if (pathname.startsWith(LinkService.welcome())) {
            this.scrollToId(this.props.loginId);
        } else {
            this.props.history.push(LinkService.welcome());
            setTimeout(() => {
                itself.scrollToId(this.props.loginId);
            }, 300);
        }
    };

    async longPollingForUpdates() {
        const itself = this;
        const isMainScreen = this.isMainScreen(this.props.locationPathname.toLowerCase());
        if (isMainScreen) {
            axios
                .get("/api/v4/updates")
                .then((response) => {
                    const delta = (new Date() - this.state.lastUpdate) / 1000;
                    this.setState({lastUpdate: new Date()});
                    if (response.data.length > 0 || delta > 60) {
                        itself.props.onRefresh();
                    }
                    itself.longPollingForUpdates()
                })
                .catch(function () {
                    itself.longPollingForUpdates()
                });
        } else {
            setTimeout(() => {
                this.longPollingForUpdates()
            }, 1000);
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.longPollingForUpdates()
        }, 1000);
    }

    isMainScreen = (pathname) => {
        return pathname.startsWith(LinkService.inbox())
            || pathname.startsWith(LinkService.history())
            || pathname.startsWith(LinkService.favorites())
            || pathname.startsWith(LinkService.search())
    }

    render = () => {
        const {formatMessage} = this.props.intl;

        const pathname = this.props.locationPathname.toLowerCase();
        let title = undefined;
        if (pathname.startsWith(LinkService.welcome())) {
            title = formatMessage({id: "common.topMenuTitle.welcome"})

        } else if (pathname.startsWith(LinkService.inbox())) {
            title = formatMessage({id: "common.topMenuTitle.inbox"})

        } else if (pathname.startsWith(LinkService.history())) {
            title = formatMessage({id: "common.topMenuTitle.history"})

        } else if (pathname.startsWith(LinkService.favorites())) {
            title = formatMessage({id: "common.topMenuTitle.favorites"})

        } else if (pathname.startsWith(LinkService.help())) {
            title = formatMessage({id: "common.topMenuTitle.help"})

        } else if (pathname.startsWith(LinkService.account())) {
            title = formatMessage({id: "common.topMenuTitle.account"})
        }
        const isMainScreen = this.isMainScreen(pathname);

        return (
            <Navbar bg="primary" expand={true} fixed="top" variant="dark">
                <Container fluid={false}>
                    <LinkContainer to={LinkService.home(this.props.user)}>
                        <Navbar.Brand>
                            <img
                                alt="GrabDuck Logo"
                                src="/static/images/duck-32.png"
                                width="30"
                                height="30"
                                style={{marginRight: 8}}
                                className="d-inline-block align-top"
                            />
                            <span className="d-none d-sm-inline">{formatMessage({id: "common.applicationName"})}</span>
                        </Navbar.Brand>
                    </LinkContainer>

                    {title && <div className="mx-auto order-0 d-none d-sm-inline"><Navbar.Brand>{title}</Navbar.Brand></div>}

                    <Nav>
                        {this.props.user &&
                        <React.Fragment>
                            {isMainScreen &&
                            <React.Fragment>
                                <OverlayTrigger placement="bottom" overlay={<Tooltip><FormattedMessage id="common.refresh"/></Tooltip>}>
                                    <Nav.Link onClick={this.props.onRefresh} className="top-nav-action"><i className="fas fa-2x fa-fw fa-sync"/></Nav.Link>
                                </OverlayTrigger>

                                <OverlayTrigger placement="bottom" overlay={<Tooltip><FormattedMessage id="addBookmark.newBookmark"/></Tooltip>}>
                                    <Nav.Link onClick={this.props.onNewBookmark} className="top-nav-action"><i className="fas fa-2x fa-fw fa-plus"/></Nav.Link>
                                </OverlayTrigger>
                            </React.Fragment>
                            }

                            <NavDropdown style={{paddingTop: 0, paddingBottom: 0}}
                                         className="top-nav-action no-focus"
                                         title={<i className="fas fa-2x fa-fw fa-user-astronaut"/>}
                            >
                                <AvatarCard user={this.props.user}/>
                                <NavDropdown.Divider/>
                                <LinkContainer to={LinkService.accountSummary()} active={false}>
                                    <NavDropdown.Item active={false}>
                                        <FormattedMessage id="common.topMenuTitle.account"/>
                                    </NavDropdown.Item>
                                </LinkContainer>
                                <NavDropdown.Item onClick={this.props.onLogout} active={false}><FormattedMessage id="common.logout"/></NavDropdown.Item>
                            </NavDropdown>
                        </React.Fragment>
                        }

                        {!this.props.user &&
                        <React.Fragment>
                            <Button className="top-nav-button" variant="primary" onClick={this.handleLoginClick}>
                                <FormattedMessage id="welcome.login"/>
                            </Button>
                            <Button className="top-nav-button" variant="success" onClick={this.handleRegisterClick}>
                                <FormattedMessage id="welcome.register"/>
                            </Button>
                        </React.Fragment>
                        }
                    </Nav>
                </Container>
            </Navbar>
        )
    }
}

TopNavbar.propTypes = {
    user: PropTypes.object,
    locationPathname: PropTypes.string.isRequired,
    loginId: PropTypes.string.isRequired,
    registerId: PropTypes.string.isRequired,
    onLogout: PropTypes.func.isRequired,
    onRefresh: PropTypes.func.isRequired,
    onNewBookmark: PropTypes.func.isRequired
};

export default withRouter(injectIntl(TopNavbar));
