import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl"
import Button from "react-bootstrap/Button";
import LinkService from "../services/LinkService";
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

class GoBackButton extends Component {
    render = () => {
        return (
            <React.Fragment>
                {!this.props.sidebarToggled &&
                <LinkContainer to={LinkService.home(this.props.user)}>
                    <Button block className="btn btn-flat-light">
                        <i className="fas fa-arrow-left" style={{marginRight: 8}}/>
                        <FormattedMessage id="common.back"/>
                    </Button>
                </LinkContainer>
                }

                {this.props.sidebarToggled &&
                <OverlayTrigger placement="right" overlay={<Tooltip><FormattedMessage id="common.back"/></Tooltip>}>
                    <LinkContainer to={LinkService.home(this.props.user)}>
                        <Button variant="light" block className="btn btn-flat-light">
                            <i className="fas fa-arrow-left"/>
                        </Button>
                    </LinkContainer>
                </OverlayTrigger>
                }
            </React.Fragment>
        )
    }
}

GoBackButton.propTypes = {
    user: PropTypes.object,
    sidebarToggled: PropTypes.bool.isRequired,
};

export default GoBackButton;
