import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import {Popover} from "react-bootstrap";
import SyncService from "../../../services/SyncService";
import AccountIdBasedProvider from "./AccountIdBasedProvider";

class YoutubeListProvider extends Component {
    getListUrl = (userId) => {
        return `${this.props.service.website}/playlist?list=${userId}`;
    };

    extractUserId = (userId) => {
        if (userId !== undefined) {
            const template = new RegExp(`${this.props.service.website}/playlist\\?list=(\\w+)`, "i");
            const match = userId.match(template);
            return match ? match[1] : userId;
        } else {
            return userId;
        }
    };

    checkUserId = (userId) => {
        return SyncService.checkYoutubeListId(userId);
    };

    renderHelp = () => {
        return (
            <Popover id="popover-youtube">
                <h6><FormattedMessage id="account.sync.providers.youtube.howFindListId"/></h6>
                <ol>
                    <li><p><FormattedMessage id="account.sync.providers.youtube.openYoutubeList"/></p></li>
                    <li><p><FormattedMessage id="account.sync.providers.youtubeCopyListUrl"/></p></li>
                </ol>
            </Popover>
        );
    };

    render = () => {
        const {formatMessage} = this.props.intl;
        return (
            <AccountIdBasedProvider service={this.props.service}
                                    onChange={this.props.onChange}
                                    validate={this.props.validate}

                                    providerId="youtubeListProvider"
                                    providerDescription={formatMessage({id: "account.sync.providers.youtube.desc"})}
                                    inputTitle={formatMessage({id: "account.sync.listIdOrUrl"})}
                                    inputPlaceholder={formatMessage({id: "account.sync.enterListIdOrUrl"})}
                                    invalidFeedbackMsg={formatMessage({id: "account.sync.providers.youtube.validationFailed"})}

                                    getAccountUrl={this.getListUrl}
                                    renderHelp={this.renderHelp}
                                    checkAccountId={this.checkUserId}
                                    extractAccountId={this.extractUserId}
            />
        )
    }
}

YoutubeListProvider.propTypes = {
    service: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired
};

export default injectIntl(YoutubeListProvider);
