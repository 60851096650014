import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Media} from "react-bootstrap";
import UrlService from "../../services/UrlService";
import DefaultFavicon from "../default-favicon.png";
import BookmarksService from "../../services/BookmarksService";

class BookmarkSuggestions extends Component {
    state = {
        favIconError: {},
    };

    setErrorFavIcon = (bookmarkId) => {
        const favIconError = this.state.favIconError;
        favIconError[bookmarkId] = true;
        this.setState({favIconError: favIconError});
    };

    handleOpen = (event, bookmarkId) => {
        event.stopPropagation();
        BookmarksService.updateHistory(bookmarkId);
    };

    render = () => {
        return (
            <div>
                {this.props.bookmarks.map((bookmark, index) => {
                    return (
                        <Media style={{marginBottom: 8}} key={index}>
                            <div className="bookmark-icon-placeholder">
                                <img className="bookmark-icon mr-3"
                                     onError={() => this.setErrorFavIcon(bookmark.id)}
                                     src={this.state.favIconError[bookmark.id] === true ? DefaultFavicon : bookmark.faviconUrl}
                                     alt={bookmark.title}/>
                            </div>
                            <Media.Body className="bookmark-body">
                                <h6 className="bookmark-paragraph">
                                    <a href={bookmark.canonicalUrl}
                                       rel="noopener noreferrer"
                                       onClick={(e) => this.handleOpen(e, bookmark.id)}
                                       target="_blank">
                                        {bookmark.title}
                                    </a>
                                </h6>
                                <p className="bookmark-paragraph text-secondary">{UrlService.decodeUrl(UrlService.punyCode(bookmark.canonicalUrl))}</p>
                            </Media.Body>
                        </Media>
                    )
                })}
            </div>
        )
    }
}

BookmarkSuggestions.propTypes = {
    bookmarks: PropTypes.array.isRequired
};

export default BookmarkSuggestions;
