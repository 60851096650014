import React, {Component} from 'react'
import {FormattedMessage} from "react-intl"

class PlanDemo extends Component {
    render = () => {
        return (
            <p><FormattedMessage id="account.plan.demo.description"/></p>
        )
    }
}

// PlanDemo.propTypes = {
//     objectProperty: PropTypes.object.isRequired
// };

export default PlanDemo;
