import React, {Component} from 'react'
import {FormattedMessage} from "react-intl"
import {Table} from "react-bootstrap";
import LinkService from "../../services/LinkService";
import {Link} from "react-router-dom";

class HelpPrivacyGDPR extends Component {
    render = () => {
        return (
            <div className="help-content">
                <h3>
                    <FormattedMessage id="help.privacyGDPR.full"/>
                    <small className="text-muted"> / <FormattedMessage id="help.privacy"/></small>
                </h3>

                <p>Last updated: May 25, 2018</p>

                <h4>Introduction</h4>
                <p>We believe you should always know what data we collect from you
                    and how we use it, and that you should have meaningful control over both. Right from the beginning,
                    we protect the data of our users as best as we can.</p>
                <p>The purpose of this document is to inform you of the extent and
                    manner of storing your personal data, your rights, and the form in which you can exercise your
                    rights.</p>
                <p>The processing of personal data on grabduck.com is carried out by
                    ECONOR s.r.o. Company ID: 24672807 registered in Prague Czech Republic.</p>


                <h4>Processing of personal information</h4>
                <p>At GrabDuck, we store and manage next personal information of our
                    users:</p>

                <Table bordered size="sm">
                    <thead>
                    <tr>
                        <th>Entry</th>
                        <th>Purpose / Description</th>
                        <th>Optional</th>
                        <th>Public</th>
                    </tr>
                    </thead>


                    <tbody>
                    <tr>
                        <td>User Email</td>
                        <td>Basic unique account identifier</td>
                        <td>No</td>
                        <td>No</td>
                    </tr>
                    <tr>
                        <td>Password</td>
                        <td>Stored in encrypted form</td>
                        <td>No</td>
                        <td>@No</td>
                    </tr>
                    <tr>
                        <td>Billing Information</td>
                        <td>Used for providing invoices</td>
                        <td>Yes</td>
                        <td>No</td>
                    </tr>
                    <tr>
                        <td>Kindle Email</td>
                        <td>Sending content of articles to your kindle device</td>
                        <td>Yes</td>
                        <td>No</td>
                    </tr>
                    <tr>
                        <td>
                            Account / Integrations / Services:
                            <ul>
                                <li>User ID</li>
                                <li>User name</li>
                                <li>Profile URL</li>
                                <li>etc</li>
                            </ul>
                        </td>
                        <td>Needed for integration with third party services</td>
                        <td>Yes</td>
                        <td>No</td>
                    </tr>
                    </tbody>
                </Table>

                <p><br/></p>

                <h4>Purpose and method of processing personal data</h4>
                <p>The methods of data processing and goals are described in detail
                    in the section <Link to={LinkService.helpPrivacyPolicy()}>Privacy Policy</Link>.</p>
                <p>When you use our website, your personal data can be processed
                    through cookies. For more information on how we use cookies, visit <Link
                        to={LinkService.helpPrivacyCookieInfo()}>Cookie Information</Link>.</p>


                <h4>Your rights as a data subject or what to do when you want to
                    delete or edit your personal information</h4>
                <p>If you wish to exercise the following rights and / or obtain the
                    relevant information, you can do so by logging in to your customer account at
                    grabduck.com.</p>
                <p>To sign in to your account, you need your login (email and
                    password). If you exercise your rights in a different way, we may ask you to provide unambiguous
                    identification information that will serve to verify the legitimacy of your request. We will respond
                    within 1 month after receiving your request, but we reserve the right to extend this period
                    according to the technical and time requirements of your request.</p>
                <p>In accordance with applicable law, you have the right to request
                    access to our personal data that we process as a personal data administrator, the right to repair or
                    delete it. You may also, at any time, withdraw consent to the processing of certain personal data
                    (such as marketing data) that you have provided us with.</p>
                <p>So what are your rights?</p>

                <h5>Deleting your personal information</h5>
                <p>You have the right to delete your personal information. If you
                    have an account at GrabDuck.com, you can delete it with all your personal information directly after
                    logging in to your account.</p>

                <h5>Fix your personal information</h5>
                <p>You have the right to correct your personal information. If you
                    have an account at GrabDuck.com, you can edit this information directly after logging in to your
                    account. Some data can not be corrected directly (for example, email address), then please contact
                    us via the Contact Form at bottom left hand side.</p>

                <h5>Revocation of consent</h5>
                <p>You may at any time revoke your consent to process certain
                    personal data (e.g., data for marketing purposes) without giving any reason. Please note that the
                    revocation of your consent does not affect the legality of any processing performed on the basis of
                    previously granted consent.</p>

                <h5>Your other rights</h5>
                <p>Please note that, under the General Data Protection Regulation,
                    you also have the right, in addition to the above, to:</p>
                <ul>
                    <li>ask us for information about how we process your personal information</li>
                    <li>request an explanation for us regarding the processing of personal data</li>
                    <li>request us access to these data</li>
                </ul>


                <h4>Data security</h4>
                <p>We store your data on the servers located in United States at
                    Linode, LLC, with which we have a contract in accordance with the GDPR Rules on Personal Data
                    Processing.</p>
                <p>We use Secure HTTPS to transfer data and files. We keep the
                    necessary and appropriate technical and organizational measures to ensure the safe storage of this
                    data.</p>
            </div>
        )
    }
}

export default HelpPrivacyGDPR;
