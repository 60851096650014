import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import {Tab, Tabs} from "react-bootstrap";
import TabContainer from "../subscription/TabContainer";
import ConfiguredServices from "./ConfiguredServices";
import AllServices from "./AllServices";
import SyncService from "../../services/SyncService";

class AccountSync extends Component {
    state = {
        activeKey: "configuredServices",

        configuredServices: null,
        maxAllowed: 0,
        numberOfDefined: 0,
        allServices: null
    };

    calcServiceId = (service) => {
        const n = service.provider.indexOf("/");
        service.providerId = service.provider.substring(0, n);
        return service;
    };

    loadConfiguredServices = () => {
        SyncService
            .loadUserSyncs()
            .then(sync => {
                    this.setState({
                        configuredServices: sync.items,
                        maxAllowed: sync.maxAllowed,
                        numberOfDefined: sync.items.length
                    });
                }
            )
            .catch(() => {
            });
    };

    loadAllServices = () => {
        SyncService
            .loadAllSyncs()
            .then(sync => {
                this.setState({
                    allServices: sync.items.map(service => this.calcServiceId(service))
                });
            })
            .catch(() => {
            });
    };

    handleConfiguredServicesChange = () => {
        this.loadConfiguredServices();
        this.props.onUserUpdate();
    };

    componentDidMount = () => {
        this.loadConfiguredServices();
        this.loadAllServices();
    };

    render = () => {
        const {formatMessage} = this.props.intl;

        return (
            <div className="help-content">
                <h3 className="account-title"><FormattedMessage id="account.menu.integrations"/></h3>

                <Tabs activeKey={this.state.activeKey} onSelect={(eventKey) => this.setState({activeKey: eventKey})}>
                    <Tab eventKey="configuredServices" title={formatMessage({id: "account.sync.configuredServices"})}>
                        <TabContainer>
                            {this.state.configuredServices &&
                            <ConfiguredServices onSelectAllServices={() => this.setState({activeKey: "allServices"})}
                                                services={this.state.configuredServices}
                                                maxAllowed={this.state.maxAllowed}
                                                onAddToast={this.props.onAddToast}
                                                onConfiguredServicesChange={this.handleConfiguredServicesChange}
                            />
                            }
                        </TabContainer>
                    </Tab>
                    <Tab eventKey="allServices" title={formatMessage({id: "account.sync.newService"})}>
                        <TabContainer>
                            {this.state.allServices &&
                            <AllServices onConfiguredServicesChange={this.handleConfiguredServicesChange}
                                         services={this.state.allServices}
                                         maxAllowed={this.state.maxAllowed}
                                         numberOfDefined={this.state.numberOfDefined}
                                         onAddToast={this.props.onAddToast}
                            />
                            }

                        </TabContainer>
                    </Tab>
                </Tabs>
            </div>
        )
    }
}

AccountSync.propTypes = {
    user: PropTypes.object,
    onUserUpdate: PropTypes.func.isRequired,
    onAddToast: PropTypes.func.isRequired,
};

export default injectIntl(AccountSync);
