import React, {Component} from 'react'
import {FormattedMessage, injectIntl} from "react-intl"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PaymentMethods from "../../shell/PaymentMethods";
import CheckoutWithPaypal from "../../shell/CheckoutWithPaypal";
import Container from "react-bootstrap/Container";
import {SubscriptionService} from "../../services/SubscriptionService";

class CheckoutSubscription extends Component {
    parseParams = () => {
        if (window.location.search === undefined || window.location.search.trim().length === 0) {
            return {};
        }
        const result = {};
        window.location.search
            .substring(1)
            .split("&")
            .map(item => {
                return item.split("=");
            })
            .forEach(value => {
                result[value[0].toLowerCase()] = value[1] || "true";
            });

        return result;
    };


    render = () => {
        const {formatMessage} = this.props.intl;
        const billingPlan = this.parseParams().plan;
        const subscription = SubscriptionService.getPlans((messageId) => formatMessage({"id": messageId}))[billingPlan] || null;

        return (
            <div className="help-content">
                <h3 className="account-title"><FormattedMessage id="account.menu.membership"/></h3>
                <Container fluid={true}>
                    <Row>
                        <Col md={4} sm={12}>
                            <PaymentMethods logoType="large"/>
                        </Col>
                        <Col md={8} sm={12}>
                            <CheckoutWithPaypal subscription={subscription} billingPlan={billingPlan}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default injectIntl(CheckoutSubscription);
