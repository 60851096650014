const Cookies = {
    put: function (key, value, options) {
        var cookie = encodeURIComponent(key) + "=" + encodeURIComponent(value);
        if (options) {
            var params = [cookie];
            for (var propertyName in options) {
                params.push(encodeURIComponent(propertyName) + "=" + options[propertyName]);
            }
            cookie = params.join("; ");
        }
        document.cookie = cookie;
    },

    remove: function (key) {
        this.put(key, "", {expires: new Date(Date.now() - 12096e5), path: "/"});
    },

    get: function (cname) {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return undefined;
    }
};

export default Cookies;
