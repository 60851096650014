import React, {Component} from 'react'
import PropTypes from 'prop-types'

class QuerySuggestions extends Component {
    render = () => {
        return (
            <div>
                <p className="text-secondary">{this.props.title}</p>
                {this.props.queries.map((query, index) => {
                    return (
                        <button type="button"
                                onClick={() => this.props.updateQuery(query.title)}
                                className="btn btn-outline-dark btn-sm"
                                key={index}
                                style={{marginRight: 3, marginBottom: 3}}
                        >
                            {query.title}
                        </button>
                    )
                })}
            </div>
        )
    }
}

QuerySuggestions.propTypes = {
    title: PropTypes.string.isRequired,
    queries: PropTypes.array.isRequired,
    updateQuery: PropTypes.func.isRequired
};

export default QuerySuggestions;
