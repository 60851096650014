import React, {Component} from 'react'
import {SubscriptionService} from "../../services/SubscriptionService";
import {FormattedDate, FormattedMessage, injectIntl} from "react-intl";
import {Button, ProgressBar} from "react-bootstrap";
import LinkService from "../../services/LinkService";
import CancelSubscriptionDialog from "./CancelSubscriptionDialog";
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";

class PlanBasic extends Component {
    state = {
        subscription: null,
        showCancelSubscription: false
    };

    componentDidMount = () => {
        this.loadSubscription();
    };

    loadSubscription = () => {
        SubscriptionService
            .getActiveSubscription()
            .then(subscription => {
                this.setState({subscription: subscription});
            })
            .catch(() => {
            });
    };

    calculatePassedDays = (created, finished) => {
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const firstDate = new Date(Date.parse(created));
        const secondDate = new Date(Date.parse(finished));
        const today = new Date();
        const totalDiffDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));
        const todayDiffDays = Math.round(Math.abs((firstDate.getTime() - today.getTime()) / (oneDay)));
        return Math.round(todayDiffDays * 100 / totalDiffDays);
    };

    getPeriodicDesc = () => {
        const {formatMessage} = this.props.intl;
        const billingPeriod = formatMessage({id: "account.period." + this.state.subscription.billingPeriod.toLowerCase()});
        return (
            <React.Fragment>
                <FormattedMessage id="account.subWillBeRenewed"/><br/>
                <FormattedMessage id="account.payForSub"/>
                ({this.state.subscription.price.currency}{this.state.subscription.price.amount} {billingPeriod})
                <FormattedMessage id="account.willBeTaken"/>
                <FormattedDate value={this.state.subscription.finished} year='numeric' month='short' day='numeric'/>.
            </React.Fragment>
        );
    };

    getActiveSubscription = () => {
        return (
            <React.Fragment>
                <p style={{marginTop: 8}}>
                    <FormattedMessage id="account.subEndOn"/>
                    <strong><FormattedDate value={this.state.subscription.finished} year='numeric' month='short' day='numeric'/></strong>
                    <span>. </span>
                    {this.state.subscription.type === "BASIC_ONETIME" && <FormattedMessage id="account.buyNewToProceed"/>}
                    {this.state.subscription.type !== "BASIC_ONETIME" && this.getPeriodicDesc()}
                </p>

                {this.state.subscription.type !== "BASIC_ONETIME" &&
                <Button variant="secondary" onClick={() => this.setState({showCancelSubscription: true})} style={{margin: 8}}>
                    <FormattedMessage id="account.cancelSubscription"/>
                </Button>
                }

                {this.renderViewInvoiceButton()}
            </React.Fragment>
        );
    };

    renderViewInvoiceButton = () => {
        return (
            <React.Fragment>
                {(this.state.subscription.status === "ACTIVE" || this.state.subscription.status === "CANCELLED") &&
                <LinkContainer to={LinkService.showInvoice(this.state.subscription.id)}>
                    <Button style={{margin: 8}} variant="primary"><FormattedMessage id="account.subscription.viewInvoice"/></Button>
                </LinkContainer>
                }
            </React.Fragment>
        );
    };

    getCancelledSubscription = () => {
        return (
            <React.Fragment>
                <p style={{marginTop: 8}}>
                    <FormattedMessage id="account.subscription.wasCanceled"/>
                    <FormattedDate value={this.state.subscription.canceled} year='numeric' month='short' day='numeric'/>.<br/>
                    <FormattedMessage id="account.stillHaveBenefits"/><br/><br/>
                    <FormattedMessage id="account.subEndOn"/>
                    <FormattedDate value={this.state.subscription.finished} year='numeric' month='short' day='numeric'/>.<br/>
                    <FormattedMessage id="account.subscription.noAutorenewOccurs"/>
                </p>

                {this.renderViewInvoiceButton()}
            </React.Fragment>
        );
    };

    render = () => {
        const isActiveSubscription = this.state.subscription && this.state.subscription.status !== "CANCELLED";

        return (
            <React.Fragment>
                {this.state.subscription !== null && this.state.subscription.type &&
                <React.Fragment>
                    <div className="d-flex justify-content-between">
                        <FormattedDate value={this.state.subscription.created} year='numeric' month='short' day='numeric'/>
                        <FormattedDate value={this.state.subscription.finished} year='numeric' month='short' day='numeric'/>
                    </div>
                    <ProgressBar now={this.calculatePassedDays(this.state.subscription.created, this.state.subscription.finished)}/>

                    {isActiveSubscription && this.getActiveSubscription()}
                    {!isActiveSubscription && this.getCancelledSubscription()}
                    <CancelSubscriptionDialog open={this.state.showCancelSubscription}
                                              onCancelSubscription={this.loadSubscription}
                                              onClose={() => this.setState({showCancelSubscription: false})}
                    />
                </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

export default injectIntl(PlanBasic);
