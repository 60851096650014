import React, {Component} from 'react'
import {Button} from "react-bootstrap";

class GoTop extends Component {
    state = {
        show: false
    };

    scrollTop = () => {
        window.scrollTo(0, 0);
    };

    componentDidMount = () => {
        window.addEventListener('scroll', this.handleScroll);
    };

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleScroll);
    };

    handleScroll = () => {
        const show = window.scrollY > 500;
        if (show !== this.state.show) {
            this.setState({show});
        }
    };

    render = () => {
        return (
            <Button variant="light"
                    className={["round-button", "no-focus", this.state.show ? "d-block" : "d-none"].join(" ")}
                    id="toTop" onClick={this.scrollTop}
            >
                <i className="fas fa-arrow-up fa-fw"/>
            </Button>
        )
    }
}

// GoTop.propTypes = {
//     objectProperty: PropTypes.object.isRequired
// };

export default GoTop;
