import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import {Button, Card, Collapse, Form, Row} from "react-bootstrap";
import SearchService from "../../services/SearchService";
import LinkService from "../../services/LinkService";
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";
import QuerySuggestions from "./QuerySuggestions";
import BookmarkSuggestions from "./BookmarkSuggestions";
import SearchHelp from "./SearchHelp";

class AdvancedSearchBox extends Component {
    textInput = React.createRef();

    state = {
        value: "",
        lastValue: "",
        open: false,
        queries: [],
        tags: [],
        websites: [],
        sources: [],
        bookmarks: [],
        editSelected: false,
        updatedSuggestions: Date.now(),

        lastSearch: null,
        lastPathname: null
    }

    componentDidMount = () => {
        this.timerID = setInterval(() => this.checkQueryChange(), 300);
    };

    UNSAFE_componentWillMount = () => {
        this.setState({
            value: "",
            open: false,
            queries: [],
            tags: [],
            websites: [],
            sources: [],
            bookmarks: []
        });
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.location.search !== this.state.lastSearch || nextProps.location.pathname !== this.state.lastPathname) {
            if (this.state.lastSearch !== null) {
                this.setState({
                    open: false,
                    value: "",
                    queries: [],
                    tags: [],
                    websites: [],
                    sources: [],
                    bookmarks: [],
                });
                this.textInput.current.blur();
            }
            this.setState({
                lastSearch: nextProps.location.search,
                lastPathname: nextProps.location.pathname
            });
        }
    };

    componentWillUnmount = () => {
        clearInterval(this.timerID);
    };

    checkQueryChange = () => {
        if (this.state.lastValue !== this.state.value &&
            this.state.value.trim().length > 3
            //&& !this.state.value.endsWith(" ")
        ) {
            this.setState({lastValue: this.state.value});
            this.updateSuggestions(this.state.value);
        }
    };

    handleQueryChange = (e) => {
        this.updateQuery(e.target.value)
    };

    updateQuery = (query) => {
        const value = query.trimLeft().replace(/ +(?= )/g, '')
        if (value.length === 0) {
            this.setState({
                open: false,
                queries: [],
                tags: [],
                websites: [],
                sources: [],
                bookmarks: []
            });

        } else {
            this.setState({open: true})
        }

        if (value.endsWith(" ")) { //} || (this.state.firstTime && value.length >= 3)) {
            this.updateSuggestions(value);
        }

        this.setState({value: value});
    };

    updateSuggestions = (value) => {
        if ((Date.now() - this.state.updatedSuggestions > 300) && value.length >= 3) {
            SearchService
                .getSuggestions(value)
                .then(response => {
                    this.setState({
                        queries: response.queries,
                        tags: response.tags,
                        websites: response.websites,
                        sources: response.sources,
                        bookmarks: response.bookmarks,
                        updatedSuggestions: Date.now()
                    });
                    if (value.endsWith(" ") && this.state.value !== response.q) {
                        this.setState({value: response.q})
                    }
                });
        }

    };

    startSearch = (e) => {
        e.preventDefault();
        if (this.state.value.trim().length > 0) {
            // let query = this.state.value;
            // console.log(query);

            let tags = [];
            let website = [];
            let source = [];
            let q = [];
            this.state.value.split(" ")
                .filter(token => token.trim().length > 0)
                .forEach(token => {
                    if (token.startsWith("#")) {
                        tags.push(token.substring(1));
                    } else if (token.startsWith("@")) {
                        website.push(token.substring(1));
                    } else if (token.startsWith("*")) {
                        source.push(token.substring(1));
                    } else {
                        q.push(token);
                    }
                });
            let sources = {
                "added-from-browser": "browser",
                "imported": "import",
                "sent-via-email": "email",
                "added-from-recommendations": "favorite",
                "synced-from-other-services": "integration",
                "synced-from-stackexchange": "integration-stack-exchange",
                "synced-from-habr": "integration-habrahabr",
                "synced-from-geektimes": "integration-geektimes",
                "synced-from-youtube-list": "integration-youtube-public-list",
                "synced-from-rss-feed": "integration-rss",
                "synced-from-slideshare-favorites": "integration-slideshare-favorite"
            }
            let query = {
                tags: tags.length > 0 ? tags.join(",") : undefined,
                website: website.length > 0 ? website.pop() : undefined,
                q: q.length > 0 ? q.join(" ") : undefined,
                source: source.length > 0 ? sources[source.pop()] : undefined,
            };
            this.setState({
                open: false,
                value: ""
            });

            this.textInput.current.blur();
            this.props.startSearch(query);
        }
    };

    selectEditBox = () => {
        this.textInput.current.focus();
    }

    isTagSuggestion = (token) => token.startsWith("#") || token.startsWith("@") || token.startsWith("*");

    addNewQuerySuggestion = (query) => {
        let queryIsTagSuggestion = this.isTagSuggestion(query);

        let existValue = this.state.value
            .split(" ")
            .filter(token => token.trim().length > 0)
            .filter(token => queryIsTagSuggestion || this.isTagSuggestion(token))
        if (!this.state.value.endsWith(" ") && queryIsTagSuggestion) {
            existValue.pop();
        }
        existValue.push(query);

        this.updateQuery(existValue.join(" ") + " ")
        this.selectEditBox()
    }

    handleSearchInputFocus = () => {
        const {formatMessage} = this.props.intl;

        if (this.state.value.length === 0) {
            let queryArray = [];
            const parameters = SearchService.extractSearch();

            if (parameters.tags) {
                queryArray.push(
                    parameters.tags
                        .split(",")
                        .map(tag => {
                            return "#" + tag;
                        })
                        .join(" ")
                );
            }

            if (parameters.website) {
                queryArray.push("@" + parameters.website);
            }

            if (parameters.source) {
                let messageCode = "sources." + parameters.source.replace('-', '_').toUpperCase();
                let message = formatMessage({id: messageCode}).replace(/\s+/g, '-').toLowerCase();
                queryArray.push("*" + message);
            }

            if (parameters.q) {
                queryArray.push(parameters.q);
            }

            this.setState({
                value: queryArray.join(" ")
            });
        }
        this.setState({
            editSelected: true,
            open: true
        });
    }

    render = () => {
        const {formatMessage} = this.props.intl;
        const widgets = [];

        if (this.state.queries && this.state.queries.length > 0) {
            widgets.push((
                <QuerySuggestions
                    title={formatMessage({id: "main.searchQuerySuggestions"})}
                    queries={this.state.queries}
                    updateQuery={this.addNewQuerySuggestion}
                />
            ));
        }

        if (this.state.tags && this.state.tags.length > 0) {
            widgets.push((
                <QuerySuggestions
                    title={formatMessage({id: "main.tagsSuggestions"})}
                    queries={this.state.tags}
                    updateQuery={this.addNewQuerySuggestion}
                />
            ));
        }

        if (this.state.websites && this.state.websites.length > 0) {
            widgets.push((
                <QuerySuggestions
                    title={formatMessage({id: "main.websitesSuggestions"})}
                    queries={this.state.websites}
                    updateQuery={this.addNewQuerySuggestion}
                />
            ));
        }

        if (this.state.sources && this.state.sources.length > 0) {
            widgets.push((
                <QuerySuggestions
                    title={formatMessage({id: "main.sourcesSuggestions"})}
                    queries={this.state.sources}
                    updateQuery={this.addNewQuerySuggestion}
                />
            ));
        }

        if (this.state.bookmarks && this.state.bookmarks.length > 0) {
            widgets.push((<BookmarkSuggestions bookmarks={this.state.bookmarks}/>));
        }

        widgets.push((<SearchHelp/>));

        return (
            <Form onSubmit={this.startSearch}>
                <Form.Group controlId="searchControl"
                    // onFocus={() => this.setState({open: true})}
                    // onBlur={() => this.setState({open: false})}
                >

                    <Form.Control size="lg"
                                  type="search"
                                  autoComplete="off"
                                  ref={this.textInput}
                        // onClick={() => this.setState({open: !this.state.open})}
                                  value={this.state.value}
                                  onChange={this.handleQueryChange}
                                  placeholder={formatMessage({id: "common.startNewSearchHere"})}
                                  onFocus={this.handleSearchInputFocus}
                                  onBlur={() => this.setState({editSelected: false})}
                    />
                    <Collapse in={this.state.open}>
                        <Card style={{marginTop: 4}}>
                            {widgets.length > 0 &&
                            <Card.Body>
                                {widgets.map((widget, index) => {
                                    if (index + 1 < widgets.length) {
                                        return (
                                            <React.Fragment key={index}>
                                                {widget}
                                                <hr/>
                                            </React.Fragment>
                                        )
                                    } else {
                                        return (<React.Fragment key={index}>{widget}</React.Fragment>);
                                    }
                                })}
                            </Card.Body>
                            }

                            <Card.Footer className="text-muted text-small">
                                <Row noGutters className="justify-content-between align-items-center">
                                    <LinkContainer to={LinkService.helpHowToSearchingBookmarks()}>
                                        <Card.Link><FormattedMessage id="advancedSearchBox.searchHelp"/></Card.Link>
                                    </LinkContainer>

                                    {this.state.editSelected && <span><FormattedMessage id="advancedSearchBox.title"/></span>}

                                    <Button variant="light"
                                            size="sm"
                                            onClick={() => this.setState({open: false})}
                                    >
                                        <FormattedMessage id="common.close"/>
                                    </Button>
                                </Row>
                            </Card.Footer>
                        </Card>
                    </Collapse>

                </Form.Group>
            </Form>
        )
    }
}

AdvancedSearchBox.propTypes = {
    startSearch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
};

export default injectIntl(AdvancedSearchBox);
