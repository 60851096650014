import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button} from "react-bootstrap";
import classNames from 'classnames'

class IconButton extends Component {
    render = () => {
        return (
            <Button variant="light" block
                    className="text-center bg-transparent display-grid"
                    onClick={this.props.item.link !== undefined ? (e) => this.props.onClick(this.props.item, e) : null}
                    disabled={this.props.item.link === undefined}>
                {this.props.item.done && <i className="fas fa-check fa-fw fa-lg text-success" style={{top:15, right:15, position:"absolute"}}/>}
                <i className={classNames("fa-3x", "fa-fw", "text-black-50", this.props.item.icon)} style={{margin: "auto auto 10px auto"}}/>
                <span>{this.props.item.title}</span>
            </Button>
            );
    }
}

IconButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
};

export default IconButton;
