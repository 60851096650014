import React, {Component} from 'react'
import PropTypes from 'prop-types'

class GoogleLogin extends Component {
    state = {
        clientId: "461875198256-v4dj5iqvlfa27pgiv0qtbghlb3sa6li3.apps.googleusercontent.com"
    };

    loadScript = (onLoad) => {
        const element = document.getElementsByTagName("script")[0];
        const fjs = element;
        let js = element;
        js = document.createElement("script");
        // js.id = id;
        js.src = "https://apis.google.com/js/platform.js";
        if (fjs && fjs.parentNode) {
            fjs.parentNode.insertBefore(js, fjs)
        } else {
            document.head.appendChild(js)
        }
        js.onload = onLoad;
    };

    onSuccess = (authResult) => {
        const token = authResult.getAuthResponse().id_token;
        window.gapi.auth2.getAuthInstance().signOut();
        this.props.onLogin(token);
    };

    onLoad = () => {
        const defaults = {
            onsuccess: this.onSuccess,
            cookiepolicy: 'single_host_origin',
            scope: 'profile email',
            longtitle: true,
            theme: 'dark',
            autorender: true,
            customtargetid: 'googlebutton',
            clientid: this.state.clientId
        };

        window.gapi.load('auth2', function () {
            window.gapi.auth2.init({
                client_id: defaults.clientid,
                cookie_policy: defaults.cookiepolicy
            });
            window.gapi.signin2.render(document.getElementById('googlebutton'), defaults);
        });
    };

    componentDidMount = () => {
        this.loadScript(this.onLoad);
    };

    render = () => { return <div id="googlebutton"></div> }
}

GoogleLogin.propTypes = {
    onLogin: PropTypes.func.isRequired
};

export default GoogleLogin;
