import React, {Component} from 'react';
import './App.css';
import Layout from "./shell/Layout";
import UserService from "./services/UserService";
import {Route, Switch, withRouter} from "react-router-dom";
import LinkService from "./services/LinkService";
import AuthService from "./services/AuthService";
import ScrollToTop from "./shell/ScrollToTop";
import SearchService from "./services/SearchService";


class App extends Component {
    state = {
        user: null
    };

    isPathBelongToList = (path, list) => {
        for (let i = 0; i < list.length; i++) {
            if (list[i].startsWith(path) || path.startsWith(list[i])) {
                return true;
            }
        }
        return false;
    };

    isCurrentRouteCorrect = (pathname, user) => {
        const authAllowed = [
            LinkService.inbox(),
            LinkService.history(),
            LinkService.favorites(),
            LinkService.search(),
            LinkService.account(),
            LinkService.help(),
            LinkService.reader(),
            LinkService.addBookmark()
        ];
        const anonAllowed = [
            LinkService.welcome(),
            LinkService.help(),
            LinkService.reader(),
        ];
        const path = pathname.toLowerCase();

        if (pathname === "/") {
            return false;
        }

        return user
            ? this.isPathBelongToList(path, authAllowed)
            : this.isPathBelongToList(path, anonAllowed)
    };

    reRouteIfNeeded = (user) => {
        if (!this.isCurrentRouteCorrect(window.location.pathname, user)) {
            this.redirectToDefault(user);
        }
    };

    redirectToDefault = (user) => {
        const rout = LinkService.home(user);
        this.props.history.push(rout);
    };

    handleLogout = () => {
        AuthService.logout();
        this.setState({user: null});
        this.reRouteIfNeeded(null);
    };

    handleLogin = (authToken, expires) => {
        AuthService.login(authToken, expires);
        this.loadCurrentUser();
    };

    loadCurrentUser = () => {
        UserService
            .getCurrent()
            .then(user => {
                if (user.plan === "DRAFT") {
                    this.handleLogout();

                } else {
                    this.setState({user: user});
                    this.reRouteIfNeeded(user);
                }
            })
            .catch(() => {
                this.handleLogout();
            });
    };

    updateCurrentUser = () => {
        UserService.getCurrent().then(user => {
            this.setState({user: user})
        }).catch(() => {
        });
    };

    componentDidMount() {
        const currentParams = JSON.parse(JSON.stringify(SearchService.extractSearch()));
        const keepDesktop = currentParams.m === 'false';

        if (window.screen.width <= 768 && !keepDesktop) {
            LinkService.goToMobile(false);
        } else {
            this.loadCurrentUser();
        }
    }

    renderLayout = () => {
        return (
            <Layout location={window.location}
                    user={this.state.user}
                    onLogin={this.handleLogin}
                    onLogout={this.handleLogout}
                    onUserUpdate={this.updateCurrentUser}
                    onRoot={() => this.redirectToDefault(this.state.user)}
            />
        );
    };

    render = () => {
        return (
            <ScrollToTop>
                <div className="App">
                    <Switch><Route path="/" render={this.renderLayout}/></Switch>
                </div>
            </ScrollToTop>
        );
    }
}

export default withRouter(App);
