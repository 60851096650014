import React, {Component} from 'react'
import {FormattedMessage} from "react-intl"
import {Button} from "react-bootstrap";
import LinkService from "../../services/LinkService";
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";

class PlanFree extends Component {
    render = () => {
        return (
            <React.Fragment>
                <p><FormattedMessage id="account.plan.free.description"/></p>
                <LinkContainer to={LinkService.accountSubscriptionUpgrade()}>
                    <Button variant="primary"><FormattedMessage id="account.upgradeSubscription"/></Button>
                </LinkContainer>
            </React.Fragment>
        )
    }
}

// PlanFree.propTypes = {
//     objectProperty: PropTypes.object.isRequired
// };

export default PlanFree;
