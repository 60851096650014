import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Navbar from "react-bootstrap/Navbar";
import SidebarToggler from "./SidebarToggler";
import FooterActions from "../FooterActions";
import Copyright from "../Copyright";
import FooterLinks from "../FooterLinks";

class Sidebar extends Component {
    render = () => {
        let toggled = this.props.toggled ? " toggled" : "";

        return (
            <Navbar bg="transparent" className={["sidebar", "navbar-nav", toggled].join(" ")}>
                {this.props.children}
                <hr className="divider"/>

                {!this.props.toggled &&
                <React.Fragment>
                    <FooterActions large={false} user={this.props.user} onAddToast={this.props.onAddToast}/>
                    <FooterLinks light={false}/>
                    <Copyright textAlign="center"/>
                </React.Fragment>
                }

                <SidebarToggler toggled={this.props.toggled} onToggle={this.props.onToggle}/>
            </Navbar>
        )
    }
}

Sidebar.propTypes = {
    user: PropTypes.object,
    toggled: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    onAddToast: PropTypes.func.isRequired
};

export default Sidebar;
