import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl"
import UrlService from "../services/UrlService";

class Readability extends Component {
    render = () => {
        const {article} = this.props;
        const typographyClass = `reader-typography-${this.props.typography}`;
        const textSizeTitleClass = `reader-text-size-title-${this.props.textSize}`;
        const textSizeBodyClass = `reader-text-size-body-${this.props.textSize}`;

        return (
            <React.Fragment>
                <div className="card-text">
                    <h6 className={[typographyClass, textSizeTitleClass].join(" ")}
                        dangerouslySetInnerHTML={{__html: article.title}}/>

                    <p className={typographyClass}>
                        <FormattedMessage id="reader.readabilityOriginal"/><span>: </span>
                        <a href={article.link}>{UrlService.decodeUrl(UrlService.punyCode(article.link))}</a>
                    </p>
                    {article.readTime !== undefined && article.readTime > 0 &&
                    <p className={typographyClass} id="ss">
                        <FormattedMessage id="reader.readability.readingTime"/><span>: </span>
                        {article.readTime}<span> </span>
                        <FormattedMessage id="reader.readability.minutes"/>
                    </p>
                    }

                    <hr/>
                    {article.readability &&
                    <div className={[typographyClass, textSizeBodyClass, "readability"].join(" ")}
                         dangerouslySetInnerHTML={{__html: article.body}}/>
                    }

                    {article.readability === false &&
                    <React.Fragment>
                        {/*<p className="text-center reader-image-typography">*/}
                        {/*    <img src={article.image} alt={article.title} className="reader-image"/>*/}
                        {/*</p>*/}
                        <p className={[typographyClass, textSizeBodyClass].join(" ")}
                           dangerouslySetInnerHTML={{__html: article.description}}/>
                    </React.Fragment>
                    }
                </div>
            </React.Fragment>
        )
    }
}

Readability.propTypes = {
    article: PropTypes.object.isRequired,
    classes: PropTypes.object,
    textSize: PropTypes.number.isRequired,
    typography: PropTypes.number.isRequired
};

export default Readability;
