import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button, ButtonGroup, Navbar} from "react-bootstrap";

class Selector extends Component {
    handleChange = (value) => {
        if (value !== this.props.value) {
            this.props.onChange(value);
        }
    };

    render = () => {
        return (
            <div style={{marginRight: 16}}>
                <Navbar.Text style={{marginRight: 8}}>{this.props.title}</Navbar.Text>
                <ButtonGroup aria-label="Basic example">
                    {this.props.options.map((option, index) => {
                        return (
                            <Button key={index} variant={index === this.props.value ? "secondary" : "outline-secondary"}
                                    onClick={() => this.handleChange(index)}
                                    disabled={index === this.props.value}
                            >
                                {option}
                            </Button>
                        );
                    })}
                </ButtonGroup>
            </div>
        )
    }
}

Selector.propTypes = {
    title: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
};

export default Selector;
