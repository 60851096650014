import React, {Component} from 'react'
import {FormattedMessage} from "react-intl"
import {Row} from "react-bootstrap";
import SearchService from "../../services/SearchService";
import {withRouter} from "react-router-dom";

class InboxFlagged extends Component {
    state = {
        showFlaggedOnly: false
    };

    UNSAFE_componentWillMount = () => {
        const search = SearchService.extractSearch();
        this.setState({showFlaggedOnly: ("flagged" in search) && search.flagged !== 'false'});
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        this.UNSAFE_componentWillMount();
    };

    handleChange = (event) => {
        this.props.history.push(SearchService.addShowFlaggedOnly(event.target.checked));
    };

    render = () => {
        return (
            <Row noGutters className="justify-content-center mb-3">
                <div className="custom-control custom-switch clickable">
                    <input type="checkbox"
                           checked={this.state.showFlaggedOnly}
                           className="custom-control-input clickable"
                           id="showOnlyFlagged"
                           onChange={this.handleChange}
                    />
                    <label className="custom-control-label clickable" htmlFor="showOnlyFlagged"><FormattedMessage id="search.showOnlyFlagged"/></label>
                </div>
            </Row>
        )
    }
}

export default withRouter(InboxFlagged);
