import React, {Component} from 'react'
import {FormattedMessage} from "react-intl";

class HelpFaqSeeFailed extends Component {
    render = () => {
        return (
            <div className="help-content">
                <h3>
                    <FormattedMessage id="help.faq.seeFailed"/>
                    <small className="text-muted"> / <FormattedMessage id="help.faq.full"/></small>
                </h3>

                <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item"
                            src="https://www.youtube.com/embed/WqNDDlcP4Xw?rel=0"
                            title="See all failed bookmarks"
                            allowFullScreen/>
                </div>
            </div>
        )
    }
}

export default HelpFaqSeeFailed;
