import React, {Component} from 'react'
import {FormattedMessage} from "react-intl"

class HelpRoot extends Component {
    render = () => {
        return (
            <p className="lead text-center" style={{marginTop: 48}}>
                <FormattedMessage id="help.pleaseSelectTopic"/>
            </p>
        )
    }
}

export default HelpRoot;
