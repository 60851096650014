import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import PaymentMethods from "../shell/PaymentMethods";
import CheckoutWithPaypal from "../shell/CheckoutWithPaypal";
import {SubscriptionService} from "../services/SubscriptionService";

class PayForSubscriptionStep extends Component {
    render = () => {
        const {formatMessage} = this.props.intl;
        const billingPlan = "BASIC";
        const subscription = SubscriptionService.getPlans((messageId) => formatMessage({"id": messageId}))[billingPlan];


        return (
            <React.Fragment>
                <h4 className="text-center"><FormattedMessage id="welcome.payForSubscription"/></h4>
                <p className="text-center"><FormattedMessage id="welcome.accountAlmostReady"/></p>
                <Container fluid={true}>
                    <Row>
                        <Col md={4} sm={12}>
                            <PaymentMethods logoType="small"/>
                        </Col>
                        <Col md={8} sm={12}>
                            <CheckoutWithPaypal subscription={subscription} billingPlan={billingPlan} user={this.props.user}/>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}

PayForSubscriptionStep.propTypes = {
    user: PropTypes.object.isRequired
};

export default injectIntl(PayForSubscriptionStep);
