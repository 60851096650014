import React, {Component} from 'react'
import {FormattedMessage} from "react-intl";

class HelpFaqRemoveMany extends Component {
    render = () => {
        return (
            <div className="help-content">
                <h3>
                    <FormattedMessage id="help.faq.removeMany"/>
                    <small className="text-muted"> / <FormattedMessage id="help.faq.full"/></small>
                </h3>

                <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item"
                            src="https://www.youtube.com/embed/llV3QGQHfyo?rel=0"
                            title="Remove many bookmarks"
                            allowFullScreen/>
                </div>
            </div>
        )
    }
}

export default HelpFaqRemoveMany;
