import React, {Component} from 'react'
import PropTypes from 'prop-types'
import SearchService from "../../services/SearchService";
import {withRouter} from "react-router-dom";
import {injectIntl} from "react-intl";

class TagsPanel extends Component {
    getSearchParams = () => {
        const search = SearchService.extractSearch();
        const params = {};
        if (search.q !== undefined) {
            params.q = search.q;
        }
        if (search.spell !== undefined) {
            params.spell = search.spell;
        }
        if (search.tags !== undefined) {
            params.tags = search.tags;
        }
        if (search.website !== undefined) {
            params.website = search.website;
        }
        if (search.source !== undefined) {
            params.source = search.source;
        }
        return params;
    };

    deleteChip = (link) => {
        if (link) {
            if (link === "/") {
                this.props.onRoot();

            } else {
                this.props.history.push(link);
            }
        }
    };

    generateTagButton = (icon, value, title, deleteFn) => {
        return (
            <span className="badge badge-light clickable" key={value}>
                <span>{icon}{title}</span>
                <span className="close-tag" onClick={() => this.deleteChip(deleteFn(value))}>×</span>
            </span>
        )
    };

    render = () => {
        const {formatMessage} = this.props.intl;
        const params = this.getSearchParams();
        const tags = [];

        (params.tags || "")
            .split(",")
            .filter(value => value.length > 0)
            .forEach(tag => tags.push(
                this.generateTagButton("#", tag, tag, (t) => SearchService.removeTag(t))));

        (params.website || "")
            .split(",")
            .filter(value => value.length > 0)
            .forEach(tag => tags.push(
                this.generateTagButton("@", tag, tag, (t) => SearchService.removeWebsite(t))));

        (params.source || "")
            .split(",")
            .filter(value => value.length > 0)
            .forEach(tag => tags.push(
                this.generateTagButton("*", tag, formatMessage({id: "sources." + SearchService.convertToValidSource(tag)}), (t) => SearchService.removeSource(t))));

        return (
            <React.Fragment>
                {tags.length > 0 && <div className="tags-panel" style={{marginBottom: "1rem"}}>{tags}</div>}
            </React.Fragment>
        )
    }
}

TagsPanel.propTypes = {
    onRoot: PropTypes.func.isRequired
};

export default withRouter(injectIntl(TagsPanel));
