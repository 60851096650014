import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl"
import Button from "react-bootstrap/Button";
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import classNames from 'classnames'
import {Badge} from "react-bootstrap";

class NavLinkItem extends Component {
    render = () => {
        return (
            <React.Fragment>
                {!this.props.sidebarToggled &&
                <LinkContainer to={this.props.link}>
                    <Button block
                            className={classNames("d-flex align-items-center justify-content-between", "btn", "btn-flat-light", "text-left", "gd-nav-item", "no-focus", {"btn-lg": (this.props.size === "lg")})}>
                        <span>
                        <i className={classNames("fa-fw", this.props.icon, {
                            "fa-lg": (this.props.size === "lg"),
                            [this.props.iconClass]: this.props.iconClass,
                            "text-black-50": !this.props.iconClass
                        })}
                           style={{marginRight: 16}}
                        />
                        <FormattedMessage id={this.props.titleId}/>
                        </span>
                        {this.props.badgeNumber && <Badge variant="secondary">
                            <small>{this.props.badgeNumber}</small>
                        </Badge>}
                    </Button>
                </LinkContainer>
                }

                {this.props.sidebarToggled &&
                <OverlayTrigger placement="right" overlay={<Tooltip><FormattedMessage id={this.props.titleId}/></Tooltip>}>
                    <LinkContainer to={this.props.link}>
                        <Button block className={classNames("btn", "btn-flat-light", "gd-nav-item", "no-focus", {
                            "btn-lg": (this.props.size === "lg")
                        })}>
                            <i className={classNames("fa-fw", this.props.icon, {
                                "fa-lg": (this.props.size === "lg"),
                                [this.props.iconClass]: this.props.iconClass,
                                "text-black-50": !this.props.iconClass
                            })}/>
                        </Button>
                    </LinkContainer>
                </OverlayTrigger>
                }
            </React.Fragment>
        )
    }
}

NavLinkItem.propTypes = {
    link: PropTypes.string.isRequired,
    titleId: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    iconClass: PropTypes.string,
    badgeNumber: PropTypes.number,
    sidebarToggled: PropTypes.bool.isRequired,
    size: PropTypes.oneOf(['sm', 'lg'])
};

export default NavLinkItem;
