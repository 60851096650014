import React, {Component} from 'react'
import PropTypes from 'prop-types'

class AvatarCard extends Component {
    render = () => {
        return (
            <div style={{padding: "5px 15px", outline: "none"}}>
                <img src={this.props.user.avatarUrl} className="rounded mx-auto d-block" alt="user avatar" width={80} height={80}/>
                <h5 className="text-center" style={{marginTop: 15, marginBottom: 0}}>{this.props.user.title}</h5>
                <p className="text-center" style={{margin: 0}}>{this.props.user.userName}</p>
            </div>
        )
    }
}

AvatarCard.propTypes = {
    user: PropTypes.object.isRequired
};

export default AvatarCard;
