import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl"
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";
import LinkService from "../services/LinkService";
import ContactUsForm from "./ContactUsForm";

class FooterActions extends Component {
    state = {
        showContactUsForm: false
    };

    hideContactUsForm = () => {
        this.setState({showContactUsForm: false});
    };

    showContactUsForm = () => {
        this.setState({showContactUsForm: true});
    };

    openWindow = (url) => {
        window.open(url, '_blank');
    };

    openTwitter = () => {
        this.openWindow("https://twitter.com/GrabDuck");
    };

    openSearchingBits = () => {
        this.openWindow("http://searching-bits.com/category/grabduck/");
    };

    render = () => {
        const largeIcon = this.props.large ? "fa-2x" : "";
        const iconSize = this.props.large ? 30 : 15;
        const marginSize = this.props.large ? 4 : 2;
        return (
            <React.Fragment>
                <ul className="d-flex justify-content-around footer-links">
                    <li>
                        <OverlayTrigger placement="top" overlay={<Tooltip><FormattedMessage id="common.contactUs"/></Tooltip>}>
                            <Button variant="secondary" onClick={this.showContactUsForm} className="no-focus">
                                <i className={["fas", "fa-fw", "fa-comment-dots", largeIcon].join(" ")}/>
                            </Button>
                        </OverlayTrigger>
                    </li>
                    <li className="text-light footer-links-divider">·</li>
                    <li>
                        <OverlayTrigger placement="top" overlay={<Tooltip><FormattedMessage id="common.followOnTwitter"/></Tooltip>}>
                            <Button variant="secondary" onClick={this.openTwitter} className="no-focus">
                                <i className={["fab", "fa-fw", "fa-twitter", largeIcon].join(" ")}/>
                            </Button>
                        </OverlayTrigger>
                    </li>
                    <li className="text-light footer-links-divider">·</li>
                    <li>
                        <OverlayTrigger placement="top" overlay={<Tooltip><FormattedMessage id="common.readOnSearchingBits"/></Tooltip>}>
                            <Button variant="secondary" onClick={this.openSearchingBits} className="no-focus">
                                <img
                                    src="/static/images/searching-bits.png"
                                    width={iconSize}
                                    alt="searching bits"
                                    style={{marginLeft: marginSize, marginRight: marginSize, marginBottom: 1}}
                                />
                            </Button>
                        </OverlayTrigger>
                    </li>
                    <li className="text-light footer-links-divider">·</li>
                    <li>
                        <OverlayTrigger placement="top" overlay={<Tooltip><FormattedMessage id="common.getHelp"/></Tooltip>}>
                            <LinkContainer to={LinkService.help()}>
                                <Button variant="secondary" className="no-focus">
                                    <i className={["fas", "fa-fw", "fa-question", largeIcon].join(" ")}/>
                                </Button>
                            </LinkContainer>
                        </OverlayTrigger>
                    </li>
                </ul>
                <ContactUsForm show={this.state.showContactUsForm}
                               onHide={this.hideContactUsForm}
                               onAddToast={this.props.onAddToast}
                               user={this.props.user}
                />
            </React.Fragment>
        )
    }
}

FooterActions.propTypes = {
    user: PropTypes.object,
    large: PropTypes.bool.isRequired,
    onAddToast: PropTypes.func.isRequired
};

export default FooterActions;
