import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import UserService from "../services/UserService";
import ProgressModal from "../shell/ProgressModal";
import InputGroup from "react-bootstrap/InputGroup";

class WireWithGoogleDialog extends Component {
    state = {
        showProgress: false,
        showError: false,
        validationErrorPasswordText: null,
        password: "",
        showPasswordText: false
    };

    handleHide = () => {
        this.setState({
            showError: false
        });
        this.props.onClose();
    };

    handleCloseAlert = () => {
        this.setState({showError: false});
    };

    getPasswordValidationClass = () => {
        if (this.state.passwordValidationMessage === null) {
            return "is-valid";
        } else if (this.state.passwordValidationMessage !== undefined) {
            return "is-invalid";
        } else {
            return "";
        }
    };

    handlePasswordChange = (event) => {
        let messageValidationMessage = this.validatePassword(event.target.value);
        this.setState({
            password: event.target.value,
            passwordValidationMessage: messageValidationMessage
        });
    };

    validatePassword = (password) => {
        const {formatMessage} = this.props.intl;

        if (!password || password.trim().length === 0) {
            return formatMessage({id: "common.validation.fieldRequired"});

        } else {
            return null;
        }
    };

    isFormInvalid = (password) => {
        return !this.isFormValid(password);
    };

    isFormValid = (password) => {
        return this.validatePassword(password) === null;
    };

    handleLogin = () => {
        this.setState({
            showProgress: true,
            showError: false
        });
        UserService
            .wireWithGoogle(this.props.email, this.state.password)
            .then(data => {
                this.setState({
                    showProgress: false,
                    showError: false
                }, () => {
                    this.props.onClose();
                    this.props.onWired();
                });
            })
            .catch(() => {
                this.setState({
                    showProgress: false,
                    showError: true
                });
            });

    };

    render = () => {
        const {formatMessage} = this.props.intl;
        const passwordValidationClass = this.getPasswordValidationClass();
        const {password} = this.state;

        return (
            <Modal show={this.props.show} onHide={this.handleHide} centered>
                <Modal.Header closeButton>
                    <Modal.Title><FormattedMessage id="welcome.login"/></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p><FormattedMessage id="welcome.foundGoogleAccount" values={{email: this.props.email}}/></p>

                    <Alert variant="danger" show={this.state.showError} onClose={this.handleCloseAlert} dismissible>
                        <FormattedMessage id="welcome.passwordIncorrect"/>
                    </Alert>

                    <Form>
                        <Form.Group controlId="formWireWIthGooglePassword">
                            <Form.Label><FormattedMessage id="common.password"/></Form.Label>
                            <InputGroup>
                                <Form.Control type={this.state.showPasswordText ? "text" : "password"}
                                              placeholder={formatMessage({id: "welcome.typePassword"})}
                                              value={password}
                                              className={passwordValidationClass}
                                              onChange={this.handlePasswordChange}
                                />
                                <InputGroup.Append>
                                    <Button variant="outline-secondary"
                                            onClick={() => this.setState({showPasswordText: !this.state.showPasswordText})}>
                                        {this.state.showPasswordText ? (<i className="fas fa-eye-slash"></i>) : (<i className="fas fa-eye"></i>)}
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                            <Form.Text className="invalid-feedback">{this.state.passwordValidationMessage}</Form.Text>
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="light" onClick={this.handleHide}>
                        <FormattedMessage id="common.cancel"/>
                    </Button>
                    <Button variant="primary" onClick={this.handleLogin} disabled={this.isFormInvalid(password)}>
                        <FormattedMessage id="welcome.login"/>
                    </Button>
                </Modal.Footer>
                <ProgressModal show={this.state.showProgress} message={formatMessage({id: "welcome.connectingGoogleAccount"})}/>
            </Modal>
        )
    }
}

WireWithGoogleDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    onWired: PropTypes.func.isRequired
};

export default injectIntl(WireWithGoogleDialog);
