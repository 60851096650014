import axios from "axios/index";
import ReturnPromise from "./ReturnPromise";

const UserService = {
    getCurrent: function () {
        return ReturnPromise.get(axios.get("/api/v3/users/me?expand=stats"), true);
    },

    loginWithCredentials: function (email, password, lang) {
        return ReturnPromise.get(
            axios
                .post("/api/v3/login", {
                    locale: lang,
                    type: "CREDENTIALS",
                    email: email,
                    password: password
                }));
    },

    isGoogleUserRegistered: function (token) {
        return ReturnPromise.get(axios.post("/api/v3/auth/google-token", {token: token}));
    },

    loginWithGoogle: function (token, lang) {
        return ReturnPromise.get(
            axios
                .post("/api/v3/login", {
                    locale: lang,
                    type: "PROVIDER",
                    provider: "GOOGLE",
                    providerToken: token
                }));
    },

    wireWithGoogle: function (email, password) {
        return ReturnPromise.get(
            axios
                .post("/api/v3/auth/wire-connection", {
                    provider: "GOOGLE",
                    email: email,
                    password: password
                }));
    },

    sendRestorePassword: function (email) {
        return ReturnPromise.get(
            axios
                .post("/api2/auth/forgotten", {
                    email: email
                }));
    },

    validateEmail: function (email) {
        return ReturnPromise.get(axios.post("/api2/auth/validate-email", {value: email}));
    },

    getCredsRegistrationToken: function (name, email, password) {
        return ReturnPromise.get(
            axios
                .post("/api/v3/auth/registration-token", {
                    type: "CREDENTIALS",
                    name: name,
                    email: email,
                    password: password
                }));
    },

    // getGoogleRegistrationToken: function (token) {
    //     return ReturnPromise.get(
    //         axios
    //             .post("/api/v3/auth/registration-token", {
    //                 type: "PROVIDER",
    //                 provider: "GOOGLE",
    //                 providerToken: token
    //             })
    //     );
    // },

    sendChangePasswordRequest: function (email, activationCode, newPassword) {
        return ReturnPromise.get(
            axios
                .post("/api2/auth/change-password", {
                    email: email,
                    code: activationCode,
                    password: newPassword
                })
        );
    },

    updateSetupProgress: function (key, value) {
        const setupProgress = {};
        setupProgress[key] = value;

        return ReturnPromise.get(axios.put("/api/v3/users/me", {setupProgress: setupProgress}));
    },

    changePassword: function (currentPassword, newPassword) {
        return ReturnPromise.get(
            axios
                .put("/api/v3/users/me", {
                    password: newPassword,
                    oldPassword: currentPassword
                }));
    },

    deleteAccount: function (password) {
        return ReturnPromise.get(
            axios
                .post("/api/v3/users/me", {
                    password: password,
                    method: "DELETE"
                }));
    },

    saveBillingInfo: function (fullName, fullAddress, country) {
        return ReturnPromise.get(
            axios
                .put("/api/v3/users/me/billingInfo", {
                    "fullName": fullName,
                    "fullAddress": fullAddress,
                    "country": country
                }));
    },

    saveSettings: function (makeAllBookmarkPrivate,
                            applyAutoGeneratedTags,
                            kindleEmail,
                            languages,
                            recoPercentage,
                            bookmarksEmail,
                            rssSecret) {
        return ReturnPromise.get(
            axios
                .put("/api/v3/users/me/settings", {
                    makeAllBookmarkPrivate: makeAllBookmarkPrivate,
                    applyAutoGeneratedTags: applyAutoGeneratedTags,
                    kindleEmail: kindleEmail,
                    languages: languages,
                    recoPercentage: recoPercentage,
                    bookmarksEmail: bookmarksEmail,
                    rssSecret: rssSecret
                }));
    },

    getAvailableBookmarksEmail: function () {
        return ReturnPromise.get(axios.get("/api/v3/users/me/availableBookmarksEmail"));
    },
    createUser: function (username, email, password, avatar, googlePlus, plan, billingPeriod, method) {
        return ReturnPromise.get(
            axios
                .post("/api/v3/users", {
                    user: {
                        username: username,
                        email: email,
                        password: password,
                        avatar: avatar,
                        connections: {
                            googlePlus: googlePlus
                        }
                    },
                    subscription: {
                        plan: plan,
                        billingPeriod: billingPeriod
                    },
                    paymentMethod: method
                })
        );
    }
};

export default UserService;
