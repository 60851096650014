import axios from "axios/index";
import ReturnPromise from "./ReturnPromise";

const SyncService = {
    loadUserSyncs: function () {
        return ReturnPromise.get(axios.get("/api/v3/users/me/syncs"));
    },

    loadAllSyncs: function () {
        return ReturnPromise.get(axios.get("/api/v3/syncs"));
    },

    updateSync: function (sync) {
        return ReturnPromise.get(axios.post(`/api/v3/users/me/syncs/${sync.id}`, sync));
    },

    createSync: function (sync) {
        return ReturnPromise.get(axios.post("/api/v3/users/me/syncs", sync));
    },

    removeSync: function (sync) {
        return ReturnPromise.get(axios.delete(`/api/v3/users/me/syncs/${sync.id}`));
    },

    checkYoutubeListId: function (listId) {
        return ReturnPromise.get(axios.head(`/api/v3/syncProviders/youtube/lists/${listId}`));
    },

    checkHabrUserId: function (userId) {
        return ReturnPromise.get(axios.head(`/api/v3/syncProviders/habr/habr/${userId}`));
    },

    checkStackexchangeUserId: function (provider, userId) {
        return ReturnPromise.get(axios.head(`/api/v3/syncProviders/${provider}/${userId}`));
    },

    checkSlideshareUserId: function (userId) {
        return ReturnPromise.get(axios.head(`/api/v3/syncProviders/slideshare/${userId}`));

    },

    checkRssUrls: function (rssFeeds) {
        return ReturnPromise.get(axios.post("/api/v3/syncProviders/rss", rssFeeds));
    },
};

export default SyncService;
