import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import TagsField from "../../shell/TagsField";
import {Form} from "react-bootstrap";
import SyncService from "../../services/SyncService";
import ProgressModal from "../../shell/ProgressModal";
import ProviderSelector from "./ProviderSelector";

class SyncProviderDialog extends Component {
    state = {
        service: null,
        isActive: false,
        showError: false,
        showProgress: false,

        formValid: false

    };

    resetData = () => {
        this.setState({
            service: JSON.parse(JSON.stringify(this.props.service)),
            isActive: this.props.service.status === "ACTIVE" || this.props.service.status === "FAILED"
        });
    };

    UNSAFE_componentWillMount = () => {
        this.resetData();
    };

    handleHide = () => {
        this.setState({showError: false});
        this.resetData();
        this.props.onHide();
    };

    handleTagsChange = (tags) => {
        const service = JSON.parse(JSON.stringify(this.state.service));
        service.tags = tags;
        this.setState({service});
    };

    handleChangeToInbox = (event) => {
        const service = JSON.parse(JSON.stringify(this.state.service));
        service.readLater = event.target.checked;
        this.setState({service});
    };

    handleChangeIsActive = (event) => {
        this.setState({isActive: event.target.checked});
    };

    formInvalid = (formValid, isActive) => {
        return !formValid && isActive;
    };

    onFormValidation = (formValid) => {
        this.setState({formValid});
    };

    handleServiceUpdated = (service) => {
        this.setState({service: JSON.parse(JSON.stringify(service))});
    };

    handleSave = () => {
        const {formatMessage} = this.props.intl;

        if (this.formInvalid(this.state.formValid, this.state.isActive)) {
            return;
        }

        this.setState({showProgress: true});

        const service = JSON.parse(JSON.stringify(this.state.service));

        if (this.state.isActive && (service.status === "STOPPED" || service.status === "DISABLED" || service.status === "FAILED")) {
            service.status = "ACTIVE";
        }
        if (!this.state.isActive && (service.status === "ACTIVE" || service.status === "FAILED")) {
            service.status = "DISABLED";
        }

        (service.id ? SyncService.updateSync(service) : SyncService.createSync(service))
            .then(result => {
                this.setState({
                    showProgress: false
                }, () => {
                    this.props.onHide();
                    this.props.onConfiguredServicesChange();
                    this.props.onAddToast({
                        id: Date.now(),
                        type: "success",
                        title: formatMessage({id: "account.menu.integrations"}) + " / " + this.state.service.title,
                        description: formatMessage({id: "common.successfullySaved"})
                    })
                });

            })
            .catch(() => {
                this.setState({
                    showProgress: false,
                    showError: true
                });
            });
    };

    render = () => {
        const {formatMessage} = this.props.intl;
        return (
            <React.Fragment>
                <Modal show={this.props.show} onHide={this.handleHide} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.service.title}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Alert variant="danger" show={this.state.showError} onClose={() => this.setState({showError: false})} dismissible>
                            <FormattedMessage id="account.sync.savingFailed"/>
                        </Alert>

                        <ProviderSelector type={this.state.service.provider}
                                          service={this.state.service}
                                          onChange={this.handleServiceUpdated}
                                          validate={this.onFormValidation}/>

                        <TagsField tags={this.state.service.tags}
                                   onChange={this.handleTagsChange}
                                   fieldDescription={formatMessage({id: "account.sync.applyTags"})}/>

                        <Form>
                            <div className="mb-3">
                                <Form.Check inline custom type="checkbox" id="sync.moveToInbox"
                                            label={formatMessage({id: "common.moveToInbox"})}
                                            checked={this.state.service.readLater}
                                            onChange={this.handleChangeToInbox}
                                />
                                <Form.Check inline custom type="checkbox" id="sync.isActive"
                                            label={formatMessage({id: "account.sync.isActive"})}
                                            checked={this.state.isActive}
                                            onChange={this.handleChangeIsActive}
                                />
                            </div>
                        </Form>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={this.handleHide} variant="light">
                            <FormattedMessage id="common.cancel"/>
                        </Button>

                        <Button variant="primary" onClick={this.handleSave} disabled={this.formInvalid(this.state.formValid, this.state.isActive)}>
                            {this.state.isActive ? formatMessage({id: "common.saveAndRun"}) : formatMessage({id: "common.save"})}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <ProgressModal show={this.state.showProgress} message={formatMessage({id: "common.saving"})}/>
            </React.Fragment>
        )
    }
}

SyncProviderDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    service: PropTypes.object.isRequired,
    onConfiguredServicesChange: PropTypes.func.isRequired,
    onAddToast: PropTypes.func.isRequired
};

export default injectIntl(SyncProviderDialog);
