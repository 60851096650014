import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedDate, FormattedMessage, FormattedRelative, injectIntl} from "react-intl"
import SetupProgress from "../../shell/SetupProgress";
import {Button, Table} from "react-bootstrap";
import StatisticsPanel from "../StatisticsPanel";
import LinkService from "../../services/LinkService";
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";
import ChangePasswordDialog from "./ChangePasswordDialog";
import DeleteAccountDialog from "./DeleteAccountDialog";

class AccountSummary extends Component {
    state = {
        showChangePassword: false,
        showDeleteAccount: false
    };

    getPlanId = () => {
        return this.props.user.plan
            ? "account.plan." + this.props.user.plan.toLowerCase()
            : "account.plan.demo"
    };

    render = () => {
        const {formatMessage} = this.props.intl;

        const userPlanId = this.getPlanId();
        const accountCreated = new Date(this.props.user.created);

        return (
            <div className="help-content">
                <h3 className="account-title"><FormattedMessage id="account.menu.summary"/></h3>
                <SetupProgress setupProgress={this.props.user.setupProgress} onUserUpdate={this.props.onUserUpdate} closable={false}/>
                <Table className="table-lg">
                    <tbody>
                    <tr>
                        <td className="no-top-border align-middle"><strong className="no-wrap">{formatMessage({id: "account.accountLevel"})}</strong></td>
                        <td className="no-top-border align-middle">
                            <span className="align-middle"><FormattedMessage id={userPlanId}/></span>
                            {userPlanId === "account.plan.free" &&
                            <LinkContainer to={LinkService.accountSubscriptionUpgrade()}>
                                <Button variant="link"><FormattedMessage id="account.upgradeSubscription"/></Button>
                            </LinkContainer>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td><strong className="no-wrap">{formatMessage({id: "account.usageStatistics"})}</strong></td>
                        <td><StatisticsPanel stats={this.props.user.stats}/></td>
                    </tr>
                    <tr>
                        <td><strong className="no-wrap">{formatMessage({id: "common.userName"})}</strong></td>
                        <td>{this.props.user.title}</td>
                    </tr>
                    <tr>
                        <td><strong className="no-wrap">{formatMessage({id: "common.userEmail"})}</strong></td>
                        <td>
                            <span>{this.props.user.userName}</span>
                            <small className="text-muted">&#32;(<FormattedMessage id="common.notPubliclyAvailable"/>)</small>
                        </td>
                    </tr>
                    <tr>
                        <td><strong className="no-wrap">{formatMessage({id: "account.memberSince"})}</strong></td>
                        <td>
                            <FormattedDate value={accountCreated} year='numeric' month='short' day='numeric'/>
                            <small className="text-muted">&#32;(<FormattedRelative value={accountCreated}/>)</small>
                        </td>
                    </tr>
                    <tr>
                        <td><strong className="no-wrap">{formatMessage({id: "account.changePassword"})}</strong></td>
                        <td>
                            <Button variant="secondary" onClick={() => this.setState({showChangePassword: true})}>
                                <FormattedMessage id="account.changePassword"/>
                            </Button>
                            <ChangePasswordDialog open={this.state.showChangePassword}
                                                  onClose={() => this.setState({showChangePassword: false})}
                                                  onLogout={this.props.onLogout}
                                                  onAddToast={this.props.onAddToast}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><strong className="no-wrap">{formatMessage({id: "account.deleteAccount"})}</strong></td>
                        <td>
                            <p>
                                <FormattedMessage id="account.dontForgetExportBookmarksBeforeRemoveAccount"/><span>&#32;</span>
                                <FormattedMessage id="account.youCanDoThisOn"/><span>&#32;</span>
                                <LinkContainer to={LinkService.accountImportExport()}>
                                    <a href={LinkService.accountImportExport()}><FormattedMessage id="account.menu.importExport"/></a>
                                </LinkContainer><span>&#32;</span>
                                <FormattedMessage id="account.page"/>
                            </p>
                            <Button variant="secondary" onClick={() => this.setState({showDeleteAccount: true})}>
                                <FormattedMessage id="account.deleteAccount"/>
                            </Button>
                            <DeleteAccountDialog open={this.state.showDeleteAccount}
                                                 onClose={() => this.setState({showDeleteAccount: false})}
                                                 onLogout={this.props.onLogout}
                                                 accountCreatedAt={new Date(this.props.user.created)}
                            />
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </div>
        )
    }
}

AccountSummary.propTypes = {
    user: PropTypes.object.isRequired,
    onUserUpdate: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    onAddToast: PropTypes.func.isRequired,
};

export default injectIntl(AccountSummary);
