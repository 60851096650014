import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Form from "react-bootstrap/Form";
import {Button, Card} from "react-bootstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import Alert from "react-bootstrap/Alert";

class BookmarkTitle extends Component {
    state = {
        editMode: false,
        title: "",
        changed: false,
        showError: false
    };

    startEdit = (event) => {
        event.stopPropagation();

        if (this.props.editable) {
            this.setState({editMode: true, title: this.props.title, changed: false}, () =>
                document.getElementById("addBookmarkForm.title").focus());
        }
    };

    handleCancel = () => {
        this.setState({editMode: false, title: ""});
    };

    validateTitleInput = (input) => {
        if (input.trim().length > 0) {
            this.setState({
                titleValidationClass: "is-valid",
            })
        } else {
            this.setState({
                titleValidationClass: "is-invalid",
            })
        }
    };

    handleTitleChange = (event) => {
        this.validateTitleInput(event.target.value);
        this.setState({title: event.target.value, changed: true});
    };

    isFormInvalid = (title) => {
        return title.trim().length === 0;
    };

    handleSave = (event) => {
        event.preventDefault();

        const {formatMessage} = this.props.intl;
        this.props
            .onTitleUpdate(this.state.title)
            .then(() => {
                this.props.onAddToast({
                    id: Date.now(),
                    type: "success",
                    title: formatMessage({id: "main.editBookmark"}),
                    description: formatMessage({id: "addBookmark.bookmarkUpdated"})
                });
                this.handleCancel();

            })
            .catch(() => {
                this.setState({showError: true});
            });
    };

    render = () => {
        const {formatMessage} = this.props.intl;

        let bookmarkTitle = this.props.url;
        if (this.props.title) {
            bookmarkTitle = this.props.title;
        }

        return (
            <React.Fragment>
                {!this.state.editMode &&
                <Card.Title>
                    <p className="bookmark-title" onClick={this.startEdit}>
                        <a href={this.props.url} rel="noopener noreferrer" onClick={this.props.onOpen} target="_blank">{bookmarkTitle}</a>
                    </p>
                </Card.Title>
                }
                {this.state.editMode &&
                <Form onClick={e => e.stopPropagation()}
                      onSubmit={this.handleSave}
                      className="border border-primary non-clickable rounded"
                      style={{padding: "0.5rem", marginBottom: "1rem"}}
                >
                    <Alert variant="danger" show={this.state.showError} onClose={() => this.setState({showError: false})} dismissible>
                        <FormattedMessage id="addBookmark.bookmark.updateFailed"/>
                    </Alert>
                    <Form.Group controlId="addBookmarkForm.title">
                        <Form.Label><FormattedMessage id="common.title"/></Form.Label>
                        <Form.Control type="text" placeholder={formatMessage({id: "common.typeTitle"})}
                                      value={this.state.title}
                                      className={this.state.titleValidationClass}
                                      onChange={this.handleTitleChange}
                        />
                        <Form.Text className="invalid-feedback"><FormattedMessage id="common.validation.fieldRequired"/></Form.Text>
                    </Form.Group>

                    <div className="d-flex justify-content-end">
                        <Button variant="light" size="sm" type="button" style={{marginLeft: 8}} onClick={this.handleCancel}>
                            <FormattedMessage id="common.cancel"/>
                        </Button>
                        <Button variant="primary" size="sm" type="submit"
                                disabled={this.isFormInvalid(this.state.title) || !this.state.changed}
                                style={{marginLeft: 8}}>
                            <FormattedMessage id="common.save"/>
                        </Button>
                    </div>
                </Form>
                }

            </React.Fragment>
        )
    }
}

BookmarkTitle.propTypes = {
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onOpen: PropTypes.func.isRequired,
    onTitleUpdate: PropTypes.func.isRequired,
    onAddToast: PropTypes.func.isRequired,
    editable: PropTypes.bool.isRequired
};

export default injectIntl(BookmarkTitle);
