import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl"
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import LinkService from "../services/LinkService";

class ConfigureKindleEmailDialog extends Component {
    render = () => {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide} centered>
                <Modal.Header closeButton>
                    <Modal.Title><FormattedMessage id="reader.kindle.send"/></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <FormattedMessage id="reader.kindle.notConfigured"/><span> - </span>
                    <a href={LinkService.accountSettings()} target="_blank" rel="noopener noreferrer">
                        <FormattedMessage id="reader.kindle.setup"/>
                    </a>.
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={this.props.onHide} variant="primary"><FormattedMessage id="common.ok"/></Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

ConfigureKindleEmailDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired
};

export default ConfigureKindleEmailDialog;
