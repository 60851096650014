import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Button from "react-bootstrap/Button";

class SidebarToggler extends Component {
    render = () => {
        let className = this.props.toggled ? "fa-chevron-right" : "fa-chevron-left";
        return (
            <Button variant="light"
                    className="round-button no-focus"
                    onClick={this.props.onToggle}
            >
                <i className={["fas", "fa-fw", className].join(" ")}/>
            </Button>
        )
    }
}

SidebarToggler.propTypes = {
    toggled: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired
};

export default SidebarToggler;
