const LocalStorage = {
    getOrDefault: function (key, defaultValue) {
        if (window.localStorage) {
            const value = window.localStorage.getItem(key);
            if (value === null || value === undefined || value === "undefined") {
                return JSON.parse(defaultValue);
            } else {
                return JSON.parse(value);
            }
        } else {
            return defaultValue;
        }

    },

    get: function (key) {
        if (window.localStorage) {
            const value = window.localStorage.getItem(key);

            if (value === null || value === undefined || value === "undefined") {
                return value;
            } else {
                return JSON.parse(value);
            }
        }
        return undefined;
    },

    set: function (key, value) {
        if (window.localStorage) {
            window.localStorage.setItem(key, JSON.stringify(value));
        }
    },

    delete: function (key) {
        if (window.localStorage) {
            window.localStorage.removeItem(key);
        }
    }
};

export default LocalStorage;
