import React, {Component} from 'react'
import {FormattedMessage} from "react-intl"
import HelpTOSContent from "./HelpTOSContent";

class HelpTOS extends Component {
    render = () => {
        return (
            <div className="help-content">
                <h3><FormattedMessage id="help.termsOfService"/></h3>
                <HelpTOSContent/>
            </div>
        )
    }
}

export default HelpTOS;
