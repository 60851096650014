import React, {Component} from 'react'
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import LinkService from "../../services/LinkService";
import {Button} from "react-bootstrap";

class HelpHowToSavingBookmarks extends Component {
    render = () => {
        const popupScriptlet = "javascript:u=location.href;if(document.getSelection){n=document.getSelection();}else{n='';};t=document.title;w=700;h=450;x=screen.width/2-w/2;y=screen.height/2-h/2;void(open('https://grabduck.com/add?url='+encodeURIComponent(u)+'&note='+encodeURIComponent(n)+'&title='+encodeURIComponent(t),'GrabDuck','toolbar=no,width='+w+',height='+h+',left='+x+',top='+y));";

        return (
            <div className="help-content">
                <h3>
                    <FormattedMessage id="help.howTo.savingBookmarks"/>
                    <small className="text-muted"> / <FormattedMessage id="help.howTo"/></small>
                </h3>

                <p>There are number of options available how you can save your bookmarks, using <FormattedMessage id="help.googleChromeExtension"/>, running a
                    Browser's bookmarklet or <FormattedMessage id="account.emailBookmarksTo"/> directly to your GrabDuck account</p>

                <h4>
                    <FormattedMessage id="help.googleChromeExtension"/>
                </h4>

                <p>
                    One way of saving bookmarks is to use special Google Chrome Extension.</p>

                <h6 className="text-center">
                    <a href="https://chrome.google.com/webstore/detail/save-to-grabduck/cfekjdkpggdkoebenkglfbidhjdngbfe"
                       target="_blank"
                       rel="noopener noreferrer">
                        <span>Save to GrabDuck - Google Chrome extension</span> <i
                        className="fas fa-external-link-alt"></i>
                    </a>
                </h6>

                <p>Here you can define title and tags of the webpage. Also this
                    extension sends the whole content of the page to the server for creating tags suggestion (you got tags which could be extracted from the
                    page and tags which you tend to use for such pages).
                    For already existing bookmark the extension allows you to update the bookmark without adding a new one.
                </p>
                <p className="text-center">
                    <img style={{border: "1px solid #BDBDBD"}}
                         src="/static/images/help/how-to/grabduck-chrome-extension.png"
                         alt="GrabDuck Google Chrome Extension"/>
                </p>

                <p>
                    <strong>Hint:</strong> Also might be interesting for you -&nbsp;
                    <Link to={LinkService.helpHowToShowResultsNextToISE()}>
                        How to show GrabDuck search results side by side with Google / DuckDuckGo results
                    </Link>
                </p>


                <h4>Browser's bookmarklet</h4>

                <p>
                    If you do not use Google Chrome or you do not like installing a lot of extensions there is another way how to save your bookmarks with
                    GrabDuck - by using Bookmarklets. Bookmarklets are little javascript links that sit in the bookmarks toolbar of your browser.
                </p>

                <h5>How to install</h5>

                <p>
                    If you use Chrome, Firefox, Safari or Opera, you have to drag Scriptlet's link up to your bookmarks toolbar. If you're using Internet
                    Explorer right-click on the Bookmarklet and select 'add to Favorites' at the prompt.
                </p>

                <h5>Simple Popup</h5>

                <p>
                    We're offering you a simple popup which opens a little form where you can define title and tags for the bookmark you want to add. For
                    already existing bookmark the popup allows you to update the bookmark without adding a new one.
                </p>

                <h6 className="text-center">
                    <Button variant="link" size="lg" href={popupScriptlet}>Save to GrabDuck</Button>
                    <mark>&nbsp;&larr; drag this to your bookmarks toolbar</mark>
                </h6>

                <p className="text-center">
                    <img style={{border: "1px solid #BDBDBD"}} src="/static/images/help/how-to/save-to-gd-popup.png"
                         alt="GrabDuck Popup"/></p>

                <h4><FormattedMessage id="account.emailBookmarksTo"/></h4>

                <p>
                    For the case you are working on a public machine or have no possibility to install browser's extension / bookmarklet you can email your
                    bookmarks to a special email address GradDuck gives you. In this case all the bookmarks you have emailed to GrabDuck will end up in your
                    Inbox for your later attention.
                </p>
                <p>
                    The information about your GrabDuck email address you can find in Settings of your User's account.
                </p>

                <p className="text-center">
                    <img style={{border: "1px solid #BDBDBD"}} src="/static/images/help/how-to/email-bookmarks.png"
                         alt="Email bookmark"/></p>
            </div>
        )
    }
}

export default HelpHowToSavingBookmarks;
