import React, {Component} from 'react'
import {FormattedMessage} from "react-intl"

class HelpPrivacyCookies extends Component {
    render = () => {
        return (
            <div className="help-content">
                <h3>
                    <FormattedMessage id="help.cookieInfo"/>
                    <small className="text-muted"> / <FormattedMessage id="help.privacy"/></small>
                </h3>

                <p>Last updated: Aug 07, 2017</p>

                <h4>What are cookies?</h4>
                <p>Cookies are small data files that a web site you visit may save on
                    your hard drive. They usually include an anonymous unique identifier. A cookie cannot retrieve any
                    other data from your hard drive or pass on computer viruses.</p>


                <h4>What Cookies and Similar Technologies Does GrabDuck
                    Use?</h4>
                <p>GrabDuck uses cookies and similar technologies to assist with
                    functions such as user authentication, remembering user preferences, understanding how users use our
                    Service, and managing and tracking the effectiveness of our marketing efforts. We also may include
                    tracking pixels, which are small graphic images, in our email messages and newsletters to determine
                    whether the messages were opened and the links were actually clicked.</p>
                <p>We describe in this document the cookies and similar technologies
                    found on our Service. Please check back here periodically, as we may update this information from
                    time to time.</p>


                <h4>Functional Cookies</h4>
                <p>Functional cookies are ones GrabDuck sets to enable the website to
                    work as intended.</p>
                <p>These cookies are necessary to enable you to move around our web
                    site and use its features. Without these cookies, we can’t provide services you have asked
                    for.</p>
                <p>We use other cookies to help recognize and remember you when you
                    are logged into the Service so we can remember your settings and preferences, such as your language
                    and region or your logged-in state. These cookies also may help us provide services you have asked
                    for. These cookies do not track your browsing activity on non-GrabDuck websites.</p>
                <p>As described below, you may disable any of these functional
                    cookies; but if you do so, then various functions of the web site may be unavailable to you or may
                    not work as intended.</p>

                <h4>Analytics</h4>
                <p>We use cookies and similar tracking technologies to help us
                    understand how you use our website, our browser extension products, and our Save to GrabDuck button.
                    By providing us with information about how you interact with our website and products, these tools
                    help us learn how to make the Grabduck experience even better and customize our communications with
                    you.</p>
                <p>For doing analytics we are using Google Analytics. To learn more
                    about Google Analytics and your privacy, visit <a
                        href="http://www.google.com/analytics/learn/privacy.html">http://www.google.com/analytics/learn/privacy.html</a>.
                    To opt out of being tracked by Google Analytics when using our website, visit <a
                        href="http://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout</a></p>
            </div>
        )
    }
}

export default HelpPrivacyCookies;
