import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl"
import {Button, Form} from "react-bootstrap";
import BookmarksService from "../../services/BookmarksService";

class RecommendedTags extends Component {
    state = {
        tagRecommendations: null
    };

    UNSAFE_componentWillMount = () => {
        BookmarksService
            .getTagRecommendations(this.props.bookmarkId)
            .then((tagRecommendations) => this.setState({tagRecommendations: tagRecommendations.items}))
            .catch(() => {
            });
    };

    isUserTag = (tag) => {
        return tag.source === "USER";
    };

    render = () => {
        return (
            <React.Fragment>
                {this.state.tagRecommendations && this.state.tagRecommendations.length > 0 &&
                <Form.Group controlId="recommendedTagsField">
                    <Form.Label><FormattedMessage id="main.recommendedTags"/></Form.Label>
                    <div className="border border-light rounded" style={{padding: "0.5rem"}}>
                        {this.state.tagRecommendations.map(tag => {
                            const variant = this.isUserTag(tag) ? "secondary" : "outline-secondary";
                            const disabled = (this.props.tags.indexOf(tag.title) >= 0);
                            return (
                                <Button key={tag.title} style={{marginRight: "0.5rem", marginBottom: "0.25rem"}}
                                        variant={variant}
                                        disabled={disabled}
                                        size="sm"
                                        onClick={() => this.props.onAddTag(tag.title)}
                                >
                                    {tag.title}
                                </Button>
                            )
                        })}
                    </div>

                    <Form.Text className="text-muted"><FormattedMessage id="main.recommendedTags.hints"/></Form.Text>
                </Form.Group>
                }

            </React.Fragment>
        )
    }
}

RecommendedTags.propTypes = {
    bookmarkId: PropTypes.string.isRequired,
    tags: PropTypes.array.isRequired,
    onAddTag: PropTypes.func.isRequired
};

export default RecommendedTags;
