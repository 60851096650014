import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import {Tab, Tabs} from "react-bootstrap";
import TabContainer from "./TabContainer";
import CurrentLevel from "./CurrentLevel";
import BillingInfo from "./BillingInfo";
import Invoices from "./Invoices";

class AccountSubscription extends Component {
    render = () => {
        const {formatMessage} = this.props.intl;

        return (
            <div className="help-content">
                <h3 className="account-title"><FormattedMessage id="account.menu.membership"/></h3>

                <Tabs defaultActiveKey="currentLevel">
                    <Tab eventKey="currentLevel" title={formatMessage({id: "account.currentLevel"})}>
                        <TabContainer><CurrentLevel plan={this.props.user.plan}/></TabContainer>
                    </Tab>
                    <Tab eventKey="billingInfo" title={formatMessage({id: "account.billingInfo"})}>
                        <TabContainer>
                            <BillingInfo billingInfo={this.props.user.billingInfo}
                                         onUserUpdate={this.props.onUserUpdate}
                                         onAddToast={this.props.onAddToast}
                            />
                        </TabContainer>
                    </Tab>
                    <Tab eventKey="invoices" title={formatMessage({id: "account.invoices"})}>
                        <TabContainer><Invoices/></TabContainer>
                    </Tab>
                </Tabs>
            </div>
        )
    }
}

AccountSubscription.propTypes = {
    user: PropTypes.object.isRequired,
    onUserUpdate: PropTypes.func.isRequired,
    onAddToast: PropTypes.func.isRequired,
};

export default injectIntl(AccountSubscription);
