import React, {Component} from 'react'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Copyright from "./Copyright";
import PropTypes from 'prop-types'
import FooterActions from "./FooterActions";
import FooterLinks from "./FooterLinks";

class Footer extends Component {
    render = () => {
        return (
            <footer className="bg-secondary">
                <Container className="footer">
                    <Row>
                        <Col><FooterActions large={true} onAddToast={this.props.onAddToast} user={this.props.user}/></Col>
                    </Row>

                    <Row><Col><FooterLinks light={true}/></Col></Row>

                    <Row>
                        <Col className="d-flex justify-content-center text-light"><Copyright textAlign="center"/></Col>
                    </Row>
                </Container>
            </footer>
        )
    }
}

Footer.propTypes = {
    user: PropTypes.object,
    onAddToast: PropTypes.func.isRequired
};

export default Footer;
