import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Navbar} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {FormattedMessage} from "react-intl";
import LinkService from "../../services/LinkService";

class QuickAccessPanel extends Component {
    render = () => {
        const {bookmark, privileges} = this.props;
        const isInbox = window.location.pathname.startsWith(LinkService.inbox());
        // const isHistory = window.location.pathname.startsWith(LinkService.history());

        return (
            <Navbar bg="primary" className="quick-access-panel">
                {bookmark.readability && (bookmark.embed === undefined || bookmark.embed === null) &&
                <OverlayTrigger placement="bottom" overlay={<Tooltip><FormattedMessage id="main.reader"/></Tooltip>}>
                    <Button variant="light"
                            onClick={this.props.onReader}
                            disabled={!bookmark.favorite || (bookmark.favorite && !privileges.includes('VIEW_READABILITY_CONTENT'))}
                            className="no-focus access-panel-button text-black-50"
                    >
                        <i className="fa-fw fas fa-book-reader"/>
                    </Button>
                </OverlayTrigger>
                }

                {bookmark.readability && (bookmark.embed !== undefined && bookmark.embed !== null) &&
                <OverlayTrigger placement="bottom" overlay={<Tooltip><FormattedMessage id="main.play"/></Tooltip>}>
                    <Button variant="light" onClick={this.props.onPlay} className="no-focus access-panel-button text-black-50">
                        <i className="fa-fw fas fa-play-circle"/>
                    </Button>
                </OverlayTrigger>
                }

                {!bookmark.favorite &&
                <OverlayTrigger placement="bottom" overlay={<Tooltip><FormattedMessage id="main.addToFavs"/></Tooltip>}>
                    <Button variant="light" onClick={() => this.props.onBookmarkUpdateAction("ADD_TO_FAVORITES")}
                            className="no-focus access-panel-button text-black-50">
                        <i className="fa-fw fas fa-star"/>
                    </Button>
                </OverlayTrigger>
                }

                {bookmark.favorite &&
                <OverlayTrigger placement="bottom" overlay={<Tooltip><FormattedMessage id="main.removeFromFavs"/></Tooltip>}>
                    <Button variant="light" onClick={() => this.props.onBookmarkUpdateAction("REMOVE_FROM_FAVORITES")}
                            className="no-focus access-panel-button text-black-50">
                        <i className="fa-fw fas fa-trash"/>
                    </Button>
                </OverlayTrigger>
                }

                {bookmark.favorite && !bookmark.readLater &&
                <OverlayTrigger placement="bottom" overlay={<Tooltip><FormattedMessage id="main.moveToInbox"/></Tooltip>}>
                    <Button variant="light" onClick={() => this.props.onBookmarkUpdateAction("MOVE_TO_INBOX")}
                            className="no-focus access-panel-button text-black-50">
                        <i className="fa-fw fas fa-inbox"/>
                    </Button>
                </OverlayTrigger>
                }

                {bookmark.favorite && bookmark.readLater && !isInbox &&
                <OverlayTrigger placement="bottom" overlay={<Tooltip><FormattedMessage id="main.removeFromInbox"/></Tooltip>}>
                    <Button variant="light" onClick={() => this.props.onBookmarkUpdateAction("REMOVE_FROM_INBOX")}
                            className="no-focus access-panel-button text-black-50">
                        <i className="fa-fw fas fa-inbox text-info"/>
                    </Button>
                </OverlayTrigger>
                }

                {bookmark.favorite && bookmark.readLater && !bookmark.flagged && isInbox &&
                <OverlayTrigger placement="bottom" overlay={<Tooltip><FormattedMessage id="main.flagBookmark"/></Tooltip>}>
                    <Button variant="light" onClick={() => this.props.onBookmarkUpdateAction("FLAG")} className="no-focus access-panel-button text-black-50">
                        <i className="fa-fw fas fa-flag"/>
                    </Button>
                </OverlayTrigger>
                }

                {bookmark.favorite && bookmark.readLater && bookmark.flagged && isInbox &&
                <OverlayTrigger placement="bottom" overlay={<Tooltip><FormattedMessage id="main.unflagBookmark"/></Tooltip>}>
                    <Button variant="light" onClick={() => this.props.onBookmarkUpdateAction("UNFLAG")}
                            className="no-focus access-panel-button text-black-50">
                        <i className="fa-fw fas fa-flag text-info"/>
                    </Button>
                </OverlayTrigger>
                }

                {bookmark.favorite && bookmark.readLater && isInbox &&
                <OverlayTrigger placement="bottom" overlay={<Tooltip><FormattedMessage id="main.markAsDone"/></Tooltip>}>
                    <Button variant="light" onClick={() => this.props.onBookmarkUpdateAction("MARK_AS_DONE")}
                            className="no-focus access-panel-button text-black-50">
                        <i className="fa-fw fas fa-check"/>
                    </Button>
                </OverlayTrigger>
                }

                {!bookmark.private &&
                <OverlayTrigger placement="bottom" overlay={<Tooltip><FormattedMessage id="main.share"/></Tooltip>}>
                    <Button variant="light" onClick={this.props.onShareBookmark} className="no-focus access-panel-button text-black-50">
                        <i className="fa-fw fas fa-share-alt"/>
                    </Button>
                </OverlayTrigger>
                }

                {bookmark.favorite && !bookmark.private &&
                <OverlayTrigger placement="bottom" overlay={<Tooltip><FormattedMessage id="main.makeBookmarkPrivate"/></Tooltip>}>
                    <Button variant="light" disabled={!privileges.includes('MAKE_BOOKMARK_PRIVATE')}
                            onClick={() => this.props.onBookmarkUpdateAction("MAKE_PRIVATE")}
                            className="no-focus access-panel-button text-black-50">
                        <i className="fa-fw fas fa-lock"/>
                    </Button>
                </OverlayTrigger>
                }

                {bookmark.favorite && bookmark.private &&
                <OverlayTrigger placement="bottom" overlay={<Tooltip><FormattedMessage id="main.makeBookmarkVisible"/></Tooltip>}>
                    <Button variant="light" disabled={!privileges.includes('MAKE_BOOKMARK_PRIVATE')}
                            onClick={() => this.props.onBookmarkUpdateAction("MAKE_VISIBLE")}
                            className="no-focus access-panel-button text-black-50">
                        <i className="fa-fw fas fa-lock-open text-info"/>
                    </Button>
                </OverlayTrigger>
                }

                <OverlayTrigger placement="bottom" overlay={<Tooltip><FormattedMessage id="main.details"/></Tooltip>}>
                    <Button variant="light" onClick={this.props.onDetails} className="no-focus access-panel-button text-black-50">
                        <i className="fa-fw fas fa-pencil-alt"/>
                        {/*<i className="fa-fw fas fa-info-circle"></i>*/}
                    </Button>
                </OverlayTrigger>

                {/*{(isHistory || bookmark.favorite) &&*/}
                {/*<DropdownButton variant="light" title={<i className="fa-fw fas fa-ellipsis-v text-black-50"/>}*/}
                {/*                className="no-focus text-black-50 quick-access-panel-dropdown"*/}
                {/*>*/}
                {/*    {isHistory &&*/}
                {/*    <Dropdown.Item onClick={() => this.props.onBookmarkUpdateAction("REMOVE_FROM_HISTORY")}>*/}
                {/*        <FormattedMessage id="main.removeFromHistory"/>*/}
                {/*    </Dropdown.Item>*/}
                {/*    }*/}

                {/*    {bookmark.favorite &&*/}
                {/*    <Dropdown.Item onClick={() => this.props.onBookmarkUpdateAction("REFRESH")}>*/}
                {/*        <FormattedMessage id="main.refreshBookmark"/>*/}
                {/*    </Dropdown.Item>*/}
                {/*    }*/}
                {/*</DropdownButton>*/}
                {/*}*/}

            </Navbar>
        )
    }
}

QuickAccessPanel.propTypes = {
    bookmark: PropTypes.object.isRequired,
    privileges: PropTypes.array.isRequired,
    onReader: PropTypes.func.isRequired,
    onPlay: PropTypes.func.isRequired,
    onShareBookmark: PropTypes.func.isRequired,
    onDetails: PropTypes.func.isRequired,
    onBookmarkUpdateAction: PropTypes.func.isRequired
};

export default QuickAccessPanel;
