import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl"
import {Card, Col, Container, Nav, Row} from "react-bootstrap";
import CreateAccountStep from "./CreateAccountStep";
import PayForSubscriptionStep from "./PayForSubscriptionStep";

class CreateAccount extends Component {
    state = {
        activeStep: 1,
        user: {}
    };

    handleShowTrigger = (trigger, show) => {
        this.setState({[trigger]: show})
    };

    handleActivatePayStep = (user) => {
        this.setState({user: user, activeStep: 2});
    };

    render = () => {
        return (
            <Container fluid={true} style={{marginTop: 96, marginBottom: 96}}>
                <Row noGutters={false} className="justify-content-center">
                    <Col lg={6} md={8} sm={10} xm={12}>
                        <h2 className="text-center"><FormattedMessage id="welcome.createAccount"/></h2>
                        <p className="lead text-center" id={this.props.registerId}><FormattedMessage id="welcome.createAccount.desc"/></p>

                        <Card>
                            <Card.Body>
                                <Nav activeKey={this.state.activeStep} justify variant="pills" defaultActiveKey={1} style={{marginBottom: 32}}>
                                    <Nav.Item>
                                        <Nav.Link eventKey={1} disabled={this.state.activeStep !== 1}>
                                            <span>1. </span><FormattedMessage id="welcome.createAccount"/>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={2} disabled={this.state.activeStep !== 2}>
                                            <span>2. </span><FormattedMessage id="welcome.pay"/>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                {this.state.activeStep === 1 &&
                                <CreateAccountStep onWireWithGoogle={this.props.onWireWithGoogle}
                                                   handleProceed={this.handleActivatePayStep}/>
                                }
                                {this.state.activeStep === 2 &&
                                <PayForSubscriptionStep user={this.state.user}/>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

CreateAccount.propTypes = {
    registerId: PropTypes.string.isRequired,
    onWireWithGoogle: PropTypes.object
};

export default CreateAccount;
