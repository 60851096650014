import React, {Component} from 'react'
import PropTypes from 'prop-types'
import GoBackButton from "../shell/GoBackButton";
import {FormattedMessage} from "react-intl";
import LinkService from "../services/LinkService";
import NavLinkItem from "../shell/NavLinkItem";
import NavListItem from "../shell/NavListItem";

class HelpSidebar extends Component {
    render = () => {
        return (
            <React.Fragment>
                <GoBackButton user={this.props.user} sidebarToggled={this.props.sidebarToggled}/>
                <hr className="divider"/>

                {!this.props.sidebarToggled && <p><FormattedMessage id="help.selectTopic"/></p>}

                <NavLinkItem link={LinkService.helpAbout()}
                             titleId="help.aboutTheService"
                             sidebarToggled={this.props.sidebarToggled}
                             icon="fas fa-file-alt"
                             size="sm"
                />
                <NavLinkItem link={LinkService.helpTour()}
                             titleId="help.tourOfGrabDuck"
                             sidebarToggled={this.props.sidebarToggled}
                             icon="fas fa-file-alt"
                             size="sm"
                />
                <NavListItem listId="help.toc.howTo"
                             titleId="help.howTo"
                             size="sm"
                             sidebarToggled={this.props.sidebarToggled}>
                    <NavLinkItem link={LinkService.helpHowToSearchingBookmarks()}
                                 titleId="help.howTo.searchingBookmarks"
                                 sidebarToggled={false}
                                 icon="fas fa-file-alt"
                                 size="sm"
                    />
                    <NavLinkItem link={LinkService.helpHowToSavingBookmarks()}
                                 titleId="help.howTo.savingBookmarks"
                                 sidebarToggled={false}
                                 icon="fas fa-file-alt"
                                 size="sm"
                    />
                    <NavLinkItem link={LinkService.helpHowToShowResultsNextToISE()}
                                 titleId="help.howTo.showResultsNextToISE"
                                 sidebarToggled={false}
                                 icon="fas fa-file-alt"
                                 size="sm"
                    />
                    <NavLinkItem link={LinkService.helpHowToImportBookmarks()}
                                 titleId="help.howTo.importBookmarks"
                                 sidebarToggled={false}
                                 icon="fas fa-file-alt"
                                 size="sm"
                    />
                </NavListItem>
                <NavListItem listId="help.toc.faq"
                             titleId="help.faq.full"
                             size="sm"
                             sidebarToggled={this.props.sidebarToggled}>
                    <NavLinkItem link={LinkService.helpFAQSeeFailed()}
                                 titleId="help.faq.seeFailed"
                                 sidebarToggled={false}
                                 icon="fas fa-file-alt"
                                 size="sm"
                    />
                    <NavLinkItem link={LinkService.helpFAQRemoveMany()}
                                 titleId="help.faq.removeMany"
                                 sidebarToggled={false}
                                 icon="fas fa-file-alt"
                                 size="sm"
                    />
                    <NavLinkItem link={LinkService.helpFAQWhatArePermalinks()}
                                 titleId="help.faq.whatAreFixedLinks"
                                 sidebarToggled={false}
                                 icon="fas fa-file-alt"
                                 size="sm"
                    />
                </NavListItem>
                <NavListItem listId="help.toc.privacy"
                             titleId="help.privacy"
                             size="sm"
                             sidebarToggled={this.props.sidebarToggled}>
                    <NavLinkItem link={LinkService.helpPrivacyCookieInfo()}
                                 titleId="help.cookieInfo"
                                 sidebarToggled={false}
                                 icon="fas fa-file-alt"
                                 size="sm"
                    />
                    <NavLinkItem link={LinkService.helpPrivacyPolicy()}
                                 titleId="help.privacyPolicy"
                                 sidebarToggled={false}
                                 icon="fas fa-file-alt"
                                 size="sm"
                    />
                    <NavLinkItem link={LinkService.helpPrivacyGDPR()}
                                 titleId="help.privacyGDPR"
                                 sidebarToggled={false}
                                 icon="fas fa-file-alt"
                                 size="sm"
                    />
                </NavListItem>
                <NavLinkItem link={LinkService.helpTOS()}
                             titleId="help.termsOfService"
                             sidebarToggled={this.props.sidebarToggled}
                             icon="fas fa-file-alt"
                             size="sm"
                />

            </React.Fragment>
        )
    }
}

HelpSidebar.propTypes = {
    user: PropTypes.object,
    sidebarToggled: PropTypes.bool.isRequired,
};

export default HelpSidebar;
