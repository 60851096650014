import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Navbar} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import LinkService from "../../services/LinkService";

class BookmarkIndicators extends Component {
    renderIndicator = (icon) => {
        return (
            <Button variant="light" disabled
                    className="no-focus access-panel-button indicator-button clickable bg-transparent">
                <i className={["fa-fw", icon].join(" ")}/>
            </Button>
        );
    };

    render = () => {
        const {bookmark} = this.props;
        const isInbox = window.location.pathname.startsWith(LinkService.inbox());
        const showIndicators = (!bookmark.favorite) || (bookmark.readLater && !isInbox) ||
            (bookmark.flagged && isInbox) || (bookmark.favorite && bookmark.private) || (bookmark.permalink);

        return (
            <React.Fragment>
                {showIndicators &&
                <Navbar bg="transparent" className="bookmark-indicators">
                    {!bookmark.favorite && this.renderIndicator("far fa-star")}
                    {bookmark.readLater && !isInbox && this.renderIndicator("fas fa-inbox text-info")}
                    {bookmark.flagged && isInbox && this.renderIndicator("fas fa-flag text-info")}
                    {bookmark.permalink && this.renderIndicator("fas fa-link text-info")}
                    {bookmark.favorite && bookmark.private && this.renderIndicator("fas fa-lock text-info")}

                </Navbar>
                }
            </React.Fragment>
        )
    }
}

BookmarkIndicators.propTypes = {
    bookmark: PropTypes.object.isRequired,
};

export default BookmarkIndicators;
