const i18n = {
    "en": {
        "common.applicationName": 'GrabDuck',
        "common.econorCompany": "Econor, s.r.o.",
        "common.handcraftedInGermany": "Handcrafted with love in Berlin, Germany",
        "common.topMenuTitle.inbox": "Inbox",
        "common.topMenuTitle.favorites": "Favorites",
        "common.topMenuTitle.welcome": "Welcome",
        "common.topMenuTitle.history": "History",
        "common.topMenuTitle.help": "Help",
        "common.topMenuTitle.account": "My Account",
        "common.contactUs": "Have a question?",
        "common.followOnTwitter": "Follow Us on Twitter",
        "common.readOnSearchingBits": "Read on Searching Bits",
        "common.getHelp": "Get Help",
        "common.contactUs.greeting": "We are always happy to help you make the most of our service. Also, feel free to make a suggestion to help us improve for the future.",
        "common.contactUs.onlyEnglish": "Please note that we are only able to respond to requests in English.",
        "common.cancel": "Cancel",
        "common.send": "Send",
        "common.emailAddress": "Email Address",
        "common.message": "Message",
        "common.validation.fieldRequired": "Field is required.",
        "common.validation.validEmail": "Field must contain a valid email address.",
        "common.validation.validUrl": "Field must contain a valid web address.",
        "common.validation.agreementRequired": "Your Agreement is required.",
        "common.sendingMessage": "Sending message...",
        "common.message.cannotBeSent": "The message can't be sent right now. Please try again later",
        "common.message.wasSent": "Your message has been successfully sent",
        "common.back": 'Go Back',
        "common.userName": "User Name",
        "common.userEmailNotPublic": "User Email (not publicly available)",
        "common.notPubliclyAvailable": "not publicly available",
        "common.userEmail": "User Email",
        "common.password": "Password",
        "common.display": "display",
        "common.proceed": "Proceed",
        "common.gotIt": "Got It",
        "common.agreeToUseCookies": "GrabDuck use cookies to improve the service we provide to our customers. By using this website, you agree to our cookies policy. You can read more here ",
        "common.gdprPolicy": "We have adapted our privacy policy to the new EU General Data Protection Regulation. Take a look at our changes ",
        "common.cookiesPolicy": "Cookies Policy",
        "common.logout": "Log Out",
        "common.passwordChangeError": 'Password cannot be changed. Please try to send your request again.',
        "common.change": "Change",
        "common.changingPassword": "Changing password...",
        "common.passwordChanged": 'Your password was changed successfully.',
        "common.setupProgress": "Setup progress",
        "common.close": "Close",
        "common.save": "Save changes",
        "common.saveAndRun": "Save changes and Run",
        "common.reset": "Reset",
        "common.errorWhileSavingData": "Error while saving data. Please try again later.",
        "common.saving": "Saving changes...",
        "common.successfullySaved": "Successfully saved.",
        "common.see": "See",
        "common.yes": "Yes",
        "common.no": "No",
        "common.unlimited": "unlim.",
        "common.tags": "Tags",
        "common.moveToInbox": "Move to Inbox",
        "common.ok": "Ok",
        "common.share.facebook": "Share on Facebook",
        "common.share.twitter": "Share on Twitter",
        "common.share.email": "Share via Email",
        "common.title": "Title",
        "common.typeTitle": "Enter a Title of the Bookmark",
        "common.delete": "Delete",
        "common.search": "Search...",
        "common.startNewSearchHere": "Start a new search here...",
        "common.nothingWasFound": "Nothing was found. Try to change your search criteria.",
        "common.hint": "Hint:",
        "common.rssFeed": "RSS Feed",
        "common.processing": "Processing...",
        "common.refresh": "Refresh",
        "common.created": "Created",
        "common.lastAccessed": "Opened",
        "common.refreshed": "Refreshed",
        "common.sharedTimes": "Shared: {num} times",
        "common.makeFixLink": "Make Permalink",
        "common.mobileWebsite": "Mobile Website",

        "sources.title": "Source",
        "sources.BROWSER": "added from browser",
        "sources.IMPORT": "imported",
        "sources.EMAIL": "sent via email",
        "sources.FAVORITE": "added from recommendations",
        "sources.INTEGRATION": "synced from other services",
        "sources.INTEGRATION_STACK_EXCHANGE": "synced from StackExchange",
        "sources.INTEGRATION_HABRAHABR": "synced from Habr",
        "sources.INTEGRATION_GEEKTIMES": "synced from Geektimes",
        "sources.INTEGRATION_YOUTUBE_PUBLIC_LIST": "synced from YouTube list",
        "sources.INTEGRATION_RSS": "synced from RSS feed",
        "sources.INTEGRATION_SLIDESHARE_FAVORITE": "synced from Slideshare Favorites",

        "welcome.login": 'Login',
        "welcome.register": 'Register',
        "welcome.welcomeToGD": 'Welcome to GrabDuck!',
        "welcome.gdOnlyBookmarkingService": ' The bookmarking service that offers genuine full text search for your bookmarks.',
        "welcome.helpsSaveAllInteresting": 'GrabDuck helps keep every interesting thing that you find on the Web.',
        "welcome.collectYouInternet": 'Collect your internet here!',
        "welcome.getStarted": 'Get started!',
        "welcome.createAccount": 'Create an Account',
        "welcome.createAccount.desc": 'Thinking of creating an account on GrabDuck? Enter your details and get started!',
        "welcome.pay": "Pay",
        "welcome.grabduckChargesForBasicAccount": "GrabDuck charges $11.88 per year for a basic account. This fee is fully refundable for three days following signup.",
        "welcome.agreeWithProcessingPersonalData": "I agree to the processing of personal data",
        "welcome.agreeWithTermOfService": "I agree with Terms of Service",
        "welcome.consent": "Consent to the processing of personal data",
        "welcome.weNeverShareYourEmail": "We'll never share your email with anyone else.",
        "welcome.enterUserName": "Enter User Name",
        "welcome.enterEmail": "Enter Email",
        "welcome.typePassword": "Type Password",
        "welcome.haveAccount": 'Already have a GrabDuck Account? Enter your details below and collect your internet.',
        "welcome.forgotPassword": 'Forgot password?',
        "welcome.loggingIn": "Logging In...",
        "welcome.usernameOrPasswordIncorrect": "Email or password is not correct or doesn't exist!",
        "welcome.passwordIncorrect": "Password is not correct!",
        "welcome.foundGoogleAccount": 'We found an account for {email}. Enter your password to sign in with Google next time.',
        "welcome.connectingGoogleAccount": "Connecting to Google Account...",
        "welcome.allowSingInWithGoogle": "Allow signing in with Google",
        "welcome.createAccountAndWireToGoogle": "Thank for signing in with Google. In order to start using GrabDuck you need to create your account first. Please check all the data below and click on Proceed button.",
        "welcome.sendUsEmail": 'Forgot your password? No problem! Enter your email address below to receive a reset email.',
        "welcome.forgotPasswordSent": "A password reset email has been sent to {email}",
        "welcome.registering": "Registering...",
        "welcome.emailAlreadyRegistered": 'This email address is already registered or incorrect.',
        "welcome.payForSubscription": "Pay for Subscription",
        "welcome.accountAlmostReady": "Your new account is almost ready!",
        "welcome.changePassword": "Change your password",
        "welcome.changePassword.desc": "Please type here your new password",

        "help.termsOfService": "Terms of Service",
        "help.privacyPolicy": "Privacy Policy",
        "help.privacy": "Privacy",
        "help.pleaseSelectTopic": "Please select a topic from the left hand side.",
        "help.privacyGDPR.full": "General Data Protection Regulation",
        "help.privacyGDPR": "GDPR",
        "help.cookieInfo": "Cookie Information",
        "help.aboutTheService": "About the Service",
        "help.whyPay": "Why Pay For Bookmarking?",
        "help.tourOfGrabDuck": "Tour of GrabDuck",
        "help.howTo": "How to",
        "help.howTo.importBookmarks": "Import Bookmarks",
        "help.howTo.savingBookmarks": "Saving Bookmarks",
        "help.howTo.searchingBookmarks": "Searching Bookmarks",
        "help.howTo.showResultsNextToISE": "Show results next to Search Engine",
        "help.import.bookmarks.chrome.short": "Import from Google Chrome",
        "help.import.bookmarks.firefox.short": "Import from Mozilla Firefox",
        "help.googleChromeExtension": "Google Chrome Extension",
        "help.faq.seeFailed": "See all failed bookmarks",
        "help.faq.removeMany": "Remove many bookmarks",
        "help.faq": "FAQ",
        "help.faq.full": "Questions and Answers",
        "help.faq.whatAreFixedLinks": "What are Permalinks",
        "help.selectTopic": "Please select a topic",

        "account.emailBookmarksTo": "Email Bookmarks",
        "account.paymentMethods": "Payment Methods",
        "account.checkout.billedAnnually": 'Billed annually',
        "account.checkout.billedOneTime": 'Billed one time',
        "account.notInAllCountries": 'Not available in all countries',
        "account.yearSubscription": 'One year membership',
        "account.checkout.failed": "Sorry, there were problems with creating a new Membership for you. Please try again later or contact us.",
        "account.checkout.monthly": "monthly",
        "account.bestValueSave": "Best value: save {saveValue}%!",
        "account.paypalWillBeCharged": 'Your PayPal account will be charged {totalPayment}. ',
        "account.subscriptionAutoRenew": 'This membership will automatically renew.',
        "account.checkout.paypal": 'Pay with PayPal',
        "account.cancelAnyTime": 'Cancel any time, no questions asked.',
        "account.autoFinishInYear": 'Automatically finishes in one year time.',
        "account.feeRefundableForThreeDays": "This fee is fully refundable for three days following signup.",
        "account.checkingOut": "Checking out...",
        "account.menu.summary": "Account Summary",
        "account.menu.membership": "Membership",
        "account.menu.settings": "Settings",
        "account.menu.importExport": "Import and Export",
        "account.menu.integrations": "Integrations",
        "account.currentPlan": "Current plan",
        "account.plan.free": "Grabduck Free",
        "account.plan.basic": "Grabduck Basic",
        "account.plan.basic2": "Grabduck Basic",
        "account.plan.admin": "Grabduck Admin",
        "account.plan.demo": "Grabduck Demo",
        "account.plan.free.description": "Your GrabDuck Free membership is currently active. The membership is totally free and has no expiration date. But for better user experience we would recommend you to upgrade your Membership to enjoy new great features!",
        "account.plan.demo.description": "GrabDuck Demo membership is currently active. This is common account created only for demonstration purposes. It has a lot of restrictions and is not supposed to be used for regular work. So for better user experience we would recommend you to logout from this demo account and create a new regular one instead!",
        "account.activateAccount": "Activate Account",
        "account.setupIntegrations": "Setup Integrations",
        "account.installExtensions": "Install Extensions",
        "account.readHelp": "Read Help",
        "account.startMembership": "Start Membership",
        "account.accountLevel": "Account Level",
        "account.usageStatistics": 'Usage Statistics',
        "account.memberSince": 'Member Since',
        "account.changePassword": 'Change password',
        "account.deleteAccount": 'Delete Account',
        "account.upgradeSubscription": 'Upgrade Membership',
        "account.dontForgetExportBookmarksBeforeRemoveAccount": "Before removing your account: don't forget to download a copy of your bookmarks for safe-keeping or to transfer later into your browser.",
        "account.youCanDoThisOn": "You can do this on",
        "account.page": "page",
        "account.currentPassword": 'Current Password',
        "account.newPassword": 'New password',
        "account.neverWantTrapPeople": "We never want to trap people inside GrabDuck. While we're sorry when people decide to cancel account, we never make this process confusing.",
        "account.doForRefund": "Hey, it looks like you are still in your initial three days refundable period. If you're removing your account now we do not want to keep your money either. Please feel free to cancel your payment and refund your money back (this is a separate process you should do it manually). In case of any troubles with that do not hesitate to contact us.",
        "account.delete.yesWantDeleteAccount": "Yes, I want to delete My Account",
        "account.delete.confidentDeleteAccount": "Yes, I am fully confident, please delete My Account",
        "account.deletingAccount": "Deleting your account...",
        "account.errorWhileDeletingAccount": "Error while deleting your account. Please try again later.",
        "account.bookmarksPrivacy": "Bookmark's Privacy",
        "account.tagsDiscovery": 'Tags discovery',
        "account.recommendations": 'Recommendations',
        "account.setupKindle": 'Setting up your Kindle',
        "account.userLangs": 'User languages',
        "account.bookmarksPrivacy.privateByDefault": "Make all your new bookmarks private by default",
        "account.bookmarksPrivacy.privateByDefault.desc": "While saving new bookmarks GrabDuck will mark them as private, so no one will see them but you. Please pay attention that all already existed bookmarks stay in their existing state: private or public.",
        "account.bookmarksPrivacy.privateByDefault.notAllowed": "Your current Account Level does not allow you to make your bookmarks private. Please consider upgrading your Account Level!",
        "account.settings.applyAutoGeneratedTags": 'Apply automatically discovered tags for new bookmarks',
        "account.settings.applyAutoGeneratedTags.desc": 'While saving new bookmarks GrabDuck tries to discover and suggest possible tags for the bookmark. If you are not happy with tags GrabDuck provides or you would like to have full control under your bookmarks, clear this checkbox.',
        "account.settings.howManyRecosShow": 'How many recommendations should be shown: {recoPercentage}%',
        "account.settings.howManyRecosShow.desc": "When showing results of your searches Grabduck display recommendations. These are other user's bookmarks which might be interesting for you. The number of recommendations could be defined here.",
        "account.settings.setupKindle.desc": "You can send Reader content directly to you Kindle device and read the article you need at the time which better suits you.",
        "account.settings.kindle.onAmazonGoto": 'On Amazon go to the ',
        "account.settings.kindle.manageDevicesScreen": 'Manage Your Content and Devices screen',
        "account.settings.kindle.findPersonalDocSettings": "Under the Settings tab, scroll down to find the Personal Document Settings section.",
        "account.settings.kindle.addApprovelEmail": "Add grabduck@gmail.com to your Approved Personal Document E-Mail List.",
        "account.settings.kindle.findKindleEmail": "Find the email address of your Kindle device under Send-to-Kindle E-Mail Settings and enter it below.",
        "account.settings.kindle.email": "Send-to-Kindle E-Mail (not publicly available)",
        "account.settings.grabduckEmail": "Your GrabDuck Email (not publicly available)",
        "account.settings.grabduckEmail.desc": "Email your bookmarks directly into your account. If you receive spam in your account, generate a new incoming email address. GrabDuck will send you a confirmation email.",
        "account.settings.userLangs.desc": 'When showing recommendations Grabduck takes into account which languages are undestandable for you. If something is not correct, and you have been shown recommendations you do not understand, you can remove these languages from the list above.',
        "account.exportBookmarks": 'Export / Backup Bookmarks',
        "account.selectBookmarksFile": 'Select HTML document with bookmarks or just drop the file into area below (max 5MB)',
        "account.importBookmarks.desc": 'This feature allows you to put all your previously stored elsewhere bookmarks into service, so they are available for your search and use on GrabDuck.',
        "account.exportBookmarks.desc": 'Download copy of your bookmarks for safe-keeping or to transfer into your browser and then save it on your local machine.',
        "account.downloadBookmarks": 'Download Bookmarks',
        "account.errorWhileDownloadingBookmarks": "Error while downloading bookmarks. Please try again later.",
        "account.downloadingBookmarks": "Downloading Your Bookmarks...",
        "account.errorWhileImportingBookmarks": "Error while importing bookmarks. Please try again later.",
        "account.uploadingBookmarks": "Uploading Bookmarks...",
        "account.bookmarksImportFinished": 'Bookmarks import started. Found {count} bookmarks.',
        "account.currentLevel": "Current Level",
        "account.billingInfo": 'Billing Information',
        "account.invoices": "Invoices",
        "account.subEndOn": 'Your membership ends on ',
        "account.buyNewToProceed": 'After that if you would like to proceed you will need to apply for another Membership of your choice.',
        "account.subWillBeRenewed": 'After that it will be automatically renewed for next period.',
        "account.payForSub": 'Payment for your membership ',
        "account.period.annually": 'a year',
        "account.period.monthly": 'a month',
        "account.willBeTaken": ' will be taken on ',
        "account.cancelSubscription": 'Cancel Membership',
        "account.subscription.viewInvoice": 'View Invoice',
        "account.subscription.cancelation": 'Membership cancelation',
        "account.subscription.cancelation.text": 'Do you want to cancel your current Membership?',
        "account.subscription.cancelation.yes": 'Yes, cancel my Membership',
        "account.subscription.cancelation.no": 'No, keep my Membership',
        "account.subscription.cancelling.error": "Error while cancelling your subscription. Please try again later.",
        "account.subscription.cancelling": "Cancelling your Subscription...",
        "account.subscription.wasCanceled": 'Your Membership has been canceled on ',
        "account.stillHaveBenefits": 'After cancellation you still can use all benefits of your current membership until it ends.',
        "account.subscription.noAutorenewOccurs": 'Then no charging will occur and your level will be switched to Grabduck Free.',
        "account.billingInfo.fullName": 'Full name',
        "account.billingInfo.fullAddress": 'Full address',
        "account.billingInfo.country": 'Country',
        "account.invoices.nothingFound": "No Invoices",
        "account.invoices.tableHeader.id": "Id",
        "account.invoices.tableHeader.type": "Type",
        "account.invoices.tableHeader.start": "Start",
        "account.invoices.tableHeader.finish": "Finish",
        "account.invoices.tableHeader.status": "Status",
        "account.invoices.tableHeader.invoice": "Invoice",
        "account.subscriptionTypes.basic_annually": "GrabDuck Basic Annually",
        "account.subscriptionTypes.basic_monthly": "GrabDuck Basic Monthly",
        "account.subscriptionTypes.basic_onetime": "GrabDuck Basic One time for a year",
        "account.subscriptionStatuses.active": "Active",
        "account.subscriptionStatuses.pending": "Pending",
        "account.subscriptionStatuses.cancelled": "Cancelled",
        "account.subscriptionStatuses.terminated": "Terminated",
        "account.tariff.free": "Free",
        "account.tariff.oneDollar": "USD 0.99 / mo (Billed annually)",
        "account.tariff.details.bookmarks": "Bookmarks to save",
        "account.tariff.details.integrations": "Integrations with third-party services",
        "account.tariff.details.fulltext.search": "Fulltext search on bookmarks",
        "account.tariff.details.tags": "Tags and websites clouds",
        "account.tariff.details.related.content": "Show related content",
        "account.tariff.details.faster.integration": "Faster Integrations updating",
        "account.tariff.details.reader": "Keeping an archive of article content (if possible)",
        "account.tariff.details.privateBookmarks": "Making bookmarks private",
        "account.tariff.details.promotingEmails": "Receiving promoting emails about the service",
        "account.sync.newService": 'Add new service',
        "account.sync.configuredServices": 'Configured services',
        "account.sync.freeSpots": "Free integration's spots: {spots}",
        "account.sync.firstStepsDesc": 'Set up automatic integration with dozens of third-party services such as StackOverflow, and get access to the full text search on your saved documents and favorites from the services at Grabduck.',
        "account.sync.addFirstIntegration": "Configure Your First Integration",
        "account.sync.never": "Never",
        "account.sync.lastUpdated": 'Last checked: {date}',
        "account.sync.status.active": 'OK',
        "account.sync.status.failed": 'Failed',
        "account.sync.status.stopped": 'Stopped',
        "account.sync.status.disabled": 'Paused',
        "account.sync.savingFailed": "Sorry, there were problems with saving your integration. Please try again later or contact us.",
        "account.tariff.youAreHere": "You are here",
        "account.tariff.subscribe": "Subscribe",
        "account.sync.newTag": "+tag",
        "account.sync.applyTags": "Automatically apply tags to synchronized bookmarks (optional)",
        "account.sync.isActive": 'Integration is active',
        "account.sync.providers.youtube.desc": "This feature allows you to integrate all your previously stored Videos from a publicly available or unlisted YouTube List.",
        "account.sync.listIdOrUrl": "List Id or List url",
        "account.sync.enterListIdOrUrl": "Enter List Id or List url",
        "account.sync.providers.youtube.howFindListId": "How do I find my List Id on YouTube website?",
        "account.sync.providers.youtube.openYoutubeList": "Open Your YouTube List Page (list should be publicly available)",
        "account.sync.providers.youtubeCopyListUrl": "When you're on your youtube list, copy the whole url and paste it in the text box.",
        "account.sync.providers.youtube.validationFailed": "Seems your Youtube List ID is not correct. Please check if the List itself is publicly available or unlisted.",
        "account.sync.providers.habr.desc": "This feature allows you to integrate all your previously stored Favorites from Habrahabr or Geektimes website.",
        "account.sync.providers.habr.howFindUserId": 'How do I find my User name on Habrahabr website?',
        "account.sync.providers.habr.help1": "Open Your Profile Page",
        "account.sync.providers.habr.help2": "When you're on your profile page, copy the whole url and paste it in the text box.",
        "account.sync.providers.habr.userOrProfile": 'User name or Profile url',
        "account.sync.providers.habr.enterUserOrProfile": 'Enter User name or Profile url',
        "account.sync.providers.habr.validationFailed": "Seems your Habr User name is not correct. Please check if the User name you provided is correct.",
        "account.sync.providers.stackexchange.howFindUserId": "How do I find my User ID on a StackExchange website?",
        "account.sync.providers.stackexchange.help1": "Open Your Profile Page",
        "account.sync.providers.stackexchange.help2": "When you're on your profile page, copy the whole url and paste it in the text box.",
        "account.sync.providers.stackexchange.desc": "This feature allows you to integrate all your previously stored Favorites from StackExchange website (for example from StackOverflow).",
        "account.sync.providers.stackexchange.userIdOrProfile": 'User ID (number) or Profile url',
        "account.sync.providers.stackexchange.enterUserIdOrProfile": 'Enter User ID or Profile url',
        "account.sync.providers.stackexchange.validationFailed": "Seems your StackExchange User Number is not correct. Please check if the User Number you provided is correct.",
        "account.sync.providers.slideshare.desc": "This feature allows you to integrate all your previously stored Favorites from SlideShare website.",
        "account.sync.providers.slideshare.validationFailed": "Seems your SlideShare User name is not correct. Please check if the User name you provided is correct.",
        "account.sync.providers.slideshare.howFindUserId": "How do I find my User name on SlideShare website?",
        "account.sync.providers.rss.desc": "This feature allows you to integrate RSS feeds.",
        "account.sync.providers.rss.url": "RSS feed url",
        "account.sync.providers.rss.enterUrl": "Enter RSS feed url",
        "account.sync.providers.rss.validationFailed": "Seems your RSS Feed url is not correct. Please check if you have provided correct data.",
        "account.sync.delete.title": 'Delete integration',
        "account.sync.delete.desc": 'Do you want to delete this integration?',
        "account.sync.deletingIntegration": "Deleting Integration...",
        "account.sync.errorWhileDeleting": "Sorry, there were problems while deleting your integration. Please try again later or contact us.",
        "account.sync.successfullyDeleted": "Integration {integration} was successfully deleted.",
        "account.sync.reachedAccountLimit": "You have reached your account limits. It is not possible to configure new Integration. Please consider upgrading your Account Level!",

        "account.lang.af": 'Afrikaans',
        "account.lang.ar": 'Arabic',
        "account.lang.bg": 'Bulgarian',
        "account.lang.bn": 'Bengali',
        "account.lang.cs": 'Czech',
        "account.lang.da": 'Danish',
        "account.lang.de": 'German',
        "account.lang.el": 'Greek',
        "account.lang.en": 'English',
        "account.lang.es": 'Spanish',
        "account.lang.et": 'Estonian',
        "account.lang.fa": 'Persian',
        "account.lang.fi": 'Finnish',
        "account.lang.fr": 'French',
        "account.lang.gu": 'Gujarati',
        "account.lang.he": 'Hebrew',
        "account.lang.hi": 'Hindi',
        "account.lang.hr": 'Croatian',
        "account.lang.hu": 'Hungarian',
        "account.lang.id": 'Indonesian',
        "account.lang.it": 'Italian',
        "account.lang.ja": 'Japanese',
        "account.lang.kn": 'Kannada',
        "account.lang.ko": 'Korean',
        "account.lang.lt": 'Lithuanian',
        "account.lang.lv": 'Latvian',
        "account.lang.mk": 'Macedonian',
        "account.lang.ml": 'Malayalam',
        "account.lang.mr": 'Marathi',
        "account.lang.ne": 'Nepali',
        "account.lang.nl": 'Dutch',
        "account.lang.no": 'Norwegian',
        "account.lang.pa": 'Punjabi',
        "account.lang.pl": 'Polish',
        "account.lang.pt": 'Portuguese',
        "account.lang.ro": 'Romanian',
        "account.lang.ru": 'Russian',
        "account.lang.sk": 'Slovak',
        "account.lang.sl": 'Slovene',
        "account.lang.so": 'Somali',
        "account.lang.sq": 'Albanian',
        "account.lang.sv": 'Swedish',
        "account.lang.sw": 'Swahili',
        "account.lang.ta": 'Tamil',
        "account.lang.te": 'Telugu',
        "account.lang.th": 'Thai',
        "account.lang.tl": 'Tagalog',
        "account.lang.tr": 'Turkish',
        "account.lang.uk": 'Ukrainian',
        "account.lang.ur": 'Urdu',
        "account.lang.vi": 'Vietnamese',
        "account.lang.zh-cn": 'Chinese (cn)',
        "account.lang.zh-tw": 'Chinese (tw)',

        "main.menu.inbox": "Inbox",
        "main.menu.history": 'Browsing History',
        "main.menu.favorites": "Favorites",
        "main.menu.tagsCloud": "Tags",
        "main.menu.websitesCloud": 'Websites',
        "main.menu.sourcesCloud": 'Sources',
        "main.welcomeAlert.inbox.info": "Here are your saved bookmarks ready for action - consider this your to-do list! Once you have finished with a bookmark, you can mark it as ‘Done’. Need to come back to it soon? ‘Flag’ it for faster access in the future.",
        "main.welcomeAlert.inbox.hint": "Try to keep this list as empty as you can. This means you have finished everything you needed to do and you can now have a cup of coffee as a reward!",
        "main.welcomeAlert.history.info": "Here are all the articles you have recently visited. Have something compromising in your list? Simply use the ‘Delete from history’ button to make is disappear!",
        "main.welcomeAlert.history.hint": "Did you open an interesting recommendation but forgot to save it in GrabDuck? No problem - You can find it here in your browsing history. Don’t forget to save it this time!",
        "main.welcomeAlert.favorites.info": "Here is all the treasure you have collected! Why not try using our Filter buttons? They can help you find those bookmarks that still require tagging or show the bookmarks that are no longer alive on the web!",
        "main.welcomeAlert.favorites.hint": "Look from time to time at your ‘Failed’ bookmarks, perhaps the webpage you previously saved is no longer alive - remove it to make your collection cleaner.",
        "main.noBookmarksDesc": 'In this area bookmarks from your inbox, favorites, history or search results are being displayed.',
        "main.reader": 'Reader',
        "main.play": "Play",
        "main.internetAddress": "Web Address",
        "main.addToFavs": "Add to Favorites",
        "main.fixedLink": "Permalink",
        "main.fixedLinkBookmark": "Permalink Bookmark",
        "main.fixedLinkBookmark.created": "A new permalink bookmark was successfully created.",
        "main.fixedLinkBookmark.failed": "Sorry, there were problems with creating a new permalink bookmark. Please try again later or contact us.",
        "main.removeFromFavs": "Delete from Favorites",
        "main.moveToInbox": "Move to Inbox",
        "main.removeFromInbox": "Remove from Inbox",
        "main.flagBookmark": 'Flag',
        "main.unflagBookmark": 'Unflag',
        "main.markAsDone": "Mark as Done",
        "main.share": "Share",
        "main.shareBookmark": "Share Bookmark",
        "main.wipeBookmark": "Shred",
        "main.shreddingBookmark": "Shredding the bookmark",
        "main.shreddingRequest": "Are you sure that you want to permanently delete the bookmark from GrabDuck?",
        "main.makeBookmarkVisible": "Make Public",
        "main.makeBookmarkPrivate": "Make Private",
        "main.removeFromHistory": "Remove from History",
        "main.refreshBookmark": "Sync with original",
        "main.editBookmark": "Edit Bookmark",
        "main.clickForCopyingLink": 'Copy the link',
        "main.linkCopied": 'Link is copied',
        "main.historyRemoved": 'Removed from history',
        "main.bookmarkMadePrivate": 'Marked as Private',
        "main.bookmarkMadePublic": 'Marked as Public',
        "main.bookmarkFlagged": 'Flagged',
        "main.bookmarkUnflagged": 'Unflagged',
        "main.favoriteAdded": 'Added to Favorites',
        "main.favoriteRemoved": 'Removed from Favorites',
        "main.markedDone": "Marked as Done",
        "main.removedFromInbox": "Removed from Inbox",
        "main.addedToInbox": 'Added to Inbox',
        "main.updateTaskStarted": 'Update task started',
        "main.resultsFound": "Results found: ",
        "main.selected": " selected",
        "main.selection": "Select",
        "main.selection.all": "All",
        "main.selection.none": "None",
        "main.selection.revert": "Revert",
        "main.selection.action": "Action",
        "main.bulkEditBookmark": "Bulk Edit",
        "main.multipleOps.favsRemoval.finished": 'Removed from Favorites',
        "main.multipleOps.error": "There was an error during the operation",
        "main.multipleOps.favsAdd.finished": 'Added to Favorites',
        "main.multipleOps.favsToInbox.finished": "Added to Inbox",
        "main.multipleOps.favsFromInbox.finished": "Marked as Done",
        "main.newBookmark.installExt": "For better user experience in adding new bookmarks we recommend you to install appropriate extension - ",
        "main.recommendedTags": 'Recommended Tags',
        "main.recommendedTags.hints": 'Click to assign the tag for the bookmark.',
        "main.originalLink": "Original Link",
        "main.favoritesFilter.all": 'All',
        "main.favoritesFilter.untagged": 'Untagged',
        "main.favoritesFilter.failed": 'Failed',
        "main.favoritesFilter.public": 'Public',
        "main.favoritesFilter.private": 'Private',
        "main.tags.empty.title": 'No Tags',
        "main.tags.empty.desc": 'Tags cloud helps you show only web pages and links which are marked with specific tags.',
        "main.websites.empty.title": 'No Websites',
        "main.websites.empty.desc": 'Websites cloud helps you show web pages and links exactly from one specific website.',
        "main.sources.empty.title": 'No Sources',
        "main.sources.empty.desc": 'Sources cloud helps you show where each bookmark came from, so it\'s easy to view a list of your bookmarks filtered by source.',
        "main.spellcheck.showResult": "Showing results for ",
        "main.spellcheck.searchInstead": "Search instead for ",
        "main.details": "Details",
        "common.addedToReadLater": "Added to Inbox",
        "main.addedToFavorited": "Added to Favorites",
        "main.bookmarkStatus.extracted": "Searchable",
        "main.bookmarkStatus.to_extraction": "Waiting for refresh",
        "main.bookmarkStatus.failed": "Not available",
        "main.bookmarkStatus.to_process": "Waiting to process",

        "main.resultInOtherSections": "Results in other sections",
        "main.searchQuerySuggestions": "Search Query Suggestions",
        "main.sourcesSuggestions": "Sources Suggestions",
        "main.websitesSuggestions": "Websites Suggestions",
        "main.tagsSuggestions": "Tags Suggestions",

        "main.createFixedLinkBookmark": "Create a permalink bookmark",

        "reader.readabilityOriginal": "Original",
        "reader.readability.readingTime": "Reading time",
        "reader.readability.minutes": " minutes",
        "reader.kindle.send": "Send to Kindle",
        "reader.kindle.notRegistered": "Reader can send beautifully formatted articles to your Kindle device with one click. You only have to create an Account on ",
        "reader.kindle.notConfigured": 'Once configured with Amazon, Reader can send beautifully formatted articles o your Kindle device with one click. You only have to complete this process once',
        "reader.kindle.setup": "Setup my Kindle",
        "reader.kindle.sending": "Sending to Your Kindle device...",
        "reader.kindle.articleWasSent": 'This article was successfully sent to your Kindle device!',
        "reader.kindle.articleWasSent.failed": "Sorry, there were problems with sending the article to your Kindle device. Please try again later or contact us.",
        "reader.config.textSize": "Text size",
        "reader.config.typography": "Typography",
        "reader.config.textSize.small": "Small",
        "reader.config.textSize.medium": "Medium",
        "reader.config.textSize.large": "Large",
        "reader.config.typography.serif": "Serif",
        "reader.config.typography.sans": "Sans",
        "reader.kindle.articleWasAddedToHistory": 'This Article was added to History',
        "reader.footerMessage": " is bookmarking service that offers genuine full text search for your bookmarks.",

        "addBookmark.saveToGrabDuck": "Save to GrabDuck",
        "addBookmark.newBookmark": "New Bookmark",
        "addBookmark.enterWebAddress": "Enter Web Address",
        "addBookmark.addBookmark": "Add Bookmark",
        "addBookmark.editBookmark": "Edit Bookmark",
        "addBookmark.wait": "Wait please...",
        "addBookmark.newBookmarkAdded": "New page was successfully added",
        "addBookmark.bookmarkUpdated": "Bookmark was successfully updated",
        "addBookmark.bookmark.adding": "Adding new Bookmark...",
        "addBookmark.bookmark.updating": "Updating the Bookmark...",
        "addBookmark.bookmark.failed": "Sorry, there were problems with adding new bookmark. Please try again later or contact us.",
        "addBookmark.bookmark.updateFailed": "Sorry, there were problems with saving your changes. Please try again later or contact us.",

        "search.showOnlyFlagged": "Show only Flagged Bookmarks",
        "search.helpSearchWithinTag": "search within a tag",
        "search.helpSearchWithinWebsite": "search from a website",
        "search.helpSearchWithinSource": "search from a source",
        "search.helpSearchForPhrase": "search for a phrase",

        "advancedSearchBox.title": "Press Enter to start a new search",
        "advancedSearchBox.searchHelp": "Search help"
    }
};

export default i18n;
